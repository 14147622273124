import { MB_Button } from '@workingu/rnw.components.button';
import { mbTextStyles } from '@workingu/rnw.utils.style-utils';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { StyleSheet, Text } from 'react-native';
import { COLORS } from '../../../constants/colors';
import { textStyles } from '../../../constants/textStyles';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { ResetPasswordProps } from '../../../typesAndInterfaces/componentProps';
import { useChangePasswordRecoveryCode } from '../../../hooks/userHooks';
import { STRING_CONSTANTS } from '../../../constants/constants';
import { MB_TextInput, MB_TextInputToolTipPortal, MB_TextInputToolTipPortalRef } from '@workingu/rnw.components.text-input';
import { utils } from '../../../utils/utils';
import { MB_passwordUtils } from '@workingu/rnw.utils.password-utils';
import { MB_PasswordToolTip, TOOLTIP_ARROW_DIRECTION, TOOLTIP_POSITION } from '@workingu/rnw.components.tool-tip';
import { mbShowToast } from '@workingu/rnw.components.toast';

const ResetPassword = ({ route, navigation }: ResetPasswordProps) => {

    const { mutate: changePasswordRecoveryCode, isLoading: isChangePasswordRecoveryCodeLoading } = useChangePasswordRecoveryCode()

    const [password, setPassword] = useState('');
    const [passwordErrors, setPasswordErrors] = useState<{ message: string, isError: boolean }[]>(utils.generatePasswordErrorArray(MB_passwordUtils.validatePassword('').errorArray, true));
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordErrors, setConfirmPasswordErrors] = useState<{ message: string, isError: boolean }[]>(utils.generatePasswordErrorArray(MB_passwordUtils.validatePassword('').errorArray, true));
    const portalRef = useRef<MB_TextInputToolTipPortalRef>(null);

    const onPasswordChange = useCallback((text: string) => {
        setPasswordErrors(utils.generatePasswordErrorArray(MB_passwordUtils.validatePassword(text).errorArray, text === confirmPassword));
        setConfirmPasswordErrors(utils.generatePasswordErrorArray(MB_passwordUtils.validatePassword(confirmPassword).errorArray, text === confirmPassword));
        setPassword(text);
    }, [confirmPassword]);

    const onConfirmPasswordChange = useCallback((text: string) => {
        setConfirmPasswordErrors(utils.generatePasswordErrorArray(MB_passwordUtils.validatePassword(text).errorArray, text === password));
        setPasswordErrors(utils.generatePasswordErrorArray(MB_passwordUtils.validatePassword(password).errorArray, text === password));
        setConfirmPassword(text);
    }, [password]);

    const errorMessage = useMemo(() => {
        const validatePassword = MB_passwordUtils.validatePassword(password);
        if (validatePassword.errorMessage) {
            return validatePassword.errorMessage;
        }

        const validateRepeatPassword = MB_passwordUtils.validatePassword(confirmPassword);
        if (validateRepeatPassword.errorMessage) {
            return validateRepeatPassword.errorMessage;
        }

        if (password !== confirmPassword) {
            return STRING_CONSTANTS.PASSWORDS_DO_NOT_MATCH;
        }
    }, [confirmPassword, password]);

    const onSubmit = useCallback(() => {
        const params = route.params;
        changePasswordRecoveryCode({
            email: params.email,
            code: params.code,
            newPassword: password
        }, {
            onError: (error) => mbShowToast({ type: 'error', text1: error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN, position: 'bottom' }),
            onSuccess: () => {
                mbShowToast({ text1: 'Password reset successfully', text2: '', position: 'bottom' })
                navigation.reset({
                    index: 0,
                    routes: [
                        { name: 'MobileLandingPage' },
                        { name: 'Login' }
                    ]
                });
            },
        })
    }, [changePasswordRecoveryCode, navigation, password, route.params]);

    return (
        <ComponentWrapper mobileHeaderOptions={{ showHeader: true, showBackArrow: true }}>
            <Text style={[textStyles.largeText, styles.title]}>Reset Password</Text>
            <Text style={[textStyles.smallerText, styles.subTitle]}>Please enter your new password below.</Text>
            <Text style={styles.inputTitle}>New Password <Text style={styles.required}>*</Text></Text>
            <MB_TextInput
                placeholder="Create a password"
                value={password}
                onChangeText={onPasswordChange}
                secureTextEntry
                toolTipData={passwordErrors && portalRef ? {
                    showOnFocus: true,
                    toolTipPosition: TOOLTIP_POSITION.top,
                    moveBy: {
                        x: 0,
                        y: 220,
                    },
                    toolTipElement: <MB_PasswordToolTip errorArray={passwordErrors} arrowDirection={TOOLTIP_ARROW_DIRECTION.up} arrowColor={COLORS.greyBG} containerStyle={styles.tooltip} />,
                    portalRef: portalRef,
                } : undefined}
                placeholderTextColor={COLORS.greyText}
                isError={password !== '' && passwordErrors.some(error => error.isError)}
            />
            <Text style={styles.inputTitle}>Confirm New Password <Text style={styles.required}>*</Text></Text>
            <MB_TextInput
                placeholder="Confirm new password"
                value={confirmPassword}
                onChangeText={onConfirmPasswordChange}
                secureTextEntry
                toolTipData={confirmPasswordErrors && portalRef ? {
                    showOnFocus: true,
                    toolTipPosition: TOOLTIP_POSITION.top,
                    moveBy: {
                        x: 0,
                        y: -60,
                    },
                    toolTipElement: <MB_PasswordToolTip errorArray={confirmPasswordErrors} arrowDirection={TOOLTIP_ARROW_DIRECTION.down} arrowColor={COLORS.greyBG} containerStyle={styles.tooltip} />,
                    portalRef: portalRef,
                } : undefined}
                placeholderTextColor={COLORS.greyText}
                isError={confirmPassword !== '' && confirmPasswordErrors.some(error => error.isError)}
            />
            {password !== ''  && errorMessage && <Text style={[styles.passwordNote, styles.passwordNoteError]}>{errorMessage}</Text>}
            <MB_Button
                title="Reset password"
                style={styles.resetPasswordBtn}
                textStyle={textStyles.smallerText}
                loading={isChangePasswordRecoveryCodeLoading}
                disabled={errorMessage !== undefined || isChangePasswordRecoveryCodeLoading}
                onPress={onSubmit}
            />
            <MB_TextInputToolTipPortal MB_Ref={portalRef} />
        </ComponentWrapper>
    );
};

export { ResetPassword };

const styles = StyleSheet.create({
    title: {
        color: COLORS.headerText,
        textAlign: 'left',
        letterSpacing: -0.8,
    },
    subTitle: {
        color: COLORS.greyText,
        textAlign: 'left',
        marginBottom: 9,
    },
    inputTitle: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.bodyText,
        marginTop: 15,
        marginBottom: 7,
        textAlign: 'left',
    }]),
    required: {
        color: COLORS.red,
    },
    passwordNote: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.bodyText,
        marginTop: 5,
        textAlign: 'left',
    }]),
    passwordNoteError: {
        color: COLORS.red,
    },
    resetPasswordBtn: {
        marginTop: 37,
        height: 50,
        backgroundColor: COLORS.primaryColor,
        borderRadius: 30,
    },
    tooltip: {
        marginTop: -150,
        backgroundColor: COLORS.greyBG,
    }
});
