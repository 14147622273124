import { MB_Button } from "@workingu/rnw.components.button";
import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import React, { ReactChild, useCallback } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import Feather from "react-native-vector-icons/Feather";
import Ionicons from "react-native-vector-icons/Ionicons";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import { COLORS } from "../../../constants/colors";
import { textStyles } from "../../../constants/textStyles";
import { ComponentWrapper } from "../../helperComponents/componentWrapper/ComponentWrapper";
import Entypo from "react-native-vector-icons/Entypo";
import { UniversalScreenNavigationProp } from "../../../typesAndInterfaces/componentProps";
import { useNavigation } from "@react-navigation/core";
import { useSignedInContext } from "../../../context/SignedInContext";
import { getVersionNumber } from "../../../utils/getVersionNumber/getVersionNumber";
import { envs } from "../../../../env";
import { useGetServerVersion } from "../../../hooks/serverUtilHooks";
import { useGetContractorData, useGetEmployeeData } from "../../../hooks/userHooks";
import { MB_Image, mbGetSmallestImage } from "@workingu/rnw.components.image";
import { imageUtils } from "../../../utils/imageUtils";
import { MB_utilHooks } from "@workingu/rnw.utils.util-hooks";
import { LogoutModal } from "../../modals/LogoutModal";
import { UserRole } from "@wu/business";

const versions = getVersionNumber();

interface ButtonProps {
    icon: ReactChild
    title: string,
    showArrow?: boolean,
    onPress?: () => void
}

const ProfileButtons = ({ icon, title, showArrow = true, onPress }: ButtonProps) => {
    return (
        <TouchableOpacity style={styles.button} onPress={onPress}>
            <View style={styles.buttonIcon}>
                {icon}
            </View>
            <Text style={styles.buttonTitle}>{title}</Text>
            {showArrow && <Feather name="chevron-right" color={COLORS.greyText} size={20} />}
        </TouchableOpacity>
    )
}

const Profile = () => {
    const { data: serverVersion } = useGetServerVersion();
    const navigation = useNavigation<UniversalScreenNavigationProp>();
    const { currentUserData } = useSignedInContext();
    const [isLogoutModalVisible, showLogoutModal, hideLogoutModal] = MB_utilHooks.useBool();



    const { data: employeeData } = useGetEmployeeData({ enabled: currentUserData?.role === UserRole.Employee });
    const { data: contractorData } = useGetContractorData({ enabled: currentUserData?.role === UserRole.Contractor });

    const onLogOut = useCallback(() => {
        showLogoutModal();
    }, [showLogoutModal]);

    const name = currentUserData?.role === UserRole.Employee ? `${employeeData?.firstName} ${employeeData?.lastName}` : contractorData?.name;
    const email = currentUserData?.role === UserRole.Employee ? employeeData?.email : contractorData?.email;

    const profileImageUrl = mbGetSmallestImage(currentUserData?.role === UserRole.Employee ? employeeData?.profileImage : contractorData?.profileImage) ?? imageUtils.images.emptyProfileIcon;

    return (
        <ComponentWrapper>
            <LogoutModal isVisible={isLogoutModalVisible} onDismiss={hideLogoutModal} />
            <Text style={[textStyles.largeText, styles.title]}>Profile</Text>
            <View style={styles.profileCard}>
                <MB_Image source={profileImageUrl} style={styles.profileImage} resizeMode='contain' disableInteraction />
                <Text style={styles.name}>{name}</Text>
                <Text style={styles.email}>{email}</Text>
                <MB_Button
                    title="Edit profile"
                    style={styles.editProfileBtn}
                    textStyle={textStyles.smallerText}
                    onPress={() => navigation.navigate('EditProfile')}
                />
            </View>
            <ProfileButtons title="Contact us" icon={<Feather name="headphones" size={20} color={COLORS.primaryColor} />} onPress={() => navigation.navigate('ContactUs')} />
            <ProfileButtons title="Terms of service" icon={<Entypo name="text-document" size={18} color={COLORS.primaryColor} />} />
            <ProfileButtons title="Privacy policy" icon={<MaterialIcons name="policy" size={20} color={COLORS.primaryColor} />} />
            <ProfileButtons title="Log out" icon={<Ionicons name="exit" size={18} color={COLORS.red} />} showArrow={false} onPress={onLogOut} />


            <View style={{ marginTop: 'auto', marginBottom: 80, alignSelf: 'flex-end' }}>
                <Text style={styles.versionInfoText}>JS Version: {versions.jsVersion}</Text>
                <Text style={styles.versionInfoText}>App Version: {`${versions.appVersion}(${versions.buildVersion})`}</Text>
                <Text style={styles.versionInfoText}>isDev: {__DEV__?.toString()}</Text>
                <Text style={styles.versionInfoText}>Flavor: {envs.FLAVOR}</Text>
                <Text style={styles.versionInfoText}>Server Version: {serverVersion}</Text>
            </View>
        </ComponentWrapper>
    )
};

export { Profile };

const styles = StyleSheet.create({
    title: {
        color: COLORS.headerText,
        textAlign: 'left',
        letterSpacing: -0.8,
        marginTop: 10,
    },
    profileCard: {
        backgroundColor: COLORS.lightOrange,
        paddingVertical: 22,
        marginTop: 20,
        marginBottom: 10,
        borderRadius: 15,
        alignItems: 'center',
        justifyContent: 'center',
    },

    name: mbTextStyles([textStyles.smallText, {
        color: COLORS.headerText,
        marginTop: 13,
    }]),
    email: mbTextStyles([textStyles.smallerText, {
        color: COLORS.primaryColor,
        marginTop: 2
    }]),
    editProfileBtn: {
        height: 39,
        backgroundColor: COLORS.primaryColor,
        borderRadius: 30,
        marginTop: 18,
        width: 150,
    },

    // Button
    button: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 25,
    },
    buttonIcon: {
        width: 40,
        height: 40,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: COLORS.lightOrange,
    },
    buttonTitle: mbTextStyles([textStyles.smallerText, {
        marginLeft: 14,
        marginRight: 'auto',
        color: COLORS.bodyText,
    }]),
    versionInfoText: {
        ...mbTextStyles({
            ...textStyles.tinyText,
            alignSelf: 'flex-start',
            color: COLORS.bodyText,
        }),
    },
    profileImage: {
        width: 75,
        height: 75,
        borderRadius: 300,
    },
});
