import {
  StatsResponseSchema,
  StatsResponse,
  StatsRequest,
  ContractorsRequest,
  EmployeesRequest,
  EmployeesResponse,
  ContractorsResponse,
  ContractorsRequestSchema,
  EmployeesRequestSchema,
} from '@wu/business';

import { Token } from '@workingu/rnw.utils.access-token-utils';

import { TokensObj } from '../utils/zod/zodObjects';
import { axiosCaller } from './customAxios';

const adminApiCall = {
  login: async (email: string, password: string): Promise<Token> => {
    return await axiosCaller({
      path: '/api/users/admin/login',
      method: 'post',
      responseDataKey: 'tokenPair',
      responseValidatorObj: TokensObj,
      responseFormatValidatorMessage: 'Data mismatch for login response',
      data: { email, password },
    });
  },
  getEmployeesData: async (params: EmployeesRequest): Promise<EmployeesResponse> => {
    return await axiosCaller({
      path: '/api/employees',
      method: 'get',
      responseValidatorObj: EmployeesRequestSchema,
      responseFormatValidatorMessage: 'Data mismatch for get employees response',
      passToken: true,
      params,
    });
  },
  getContractorsData: async (params: ContractorsRequest): Promise<ContractorsResponse> => {
    return await axiosCaller({
      path: '/api/contractors',
      method: 'get',
      responseValidatorObj: ContractorsRequestSchema,
      responseFormatValidatorMessage: 'Data mismatch for get contractors response',
      passToken: true,
      params,
    });
  },
  getStatsData: async (params: StatsRequest): Promise<StatsResponse> => {
    return await axiosCaller({
      path: '/api/stats',
      method: 'get',
      responseValidatorObj: StatsResponseSchema,
      responseFormatValidatorMessage: 'Data mismatch for get contractors response',
      passToken: true,
      params,
    });
  },
  deleteEmployee: async (id: string): Promise<Token> => {
    return await axiosCaller({
      path: `/api/employees/${id}`,
      method: 'delete',
      passToken: true,
      data: { id },
    });
  },
  deleteContractor: async (id: string): Promise<Token> => {
    return await axiosCaller({
      path: `/api/contractors/${id}`,
      method: 'delete',
      passToken: true,
      data: { id },
    });
  },
};

export { adminApiCall };
