import { mbTextStyles } from '@workingu/rnw.utils.style-utils';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { BaseToast, ErrorToast, ToastConfigParams } from 'react-native-toast-message';
import AntDesign from 'react-native-vector-icons/AntDesign';
import Entypo from 'react-native-vector-icons/Entypo';
import Feather from 'react-native-vector-icons/Feather';
import { COLORS } from '../../constants/colors';
import { textStyles } from '../../constants/textStyles';

const CorrectAnswerIcon = () => {
    return (
        <View style={styles.defaultIconWrapper}>
            <Feather name="check-circle" size={12} color={COLORS.white} />
        </View>
    );
};

const IncorrectAnswerIcon = () => {
    return (
        <View style={styles.defaultIconWrapper}>
            <Entypo name="cross" size={24} color={COLORS.white} />
        </View>
    );
};

const ErrorCheckbox = () => {
    return (
        <View style={styles.defaultIconWrapper}>
            <AntDesign name="closecircleo" size={10} color={COLORS.white} style={styles.iconStyles}/>
        </View>
    );
};

const CustomToastConfig = {
    correctAnswer: (props: ToastConfigParams<any>) => {
        const { text1, ...restOfProps } = props;
        return (
            <BaseToast
                style={styles.toastCorrectAnswerStyle}
                contentContainerStyle={styles.toastContentContainerStyle}
                text1Style={styles.text1Style}
                text2Style={styles.text2Style}
                renderLeadingIcon={CorrectAnswerIcon}
                text1={text1 ?? 'Correct!'}
                {...restOfProps}
            />
        );
    },
    incorrectAnswer: (props: ToastConfigParams<any>) => {
        const { text1, ...restOfProps } = props;
        return (
            <ErrorToast
                style={styles.toastIncorrectAnswerStyle}
                contentContainerStyle={styles.toastContentContainerStyle}
                text1Style={styles.text1Style}
                text2Style={styles.text2Style}
                renderLeadingIcon={IncorrectAnswerIcon}
                text1={text1 ?? 'Oops! Try again'}
                {...restOfProps}
            />
        );
    },
    success: (props: ToastConfigParams<any>) => {
        const { text1, text2, ...restOfProps } = props;
        return (
            <BaseToast
                style={styles.toastCorrectAnswerStyle}
                contentContainerStyle={styles.toastContentContainerStyle}
                text1Style={styles.text1Style}
                text2Style={styles.text2Style}
                renderLeadingIcon={CorrectAnswerIcon}
                text1={text1 ?? 'Success!'}
                text2={text2 ?? 'Updated successfully'}
                {...restOfProps}
            />
        );
    },
    error: (props: ToastConfigParams<any>) => {
        const { text1, text2, ...restOfProps } = props;
        return (
            <ErrorToast
                style={styles.toastIncorrectAnswerStyle}
                contentContainerStyle={styles.toastContentContainerStyle}
                text1Style={styles.text1Style}
                text2Style={styles.text2Style}
                renderLeadingIcon={ErrorCheckbox}
                text1={text1 ?? 'Failed!'}
                text2={text2}
                {...restOfProps}
            />
        );
    },
};

export { CustomToastConfig };

const baseStyles = StyleSheet.create({
    toastStyle: {
        borderStartWidth: 0,
        paddingHorizontal: 10,
        alignItems: 'center',
        height: undefined,
        paddingVertical: 5,
    },
});

const styles = StyleSheet.create({
    defaultIconWrapper: {
        width: 32,
        height: 32,
        alignItems: 'center',
        justifyContent: 'center',
        // backgroundColor: COLORS.white,
        // borderRadius: 300,
        // borderColor: COLORS.white,
        // borderWidth: 1,
    },
    iconStyles: {
        borderRadius: 300,
        borderColor: COLORS.white,
        borderWidth: 1,
    },
    text1Style: mbTextStyles([
        textStyles.smallerText, {
            lineHeight: undefined,
            textAlign: 'left',
            fontWeight: '700',
            marginStart: 0,
            marginLeft: 0,
            margin: 0,
            marginHorizontal: 0,
            alignSelf: 'flex-start',
        },
    ]),
    text2Style: mbTextStyles([
        textStyles.tinyText, {
            lineHeight: undefined,
            textAlign: 'left',
            fontWeight: '400',
            marginStart: 0,
            marginLeft: 0,
            margin: 0,
            marginHorizontal: 0,
            alignSelf: 'flex-start',
        },
    ]),
    toastCorrectAnswerStyle: StyleSheet.flatten([
        baseStyles.toastStyle, {
            backgroundColor: COLORS.toastGreen,
        },
    ]),
    toastIncorrectAnswerStyle: StyleSheet.flatten([
        baseStyles.toastStyle, {
            backgroundColor: COLORS.red,
        },
    ]),
    toastContentContainerStyle: {
        paddingStart: 8,
    },
});
