import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import { useNavigation } from "@react-navigation/core";
import React, { useCallback, useMemo, useState } from "react";
import { FlatList, StyleSheet, Text, View } from "react-native";
import { COLORS } from "../../../../constants/colors";
import { textStyles } from "../../../../constants/textStyles";
import { UniversalScreenNavigationProp } from "../../../../typesAndInterfaces/componentProps";
import { ComponentWrapper } from "../../../helperComponents/componentWrapper/ComponentWrapper";
import { RecommendedJobs } from "./RecommendedJobs";
import { useMBInfiniteFetch } from "@workingu/rnw.utils.util-hooks";
import { SachelForEmptyTable } from "../../../../resources/svgComponents/SachelForEmptyTable";
import { FlatListLoadingIndicator } from "../../../helperComponents/FlatListLoadingIndicator";
import { Job, JobsRequest } from "@wu/business";
import { useGetAllJobs, useGetRecommendedJobs } from "../../../../hooks/jobsHooks";
import { useRenderJobs } from "./renderJobs";
import { CustomHeader } from "../../../helperComponents/CustomHeader";

const TOTAL_RECOMMENDED_JOBS = 3;
const TOTAL_JOBS_PER_PAGE = 10;

const Explore = () => {
    const { renderJobs } = useRenderJobs()

    const [filter, setFilter] = useState<JobsRequest['filter']>();

    const getRecommendedJobs = useGetRecommendedJobs({ totalItemsPerPage: TOTAL_RECOMMENDED_JOBS, completed: "false", filter });
    const getAllJobs = useGetAllJobs({ totalItemsPerPage: TOTAL_JOBS_PER_PAGE, filter });

    const { data: recommendedJobs, isFetching: isFetchingRecommendations, isLoading: isLoadingRecommendations } = useMBInfiniteFetch(getRecommendedJobs, { prefillWhileLoading: true });
    const { data: jobs, isFetching, isLoading } = useMBInfiniteFetch(getAllJobs, { prefillWhileLoading: true });

    const recommendedJobsData: Job[] = useMemo(() => {
        if (!recommendedJobs || typeof recommendedJobs === 'string') {
            return [];
        }

        return recommendedJobs as Job[];
    }, [recommendedJobs]);


    const jobsData: Job[] = useMemo(() => {
        if (!jobs || typeof jobs === 'string') {
            return [];
        }

        return jobs as Job[];
    }, [jobs]);


    const navigation = useNavigation<UniversalScreenNavigationProp>();

    const ItemSeparatorComponent = useCallback(() => <View style={styles.separator} />, []);

    const keyExtractor = React.useMemo(() => (item: Job | string) => typeof item === 'string' ? item : item.id, []);

    const ListEmptyComponent = useMemo(() => (
        <View style={styles.emptyTableContainer}>
            <SachelForEmptyTable />
            <Text style={styles.emptyTableTitle}>No jobs Found</Text>
            <Text style={styles.emptyTableSubtitle}>There are currently no jobs. Once contractors post new jobs they will show up here.</Text>
        </View>
    ), []);

    const ListHeaderComponent = useMemo(() => (
        <View>
            <RecommendedJobs
                jobs={recommendedJobsData}
                isLoading={isFetchingRecommendations || isLoadingRecommendations}
            />
            {jobs.length > 0 &&
                <View style={styles.header}>
                    <Text style={styles.headerTitle}>All Jobs</Text>
                    <Text style={styles.headerViewAll} onPress={() => navigation.navigate('ViewAll', { type: "All" })}>View all</Text>
                </View>
            }
        </View>
    ), [recommendedJobsData, isFetchingRecommendations, isLoadingRecommendations, jobs.length, navigation]);

    return (
        <ComponentWrapper
            containerStyle={styles.container}
            mobileHeaderOptions={{
                showHeader: true,
                customHeader: <CustomHeader filter={filter} onFilterChange={setFilter} />,
                containerStyle: styles.containerHeader,
            }}
            wrapInScrollView={false}
            >
            <FlatList
                ListHeaderComponent={ListHeaderComponent}
                keyExtractor={keyExtractor}
                data={jobsData}
                contentContainerStyle={styles.flatList}
                renderItem={renderJobs}
                ItemSeparatorComponent={ItemSeparatorComponent}
                ListFooterComponent={isFetching || isLoading ? FlatListLoadingIndicator : null}
                ListEmptyComponent={ListEmptyComponent}
            />
        </ComponentWrapper>
    )
}

export { Explore };

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 0,
    },
    containerHeader: {
        zIndex: 2,
        paddingBottom: 0,
    },
    flatList: {
        paddingBottom: 100,
        paddingHorizontal: 16,
    },
    separator: {
        height: 12,
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 30,
        marginBottom: 17,
    },
    headerTitle: mbTextStyles([textStyles.normalText, {
        fontSize: 15,
        color: COLORS.headerText,
    }]),
    headerViewAll: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.primaryColor,
        textDecorationLine: 'underline',
    }]),
    job: {
        width: 'auto',
        marginHorizontal: 20,
    },
    // empty data
    emptyTableContainer: {
        width: '80%',
        flex: 1,
        alignItems: 'center',
        paddingTop: '40%',
        alignSelf: 'center'
    },
    emptyTableTitle: mbTextStyles([
        textStyles.normalText, {
            color: COLORS.bodyText,
            fontSize: 15,
            fontWeight: '700',
            marginTop: 12
        }
    ]),
    emptyTableSubtitle: mbTextStyles([
        textStyles.smallerText, {
            color: COLORS.bodyText2,
            marginTop: 6,
        }
    ]),
})
