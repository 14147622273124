import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import { COLORS } from '../../constants/colors';

// TODO: Hovo: Remove when you move the table
const SachelForEmptyTable = React.memo((props: { size?: number, containerStyle?: StyleProp<ViewStyle>, color?: string }) => {
    return (
        <View style={props.containerStyle}>
            <Svg
                width={props.size ?? 55}
                height={props.size ?? 55}
                viewBox="0 0 55 55"
                fill="none"
            >
                <Path
                    d="M27.489 38.218v-5.814"
                    stroke={props.color ?? COLORS.primaryColor}
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <Path
                    clipRule="evenodd"
                    d="M41.685 12.215c3.873 0 6.99 3.14 6.99 7.013v7.883c-5.638 3.3-13.086 5.294-21.198 5.294-8.113 0-15.538-1.994-21.175-5.294v-7.906c0-3.873 3.14-6.99 7.012-6.99h28.371z"
                    stroke={props.color ?? COLORS.primaryColor}
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <Path
                    d="M35.51 12.205v-.839a5.066 5.066 0 00-5.065-5.064h-5.913a5.066 5.066 0 00-5.064 5.064v.84M6.358 35.482l.433 5.75a7.43 7.43 0 007.407 6.87h26.58a7.43 7.43 0 007.407-6.87l.433-5.75"
                    stroke={props.color ?? COLORS.primaryColor}
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </Svg>
        </View>
    );
});

export { SachelForEmptyTable };
