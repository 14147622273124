import React from 'react';
import { BottomTabNavigationOptions, createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Explore } from '../components/screens/HomeFlow/Explore/Explore';
import { Profile } from '../components/screens/ProfileFlow/Profile';
import { RouteProp } from '@react-navigation/core';
import { StyleSheet } from 'react-native';
import { mbShadow, mbTextStyles } from '@workingu/rnw.utils.style-utils';
import { COLORS } from '../constants/colors';
import { FONT_STYLES } from '../constants/textStyles';
import Feather from 'react-native-vector-icons/Feather';
import Ionicons from 'react-native-vector-icons/Ionicons';
import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
import { useSignedInContext } from '../context/SignedInContext';
import { ContractorJobs } from '../components/screens/HomeFlow/ContractorJobs';
import { Applied } from '../components/screens/HomeFlow/Applied';
import { MyJobs } from '../components/screens/HomeFlow/MyJobs';
import { Employees } from '../components/screens/HomeFlow/Employees';
import { SafeAreaView } from 'react-native-safe-area-context';
import { UserRole } from '@wu/business';


export type EmployeeHomeNavigatorParamList = {
    Explore: undefined,
    Applied: undefined,
    MyJobs: undefined,
    Profile: undefined,
};

export type ContractorHomeNavigatorParamList = {
    Jobs: undefined,
    Employees: undefined,
    Profile: undefined,
};

const tabTitles: { [key in keyof (EmployeeHomeNavigatorParamList & ContractorHomeNavigatorParamList)]: string } = {
    Jobs: 'Jobs',
    Employees: 'Employees',
    Explore: 'Explore',
    Applied: 'Applied',
    MyJobs: 'My Jobs',
    Profile: 'Profile',
};

const Tab = createBottomTabNavigator<EmployeeHomeNavigatorParamList & ContractorHomeNavigatorParamList>();


interface HomeNavigatorOptionsProps { // TODO: Hovo: Verify these props. Why is navigation declared as any?
    route: RouteProp<EmployeeHomeNavigatorParamList & ContractorHomeNavigatorParamList, keyof (EmployeeHomeNavigatorParamList & ContractorHomeNavigatorParamList)>,
    navigation: any;
}

const homeNavigatorOptions = (props: HomeNavigatorOptionsProps): BottomTabNavigationOptions => {
    const navOptions: BottomTabNavigationOptions = {
        tabBarShowLabel: true,
        tabBarLabelStyle: styles.tabBarLabel,
        tabBarActiveTintColor: COLORS.primaryColor,
        tabBarInactiveTintColor: COLORS.bluishGrey,
        tabBarStyle: styles.tabStyle,
        tabBarIcon: ({ color }) => {
            const icons = {
                Jobs: <Ionicons name="briefcase-outline" size={22} color={color} />,
                Employees: <MaterialIcons name="groups" size={22} color={color} />,
                Explore: <Feather name="search" size={22} color={color} />,
                Applied: <Feather name="folder-minus" size={22} color={color} />,
                MyJobs: <Ionicons name="briefcase-outline" size={22} color={color} />,
                Profile: <Ionicons name="person-outline" size={22} color={color} />
            }
            return (icons[props.route.name]);
        },
        tabBarLabel: tabTitles[props.route.name],
    };

    return navOptions;
};

const screenOptions = { headerShown: false }

const HomeNavigator = () => {
    const { currentUserData } = useSignedInContext();

    const isContractor = currentUserData?.role === UserRole.Contractor;

    return (
        <SafeAreaView style={styles.container} edges={['bottom']}>
            <Tab.Navigator screenOptions={screenOptions}>
                {!isContractor && <Tab.Screen name="Explore" component={Explore} options={homeNavigatorOptions} />}
                {!isContractor && <Tab.Screen name="Applied" component={Applied} options={homeNavigatorOptions} />}
                {!isContractor && <Tab.Screen name="MyJobs" component={MyJobs} options={homeNavigatorOptions} />}
                {isContractor && <Tab.Screen name="Jobs" component={ContractorJobs} options={homeNavigatorOptions} />}
                {isContractor && <Tab.Screen name="Employees" component={Employees} options={homeNavigatorOptions} />}
                <Tab.Screen name="Profile" component={Profile} options={homeNavigatorOptions} />
            </Tab.Navigator>
        </SafeAreaView>
    );
};

export { HomeNavigator };

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: COLORS.white,
    },
    tabStyle: {
        position: 'absolute',
        height: 80,
        backgroundColor: COLORS.white,
        borderTopLeftRadius: 24,
        borderTopRightRadius: 24,
        borderTopColor: 'transparent',
        paddingBottom: 0,
        paddingTop: 10,
        ...mbShadow({
            offsetWidth: 0,
            offsetHeight: -12,
            radius: 10,
            opacity: 0.07,
            color: COLORS.black,
            elevation: 10,
        }),
    },
    tabBarLabel: mbTextStyles([{
        fontFamily: FONT_STYLES.ManropeBase,
        fontWeight: 'normal',
        fontSize: 11,
        textAlign: 'center',
        flex: 1,
    }]),
});

