import { MB_Button } from "@workingu/rnw.components.button";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";
import Feather from "react-native-vector-icons/Feather";
import { COLORS } from "../../constants/colors";
import { US_STATES } from "../../constants/constants";
import { textStyles } from "../../constants/textStyles";
import { Dropdown } from "./Dropdown";
import { mbApplyTransparency, mbTextStyles } from "@workingu/rnw.utils.style-utils";
import { MB_TextInput } from "@workingu/rnw.components.text-input";
import { DocumentPicker } from "./DocumentPicker";
import { MB_Modal } from "@workingu/rnw.components.modal";
import { SkypeIndicator } from "@workingu/rnw.components.activity-indicators";
import { BaseContractorLicenseSchema, LicenseNumberSchema } from "@wu/business";
import { z } from "zod";
import { DeleteLicenseModal } from "../modals/DeleteLicenseModal";
import { MB_utilHooks } from "@workingu/rnw.utils.util-hooks";
import { useValidatedText } from "../../hooks/text";


interface LicenseEditorProps {
    isLoading?: boolean,
    showModalInitially?: boolean,
    initialLicenses?: LicenseEditorLicense[],
    onLicensesChange?: (type: 'edit' | 'create' | 'delete', licenses: LicenseEditorLicense[]) => void
}



interface LicenseCreatorProps {
    license?: LicenseEditorLicense,
    licenseIndex?: number,
    onSave: (props: { license: LicenseEditorLicense, isEditing: boolean, editingIndex?: number }) => void,
    onDismiss: () => void,
}


const licenseSchema = BaseContractorLicenseSchema.extend({
    fileData: z.object({
        id: z.string(),
        name: z.string(),
        size: z.number(),
    }).optional(),
})

export type LicenseEditorLicense  = z.infer<typeof licenseSchema>

type License = z.infer<typeof licenseSchema>
type FileData = License['fileData']


const LicenseCreator = ({ license, licenseIndex, onSave, onDismiss }: LicenseCreatorProps) => {
    const [licenseNumber, setLicenseNumber] = useValidatedText(LicenseNumberSchema, license?.number)
    const [licenseState, setLicenseSate] = useState(license?.state)
    const [fileData, setFileData] = useState<FileData | undefined>(license?.fileData)

    const currentLicense = useMemo(() => ({
        fileData,
        number: licenseNumber,
        state: licenseState,
    }), [licenseNumber, licenseState, fileData])

    const { success: isValid } = licenseSchema.safeParse(currentLicense);

    const isLicenseValid = LicenseNumberSchema.optional().safeParse(licenseNumber || undefined).success;


    const onSavePressed = useCallback(() => {
        if (isValid) {
            onSave({ license: currentLicense as LicenseEditorLicense, isEditing: license !== undefined, editingIndex: licenseIndex });
        } else {
            onDismiss();
        }
    }, [isValid, onSave, onDismiss, licenseIndex, currentLicense, license]);

    return (
        <>
            <Text style={[textStyles.normalText, styles.licenseCreatorFormTitle]}>{(licenseIndex !== undefined) ? "Edit" : "Add"} License</Text>
            <Text style={styles.inputTitle}>State <Text style={styles.required}>*</Text></Text>

            <Dropdown
                options={US_STATES}
                onSelect={(option) => setLicenseSate(option)}
                option={licenseState}
            />
            {licenseState && licenseState !== "California" && <Text style={styles.stateError}>Wu is currently only supported for California licensed contractors.{'\n'}If you sign up with other state license, you won’t have access to the apps features.</Text>}

            <Text style={styles.inputTitle}>State License Number</Text>
            <MB_TextInput
                placeholder="State license number"
                value={licenseNumber}
                onChangeText={setLicenseNumber}
                placeholderTextColor={COLORS.greyText}
                isError={currentLicense.number !== undefined && currentLicense.number !== '' && !currentLicense.number?.match(/^[a-zA-Z0-9]+$/)}
            />
            {!isLicenseValid && <Text style={[styles.passwordNote, styles.passwordNoteError]}>Invalid State License Number</Text>}
            <DocumentPicker
                containerStyle={styles.documentPickerContainer}
                selectedFile={fileData}
                pageTitle="Upload License Document"
                required={false}
                onFileSelect={(data) => setFileData({
                    id: data.id,
                    name: data.fileName,
                    size: data.fileSize
                })}
            />
            <View style={styles.licenseCreatorFormBtns}>
                <MB_Button
                    title="Cancel"
                    style={styles.licenseCreatorFormCancelBtn}
                    textStyle={[textStyles.smallerText, { color: COLORS.primaryColor }]}
                    onPress={onDismiss}
                />
                <MB_Button
                    title="Save"
                    style={[styles.licenseCreatorFormSaveBtn, !isValid && styles.licenseCreatorFormSaveBtnDisabled]}
                    textStyle={[textStyles.smallerText]}
                    onPress={onSavePressed}
                    disabled={!isValid}
                />
            </View>
        </>
    );
};

const LicenseCard = ({ license, onEdit, onDelete, disableDelete }: LicenseCardProps) => {

    const [isDeleteModalVisible, showDeleteModal, hideDeleteModal] = MB_utilHooks.useBool();

    return (
        <View style={styles.licenseCard}>
            <DeleteLicenseModal
                isVisible={isDeleteModalVisible}
                state={license.state}
                onDismiss={hideDeleteModal}
                onDelete={onDelete}
            />
            <View style={{ flex: 1 }}>
                <Text style={styles.licenseCardText}>State: <Text style={styles.licenseCardTextImportant}>{license.state}</Text></Text>
                <Text style={[styles.licenseCardText, styles.licenseCardTextMargin]}>License number: <Text style={styles.licenseCardTextImportant}>{license.number}</Text></Text>
                <Text style={[styles.licenseCardText, styles.licenseCardTextMargin, { color: COLORS.primaryColor }]}>{license.fileData?.name || ''}</Text>
            </View>
            <View>
                <TouchableOpacity onPress={onEdit}>
                    <View style={styles.licenseCardEdit}>
                        <Feather name="edit" size={15} color={COLORS.bodyText} />
                        <Text style={[textStyles.smallerText, styles.licenseCardEditText]}>Edit</Text>
                    </View>
                </TouchableOpacity>
                {!disableDelete &&
                    <TouchableOpacity style={styles.licenseCardDeleteContainer} onPress={showDeleteModal}>
                        <View style={styles.licenseCardEdit}>
                            <Feather name="trash" size={15} color={COLORS.red} />
                            <Text style={[textStyles.smallerText, styles.licenseCardDeleteText]}>Delete</Text>
                        </View>
                    </TouchableOpacity>
                }
            </View>
        </View>
    );
};

type LicenseCardProps = { license: LicenseEditorLicense, onEdit?: () => void, onDelete?: () => void, disableDelete?: boolean };

const LicenseEditor = ({ isLoading, showModalInitially, initialLicenses, onLicensesChange }: LicenseEditorProps) => {
    const [licenseAddModalData, setLicenseAddModalDatails] = useState<{ isVisible: boolean, licenseToEdit?: LicenseEditorLicense, index?: number }>({ isVisible: showModalInitially ?? false });
    const [licenses, setLicenses] = useState<(LicenseEditorLicense & { isEditing?: boolean })[]>(initialLicenses ?? []);


    useEffect(() => {
        if (initialLicenses) {
            setLicenses(initialLicenses);
        }

    }, [initialLicenses]);

    const onSavePressed = useCallback(({ license, isEditing, editingIndex }: { license: LicenseEditorLicense, isEditing: boolean, editingIndex?: number }) => {
        setLicenseAddModalDatails({ isVisible: false });

        if (!isEditing) {
            setLicenses((oldLicenses) => {
                const newLicenses = [...oldLicenses, license];
                onLicensesChange?.('create', newLicenses.map(el => ({ state: el.state, number: el.number, fileData: el.fileData })));
                return newLicenses;
            });
        } else if (isEditing && editingIndex !== undefined) {
            setLicenses(oldLicenses => {
                oldLicenses[editingIndex].isEditing = false;
                oldLicenses[editingIndex].state = license.state;
                oldLicenses[editingIndex].number = license.number;
                oldLicenses[editingIndex].fileData = license.fileData;
                const newLicenses = [...oldLicenses];
                onLicensesChange?.('edit', newLicenses.map(el => ({ state: el.state, number: el.number, fileData: el.fileData })));
                return newLicenses;
            });
        }
    }, [setLicenseAddModalDatails, onLicensesChange]);


    const onDelete = useCallback((index: number) => {
        const newLicenses = licenses.filter((_, currentIndex) => currentIndex !== index);
        setLicenses(newLicenses);
        onLicensesChange?.('delete', newLicenses.map(el => ({ state: el.state, number: el.number, fileData: el.fileData })));
    }, [licenses, onLicensesChange])


    return (
        <View>
            <MB_Modal
                isVisible={licenseAddModalData.isVisible}
                onDismiss={() => setLicenseAddModalDatails({ isVisible: false })}
                childrenWrapperStyle={styles.licenseCreatorContainer}
            >
                <LicenseCreator
                    onDismiss={() => setLicenseAddModalDatails({ isVisible: false })}
                    onSave={onSavePressed}
                    license={licenseAddModalData.licenseToEdit}
                    licenseIndex={licenseAddModalData.index}
                />
            </MB_Modal>
            <View>
                {licenses.map((license, index) => {
                    return (
                        <LicenseCard
                            key={index}
                            license={license}
                            onEdit={() => setLicenseAddModalDatails({ isVisible: true, licenseToEdit: license, index })}
                            onDelete={() => onDelete(index)}
                            disableDelete={licenses.length === 1}
                        />);
                })}
            </View>

            <MB_Button
                title="+ Add another license"
                onPress={() => setLicenseAddModalDatails({ isVisible: true })}
                style={styles.addAnotherButton}
                textStyle={styles.addAnotherButtonText}
            />

            {isLoading &&
                <View style={styles.loadingContainer}>
                    <SkypeIndicator color={COLORS.primaryColor} size={64} />
                </View>
            }
        </View>
    );
}

export { LicenseEditor };

const styles = StyleSheet.create({
    loadingContainer: {
        width: '100%',
        height: '100%',
        backgroundColor: mbApplyTransparency(COLORS.black, 0.5),
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputTitle: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.bodyText,
        marginTop: 15,
        marginBottom: 7,
        textAlign: 'left',
        alignSelf: 'flex-start',
    }]),
    required: {
        color: COLORS.red,
    },
    stateError: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.red,
        textAlign: 'left',
        marginTop: 2,
    }]),
    licenseCreatorContainer: {
        backgroundColor: COLORS.greyBG,
        alignSelf: 'stretch',
    },
    licenseCreatorFormTitle: {
        fontSize: 15,
        color: COLORS.bodyText,
        textAlign: 'left',
    },
    licenseCreatorFormBtns: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 24,
    },
    licenseCreatorFormCancelBtn: {
        height: 40,
        backgroundColor: 'transparent',
        borderColor: COLORS.primaryColor,
        borderWidth: 1,
        borderRadius: 36,
        width: 96,
        marginEnd: 5,
    },
    licenseCreatorFormSaveBtn: {
        height: 40,
        backgroundColor: COLORS.primaryColor,
        borderRadius: 36,
        width: 96,
    },
    licenseCreatorFormSaveBtnDisabled: {
        backgroundColor: '#FFD3BE',
    },
    addLicense: {
        color: COLORS.primaryColor,
        textDecorationLine: 'underline',
        marginTop: 11,
        textAlign: 'left',
    },
    // LicenseCard
    licenseCard: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: COLORS.lightOrange,
        borderRadius: 6,
        paddingTop: 12,
        paddingBottom: 18,
        paddingLeft: 14,
        paddingRight: 10,
        justifyContent: 'space-between',
        marginTop: 12,
        flex: 1,
    },
    licenseCardText: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.bodyText,
        textAlign: 'left',
        flex: 1,
    }]),
    licenseCardTextImportant: {
        color: COLORS.headerText,
    },
    licenseCardTextMargin: {
        marginTop: 7,
    },
    licenseCardEdit: {
        flexDirection: 'row',
    },
    licenseCardEditText: {
        fontSize: 11,
        color: COLORS.bodyText,
        marginLeft: 6,
    },
    licenseCardDeleteContainer: {
        marginTop: 8,
    },
    licenseCardDeleteText: {
        fontSize: 11,
        color: COLORS.red,
        marginLeft: 6,
    },
    passwordNote: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.bodyText,
        marginTop: 5,
        textAlign: 'left',
        alignSelf: 'stretch',
    }]),
    passwordNoteError: {
        color: COLORS.red,
    },
    documentPickerContainer: {
        marginTop: 15,
        alignSelf: 'stretch',
    },
    addAnotherButton: {
        alignSelf: 'flex-start',
        marginTop: 8,
        backgroundColor: 'transparent',
    },
    addAnotherButtonText: mbTextStyles([
        textStyles.smallerText, {
            color: COLORS.primaryColor,
            fontWeight: '600',
            textDecorationLine: 'underline',
        }
    ]),
    popUpDeletebutton: {
        backgroundColor: COLORS.red,
    }
})
