import React, { useCallback } from "react";
import { FlatList, ListRenderItemInfo, StyleSheet, Text, View } from "react-native";
import { useMBInfiniteFetch } from "@workingu/rnw.utils.util-hooks";
import { useReviews } from "../../../hooks/userHooks";
import { Spacer } from "../../Spacer";
import { FlatListLoadingIndicator } from "../../helperComponents/FlatListLoadingIndicator";
import { ReviewCard } from "../../helperComponents/ReviewCard";
import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import { Review } from "@wu/business";
import { COLORS } from "../../../constants/colors";
import { textStyles } from "../../../constants/textStyles";

interface ReviewProps {
    employeeId: string;
}

const Reviews = (props: ReviewProps) => {
    const getReviews = useReviews({ totalItemsPerPage: 10, employeeId: props.employeeId }); 

    const { data: reviews, loadMore, isLoading, isFetching } = useMBInfiniteFetch(getReviews, { prefillWhileLoading: true });

    const ListEmptyComponent = useCallback(() => {
        return (
            <View>
                <Text style={styles.listEmptyTitle}>No reviews yet</Text>
                <Text style={styles.listEmptyDescription}>This employee currently has no reviews.</Text>
            </View>
        );
    }, []);

    const renderItem = useCallback(({ item }: ListRenderItemInfo<Review | string>) => {
        if (typeof item === 'string'){
            return null
        }

        return <ReviewCard review={item} />
    }, []);

    return (
        <FlatList
            data={reviews}
            renderItem={renderItem}
            contentContainerStyle={styles.tabScreenContainer}
            onEndReached={loadMore}
            onEndReachedThreshold={0.3}
            ItemSeparatorComponent={Spacer}
            ListEmptyComponent={ListEmptyComponent}
            ListFooterComponent={isFetching || isLoading ? FlatListLoadingIndicator : null}
        />
    )
}

export { Reviews };

const styles = StyleSheet.create({
    tabScreenContainer: {
        paddingHorizontal: 20,
        paddingVertical: 20,
    },
    listEmptyTitle: mbTextStyles([textStyles.smallText, {
        fontSize: 15,
        color: COLORS.bodyText,
        marginTop: 80,
    }]),
    listEmptyDescription: mbTextStyles([textStyles.smallerText, {
        color: COLORS.bodyText2,
        marginTop: 5,
    }]),
});
