import { MB_Button } from '@workingu/rnw.components.button';
import { useNavigation } from '@react-navigation/core';
import React, { useCallback, useState } from 'react';
import { StyleSheet, Text } from 'react-native';
import { COLORS } from '../../../constants/colors';
import { textStyles } from '../../../constants/textStyles';
import { UniversalScreenNavigationProp } from '../../../typesAndInterfaces/componentProps';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { VerifyOtpType } from './VerifyOtp';
import { useResetPasswordCodeRequest } from '../../../hooks/userHooks';
import { STRING_CONSTANTS } from '../../../constants/constants';
import { MB_TextInput } from '@workingu/rnw.components.text-input';
import { mbShowToast } from '@workingu/rnw.components.toast';

const ForgotPassword = () => {

    const navigation = useNavigation<UniversalScreenNavigationProp>();
    const [email, setEmail] = useState('');
    const { mutate: resetPasswordCodeRequest, isLoading: isResetPasswordRequestLoading } = useResetPasswordCodeRequest();

    const onSubmit = useCallback(() => {
        resetPasswordCodeRequest(email, {
            onError: (error) => mbShowToast({ type: 'error', text1: error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN, position: 'bottom' }),
            onSuccess: () => navigation.navigate('VerifyOtp', { type: VerifyOtpType.ForgetPassword, data: { email } })
        })
    }, [email, navigation, resetPasswordCodeRequest]);

    const isDisabled = email === ''

    return (
        <ComponentWrapper mobileHeaderOptions={{ showHeader: true, showBackArrow: true }}>
            <Text style={[textStyles.largeText, styles.title]}>Forgot Password?</Text>
            <Text style={[textStyles.smallerText, styles.subTitle]}>Please enter the email address attached to your account and an OTP will be sent to you.</Text>
            <MB_TextInput
                placeholder="Email address"
                style={styles.emailField}
                value={email}
                onChangeText={setEmail}
                placeholderTextColor={COLORS.greyText}
            />
            <MB_Button
                title="Send email"
                style={styles.sendEmailBtn}
                textStyle={textStyles.smallerText}
                onPress={onSubmit}
                disabled={isDisabled || isResetPasswordRequestLoading}
                loading={isResetPasswordRequestLoading}
            />
        </ComponentWrapper>
    );
};

export { ForgotPassword };

const styles = StyleSheet.create({
    title: {
        color: COLORS.headerText,
        textAlign: 'left',
        letterSpacing: -0.8,
    },
    subTitle: {
        color: COLORS.greyText,
        textAlign: 'left',
        marginBottom: 9,
    },
    emailField: {
        marginTop: 18,
    },
    sendEmailBtn: {
        marginTop: 22,
        height: 50,
        backgroundColor: COLORS.primaryColor,
        borderRadius: 30,
    },
});
