import * as React from "react"
import Svg, { Path } from "react-native-svg"

const PhoneIcon = ({ size = 48, color = "#FF5F15" }: { size?: number, color?: string }) => {
  return (
    <Svg
      width={size * (27 / 48)}
      height={size}
      viewBox="0 0 27 48"
      fill="none"
    >
      <Path
        d="M20.917 47H6.08a4.885 4.885 0 01-4.9-4.9L1.18 5.243c0-2.753 2.215-4.9 4.9-4.9h14.836c2.753 0 4.9 2.215 4.9 4.9v36.855c0 2.686-2.214 4.9-4.9 4.9h0zm3.29-5.84V5.914c0-2.014-1.679-3.692-3.693-3.692H6.484c-2.014 0-3.693 1.678-3.693 3.692V41.16c0 2.014 1.679 3.692 3.693 3.692h14.03c2.014 0 3.692-1.679 3.692-3.693z"
        fill={color}
        stroke={color}
        strokeWidth={0.671319}
        strokeMiterlimit={10}
      />
      <Path
        d="M15.345 41.092a1.947 1.947 0 11-3.894 0 1.947 1.947 0 013.894 0z"
        stroke={color}
        strokeWidth={0.671319}
        strokeMiterlimit={10}
      />
      <Path
        d="M16.218 4.371h-5.64c-.2 0-.335-.134-.335-.336v-.067c0-.2.135-.335.336-.335h5.639c.201 0 .336.134.336.335v.067c0 .202-.135.336-.336.336z"
        stroke={color}
        strokeWidth={0.33566}
        strokeMiterlimit={10}
      />
    </Svg>
  )
}

export { PhoneIcon };
