import { MB_ModalMobileBottom } from "@workingu/rnw.components.modal";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { NativeSyntheticEvent, StyleProp, StyleSheet, Text, TextInputSubmitEditingEventData, TouchableOpacity, View, ViewStyle } from "react-native";
import Feather from "react-native-vector-icons/Feather";
import { COLORS } from "../../constants/colors";
import { ModalFilterContent } from "../modals/ModalFilterContent";
import { MB_utilHooks } from '@workingu/rnw.utils.util-hooks';
import { JobsRequest } from "@wu/business";
import { mbShadow, mbTextStyles } from "@workingu/rnw.utils.style-utils";
import { textStyles } from "../../constants/textStyles";
import { MB_TextInputRef, MB_TextInput } from "@workingu/rnw.components.text-input";
import FilterIcon from "../../resources/svgComponents/FilterIcon";
import { useGetAllTrades } from "../../hooks/serverUtilHooks";

interface SearchInputFilterProps {
    style?: StyleProp<ViewStyle>,
    filter?: JobsRequest['filter'],
    onFilterChange?: (filter: JobsRequest['filter']) => void
}

const SearchInputFilter = ({ style, filter, onFilterChange }: SearchInputFilterProps) => {

    const [isFilterVisible, showFilter, hideFilter] = MB_utilHooks.useBool();
    const [isSuggestionsVisible, showSuggestions, hideSuggestions] = MB_utilHooks.useBool(false);

    const { data: allTrades } = useGetAllTrades();

    const [searchText, setSearchText] = useState('');
    const [options, setOptions] = useState<string[]>([]);
    const textRef = useRef<MB_TextInputRef>(null);

    const onSubmitEditing = useCallback((e: NativeSyntheticEvent<TextInputSubmitEditingEventData>) => {
        if (e.nativeEvent.text === '') {
            onFilterChange?.({ ...filter, trades: [] })
        }
    }, [filter, onFilterChange]);

    useEffect(() => {
        if (filter?.trades?.length === 0) {
            setSearchText('');
            hideSuggestions();
        }
    }, [filter?.trades?.length, hideSuggestions]);

    const onFocus = useCallback(() => {
        if (searchText === '') {
            setOptions([]);
        }
        showSuggestions();
    }, [searchText, showSuggestions]);

    const onChangeText = useCallback((text: string) => {
        setSearchText(text);
        if (text !== '') {
            const filteredTrades = allTrades?.filter(trade => trade.name.match(new RegExp(text, 'i'))) ?? [];
            setOptions(filteredTrades.map(trade => trade.name));
        } else {
            setOptions([]);
        }
    }, [allTrades]);

    const onSelectOption = useCallback((option: string) => {
        setSearchText(option);
        textRef.current?.blur();
        onFilterChange?.({ ...filter, trades: [option] })
    }, [filter, onFilterChange]);

    const onApplyFilter = useCallback((newFilter: JobsRequest['filter']) => {
        onFilterChange?.(newFilter);
        hideFilter();
    }, [hideFilter, onFilterChange]);

    const appliedFiltersLength = useMemo(() => {
        return Object.values(filter ?? {}).filter(value => value !== undefined && value.length !== 0).length;
    }, [filter]);

    return (
        <View style={[styles.headerSearchInputContainer, style]}>
            <MB_TextInput
                MB_Ref={textRef}
                placeholderTextColor={COLORS.greyText}
                placeholder="Search for jobs..."
                style={styles.headerSearchInput}
                styleAdditionsFocused={styles.headerSearchInputFocused}
                onChangeText={onChangeText}
                onSubmitEditing={onSubmitEditing}
                onFocus={onFocus}
                onBlur={hideSuggestions}
                value={searchText}
            />
            <Feather name="search" size={17} style={styles.headerSearchIcon} color={COLORS.primaryColor} />
            <TouchableOpacity style={[styles.filter, appliedFiltersLength > 0 && styles.filterActive]} onPress={showFilter}>
                {appliedFiltersLength > 0 &&
                    <View style={styles.filterLength}>
                        <Text style={styles.filterLengthText}>{appliedFiltersLength}</Text>
                    </View>
                }
                <FilterIcon color={appliedFiltersLength > 0 ? COLORS.white : COLORS.primaryColor} />
            </TouchableOpacity>
            <View style={styles.result}>
                {isSuggestionsVisible && options.slice(0, 4).map(option => (
                    <Text
                        key={option}
                        style={styles.option}
                        onPress={() => onSelectOption(option)}
                    >
                        {option}
                    </Text>
                ))}
            </View>
            <MB_ModalMobileBottom
                isVisible={isFilterVisible}
                childrenWrapperStyle={styles.childrenWrapper}
                onDismiss={hideFilter}
                closeButtonXProps={{ color: COLORS.greyText, size: 15 }}
            >
                <ModalFilterContent filter={filter} onFilterChange={onApplyFilter} />
            </MB_ModalMobileBottom>
        </View>
    )
}

export { SearchInputFilter };

const styles = StyleSheet.create({
    headerSearchInputContainer: {
        justifyContent: 'center',
    },
    headerSearchInput: {
        paddingLeft: 40,
        paddingEnd: 40,
    },
    headerSearchInputFocused: {
        zIndex: 0,
    },
    headerSearchIcon: {
        position: 'absolute',
        left: 14,
        zIndex: 2,
    },
    filter: {
        position: 'absolute',
        right: 15,
        zIndex: 2,
        borderRadius: 360,
        width: 34,
        height: 34,
        justifyContent: 'center',
        alignItems: 'center',
    },
    filterActive: {
        backgroundColor: COLORS.primaryColor,
    },
    filterLength: {
        width: 14,
        height: 14,
        position: 'absolute',
        borderRadius: 360,
        backgroundColor: '#F04438',
        top: -2,
        right: -2,
        alignItems: 'center',
        justifyContent: 'center',
    },
    filterLengthText: StyleSheet.flatten([textStyles.tinyText, {
        fontSize: 9,
        fontWeight: '400',
    }]),
    childrenWrapper: {
        backgroundColor: COLORS.white,
        paddingHorizontal: 20,
    },
    result: {
        position: 'absolute',
        top: 50,
        left: 0,
        right: 0,
        paddingHorizontal: 20,
        backgroundColor: COLORS.white,
        borderRadius: 5,
        ...mbShadow({
            offsetWidth: 0,
            offsetHeight: 0,
            radius: 5,
            opacity: 0.1,
            color: '#242424',
            elevation: 5,
        }),
    },
    option: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.bodyText,
        textAlign: 'left',
        paddingVertical: 12,
    }])
});

