import React, { useCallback, useMemo } from "react"
import { ComponentWrapper } from "../helperComponents/componentWrapper/ComponentWrapper"
import { FlatList, ListRenderItemInfo, StyleSheet, Text, View } from "react-native";
import Feather from "react-native-vector-icons/Feather";
import { COLORS } from "../../constants/colors";
import { textStyles } from "../../constants/textStyles";
import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import { Notification, NotificationType } from "@wu/business";
import { imageUtils } from "../../utils/imageUtils";
import { MB_Image } from "@workingu/rnw.components.image";
import { useGetNotifications, useReadAllNotification, useReadNotification } from "../../hooks/notificationsHooks";
import { TOTAL_ITEMS_PER_PAGE } from "../../typesAndInterfaces/typesAndInterfaces";
import { useMBInfiniteFetch } from "@workingu/rnw.utils.util-hooks";
import { FlatListLoadingIndicator } from "../helperComponents/FlatListLoadingIndicator";
import { TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/core";
import { UniversalScreenNavigationProp } from "../../typesAndInterfaces/componentProps";

const getNotificationRouter = (notification: Notification, navigation: UniversalScreenNavigationProp) => {
    const { id, type } = notification.payload;
    const isReview = type === NotificationType.EmployeeReview;


    if ([NotificationType.EmployeeReview, NotificationType.NewApplicant].includes(type)) {
        return () => navigation.navigate('ApplicationDetails', { applicationId: id, isReview });
    }

    return () => navigation.navigate('JobDetails', { jobId: id });
};

const Notifications = () => {

    const navigation = useNavigation<UniversalScreenNavigationProp>();
    const { mutate: readNotification, isLoading: isReading } = useReadNotification();
    const { mutate: readAllNotification } = useReadAllNotification();

    const getNotifications = useGetNotifications({ totalItemsPerPage: TOTAL_ITEMS_PER_PAGE });

    const { data: notifications, isLoading, isFetching } = useMBInfiniteFetch(getNotifications, { prefillWhileLoading: true });


    const openNotification = useCallback((notification: Notification) => {
        const navigate = getNotificationRouter(notification, navigation);
        if (notification.readAt) {
            navigate();
            return;
        }
        readNotification({ id: notification.id }, {
            onSuccess: () => {
                navigate()
            }
        });
    }, [navigation, readNotification]);


    const renderItem = useCallback(({ item }: ListRenderItemInfo<Notification | string>) => {

        if (typeof item === 'string') {
            return null;
        }

        const profileImageUrl = item.image?.url ?? imageUtils.images.emptyProfileIcon;

        const notificationBackgroundColor = item.readAt ? undefined : COLORS.lightOrange;
        const notificationTitleColor = item.readAt ? COLORS.headerText : COLORS.bodyText;
        const notificationMessageColor = item.readAt ? COLORS.bodyText : COLORS.bodyText2;

        return (
            <TouchableOpacity
                style={[styles.notification, { backgroundColor: notificationBackgroundColor }]}
                onPress={() => openNotification(item)}
                disabled={isReading}
            >
                <MB_Image source={profileImageUrl} style={styles.profileImage} resizeMode='contain' disableInteraction />
                <View style={styles.notificationContent}>
                    <Text style={[styles.notificationTitle, { color: notificationTitleColor }]}>{item.title}</Text>
                    <Text style={[styles.notificationMessage, { color: notificationMessageColor }]}>{item.message}</Text>
                </View>
            </TouchableOpacity>
        )
    }, [openNotification, isReading]);

    const ItemSeparatorComponent = useCallback(() => <View style={styles.separtor} />, []);

    const ListEmptyComponent = useMemo(() => {
        return (
            <View style={styles.emptyTableContainer}>
                <Feather name="bell" color={COLORS.primaryColor} size={40} />
                <Text style={styles.emptyTableTitle}>No notification received yet</Text>
            </View>
        );
    }, [])

    return (
        <ComponentWrapper
            mobileHeaderOptions={{ showHeader: true, showBackArrow: true, containerStyle: styles.mobileHeader }}
            containerStyle={styles.container}
            wrapInScrollView={false}
        >
            <View style={styles.header}>
                <View style={styles.headerLeftSide}>
                    <Feather name="bell" color={COLORS.primaryColor} size={24} />
                    <Text style={styles.title}>Notifications</Text>
                </View>
                <Text style={styles.markAsReadText} onPress={() => readAllNotification()}>Mark all as read</Text>
            </View>
            <FlatList
                data={notifications}
                ListEmptyComponent={ListEmptyComponent}
                renderItem={renderItem}
                ItemSeparatorComponent={ItemSeparatorComponent}
                ListFooterComponent={isFetching || isLoading ? FlatListLoadingIndicator : null}
            />
        </ComponentWrapper>
    )
}

export { Notifications };

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 0,
    },
    mobileHeader: {
        marginHorizontal: 0,
        paddingHorizontal: 16,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 18,
        paddingHorizontal: 24,
    },
    headerLeftSide: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: mbTextStyles([textStyles.largeText, {
        fontSize: 21,
        color: COLORS.headerText,
        textAlign: 'left',
        marginStart: 9,
        fontWeight: '800',
    }]),
    markAsReadText: mbTextStyles([textStyles.smallText, {
        color: COLORS.primaryColor,
        textAlign: 'left',
    }]),
    profileImage: {
        width: 40,
        height: 40,
        borderRadius: 300,
    },
    notification: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 12,
        paddingHorizontal: 20,
    },
    notificationContent: {
        marginLeft: 12,
        flex: 1,
    },
    notificationTitle: mbTextStyles([textStyles.smallerText, {
        textAlign: 'left',
        color: COLORS.bodyText,
        fontWeight: '600',
    }]),
    notificationMessage: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        textAlign: 'left',
        color: COLORS.bodyText2,
        marginTop: 2,
    }]),
    separtor: {
        height: 0.3,
        width: '100%',
        backgroundColor: '#C4C4C4'
    },
    emptyTableContainer: {
        width: '80%',
        flex: 1,
        alignItems: 'center',
        paddingTop: '40%',
        alignSelf: 'center'
    },
    emptyTableTitle: mbTextStyles([
        textStyles.normalText, {
            color: COLORS.bodyText,
            fontSize: 15,
            fontWeight: '700',
            marginTop: 12
        }
    ]),
});
