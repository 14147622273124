import { WaveIndicator } from '@workingu/rnw.components.activity-indicators';
import React, { memo } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { COLORS } from '../../constants/colors';
import { mbTextStyles } from '@workingu/rnw.utils.style-utils';
import { textStyles } from '../../constants/textStyles';




const FlatListLoadingIndicator = memo(() => {
    return (
        <View style={styles.container}>
            <WaveIndicator color={COLORS.primaryColor} />
            <Text style={[textStyles.smallText, { color: COLORS.white }]} >Loading...</Text>
        </View>
    );
});


export { FlatListLoadingIndicator };


const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        minWidth: 100,
    },
    loadingText: mbTextStyles([
        textStyles.smallText, {
            color: COLORS.primaryColor,
        }
    ]),
});