import { mbTextStyles } from '@workingu/rnw.utils.style-utils';
import React from 'react';

import { StyleSheet, Text, View } from 'react-native';
import { COLORS } from '../../constants/colors';
import { textStyles } from '../../constants/textStyles';
import { UpdateAppButton } from './UpdateAppButton';

const UpdateApp = ({ version }: { version: string }) => {

    return (
        <View style={styles.container}>
            <Text style={styles.wuLogoStyle}>W.U.</Text>
            <Text style={styles.newVersion}>New Version</Text>
            <Text style={mbTextStyles([textStyles.smallText, { color: COLORS.white }])}>V{version}</Text>
            <Text style={mbTextStyles([textStyles.smallText, { marginTop: 30, color: COLORS.white }])}>{'The current version of this application is\nno longer supported.'}</Text>
            <UpdateAppButton title="Update Now" style={{ backgroundColor: COLORS.white, borderRadius: 30, height: 50, width: 276 }} textStyle={{ color: COLORS.primaryColor }} />
        </View>
    );
};

export { UpdateApp };

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: COLORS.primaryColor,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 30,
    },
    wuLogoStyle: mbTextStyles([
        textStyles.largestText, {
            fontWeight: '800',
            fontSize: 30,
            color: COLORS.white,
        }
    ]),
    newVersion: mbTextStyles([
        textStyles.normalText, {
            fontSize: 18,
            fontWeight: '700',
            color: COLORS.white,
            marginTop: 20,
        }
    ]),
});
