import React, { useCallback, useRef, memo } from "react";
import { FlatList, StyleSheet, ListRenderItemInfo, TouchableOpacity, Text, View, Dimensions } from "react-native";
import { ComponentWrapper } from "../../helperComponents/componentWrapper/ComponentWrapper";
import { COLORS } from "../../../constants/colors";
import Feather from "react-native-vector-icons/Feather";
import { MB_utilHooks, useMBInfiniteFetch } from "@workingu/rnw.utils.util-hooks";
import { CreateJobsPopUp } from "../../helperComponents/CreateJobsPopUp";
import { useGetContractorJobs } from "../../../hooks/jobsHooks";
import { FlatListLoadingIndicator } from "../../helperComponents/FlatListLoadingIndicator";
import { Job, StringBoolean } from "@wu/business";
import { JobCard } from "../../helperComponents/JobCard";
import { MB_TabView } from "@workingu/rnw.components.tab-view";
import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import { textStyles } from "../../../constants/textStyles";
import { SachelForEmptyTable } from "../../../resources/svgComponents/SachelForEmptyTable";
import { TOTAL_ITEMS_PER_PAGE } from "../../../typesAndInterfaces/typesAndInterfaces";
import { CustomHeader } from "../../helperComponents/CustomHeader";
import { JOBS_QUERY_KEYS } from "../../../constants/constants";
import { ComingSoonModal } from "../../modals/ComingSoonModal";
import { useGetContractorData } from "../../../hooks/userHooks";



const JobsList = memo(({ type }: { type: 'recent' | 'completed' }) => {
    const key = type === 'recent' ? JOBS_QUERY_KEYS.getContractorRecentJobs : JOBS_QUERY_KEYS.getContractorCompletedJobs;

    const completed: StringBoolean = type === 'completed' ? 'true' : 'false';


    
    const getContractorJobs = useGetContractorJobs({ totalItemsPerPage: TOTAL_ITEMS_PER_PAGE, completed, key });

    const { data: jobsData, loadMore, isLoading, isFetching } = useMBInfiniteFetch(getContractorJobs, { prefillWhileLoading: true });



    const keyExtractor = React.useMemo(() => (item: Job | string) => typeof item === 'string' ? item : item.id, []);

    const RenderJobCard = useCallback(({ item }: ListRenderItemInfo<Job | string>) => {

        if (typeof item === 'string') {
            // TODO: Render temp card cloadings
            return null;
        }

        return (
            <JobCard
                job={item}
                style={styles.jobCardContainer}
            />
        );
    }, []);


    if (jobsData.length === 0) {
        return (
            <View style={styles.emptyTableContainer}>
                <SachelForEmptyTable />
                <Text style={styles.emptyTableTitle}>{type === 'recent' ? 'No jobs yet' : 'No completed jobs yet'}</Text>
                <Text style={styles.emptyTableSubtitle}>
                    {type === 'recent' ? 'Tap the “+” button below to start creating job postings and manage your jobs from here.' : 'You currently have no completed jobs. Once a job is completed it will show up here.'}
                </Text>
            </View>
        );
    }

    return (
        <FlatList
            keyExtractor={keyExtractor}
            data={jobsData}
            renderItem={RenderJobCard}
            onEndReached={loadMore}
            onEndReachedThreshold={0.3}
            ListFooterComponent={isFetching || isLoading ? FlatListLoadingIndicator : null}
            contentContainerStyle={styles.contentList}
        />
    );
})


const ContractorJobs = () => {
    const [isJobCreationVisible, showJobCreation, hideJobCreation] = MB_utilHooks.useBool();
    const { data: contractor } = useGetContractorData();
    const showComingSoonModal = contractor?.licenses.every(license => license.state !== 'California') || false;

    const tabData = useRef({
        titles: ['Recently Posted', 'Completed'],
        screens: [() => <JobsList type="recent" />, () => <JobsList type="completed" />],
    });

    return (
        <ComponentWrapper
            containerStyle={styles.container}
            mobileHeaderOptions={{ showHeader: true, customHeader: <CustomHeader title="Post jobs and find employees" /> }}
            wrapInScrollView={false}
        >
            <CreateJobsPopUp
                isVisible={isJobCreationVisible}
                onDismiss={hideJobCreation}
            />
            <ComingSoonModal
                isVisible={showComingSoonModal}
            />

            <MB_TabView
                titles={tabData.current.titles}
                screens={tabData.current.screens}
                tabLabelStyle={styles.tabLabel}
                tabIndicatorStyle={styles.tabIndicatorStyle}
                screenContainerStyle={styles.tabScreenContainer}
            />


            <TouchableOpacity
                style={styles.addJobsButton}
                onPress={showJobCreation}
            >
                <Feather name="plus" size={32} color={COLORS.white} />
            </TouchableOpacity>
        </ComponentWrapper>
    )
};

export { ContractorJobs };

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 0,
    },
    addJobsButton: {
        position: 'absolute',
        width: 53,
        height: 53,
        backgroundColor: COLORS.primaryColor,
        right: 17,
        bottom: 110,
        borderRadius: 300,
        marginHorizontal: 0,
        marginStart: 0,
        marginEnd: 0,
        margin: 0,
        alignItems: 'center',
        justifyContent: 'center'
    },
    tabLabel: mbTextStyles([
        textStyles.smallText, {
            color: COLORS.greyText,
            fontSize: 13,
            textTransform: 'none'
        }
    ]),
    tabIndicatorStyle: {
        backgroundColor: COLORS.primaryColor,
        width: (Dimensions.get('screen').width / 2) - 40,
        marginHorizontal: 20,
    },
    tabScreenContainer: {
        borderTopColor: '#DADADA',
        borderTopWidth: 0.5,
    },
    jobCardContainer: {
        width: '100%',
        marginBottom: 12,
    },
    emptyTableTitle: mbTextStyles([
        textStyles.normalText, {
            color: COLORS.bodyText,
            fontSize: 15,
            fontWeight: '700',
            marginTop: 12
        }
    ]),
    emptyTableSubtitle: mbTextStyles([
        textStyles.smallerText, {
            color: COLORS.bodyText2,
            marginTop: 6,
        }
    ]),
    emptyTableContainer: {
        width: '80%',
        flex: 1,
        alignItems: 'center',
        paddingTop: '40%',
        alignSelf: 'center'
    },
    contentList: {
        paddingTop: 20,
        paddingBottom: 100,
        paddingHorizontal: 20,
    }
});
