import React from "react";
import { ApplicationStatus } from "@wu/business";
import { StyleSheet, Text } from "react-native";
import { COLORS } from "../../constants/colors";
import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import { textStyles } from "../../constants/textStyles";

const StatusColor = {
    [ApplicationStatus.Accepted]: COLORS.statusGreen,
    [ApplicationStatus.Rejected]: COLORS.red,
    [ApplicationStatus.AwaitingResponse]: COLORS.bluishGrey,
    Completed: '#48B560',
}


const ApplicationStatusText = ({status,completed}: {status?: ApplicationStatus, completed?: boolean}) => {
    const text = completed ? 'Completed': status;
    if(!text) return null;

    const color = StatusColor[text];
    return <Text style={[styles.statusText, {color}]}>{text}</Text>
}

export { ApplicationStatusText, StatusColor };

const styles = StyleSheet.create({
    statusText: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        textAlign: 'left',
        fontWeight: '600',
    }]),
})
