const COLORS = {
  black: '#000000',
  darkBackground: '#000000',
  backgroundPurple: '#412366',
  plum: '#9A4C95',
  errorColor: '#FF563C',
  textLightPurple: '#E9CEFF',
  buttonPurple: '#A430FF',
  textPurple: '#A430FF',
  darkPurple: '#1D1A31',
  englishViolet: '#4D2D52',
  correctAnswerColor: '#3E9D0C',
  toastGreen: '#17B472',
  incorrectAnswerColor: '#E53232',
  backgroundDarkPurple: '#1D183B',
  dimViolet: '#B8ABBA',
  darkPurpleLight: '#2B203B',

  statusGreen: '#48B560',

  primaryColor: '#FF5F15',
  background: '#EEEEEE',
  headerText: '#000000',
  greyText: '#777777',
  bodyText: '#333333',
  bodyText2: '#555555',
  white: '#FFFFFF',
  red: '#DD3636',
  lightOrange: '#FFF4F0',
  lightGrey: '#DEDEDE',
  greyBG: '#F9F9F9',
  bluishGrey: '#7D8FA5',
  success_50: '#ECFDF3',
  success_500: '#12B76A',
  success_700: '#027A48',
};

export { COLORS };
