import {
  CreateFileRequest,
  CreateFileResponse,
  CreateFileResponseSchema,
  File,
  FileSchema,
  GetFileRequest,
} from '@wu/business';

import { axiosCaller } from './customAxios';

const fileApiCalls = {
  createFile: async (data: CreateFileRequest): Promise<CreateFileResponse> => {
    return await axiosCaller({
      path: '/api/files',
      method: 'post',
      passToken: true,
      responseValidatorObj: CreateFileResponseSchema,
      responseFormatValidatorMessage: 'Data mismatch for create file response',
      data,
    });
  },
  getFile: async (data: GetFileRequest): Promise<File> => {
    return await axiosCaller({
      path: `/api/files/${data.id}`,
      method: 'get',
      passToken: true,
      responseDataKey: 'file',
      responseValidatorObj: FileSchema,
      responseFormatValidatorMessage: 'Data mismatch for get file response',
    });
  },
  uploadFile: async ({
    formData,
    uploadUrl,
    uploadProgress,
  }: {
    formData: FormData;
    uploadUrl: string;
    uploadProgress?: (percentCompleted: number) => void;
  }): Promise<void> => {
    await axiosCaller({
      path: uploadUrl,
      doNotPrependToPath: true,
      method: 'post',
      data: formData,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = !progressEvent.total
          ? 0
          : Math.round((progressEvent.loaded * 100) / progressEvent.total);
        uploadProgress?.(percentCompleted);
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },
};

export { fileApiCalls };
