import {
  CreateJobRequest,
  CreateJobResponse,
  CreateJobResponseSchema,
  Job,
  JobSchema,
  JobsMeRequest,
  JobRequest,
  JobResponse,
  JobResponseSchema,
  JobsRequest,
  ApplyJobRequest,
  JobApplicationsRequest,
  ApplicationSchema,
  Application,
  AcceptApplicationRequest,
  DeclineApplicationRequest,
  ApplicationRequest,
  UpdateJobRequest,
  DeleteJobRequest,
} from '@wu/business';

import { MB_InfinitePaginationPage } from '@workingu/rnw.utils.util-hooks';

import { zodUtils } from '../utils/zod/zodUtils';
import { axiosCaller } from './customAxios';

const jobsApiCalls = {
  createJob: async (params: CreateJobRequest): Promise<CreateJobResponse> => {
    return await axiosCaller({
      path: '/api/jobs',
      method: 'post',
      responseValidatorObj: CreateJobResponseSchema,
      responseFormatValidatorMessage: 'Data mismatch for create job',
      passToken: true,
      data: params,
    });
  },
  updateJob: async (params: UpdateJobRequest): Promise<Job> => {
    return await axiosCaller({
      path: `/api/jobs/${params.id}`,
      method: 'patch',
      responseValidatorObj: JobSchema,
      responseDataKey: 'job',
      responseFormatValidatorMessage: 'Data mismatch for update job',
      passToken: true,
      data: params,
    });
  },
  deleteJob: async (params: DeleteJobRequest): Promise<null> => {
    return await axiosCaller({
      path: `/api/jobs/${params.id}`,
      method: 'delete',
      passToken: true,
      data: params,
    });
  },
  getAppliedJobs: async (params: JobsMeRequest): Promise<MB_InfinitePaginationPage<Job>> => {
    return await axiosCaller({
      path: '/api/jobs/applied',
      method: 'get',
      responseValidatorObj: zodUtils.getInfinitePagination(JobSchema),
      responseFormatValidatorMessage: 'Data mismatch for get jobs for contractor',
      passToken: true,
      params,
    });
  },
  getCurrentJobs: async (params: JobsMeRequest): Promise<MB_InfinitePaginationPage<Job>> => {
    return await axiosCaller({
      path: '/api/jobs/current',
      method: 'get',
      responseValidatorObj: zodUtils.getInfinitePagination(JobSchema),
      responseFormatValidatorMessage: 'Data mismatch for get jobs for contractor',
      passToken: true,
      params,
    });
  },

  getJobsForContractor: async (params: JobsMeRequest): Promise<MB_InfinitePaginationPage<Job>> => {
    return await axiosCaller({
      path: '/api/jobs/me',
      method: 'get',
      responseValidatorObj: zodUtils.getInfinitePagination(JobSchema),
      responseFormatValidatorMessage: 'Data mismatch for get jobs for contractor',
      passToken: true,
      params,
    });
  },
  getAllJobs: async (params: JobsRequest): Promise<MB_InfinitePaginationPage<Job>> => {
    return await axiosCaller({
      path: '/api/jobs',
      method: 'get',
      responseValidatorObj: zodUtils.getInfinitePagination(JobSchema),
      responseFormatValidatorMessage: 'Data mismatch for get jobs for contractor',
      passToken: true,
      params,
    });
  },
  getJobApplications: async (
    params: JobApplicationsRequest
  ): Promise<MB_InfinitePaginationPage<Application>> => {
    return await axiosCaller({
      path: `/api/jobs/${params.id}/applications}`,
      method: 'get',
      responseValidatorObj: zodUtils.getInfinitePagination(ApplicationSchema),
      responseFormatValidatorMessage: 'Data mismatch for get job applications',
      passToken: true,
      params,
    });
  },
  getRecommendedJobs: async (params: JobsMeRequest): Promise<MB_InfinitePaginationPage<Job>> => {
    return await axiosCaller({
      path: '/api/jobs/recommended',
      method: 'get',
      responseValidatorObj: zodUtils.getInfinitePagination(JobSchema),
      responseFormatValidatorMessage: 'Data mismatch for get jobs for contractor',
      passToken: true,
      params,
    });
  },
  getJob: async (params: JobRequest): Promise<JobResponse> => {
    return await axiosCaller({
      path: `/api/jobs/${params.id}`,
      method: 'get',
      responseValidatorObj: JobResponseSchema,
      responseFormatValidatorMessage: 'Data mismatch for get job',
      passToken: true,
    });
  },
  getApplication: async (params: ApplicationRequest): Promise<Application> => {
    return await axiosCaller({
      path: `/api/applications/${params.id}`,
      method: 'get',
      responseDataKey: 'application',
      responseValidatorObj: ApplicationSchema,
      responseFormatValidatorMessage: 'Data mismatch for get application',
      passToken: true,
    });
  },
  applyForJob: async (params: ApplyJobRequest): Promise<void> => {
    return await axiosCaller({
      path: `/api/jobs/${params.id}/apply`,
      method: 'post',
      passToken: true,
    });
  },

  declineApplication: async (params: DeclineApplicationRequest): Promise<Application> => {
    return await axiosCaller({
      path: `/api/applications/decline`,
      method: 'post',
      passToken: true,
      responseDataKey: 'application',
      responseValidatorObj: ApplicationSchema,
      data: params,
    });
  },

  acceptApplication: async (params: AcceptApplicationRequest): Promise<Application> => {
    return await axiosCaller({
      path: `/api/applications/accept`,
      method: 'post',
      responseDataKey: 'application',
      responseValidatorObj: ApplicationSchema,
      passToken: true,
      data: params,
    });
  },
};

export { jobsApiCalls };
