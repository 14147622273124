import { useCallback, useState } from 'react';
import { ZodString } from 'zod';

export function useTextLimiter(limit: number) {
  const [limitedText, setText] = useState('');

  const onChangeText = useCallback(
    (text: string) => {
      const newText = text.slice(0, limit);
      setText(newText);
    },
    [limit]
  );

  return [limitedText, onChangeText] as const;
}

export function useTrimmedText(initialText?: string) {
  const [trimmedText, setText] = useState(initialText || '');

  const onChangeText = useCallback(
    (text: string) => {
      const newText = text.trim();
      setText(newText);
    },
    [setText]
  );

  return [trimmedText, onChangeText] as const;
}

export function useValidatedText(schema: ZodString, initialValue?: string) {
  const [value, setValue] = useState(initialValue);

  const onValueChange = useCallback(
    (text: string) => {
      if (text === '') {
        setValue(text);
        return;
      }
      const parsed = schema.safeParse(text);
      if (parsed.success) {
        setValue(parsed.data);
        return;
      }
    },
    [setValue, schema]
  );

  return [value, onValueChange] as const;
}
