import { isMobileApp } from '@workingu/rnw.utils.device-info';
import { NativeModules } from 'react-native';

// NOTE: process.env.flavor is being set by webpack but is not recognized by turbo.
// eslint-disable-next-line turbo/no-undeclared-env-vars
const webFlavor = process.env.flavor;

const appFlavor = (isMobileApp ? NativeModules.RNConfig.flavor : webFlavor) as
  | 'dev'
  | 'staging'
  | 'prod'
  | undefined;

// const appFlavor = 'dev';

if (!appFlavor || (appFlavor !== 'prod' && appFlavor !== 'staging' && appFlavor !== 'dev')) {
  throw 'Error: Invalid flavor specified. Must be "dev", "staging", or "prod"';
}

const serverURLs: { dev: string; staging: string; prod: string } = {
  dev: 'http://localhost:4000',
  staging: 'https://api.staging.workingunited.com',
  prod: 'https://api.workingunited.com',
};

const websiteBaseUrls: { dev: string; staging: string; prod: string } = {
  dev: 'http://localhost:8001',
  staging: 'https://app.staging.workingunited.com',
  prod: 'https://app.workingunited.com',
};

const locationPickerKeys = {
  dev: 'AIzaSyAgC25-z0VOVjc0FMgu-MqgieL8fkM4w18',
  staging: 'AIzaSyAgC25-z0VOVjc0FMgu-MqgieL8fkM4w18',
  prod: 'AIzaSyAgC25-z0VOVjc0FMgu-MqgieL8fkM4w18',
};

const envs = {
  FLAVOR: appFlavor,
  SERVER_URL: serverURLs[appFlavor],
  WEBSITE_BASE_URL: websiteBaseUrls[appFlavor],
  MOBILE_DEEP_LINK: 'wu://',
  MOBILE_DEEP_LINK_FOR_SERVER_REDIRECT: 'wu-server-redirect://',
  LOCATION_PICKER_API_KEY: locationPickerKeys[appFlavor],
};

export { envs };
