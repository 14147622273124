import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import { useNavigation } from "@react-navigation/core";
import React, { useCallback } from "react";
import { View, Text, StyleSheet, FlatList, Dimensions } from "react-native";
import { COLORS } from "../../../../constants/colors";
import { textStyles } from "../../../../constants/textStyles";
import { UniversalScreenNavigationProp } from "../../../../typesAndInterfaces/componentProps";
import { Job } from "@wu/business";
import { useRenderJobs } from "./renderJobs";
import { FlatListLoadingIndicator } from "../../../helperComponents/FlatListLoadingIndicator";

const RecommendedJobs = (props: Props) => {
    const { jobs, isLoading } = props
    const { renderJobs } = useRenderJobs(styles.jobContainer)

    const navigation = useNavigation<UniversalScreenNavigationProp>();

    const ItemSeparatorComponent = useCallback(() => <View style={styles.separator} />, []);


    if (!jobs.length) {
        return null;
    }

    return (
        <View>
            <View style={styles.header}>
                <View>
                    <Text style={styles.title}>Recommended For You</Text>
                    <Text style={styles.subTitle}>Based on your profile information</Text>
                </View>
                <Text style={styles.viewAll} onPress={() => navigation.navigate('ViewAll', { type: "Recommended" })}>View all</Text>
            </View>
            <FlatList
                horizontal
                data={jobs}
                contentContainerStyle={styles.flatListContentContainer}
                style={styles.flatList}
                renderItem={renderJobs}
                ItemSeparatorComponent={ItemSeparatorComponent}
                ListFooterComponent={ isLoading ? FlatListLoadingIndicator : null}
            />
        </View>
    )
}

interface Props { 
    jobs: Job[]
    isLoading: boolean
}

export { RecommendedJobs };

const styles = StyleSheet.create({
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 18,
        marginTop: 19,
    },
    jobContainer: {
        width: Dimensions.get('window').width * 0.85,
    },
    title: mbTextStyles([textStyles.smallText, {
        fontSize: 15,
        color: COLORS.headerText,
        textAlign: 'left',
    }]),
    subTitle: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.greyText,
        textAlign: 'left',
    }]),
    viewAll: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.primaryColor,
        textDecorationLine: 'underline',
    }]),
    flatList: {
        marginHorizontal: -20,
    },
    flatListContentContainer: {
        paddingHorizontal: 20,
    },
    separator: {
        width: 9,
    },
})
