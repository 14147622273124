import React, { useCallback, useMemo, useState } from "react";
import { MB_ModalMobileBottom } from "@workingu/rnw.components.modal";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { MB_Button } from "@workingu/rnw.components.button";
import { COLORS } from "../../constants/colors";
import { textStyles } from "../../constants/textStyles";
import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import { Checkbox } from "../helperComponents/Checkbox";
import { useCreateEmployeeReview } from "../../hooks/userHooks";
import { Application } from "@wu/business";
import { MB_Image, mbGetSmallestImage } from "@workingu/rnw.components.image";
import { imageUtils } from "../../utils/imageUtils";
import { Rating } from "../helperComponents/Rating";



const RoundCheck = ({ isChecked, onChange }: { isChecked: boolean, onChange?: (isChecked: boolean) => void }) => {
    return (
        <TouchableOpacity style={styles.circleContainer} onPress={() => onChange?.(!isChecked)}>
            {isChecked && <View style={styles.circle} />}
        </TouchableOpacity>
    )
}

type ReviewModalProps = {
    isVisible: boolean,
    employee: Application['employee'],
    jobId: string, onDismiss?: () => void
    applicationId: string,
}

const ReviewModal = ({ isVisible, employee, jobId, onDismiss, applicationId }: ReviewModalProps) => {

    const { mutate: createEmployeeReview, isLoading: isCreatingReview } = useCreateEmployeeReview();

    const [isReviewQuestionsVisible, showReviewQuestions] = useState(false);
    const [isEmployeeNotShowUp, setEmployeeShowUp] = useState(false);
    const [questions, setQuestions] = useState<{ rating: number, answer?: boolean, title: string }[]>([
        { rating: 0, title: 'Is the employee knowledgeable in their field?' },
        { rating: 0, title: 'Was the employee on time?' },
        { rating: 0, title: 'Did the employee have appropriate tools?' },
        { rating: 0, title: 'Would you hire the employee again?' },
    ])

    const updateQuestion = useCallback((questionIndex, params: { stars?: number, answer?: boolean }) => {
        setQuestions(oldQuestions => {
            if (params.stars !== undefined) {
                oldQuestions[questionIndex].rating = params.stars;
            }
            if (params.answer !== undefined) {
                oldQuestions[questionIndex].answer = params.answer;
            }
            return [...oldQuestions];
        })
    }, []);

    const isDisabled = isReviewQuestionsVisible && !isEmployeeNotShowUp && (questions.some(question => !question.rating || question.answer === undefined))

    const onSubmit = useCallback(() => {
        if (!isReviewQuestionsVisible) {
            showReviewQuestions(true)
        } else {
            createEmployeeReview({
                id: employee.id,
                applicationId,
                jobId,
                isAbsent: isEmployeeNotShowUp,
                questions: isEmployeeNotShowUp ? [] : questions.map(question => ({ ...question, answer: question.answer ?? false }))
            }, {
                onSuccess: () => onDismiss?.(),
                onError: () => { }
            })
        }
    }, [createEmployeeReview, employee.id,onDismiss, isEmployeeNotShowUp, isReviewQuestionsVisible, jobId, questions, applicationId]);

    const profile = useMemo(() => {
        if (isReviewQuestionsVisible) {
            return (
                <View style={styles.quetions}>
                    <View style={styles.row}>
                        <Checkbox isChecked={isEmployeeNotShowUp} onPress={() => setEmployeeShowUp(v => !v)} />
                        <Text onPress={() => setEmployeeShowUp(v => !v)} style={[styles.question, { marginStart: 7 }]}>Mark this box if the employee didn’t show up at all</Text>
                    </View>
                    {!isEmployeeNotShowUp &&
                        <>
                            {questions.map((question, index) => (
                                <View key={`${index}`}>
                                    <View style={[styles.row, styles.questionTop]}>
                                        <Text style={styles.question}>{question.title} <Text style={styles.required}>*</Text></Text>
                                        <Rating rating={question.rating} onRatingChange={(stars) => updateQuestion(index, { stars })} />
                                    </View>
                                    <View style={[styles.row, styles.answerTop]}>
                                        <RoundCheck isChecked={question.answer ?? false} onChange={(isChecked) => updateQuestion(index, { answer: isChecked })} />
                                        <Text style={[styles.question, styles.questionYesNo]} onPress={() => updateQuestion(index, { answer: true })}>Yes</Text>
                                        <RoundCheck isChecked={question.answer === false} onChange={(isChecked) => updateQuestion(index, { answer: !isChecked })} />
                                        <Text style={[styles.question, styles.questionYesNo]} onPress={() => updateQuestion(index, { answer: false })}>No</Text>
                                    </View>
                                </View>
                            ))}
                        </>
                    }
                </View>
            )
        }

        const profileImageUrl = mbGetSmallestImage(employee.profileImage) ?? imageUtils.images.emptyProfileIcon;

        return (
            <View>
                <MB_Image source={profileImageUrl} style={styles.profile} resizeMode='contain' disableInteraction />
                <Text style={styles.fullName}>{employee.firstName} {employee.lastName}</Text>
            </View>
        );
    }, [employee, isEmployeeNotShowUp, isReviewQuestionsVisible, questions, updateQuestion]);

    return (
        <MB_ModalMobileBottom
            isVisible={isVisible}
            onDismiss={onDismiss}
            childrenWrapperStyle={styles.container}
            closeButtonXProps={{ color: COLORS.greyText, size: 15 }}
        >
            <Text style={styles.title}>Rate this employee</Text>
            <Text style={styles.subTitle}>This job has been completed. Please leave a rating and review for your employee.</Text>
            {profile}
            <MB_Button
                title={isReviewQuestionsVisible ? "Submit review" : "Continue"}
                style={styles.submitBtn}
                onPress={onSubmit}
                disabled={isDisabled || isCreatingReview}
                loading={isCreatingReview}
            />
        </MB_ModalMobileBottom>
    )
}

export { ReviewModal };

const styles = StyleSheet.create({
    container: {
        backgroundColor: COLORS.white,
        paddingHorizontal: 20,
        alignItems: 'stretch',
        paddingBottom: 32,
    },
    title: mbTextStyles([textStyles.largeText, {
        fontSize: 21,
        color: COLORS.headerText,
        textAlign: 'left',
    }]),
    subTitle: mbTextStyles([textStyles.smallerText, {
        color: COLORS.bodyText,
        textAlign: 'left',
        marginTop: 3,
    }]),
    submitBtn: {
        backgroundColor: COLORS.primaryColor,
        height: 50,
        borderRadius: 30,
        marginTop: 31,
    },
    profile: {
        width: 120,
        height: 120,
        borderRadius: 360,
        alignSelf: 'center',
    },
    fullName: mbTextStyles([textStyles.normalText, {
        fontSize: 15,
        color: COLORS.headerText,
        marginTop: 11,
    }]),
    row: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    questionTop: {
        marginTop: 21,
    },
    answerTop: {
        marginTop: 10,
    },
    quetions: {
        marginTop: 15,
    },
    question: mbTextStyles([textStyles.smallerText, {
        color: COLORS.bodyText,
        marginEnd: 8,
    }]),
    required: {
        color: COLORS.red,
    },
    questionYesNo: {
        marginEnd: 25,
        marginStart: 6,
    },
    star: {
        marginEnd: 4,
    },
    circleContainer: {
        height: 18,
        width: 18,
        borderRadius: 360,
        borderWidth: 1,
        borderColor: COLORS.primaryColor,
        alignItems: 'center',
        justifyContent: 'center',
    },
    circle: {
        height: 12,
        width: 12,
        backgroundColor: COLORS.primaryColor,
        borderRadius: 360,
    },
});
