import { CreateFileRequest, CreateFileResponse } from '@wu/business';

import { MB_ResourceUploaderFunctionProps } from '@workingu/rnw.components.resource-uploader';
import { UseMutateFunction } from '@tanstack/react-query';

import { STRING_CONSTANTS } from '../constants/constants';
import { UploadFileVariables } from '../hooks/fileHooks';
import { ServerError } from '../typesAndInterfaces/typesAndInterfaces';
import { utils } from './utils';
import { envs } from '../../env';

export const createUploader = (options: CreateUploaderOptions) => {
  const { getFileUploadUrl, uploadFile, onCompleted } = options;
  const uploader = (uploadProgress: UploadProgressListener) => {
    return (fileData: MB_ResourceUploaderFunctionProps) =>
      new Promise<void>((resolve, reject) => {
        const fileName = fileData.fileName ?? fileData.uri.substring(
          fileData.uri.lastIndexOf('/') + 1,
          fileData.uri.length
        );

        getFileUploadUrl(
          {
            name: fileName,
            contentType: fileData.mimeType,
            contentLength: fileData.fileSize,
          },
          {
            onSuccess: (data) => {
              const formData = utils.generateFormDataForFileUpload({
                blob: fileData.blob,
                uri: fileData.uri,
                extraFields: data.fields,
              });

              uploadFile(
                { formData, uploadUrl: data.url, uploadProgress },
                {
                  onSuccess: () => {
                    const file: File = {
                      id: data.file.id,
                      name: fileName,
                      uri: fileData.uri,
                      contentLength: fileData.fileSize,
                    };
                    onCompleted(file);
                    resolve();
                  },
                  onError: (err) => {
                    console.log('Error when uploading profile image in Profile', err);
                    reject(STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN + (envs.FLAVOR !== 'prod' ? err.message ?? JSON.stringify(err) : ''));
                  },
                }
              );
            },
            onError: (err) => {
              console.log('Error when requesting image upload url in profile', err);
              reject(STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN + (envs.FLAVOR !== 'prod' ? err.message ?? JSON.stringify(err) : ''));
            },
          }
        );
      });
  };
  return uploader;
};

export type File = {
  id: string;
  name: string;
  uri?: string;
  contentLength: number;
};

export type OnUploadCompleted = (file: File) => void;

type CreateUploaderOptions = {
  getFileUploadUrl: UseMutateFunction<CreateFileResponse, ServerError, CreateFileRequest, unknown>;
  uploadFile: UseMutateFunction<void, any, UploadFileVariables, unknown>;
  onCompleted: OnUploadCompleted;
};

export type UploadProgressListener = (percentCompleted: number) => void;
