import React from 'react';
import { MB_SVG_ChevronLeft, MB_SVG_ChevronRight } from '@workingu/rnw.components.svg-components';
import { mbApplyTransparency } from '@workingu/rnw.utils.style-utils';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';
import { PageIndicatorButton, PageIndicatorButtonProps } from './page-indicator-button';

// TODO: Add customization options.

export interface MB_PageIndicatorProps {
    current: number,
    pages: number,
    style?: StyleProp<ViewStyle>,
    onChange?: (page: number) => void,
    leftButtonProps?: Omit<PageIndicatorButtonProps, 'disabled' | 'onPress'>,
    rightButtonProps?: Omit<PageIndicatorButtonProps, 'disabled' | 'onPress'>,
    indicatorButtonProp?: Omit<PageIndicatorButtonProps, 'disabled' | 'onPress' | 'title'>,
}

const MB_PageIndicator = ({ current, pages, style, onChange, leftButtonProps, rightButtonProps, indicatorButtonProp }: MB_PageIndicatorProps) => {
    const getIndicators = () => {
        let indicators: (number | string)[] = [];
        for (let indicator = current - 6; indicator <= current + 6; indicator++) {
            if (indicator >= 1 && indicator <= pages) {
                indicators.push(indicator);
            }
        }

        if (pages <= 7) {
            return indicators;
        }

        const mainIndicator = indicators.findIndex(indicator => indicator === current);
        if (mainIndicator + 3 >= indicators.length) {
            indicators = indicators.slice(indicators.length - 7, indicators.length);
        }
        else if (mainIndicator - 3 < 0) {
            indicators = indicators.slice(0, 7);
        }
        else {
            indicators = indicators.slice(mainIndicator - 3, mainIndicator + 3 + 1);
        }
        if (Number(indicators[6]) <= pages - 1) {
            indicators[6] = pages;
            indicators[5] = '...';
        }
        if (Number(indicators[0]) >= 2) {
            indicators[0] = 1;
            indicators[1] = '...';
        }
        return indicators;
    };

    const onNextPage = () => (current < pages) && onChange?.(current + 1);
    const onPrevPage = () => (current > 1) && onChange?.(current - 1);

    const isPrevVisible = current > 1;
    const isNextVisible = current < pages;

    const indicators = getIndicators();

    return (
        <View style={[styles.container, style]}>
            <PageIndicatorButton
                title=''
                icon={<MB_SVG_ChevronLeft color="#FFFFFF" size={20} />}
                onPress={isPrevVisible ? onPrevPage : undefined}
                disabled={!isPrevVisible}
                {...leftButtonProps}
                containerStyle={[{ marginEnd: 8 }, leftButtonProps?.containerStyle]}
            />
            {indicators.map((indicator, index) => (
                <PageIndicatorButton
                    key={index}
                    title={indicator.toString()}
                    active={indicator === current}
                    onPress={(typeof indicator === 'string' || indicator === current) ? undefined : () => onChange?.(indicator)}
                    {...indicatorButtonProp}
                    containerStyle={[{ marginEnd: 8 }, indicatorButtonProp?.containerStyle]}
                />
            ))}
            <PageIndicatorButton
                title=''
                icon={<MB_SVG_ChevronRight color="#FFFFFF" size={20} />}
                onPress={isNextVisible ? onNextPage : undefined}
                disabled={!isNextVisible}
                {...rightButtonProps}
            />
        </View>
    );
};

export { MB_PageIndicator };

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignSelf: 'flex-end',
    },
    button: {
        height: 41.16,
        width: 41.16,
        borderRadius: 9.73,
        borderWidth: 1,
        borderColor: mbApplyTransparency('#BAC0CA', 0.30),
    },
});
