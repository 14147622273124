import React from "react";
import { Application } from "@wu/business";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import Feather from "react-native-vector-icons/Feather";
import FontAwesome from "react-native-vector-icons/FontAwesome";
import { COLORS } from "../../../constants/colors";
import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import { textStyles } from "../../../constants/textStyles";

interface BasicInformationProps {
    employee?: Application['employee']
}
const BasicInformation = (props: BasicInformationProps) => {

    const { employee } = props;

    if (!employee) {
        return null;
    }

    const isAboutAvailable = employee.about?.trim() !== ''

    return (
        <ScrollView contentContainerStyle={styles.tabScreenContainer}>
            {isAboutAvailable &&
                <>
                    <Text style={styles.sectionTitle}>About</Text>
                    <Text style={styles.sectionText}>{employee.about}</Text>
                </>
            }
            <Text style={[styles.sectionTitle, isAboutAvailable && { marginTop: 22 }]}>Contact information</Text>
            <View style={[styles.sectionInfo, { marginTop: 10 }]}>
                <FontAwesome name="phone" color={COLORS.primaryColor} size={13} />
                <Text style={styles.sectionInfoText}>{employee.mobileNumber}</Text>
            </View>
            <View style={[styles.sectionInfo, { marginTop: 12 }]}>
                <Feather name="mail" color={COLORS.primaryColor} size={13} />
                <Text style={styles.sectionInfoText}>{employee.email}</Text>
            </View>
        </ScrollView>
    );
};

export { BasicInformation };

const styles = StyleSheet.create({
    tabScreenContainer: {
        paddingHorizontal: 20,
        paddingVertical: 20,
    },
    sectionTitle: mbTextStyles([textStyles.smallText, {
        fontSize: 13,
        color: COLORS.bodyText,
        textAlign: 'left',
        fontWeight: '700',
    }]),
    sectionText: mbTextStyles([textStyles.smallerText, {
        color: COLORS.bodyText,
        textAlign: 'left',
        marginTop: 2,
    }]),
    sectionInfo: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    sectionInfoText: mbTextStyles([textStyles.smallText, {
        color: COLORS.bodyText2,
        textAlign: 'left',
        marginStart: 6.25,
    }]),
});
