import React from "react";
import { MB_Button } from "@workingu/rnw.components.button";
import { MB_ModalMobileBottom } from "@workingu/rnw.components.modal";
import { StyleSheet, Text } from "react-native";
import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import { COLORS } from "../../constants/colors";
import { textStyles } from "../../constants/textStyles";
import Feather from "react-native-vector-icons/Feather";


const DeleteLicenseModal = ({ isVisible, state, onDismiss, onDelete }: { isVisible: boolean, state: string, onDismiss?: () => void, onDelete?: () => void }) => {

    return (
        <MB_ModalMobileBottom
            isVisible={isVisible}
            onDismiss={onDismiss}
            childrenWrapperStyle={styles.container}
        >
            <Text style={styles.title}>Delete this license?</Text>
            <Text style={styles.description}>Are you sure you want to delete the license “<Text style={styles.important}>{state}</Text>” ? Please note that this action cannot be undone.</Text>
            <MB_Button
                title="Delete"
                style={styles.deleteBtn}
                textStyle={styles.deleteBtnText}
                leftElement={<Feather name="trash" color={COLORS.white} size={15} />}
                onPress={onDelete}
            />
            <MB_Button
                title="Cancel"
                style={styles.cancelBtn}
                textStyle={styles.cancelBtnText}
                onPress={onDismiss}
            />
        </MB_ModalMobileBottom>
    )
};

export { DeleteLicenseModal };

const styles = StyleSheet.create({
    container: {
        backgroundColor: COLORS.white,
        paddingHorizontal: 20,
        alignItems: 'stretch',
        paddingBottom: 32,
        paddingTop: 20,
    },
    title: mbTextStyles([textStyles.largeText, {
        fontSize: 21,
        color: COLORS.headerText,
        textAlign: 'left',
        fontWeight: '800',
    }]),
    description: mbTextStyles([textStyles.smallerText, {
        color: COLORS.bodyText,
        textAlign: 'left',
        marginTop: 3,
    }]),
    important: mbTextStyles([textStyles.smallerText, {
        color: COLORS.bodyText,
        fontWeight: '600',
    }]),
    deleteBtn: {
        backgroundColor: COLORS.red,
        borderRadius: 30,
        height: 50,
        marginTop: 25,
    },
    deleteBtnText: {
        color: COLORS.white
    },
    cancelBtn: {
        backgroundColor: 'transparent',
        borderRadius: 30,
        height: 50,
        borderWidth: 1,
        borderColor: COLORS.primaryColor,
        marginTop: 10,
    },
    cancelBtnText: {
        color: COLORS.primaryColor,
    }
})