import { MB_Button } from "@workingu/rnw.components.button";
import { mbApplyTransparency, mbTextStyles } from "@workingu/rnw.utils.style-utils";
import { useNavigation } from "@react-navigation/core";
import React, { useCallback, useMemo, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { COLORS } from "../../../constants/colors";
import { textStyles } from "../../../constants/textStyles";
import { UniversalScreenNavigationProp } from "../../../typesAndInterfaces/componentProps";
import { ComponentWrapper } from "../../helperComponents/componentWrapper/ComponentWrapper";
import { useGetContractorData, useGetEmployeeData, useUpdateContractorData, useUpdateEmployeeData } from "../../../hooks/userHooks";
import { mbShowPopUp } from "@workingu/rnw.components.pop-up";
import { STRING_CONSTANTS } from "../../../constants/constants";
import { useSignedInContext } from "../../../context/SignedInContext";
import { Dropdown } from "../../helperComponents/Dropdown";
import { Trades } from "../../helperComponents/Trades";
import { MB_TextInput } from "@workingu/rnw.components.text-input";
import { ProfileImageUploader } from "../../helperComponents/ProfileImageUploader";
import { mbShowToast } from "@workingu/rnw.components.toast";
import { CompanySize, MobileNumberSchema, UserRole } from "@wu/business";
import { isEqual } from 'lodash'

const EditAccountDetails = () => {

    const navigation = useNavigation<UniversalScreenNavigationProp>();
    const { mutate: updateEmployeeData, isLoading: isUpdateEmployeeDataLoading } = useUpdateEmployeeData();
    const { mutate: updateContractorData, isLoading: isUpdateContractorDataLoading } = useUpdateContractorData();
    const { currentUserData } = useSignedInContext();

    const isContractor = currentUserData?.role === UserRole.Contractor

    const { data: employeeData } = useGetEmployeeData({ enabled: !isContractor });
    const { data: contractorData } = useGetContractorData({ enabled: isContractor });

    const { profileImage, email } = (employeeData || contractorData) || {};

    const [firstName, setFirstName] = useState(employeeData?.firstName ?? '');
    const [lastName, setLastName] = useState(employeeData?.lastName ?? '');
    const [mobileNumber, setMobileNumber] = useState(employeeData?.mobileNumber ?? '');

    const [name, setName] = useState(contractorData?.name ?? '');
    const companySizes = useMemo(() => Object.values(CompanySize).map(size => `${size} employees`), []);
    const [companySizeIndex, setCompanySizeIndex] = useState<number | undefined>(contractorData?.size ? Object.values(CompanySize).indexOf(contractorData?.size) : undefined);
    const [trades, setTrades] = useState<string[]>(contractorData?.trades ?? []);

    const companySize = companySizeIndex !== undefined ? Object.values(CompanySize)[companySizeIndex] : undefined;

    const isPhoneNumberValid = MobileNumberSchema.safeParse(mobileNumber).success

    const isContractorDataNotChanged = name === contractorData?.name && isEqual(trades, contractorData?.trades) && contractorData?.size === companySize;
    const isEmployeeDataNotChanged = employeeData?.firstName === firstName && employeeData.lastName === lastName && employeeData.mobileNumber === mobileNumber;
    const isDisabled = isContractor ? (trades.length === 0 || !name || isContractorDataNotChanged) : (firstName === '' || lastName === '' || mobileNumber === '' || !isPhoneNumberValid || isEmployeeDataNotChanged);

    const onSave = useCallback(() => {
        if (isContractor) {
            updateContractorData({
                name,
                trades,
                size: companySize,
            }, {
                onSuccess: () => {
                    mbShowToast({ text1: 'Changes saved', text2: '', position: 'bottom' });
                },
                onError: (error) => {
                    mbShowPopUp({
                        title: 'Error',
                        message: error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN,
                    });
                },
            })
        } else {
            updateEmployeeData({
                firstName,
                lastName,
                mobileNumber
            }, {
                onSuccess: () => {
                    mbShowToast({ text1: 'Changes saved', text2: '', position: 'bottom' });
                },
                onError: (error) => {
                    mbShowPopUp({
                        title: 'Error',
                        message: error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN,
                    });
                },
            })
        }
    }, [isContractor, updateContractorData, name, trades, companySize, updateEmployeeData, firstName, lastName, mobileNumber]);

    const onProfileImageUploaded = useCallback((fileId: string) => {
        if (currentUserData?.role === UserRole.Employee) {
            updateEmployeeData({ fileId }, {
                onSuccess: () => {
                    mbShowToast({ text1: `Profile photo updated`, text2: '', position: 'bottom' });
                },
            });
        } else {
            updateContractorData({ profileImageFileId: fileId });
        }
    }, [currentUserData?.role, updateContractorData, updateEmployeeData]);

    return (
        <ComponentWrapper
            mobileHeaderOptions={{ showHeader: true, showBackArrow: true }}
            keyboardShouldPersistTapsForScrollView="handled"
        >
            <Text style={[textStyles.largeText, styles.title]}>Edit Account Details</Text>
            <Text style={[textStyles.smallerText, styles.subTitle]}>Edit your account information below and save changes.</Text>
            <ProfileImageUploader
                profileImage={profileImage}
                onSuccess={onProfileImageUploaded}
            />
            <View style={[styles.profile]}>
                <Text style={styles.email}>{email}</Text>
            </View>



            {!isContractor &&
                <>
                    <View style={styles.row}>
                        <View style={styles.inputContainer}>
                            <Text style={styles.inputTitle}>First Name <Text style={styles.required}>*</Text></Text>
                            <MB_TextInput
                                placeholder="Ryan"
                                value={firstName}
                                onChangeText={setFirstName}
                                placeholderTextColor={COLORS.greyText}
                            />
                        </View>
                        <View style={{ width: 7 }} />
                        <View style={styles.inputContainer}>
                            <Text style={styles.inputTitle}>Last Name <Text style={styles.required}>*</Text></Text>
                            <MB_TextInput
                                placeholder="George"
                                value={lastName}
                                onChangeText={setLastName}
                                placeholderTextColor={COLORS.greyText}
                            />
                        </View>
                    </View>
                    <Text style={styles.inputTitle}>Email Address <Text style={styles.required}>*</Text></Text>
                    <MB_TextInput
                        placeholder="ryangeorge@gmail.com"
                        value={email}
                        disable
                        style={{ backgroundColor: COLORS.greyBG }}
                        textInputStyle={{ color: COLORS.greyText }}
                        placeholderTextColor={COLORS.greyText}
                    />
                    <Text style={styles.inputTitle}>Phone number <Text style={styles.required}>*</Text></Text>
                    <MB_TextInput
                        placeholder="123-456-7890"
                        value={mobileNumber}
                        onChangeText={setMobileNumber}
                        placeholderTextColor={COLORS.greyText}
                        isError={mobileNumber !== '' && !isPhoneNumberValid}
                    />
                </>
            }
            {isContractor &&
                <>
                    <Text style={styles.inputTitle}>Company Name <Text style={styles.required}>*</Text></Text>
                    <MB_TextInput
                        placeholder="Company Name"
                        value={name}
                        onChangeText={setName}
                        placeholderTextColor={COLORS.greyText}
                    />
                    <Text style={styles.inputTitle}>Company Size</Text>
                    <Dropdown
                        options={companySizes}
                        option={companySizeIndex !== undefined ? companySizes[companySizeIndex] : undefined}
                        onSelect={(_, index) => setCompanySizeIndex(index)}
                    />
                    <Text style={styles.inputTitle}>Classification/Trades <Text style={styles.required}>*</Text></Text>
                    <Trades
                        trades={trades}
                        onChange={setTrades}
                    />
                </>
            }
            <MB_Button
                title="Change password"
                style={styles.changePasswordBtn}
                textStyle={styles.changePasswordBtnText}
                onPress={() => navigation.navigate('ChangePassword')}
            />
            <View style={styles.space} />
            <MB_Button
                title="Save changes"
                style={styles.saveChangeBtn}
                textStyle={textStyles.smallerText}
                onPress={onSave}
                loading={isUpdateContractorDataLoading || isUpdateEmployeeDataLoading}
                disabled={isDisabled || isUpdateContractorDataLoading || isUpdateEmployeeDataLoading}
            />
        </ComponentWrapper>
    );
};

export { EditAccountDetails };

const styles = StyleSheet.create({
    title: {
        color: COLORS.headerText,
        textAlign: 'left',
        letterSpacing: -0.8,
    },
    subTitle: {
        color: COLORS.greyText,
        textAlign: 'left',
        marginBottom: 23,
    },
    profilePicture: {
        width: 90,
        height: 90,
        backgroundColor: COLORS.bluishGrey,
        alignSelf: 'center',
        borderRadius: 360,
        overflow: 'hidden',
        marginBottom: 12,
    },
    emptyBackground: {
        backgroundColor: mbApplyTransparency(COLORS.black, 0.5),
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    row: {
        flexDirection: 'row',
        width: '100%',
    },
    profile: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
    },
    inputContainer: {
        flex: 1,
    },
    inputTitle: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.bodyText,
        marginTop: 15,
        marginBottom: 7,
        textAlign: 'left',
    }]),
    email: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.primaryColor,
        marginTop: 15,
        marginBottom: 7,
        textAlign: 'left',
    }]),
    required: {
        color: COLORS.red,
    },
    changePasswordBtn: {
        height: 44,
        borderRadius: 30,
        borderWidth: 1,
        borderColor: COLORS.primaryColor,
        marginTop: 28,
        backgroundColor: 'transparent',
    },
    changePasswordBtnText: mbTextStyles([textStyles.smallerText, {
        color: COLORS.primaryColor,
    }]),
    space: {
        marginTop: 'auto',
        minHeight: 10,
    },
    saveChangeBtn: {
        height: 50,
        backgroundColor: COLORS.primaryColor,
        borderRadius: 30,
    },
});
