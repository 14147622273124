import { MB_Button } from "@workingu/rnw.components.button";
import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import React, { useState, useCallback } from "react";
import { StyleSheet, Text } from "react-native";
import { COLORS } from "../../../constants/colors";
import { textStyles } from "../../../constants/textStyles";
import { ComponentWrapper } from "../../helperComponents/componentWrapper/ComponentWrapper"
import { MB_LocationPicker } from "@workingu/rnw.components.location-picker";
import { Address as LocationPickerAddress } from "@workingu/rnw.utils.util-hooks";
import { envs } from "../../../../env";
import { useGetEmployeeData, useUpdateEmployeeData } from "../../../hooks/userHooks";
import { mbShowPopUp } from "@workingu/rnw.components.pop-up";
import { STRING_CONSTANTS } from "../../../constants/constants";
import { MB_TextInput } from "@workingu/rnw.components.text-input";
import { Address } from "@wu/business";
import { mbShowToast } from "@workingu/rnw.components.toast";

const EditPersonalInformation = () => {

    const { mutate: updateEmployeeData, isLoading } = useUpdateEmployeeData();
    const { data: employeeData } = useGetEmployeeData();

    const [aboutYou, setAboutYou] = useState(employeeData?.about ?? '');
    const [address, setAddress] = useState<Address | undefined>(employeeData?.address);

    const isDisabled = !address

    const onSave = useCallback(() => {
        updateEmployeeData({
            about: aboutYou,
            address,
        }, {
            onSuccess: () => {
                mbShowToast({ text1: `Personal information updated`, text2: '', position: 'bottom' });
            },
            onError: (error) => {
                mbShowPopUp({
                    title: 'Error',
                    message: error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN,
                });
            },
        })
    }, [aboutYou, address, updateEmployeeData]);

    const onLocationPickerSelect = useCallback((newAddr: LocationPickerAddress) => {
        if (!newAddr.text || !newAddr.state || !newAddr.city || !newAddr.country || !newAddr.postalCode || !newAddr.lat || !newAddr.lng) {
            mbShowPopUp({ title: STRING_CONSTANTS.ERROR, message: STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN });
            // TODO: We should probably change the Address name that is coming from util-hooks. Also, why is it coming from util hooks and not from LocationPicker?
            return;
        }

        setAddress({
            text: newAddr.text,
            state: newAddr.state,
            city: newAddr.city,
            country: newAddr.country,
            postalCode: newAddr.postalCode,
            lat: newAddr.lat,
            lng: newAddr.lng,
        })
    }, []);

    // TODO: Need to figure out what we are doing with address selector

    return (
        <ComponentWrapper mobileHeaderOptions={{ showHeader: true, showBackArrow: true }} keyboardShouldPersistTapsForScrollView="handled">
            <Text style={[textStyles.largeText, styles.title]}>Edit Personal Information</Text>
            <Text style={[textStyles.smallerText, styles.subTitle]}>Edit your personal information below and save changes.</Text>
            <Text style={styles.inputTitle}>About You</Text>
            <MB_TextInput
                placeholder="Tell us more about you..."
                multiline
                style={styles.aboutYou}
                maxLength={100}
                value={aboutYou}
                onChangeText={setAboutYou}
                placeholderTextColor={COLORS.greyText}
            />
            <Text style={styles.inputTitle}>Location <Text style={styles.required}>*</Text></Text>
            <MB_LocationPicker
                apiKey={envs.LOCATION_PICKER_API_KEY}
                onSelect={onLocationPickerSelect}
                value={address?.text}
                arrowColor={COLORS.greyText}
                highlightColor={COLORS.greyText}
            />
            <MB_Button
                title="Save changes"
                style={styles.saveChangeBtn}
                textStyle={textStyles.smallerText}
                onPress={onSave}
                disabled={isDisabled || isLoading}
                loading={isLoading}
            />
        </ComponentWrapper>
    )
}

export { EditPersonalInformation };

const styles = StyleSheet.create({
    title: {
        color: COLORS.headerText,
        textAlign: 'left',
        letterSpacing: -0.8,
    },
    subTitle: {
        color: COLORS.greyText,
        textAlign: 'left',
        marginBottom: 27,
    },
    inputTitle: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.bodyText,
        marginTop: 15,
        marginBottom: 7,
        textAlign: 'left',
    }]),
    aboutYou: {
        height: 100,
    },
    required: {
        color: COLORS.red,
    },
    saveChangeBtn: {
        height: 50,
        backgroundColor: COLORS.primaryColor,
        borderRadius: 30,
        marginTop: 'auto',
    },
});
