import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import React, { useCallback, useMemo } from "react";
import { StyleSheet, Text, View } from "react-native";
import Feather from "react-native-vector-icons/Feather";
import { COLORS } from "../../constants/colors";
import { textStyles } from "../../constants/textStyles";
import { ComponentWrapper } from "../helperComponents/componentWrapper/ComponentWrapper"
import { format as formatDate, parseISO, addDays, differenceInCalendarDays } from "date-fns";
import { useApplyForJob, useDeleteJob, useGetJob } from "../../hooks/jobsHooks";
import { JobDetailsProps, UniversalScreenNavigationProp } from "../../typesAndInterfaces/componentProps";
import { LOADING_PAGE_GIVE_UP_ACTION_TYPES, LoadingPage } from "../helperComponents/LoadingPage";
import { useGetCurrentUserData } from "../../hooks/userHooks";
import { UserRole } from "@wu/business";
import { MB_Button } from "@workingu/rnw.components.button";
import { mbShowPopUp } from "@workingu/rnw.components.pop-up";
import { STRING_CONSTANTS } from "../../constants/constants";
import { DeleteJobModal } from "../modals/DeleteJob";
import { MB_utilHooks } from "@workingu/rnw.utils.util-hooks";
import { CreateJobsPopUp } from "../helperComponents/CreateJobsPopUp";
import { useNavigation } from "@react-navigation/core";
import { mbShowToast } from "@workingu/rnw.components.toast";
import { MB_Image } from "@workingu/rnw.components.image";


const JobDetails = ({ route }: JobDetailsProps) => {

    // TODO: I don`t like that job is sent in a job.job
    const { data: currentUserData } = useGetCurrentUserData({});
    const { data, isLoading } = useGetJob({ jobId: route.params.jobId });
    const { mutate: deleteJob, isLoading: isDeleting } = useDeleteJob()
    const { mutate: applyForJob, isLoading: isApplyForJobLoading } = useApplyForJob();
    const navigation = useNavigation<UniversalScreenNavigationProp>();

    const [isDeleteModalVisible, showDeleteModal, hideDeleteModal] = MB_utilHooks.useBool();
    const [isRepostEditJobModalVisible, showRepostEditJobModal, hideRepostEditJobModal] = MB_utilHooks.useBool();

    const canApply = currentUserData?.role === UserRole.Employee && !data?.job.isApplied;
    const canEdit = currentUserData?.role === UserRole.Contractor && !data?.job.completed;
    const canRepost = currentUserData?.role === UserRole.Contractor && data?.job.completed

    const jobId = route.params.jobId;

    const job = useMemo(() => {
        const baseJob = data?.job;
        if(isRepostEditJobModalVisible && baseJob){
            const startDate = new Date();
            const prevStartDate = parseISO(baseJob.startDate)
            const prevEndDate = parseISO(baseJob.endDate)
            const days = differenceInCalendarDays(prevEndDate, prevStartDate);
            const endDate = addDays(startDate, days)
            return {
                ...data?.job,
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
            }    
        }

        return baseJob;
        
    }, [data, isRepostEditJobModalVisible]);

    const onApply = useCallback(() => {
        applyForJob({ id: jobId }, {
            onSuccess: () => {
                mbShowToast({ text1: `Application successful`, text2: '', position: 'bottom', bottomOffset: 120 })
            },
            onError: (error) => {
                mbShowPopUp({
                    title: 'Error',
                    message: error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN,
                });
            }
        })
    }, [applyForJob, jobId]);

    const onDelete = useCallback(() => {
        deleteJob({ id: jobId }, {
            onSuccess: () => {
                hideDeleteModal();
                navigation.navigate('Home', { screen: 'MyJobs' })
            },
            onError: (error) => {
                mbShowPopUp({
                    title: 'Error',
                    message: error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN,
                });
            }
        })
    }, [deleteJob, jobId, hideDeleteModal, navigation]);

    if (isLoading || !job) {
        return (
            <LoadingPage giveUpActionTypes={LOADING_PAGE_GIVE_UP_ACTION_TYPES.signOut} />
        );
    }


    const image = job.image?.url ?
        <MB_Image source={job.image.url} style={styles.logo} resizeMode='contain' disableInteraction />
        : <View style={styles.logo} />;
    
    return (
        <ComponentWrapper
            mobileHeaderOptions={{ showHeader: true, showBackArrow: true, pageTitle: 'Job Details' }}
        >
            <View style={styles.container}>
                {image}
                <Text style={styles.title}>{job.title}</Text>
                <Text style={styles.company}>{job.contractor}</Text>
                <View style={styles.rangeContainer}>
                    <Text style={styles.range}>${job.hourlyRate[0]}-${job.hourlyRate[1]}/hr</Text>
                </View>
                <View style={styles.details}>
                    <View style={styles.detail}>
                        <Feather name="calendar" size={20} color={COLORS.bodyText} />
                        <Text style={styles.detailText}>{formatDate(parseISO(job.startDate), 'dd MMMM')}-{formatDate(parseISO(job.endDate), 'dd MMMM')}</Text>
                    </View>
                    <View style={styles.detail}>
                        <Feather name="clock" size={20} color={COLORS.bodyText} />
                        <Text style={styles.detailText}>{formatDate(parseISO(job.startTime), 'h:mm a')}-{formatDate(parseISO(job.endTime), 'h:mm a')}</Text>
                    </View>
                    <View style={styles.detail}>
                        <Feather name="map-pin" size={20} color={COLORS.bodyText} />
                        <Text style={styles.detailText}>{job.address.city}, {job.address.state}</Text>
                    </View>
                </View>
                {job.completed && <Text style={styles.jobCompleted}>Job completed</Text>}
                <Text style={styles.jobDescriptionTitle}>Job description</Text>
                <Text style={styles.description}>{job.description}</Text>
                <Text style={styles.requiredSkills}>Required skills</Text>
                <Text style={styles.description}>{job.skills}</Text>
                <Text style={styles.requiredSkills}>Job Site Address</Text>
                <Text style={styles.description}>{job.address.text}</Text>
                <Text style={styles.requiredSkills}>Trades</Text>
                <Text style={styles.description}>{job.trades.join(', ')}</Text>
                <View style={styles.space} />
                {canApply &&
                    <MB_Button
                        title="Apply for this job"
                        style={styles.applBtn}
                        textStyle={textStyles.smallerText}
                        onPress={onApply}
                        disabled={isApplyForJobLoading}
                        loading={isApplyForJobLoading}
                    />
                }
                {canEdit &&
                    <View style={styles.footer}>
                        <DeleteJobModal
                            isVisible={isDeleteModalVisible}
                            onDismiss={hideDeleteModal}
                            onDelete={onDelete}
                            isLoading={isDeleting}
                        />
                        <CreateJobsPopUp
                            isVisible={isRepostEditJobModalVisible}
                            onDismiss={hideRepostEditJobModal}
                            job={job}
                            shouldUpdateJob={true}
                        />
                        <MB_Button
                            title="Edit this job"
                            leftElement={<Feather name="edit-3" size={15} color={COLORS.primaryColor} />}
                            style={styles.editBtn}
                            textStyle={styles.editBtnText}
                            onPress={showRepostEditJobModal}
                        />
                        <MB_Button
                            title="Delete this job"
                            leftElement={<Feather name="check" size={15} color={COLORS.white} />}
                            style={styles.deleteBtn}
                            textStyle={styles.deleteBtnText}
                            onPress={showDeleteModal}
                        />
                    </View>
                }
                {canRepost &&
                    <>
                        <CreateJobsPopUp
                            isVisible={isRepostEditJobModalVisible}
                            onDismiss={hideRepostEditJobModal}
                            job={job}
                            shouldUpdateJob={false}
                        />
                        <MB_Button
                            title="Repost this job"
                            style={styles.applBtn}
                            textStyle={textStyles.smallerText}
                            onPress={showRepostEditJobModal}
                        />
                    </>
                }
            </View>
        </ComponentWrapper>
    );
}

export { JobDetails };

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingTop: 20,
    },
    logo: {
        width: 85,
        height: 85,
        backgroundColor: '#1859A6',
        borderRadius: 360,
        marginBottom: 10,
        alignSelf: 'center',
    },
    title: mbTextStyles([textStyles.normalText, {
        fontSize: 18,
        color: COLORS.headerText,
    }]),
    company: mbTextStyles([textStyles.smallerText, {
        color: COLORS.greyText,
        marginBottom: 9,
    }]),
    rangeContainer: {
        paddingHorizontal: 10,
        paddingVertical: 7,
        borderRadius: 360,
        borderWidth: 1,
        borderColor: COLORS.primaryColor,
        alignSelf: 'center',
    },
    range: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.primaryColor,
        textAlign: 'left',
    }]),
    details: {
        flexDirection: 'row',
        backgroundColor: '#FFF4F0',
        paddingVertical: 17.5,
        paddingHorizontal: 13,
        borderRadius: 8,
        marginTop: 14,
    },
    detail: {
        alignItems: 'center',
        flex: 1,
    },
    detailText: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.bodyText,
        marginTop: 6.4,
    }]),
    jobCompleted: mbTextStyles([textStyles.smallerText, {
        color: '#48B560',
        fontWeight: '600',
        marginTop: 18,
        marginBottom: 11,
        textAlign: 'left',
    }]),
    jobDescriptionTitle: mbTextStyles([textStyles.smallerText, {
        fontSize: 13,
        color: COLORS.bodyText,
        fontWeight: '700',
        marginTop: 24,
        textAlign: 'left',
    }]),
    description: mbTextStyles([textStyles.smallerText, {
        marginTop: 2,
        color: COLORS.bodyText2,
        textAlign: 'left',
    }]),
    requiredSkills: mbTextStyles([textStyles.smallerText, {
        fontSize: 13,
        color: COLORS.bodyText,
        fontWeight: '700',
        marginTop: 16,
        textAlign: 'left',
    }]),
    space: {
        marginTop: 'auto',
        height: 20,
    },
    applBtn: {
        height: 50,
        backgroundColor: COLORS.primaryColor,
        borderRadius: 30,
    },
    footer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        paddingVertical: 20,
    },
    deleteBtn: {
        backgroundColor: COLORS.red,
        borderRadius: 30,
        height: 46,
        width: 164,
    },
    deleteBtnText: {
        color: COLORS.white,
    },
    editBtn: {
        backgroundColor: 'transparent',
        borderRadius: 30,
        height: 46,
        borderWidth: 1,
        borderColor: COLORS.primaryColor,
        width: 164,
    },
    editBtnText: {
        color: COLORS.primaryColor,
    },
})
