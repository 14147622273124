import React from "react";
import { ComponentWrapper } from "../../helperComponents/componentWrapper/ComponentWrapper";
import { Linking, StyleSheet, Text, View } from "react-native";
import { textStyles } from "../../../constants/textStyles";
import { COLORS } from "../../../constants/colors";
import { mbTextStyles } from "@workingu/rnw.utils.style-utils";


const ContactUs = () => {

    return (
        <ComponentWrapper mobileHeaderOptions={{ showHeader: true, showBackArrow: true }}>
            <Text style={[textStyles.largeText, styles.title]}>Contact Us</Text>
            <Text style={[textStyles.smallerText, styles.subTitle]}>For more information, please contact us on:</Text>
            <View style={styles.emailWrapper}>
                <Text style={styles.email} onPress={() => Linking.openURL('mailto:info@workingunited.com')}>info@workingunited.com</Text>
            </View>
        </ComponentWrapper>
    )
}

export { ContactUs };

const styles = StyleSheet.create({
    title: {
        color: COLORS.headerText,
        textAlign: 'left',
        letterSpacing: -0.8,
    },
    subTitle: {
        color: COLORS.greyText,
        textAlign: 'left',
        marginBottom: 22,
    },
    emailWrapper: {
        paddingVertical: 12,
        paddingHorizontal: 18,
        backgroundColor: COLORS.lightOrange,
        borderRadius: 5,
    },
    email: mbTextStyles([textStyles.smallText, {
        fontSize: 13,
        color: COLORS.primaryColor,
        textAlign: 'left',
    }])
});
