import * as React from "react"
import Svg, { Path, Circle } from "react-native-svg"

const AnalyticsIcon = ({ color, size = 19 }: { color?: string, size?: number }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 19 19"
      fill="none"
    >
      <Path
        d="M4.967 12.106L7.626 8.65l3.032 2.383 2.602-3.358"
        stroke={color ?? '#FF5F15'}
        strokeWidth={0.8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Circle
        cx={16.2926}
        cy={2.70732}
        r={1.70732}
        stroke={color ?? '#FF5F15'}
        strokeWidth={0.8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M11.789 1.748H5.333C2.66 1.748 1 3.642 1 6.318v7.178c0 2.675 1.626 4.56 4.333 4.56h7.643c2.674 0 4.333-1.885 4.333-4.56V7.244"
        stroke={color ?? '#FF5F15'}
        strokeWidth={0.8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export { AnalyticsIcon };
