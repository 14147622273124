import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View, ViewStyle } from "react-native";
import Feather from "react-native-vector-icons/Feather";
import { COLORS } from "../../../constants/colors";
import { textStyles } from "../../../constants/textStyles";
import { ComponentWrapper } from "../../helperComponents/componentWrapper/ComponentWrapper"
import { Status } from "./Components/Status";
import { MB_Table } from "@workingu/rnw.components.table";
import { useGetContractorsData } from "../../../hooks/adminHooks";
import { LicenseDocumentsModal } from "../../modals/LicenseDocumentsModal";
import { PDFWhiteIcon } from "../../../resources/svgComponents/PDFWhiteIcon";
import { Contractor, UserRole } from "@wu/business";
import { MB_utilHooks } from "@workingu/rnw.utils.util-hooks";
import { DeleteUserModal } from "../../modals/DeleteUserModal";
import { DEFAULT_TABLE_MAX_ROWS } from "../../../constants/constants";
import { Indicators } from "./Indicators";

const header = [
    { content: '#', conatinerStyle: { height: 50, minWidth: 31 + 27, maxWidth: 31 + 27, paddingLeft: 24 }, textStyle: { color: COLORS.primaryColor, fontSize: 12 } },
    { content: 'COMPANY NAME', conatinerStyle: { height: 50, minWidth: 120 + 44, flex: 1 }, textStyle: { color: COLORS.primaryColor, fontSize: 12 } },
    { content: 'EMAIL ADDRESS', conatinerStyle: { height: 50, minWidth: 120 + 100, flex: 1 }, textStyle: { color: COLORS.primaryColor, fontSize: 12 } },
    { content: 'CLASSIFICATION/TRADES', conatinerStyle: { height: 50, minWidth: 169 + 100, flex: 1 }, textStyle: { color: COLORS.primaryColor, fontSize: 12 } },
    //{ content: 'ADDRESS', conatinerStyle: { height: 50, minWidth: 75 + 94, maxWidth: 75 + 94 }, textStyle: { color: COLORS.primaryColor, fontSize: 12 } },
    { content: 'STATUS', conatinerStyle: { height: 50, minWidth: 66 + 40, maxWidth: 66 + 40 }, textStyle: { color: COLORS.primaryColor, fontSize: 12 } },
    { content: 'LICENSE', conatinerStyle: { height: 50, minWidth: 47 + 27, maxWidth: 47 + 27 }, textStyle: { color: COLORS.primaryColor, fontSize: 12 } },
    { content: 'ACTION', conatinerStyle: { height: 50, minWidth: 47 + 23, maxWidth: 47 + 23 }, textStyle: { color: COLORS.primaryColor, fontSize: 12 } },
];

const Employers = () => {

    const [isLicenseDocumentsModalVisible, showLicenseDocumentModal, hideLicenseDocumentModal] = MB_utilHooks.useBool();
    const [isDeleteUserVisible, showDeleteUser, hideDeleteUser] = MB_utilHooks.useBool();
    const contractorToShow = useRef<Contractor>();
    const userToDelete = useRef<Contractor>();

    const [page, setPage] = useState(1);
    const { data: response, isLoading } = useGetContractorsData({ params: { page, limit: DEFAULT_TABLE_MAX_ROWS } });

    const contractors = useMemo(() => response?.contractors, [response]);
    const totalPages = useMemo(() => Math.ceil((response?.count || 0) / DEFAULT_TABLE_MAX_ROWS), [response]);

    const onShowLicensesModal = useCallback((contractor: Contractor) => {
        contractorToShow.current = contractor;
        showLicenseDocumentModal();
    }, [showLicenseDocumentModal]);

    const onDelete = useCallback((contractor: Contractor) => {
        userToDelete.current = contractor;
        showDeleteUser();
    }, [showDeleteUser])

    const data = useMemo(() => {
        return contractors?.map((contractor, index) => {
            const isPdfDisabled = contractor.licenses.every(lisence => !lisence.file);
            return [
                <Text style={[styles.cellText, styles.rightSpace]}>{index + 1}</Text>,
                <Text style={[styles.cellText, styles.rightSpace, styles.important]} numberOfLines={3}>{contractor.name}</Text>,
                <Text style={[styles.cellText, styles.rightSpace]} numberOfLines={3}>{contractor.email}</Text>,
                <Text style={[styles.cellText, styles.rightSpace]} numberOfLines={3}>{contractor.trades.join(' ')}</Text>,
                <Status type="Active" />,
                <TouchableOpacity onPress={() => onShowLicensesModal(contractor)} disabled={isPdfDisabled}>
                    <PDFWhiteIcon size={20} color={isPdfDisabled ? COLORS.bodyText2 : COLORS.primaryColor} />
                </TouchableOpacity>,
                <Feather name="trash" size={19} color={COLORS.red} onPress={() => onDelete(contractor)} />,
            ];
        })
    }, [contractors, onDelete, onShowLicensesModal]);

    const keyExtractor = useCallback((index) => contractors?.[index].id ?? index.toString(), [contractors]); // TODO: This is weird, we should have the proper ID here.

    return (
        <ComponentWrapper
            innerContainerStyle={styles.innerContainer}
            containerStyle={styles.container}
            scrollViewStyle={styles.scrollView}
            wrapInScrollView
            disableLinearGradient
        >
            {contractorToShow.current &&
                <LicenseDocumentsModal
                    isVisible={isLicenseDocumentsModalVisible}
                    onDismiss={hideLicenseDocumentModal}
                    contractor={contractorToShow.current}
                />
            }
            {userToDelete.current &&
                <DeleteUserModal
                    isVisible={isDeleteUserVisible}
                    onDismiss={hideDeleteUser}
                    user={userToDelete.current}
                    type={UserRole.Contractor}
                />
            }
            <Text style={styles.title}>Employers</Text>
            <View style={styles.content}>
                <MB_Table
                    header={header}
                    data={data}
                    headerStyle={styles.tableHeader}
                    rowStyle={styles.rowStyle}
                    rowTextStyle={styles.rowTextStyle}
                    style={styles.table}
                    keyExtractor={keyExtractor}
                    loading={isLoading}
                />
                <View style={styles.tableFooter}>
                    <Indicators
                        page={page}
                        totalPages={totalPages}
                        onPageChange={setPage}
                    />
                </View>
            </View>
        </ComponentWrapper>
    )
}

export { Employers };

const styles = StyleSheet.create({
    innerContainer: {
        backgroundColor: COLORS.greyBG,
        flex: 1,
        maxWidth: 'auto',
        alignSelf: 'stretch',
    },
    container: {
        minWidth: 1000,
    },
    title: mbTextStyles([textStyles.largeText, {
        color: COLORS.bodyText,
        textAlign: 'left',
    }]),
    content: {
        marginBottom: 84,
    },
    scrollView: {
        overflowX: 'visible',
    } as ViewStyle,
    table: {
        marginTop: 20,
        borderBottomEndRadius: 0,
        borderBottomStartRadius: 0,
    },
    tableFooter: {
        height: 63,
        backgroundColor: COLORS.white,
        justifyContent: 'center',
        borderBottomEndRadius: 10,
        borderBottomStartRadius: 10,
        borderWidth: 0.5,
        borderBottomColor: COLORS.lightGrey,
        borderLeftColor: COLORS.lightGrey,
        borderRightColor: COLORS.lightGrey,
        borderTopWidth: 0,
    },
    cellText: mbTextStyles([textStyles.smallerText, {
        color: COLORS.bodyText2,
        textAlign: 'left',
    }]),
    rightSpace: {
        paddingLeft: 10,
    },
    important: {
        color: COLORS.bodyText,
        fontWeight: '600',
        fontSize: 13,
    },
    tableHeader: {
        height: 50,
        backgroundColor: '#FFF4F0',
        borderBottomColor: 'transparent',
    },
    rowStyle: {
        height: 68,
        backgroundColor: COLORS.white,
        justifyContent: 'center',
    },
    rowTextStyle: mbTextStyles([textStyles.smallText, {
        fontSize: 13,
        color: COLORS.bodyText,
        textAlign: 'left',
    }]),
});
