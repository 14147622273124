import {
  ContractorsResponse,
  EmployeesResponse,
  PageParams,
  StatsFilter,
  StatsResponse,
} from '@wu/business';

import { Token } from '@workingu/rnw.utils.access-token-utils';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';

import { adminApiCall } from '../apiCalls/adminApiCall';
import { ADMIN_QUERY_KEYS } from '../constants/constants';
import { ServerError } from '../typesAndInterfaces/typesAndInterfaces';

export const useSignIn = () => {
  return useMutation<Token, ServerError, { email: string; password: string }, unknown>(
    async (data) => adminApiCall.login(data.email, data.password)
  );
};

export const useGetEmployeesData = ({
  params,
  queryOptions,
}: {
  params: PageParams;
  queryOptions?: UseQueryOptions<EmployeesResponse, ServerError>;
}) => {
  return useQuery<EmployeesResponse, ServerError>(
    [ADMIN_QUERY_KEYS.getEmployeesData, params.page, params.limit],
    () => adminApiCall.getEmployeesData(params),
    queryOptions
  );
};

export const useGetContractorsData = ({
  params,
  queryOptions,
}: {
  params: PageParams;
  queryOptions?: UseQueryOptions<ContractorsResponse, ServerError>;
}) => {
  return useQuery<ContractorsResponse, ServerError>(
    [ADMIN_QUERY_KEYS.getContractorsData, params.page, params.limit],
    () => adminApiCall.getContractorsData(params),
    queryOptions
  );
};

export const useGetStatsData = ({
  filter,
  queryOptions,
}: {
  filter: StatsFilter;
  queryOptions?: UseQueryOptions<StatsResponse, ServerError>;
}) => {
  return useQuery<StatsResponse, ServerError>(
    [ADMIN_QUERY_KEYS.getStatsData, filter],
    () => adminApiCall.getStatsData({ filter }),
    queryOptions
  );
};

export const useDeleteEmployee = () => {
  const queryClient = useQueryClient();
  return useMutation<Token, ServerError, { id: string }, unknown>(
    async (data) => adminApiCall.deleteEmployee(data.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ADMIN_QUERY_KEYS.getEmployeesData]);
      },
    }
  );
};

export const useDeleteContractor = () => {
  const queryClient = useQueryClient();
  return useMutation<Token, ServerError, { id: string }, unknown>(
    async (data) => adminApiCall.deleteContractor(data.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ADMIN_QUERY_KEYS.getContractorsData]);
      },
    }
  );
};
