import { mbApplyTransparency, mbTextStyles } from '@workingu/rnw.utils.style-utils';
import React, { useCallback, useMemo, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Slider } from '@miblanchard/react-native-slider';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { MB_Button } from '@workingu/rnw.components.button';
import { textStyles } from '../../../constants/textStyles';
import { Dropdown } from '../../helperComponents/Dropdown';
import { COLORS } from '../../../constants/colors';
import { Trades } from '../../helperComponents/Trades';
import { useGetEmployeeData, useUpdateEmployeeData } from '../../../hooks/userHooks';
import { STRING_CONSTANTS } from '../../../constants/constants';
import { mbShowPopUp } from '@workingu/rnw.components.pop-up';
import { MB_TextInput } from '@workingu/rnw.components.text-input';
import { DocumentPicker } from '../../helperComponents/DocumentPicker';
import { mbShowToast } from '@workingu/rnw.components.toast';
import { getErrorMessage } from '../../helperComponents/CreateJobsPopUp';
import { isEqual } from 'lodash'
import { EmployeeExperience } from '@wu/business';

const EditCareerInformation = () => {
    const { mutate: updateEmployeeData, isLoading } = useUpdateEmployeeData();
    const { data: employeeData } = useGetEmployeeData();

    const onFileSelect = useCallback(({ id }: { id: string }) => {
        updateEmployeeData({ resumeFileId: id }, {
            onSuccess: () => {
                mbShowToast({ text1: 'Success', text2: 'Resume updated', position: 'bottom' })
            },
            onError: () => {
                mbShowPopUp({ title: STRING_CONSTANTS.ERROR, message: STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN });
            },
        })
    }, [updateEmployeeData]);




    const [skills, setSkills] = useState(employeeData?.skills ?? '');
    const [hourlyRate, setHourlyRate] = useState(employeeData?.hourlyRate ?? [15, 50]);
    const [trades, setTrades] = useState<string[]>(employeeData?.trades ?? []);
    const [references, setReferences] = useState(employeeData?.references ?? '');

    const experiences = useMemo(() => Object.values(EmployeeExperience).map(experience => `${experience} experience`), []);
    const [experienceIndex, setExperienceIndex] = useState<number | undefined>(employeeData?.experience ? Object.values(EmployeeExperience).indexOf(employeeData?.experience) : undefined);

    const isDataNotChanged = skills === employeeData?.skills && references === employeeData.references && employeeData.experience === Object.values(EmployeeExperience)[experienceIndex as number] && isEqual(hourlyRate, employeeData?.hourlyRate) && isEqual(trades, employeeData?.trades)
    const isDisabled = !skills || !references || experienceIndex === undefined || trades.length === 0 || isDataNotChanged;


    const onSave = useCallback(() => {
        updateEmployeeData({
            experience: Object.values(EmployeeExperience)[experienceIndex as number],
            skills,
            hourlyRate,
            trades,
            references,
        }, {
            onError: (error) => {
                const message = getErrorMessage(error);
                mbShowPopUp({
                    title: 'Error',
                    message,
                });
            },
            onSuccess: () => {
                mbShowToast({ text1: 'Successfully submitted your changes', text2: '', position: 'bottom' })
            }
        })
    }, [experienceIndex, hourlyRate, references, skills, trades, updateEmployeeData]);

    return (
        <ComponentWrapper
            mobileHeaderOptions={{ showHeader: true, showBackArrow: true }}
            mobileStickyFooter={
                <MB_Button // TODO: This button should be disabled if none of the fields are changed.
                    title="Save changes"
                    style={styles.saveChangesBtn}
                    textStyle={textStyles.smallerText}
                    disabled={isDisabled || isLoading}
                    onPress={onSave}
                    loading={isLoading}
                />
            }
            keyboardShouldPersistTapsForScrollView="handled"
        >
            <Text style={[textStyles.largeText, styles.title]}>Edit Career Information</Text>
            <Text style={[textStyles.smallerText, styles.subTitle]}>Edit your career information below and save changes.</Text>
            <Text style={styles.inputTitle}>Trades <Text style={styles.required}>*</Text></Text>
            <Trades
                trades={trades}
                onChange={setTrades}
            />
            <Text style={styles.inputTitle}>Skills</Text>
            <MB_TextInput
                placeholder="Enter some of your skills..."
                multiline
                style={styles.skills}
                maxLength={100}
                value={skills}
                onChangeText={setSkills}
                placeholderTextColor={COLORS.greyText}
            />
            <Text style={styles.inputTitle}>Years of Experience <Text style={styles.required}>*</Text></Text>
            <Dropdown
                options={experiences}
                option={experienceIndex !== undefined ? experiences[experienceIndex] : undefined}
                onSelect={(_, index) => setExperienceIndex(index)}
            />
            <Text style={styles.inputTitle}>Hourly rate <Text style={styles.highlight}>(max range gap of $5)</Text> <Text style={styles.required}>*</Text></Text>
            <View style={styles.slider}>
                <Slider
                    value={hourlyRate}
                    onValueChange={setHourlyRate}
                    minimumValue={15}
                    maximumValue={50}
                    trackStyle={{ backgroundColor: COLORS.lightOrange }}
                    renderBelowThumbComponent={(index: number) => <Text style={[textStyles.smallerText, { color: COLORS.bodyText, marginLeft: -10 }]}>${hourlyRate[index]}</Text>}
                    renderThumbComponent={() => <View style={{ width: 20, height: 20, backgroundColor: COLORS.white, borderWidth: 4, borderRadius: 360, borderColor: COLORS.primaryColor }} />}
                    step={5}
                    maximumTrackTintColor={COLORS.primaryColor}
                    minimumTrackTintColor={COLORS.primaryColor}
                />
            </View>
            <DocumentPicker
                containerStyle={styles.documentPickerContainer}
                selectedFile={employeeData?.resume ? { id: employeeData.resume.id, name: employeeData.resume.name, size: employeeData.resume.contentLength } : undefined}
                onFileSelect={onFileSelect}
                pageTitle="Upload Resume"
                title='Resume'
                fileDescriptorText='Upload Resume Document'
            />
            <Text style={styles.inputTitle}>References<Text style={styles.required}>*</Text></Text>
            <MB_TextInput
                placeholder="List some of your references..."
                multiline
                style={styles.multiline}
                maxLength={100}
                value={references}
                onChangeText={setReferences}
                placeholderTextColor={COLORS.greyText}
            />
        </ComponentWrapper>
    );
};

export { EditCareerInformation };

const styles = StyleSheet.create({
    topNote: {
        marginBottom: 7,
        color: COLORS.bluishGrey,
        textAlign: 'left',
    },
    title: {
        color: COLORS.headerText,
        textAlign: 'left',
        letterSpacing: -0.8,
    },
    subTitle: {
        color: COLORS.greyText,
        textAlign: 'left',
        marginBottom: 27,
    },
    profilePicture: {
        marginTop: 27,
        width: 98,
        height: 98,
        backgroundColor: COLORS.bluishGrey,
        alignSelf: 'center',
        borderRadius: 360,
        overflow: 'hidden',
    },
    emptyBackground: {
        backgroundColor: mbApplyTransparency(COLORS.black, 0.5),
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputTitle: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.bodyText,
        marginTop: 15,
        marginBottom: 7,
        textAlign: 'left',
    }]),
    required: {
        color: COLORS.red,
    },
    skills: {
        height: 100,
    },
    highlight: {
        color: COLORS.greyText,
    },
    slider: {
        marginBottom: 23,
    },
    saveChangesBtn: {
        height: 50,
        backgroundColor: COLORS.primaryColor,
        borderRadius: 30,
        marginTop: 20,
        marginBottom: 38,
        marginHorizontal: 20,
    },
    documentPickerContainer: {
        marginTop: 15,
        alignSelf: 'stretch',
    },
    multiline: {
        height: 100,
    },
});
