import { mbApplyTransparency, mbTextStyles } from "@workingu/rnw.utils.style-utils";
import React from "react";
import { ImageBackground, Linking, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { imageUtils } from "../../utils/imageUtils";
import { COLORS } from "../../constants/colors";
import { MB_Image } from "@workingu/rnw.components.image";
import { textStyles } from "../../constants/textStyles";
import { PhoneIcon } from "../../resources/svgComponents/PhoneIcon";
import { AppStoreIcon } from "../../resources/svgComponents/AppStoreIcon";
import { GooglePlayIcon } from "../../resources/svgComponents/GooglePlayIcon";
import { isMobile } from "@workingu/rnw.utils.device-info";
import { MB_Button } from "@workingu/rnw.components.button";
import { isAndroid, isIOS } from 'react-device-detect';

const LandingPage = () => {

  const openStore = (platform?: 'ios' | 'android') => {
    if (platform) {
      if (platform === 'ios') {
        Linking.openURL('https://apps.apple.com/us/app/instagram/id389801252')
      } else {
        Linking.openURL('https://play.google.com/store/apps/details?id=com.instagram.android&hl=en&gl=US')
      }
    }
    else if (isIOS) {
      Linking.openURL('itms-apps://apps.apple.com/us/app/instagram/id389801252')
    } else if (isAndroid) {
      Linking.openURL('market://details?id=com.instagram.android')
    }
  }

  return (
    <View style={styles.container} >
      <ImageBackground
        source={imageUtils.images.landingPageBackground}
        style={styles.backgroundImage}
      >
        <View style={styles.background}>
          <MB_Image
            source={imageUtils.images.wuLogo}
            style={styles.logo}
            resizeMode="contain"
            disableInteraction
          />
          <View style={styles.content}>
            <View style={styles.phoneIcon}>
              <PhoneIcon />
            </View>
            <Text style={styles.title}>Download the app</Text>
            <Text style={styles.message}>The current version of W.U. doesn’t support web or mobile browsers. Please visit your App Store or Play Store to download the mobile app.</Text>
            {isMobile ?
              <>
                <MB_Button
                  title="Download W.U. App"
                  style={styles.downloadBtn}
                  onPress={() => openStore()}
                />
                <Text style={styles.subDownloadText}>Already have the app? <Text style={styles.important} onPress={() => openStore()}>Open here</Text></Text>
              </>
              :
              <View style={styles.install}>
                <TouchableOpacity onPress={() => openStore('ios')}>
                  <AppStoreIcon />
                </TouchableOpacity>
                <View style={styles.space} />
                <TouchableOpacity onPress={() => openStore('android')}>
                  <GooglePlayIcon />
                </TouchableOpacity>
              </View>
            }
          </View>
        </View>
      </ImageBackground>
    </View>
  )
}

export { LandingPage }

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
  },
  background: {
    backgroundColor: mbApplyTransparency(COLORS.black, 0.8),
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  logo: {
    width: 96,
    height: 54,
  },
  content: {
    marginTop: 20,
    backgroundColor: COLORS.white,
    paddingVertical: 32,
    maxWidth: 700,
    borderTopWidth: 3,
    borderColor: COLORS.primaryColor,
    justifyContent: 'center',
    marginHorizontal: 20,
    paddingHorizontal: 30,
    alignItems: 'center',
  },
  phoneIcon: {
    width: 77,
    height: 77,
    backgroundColor: COLORS.lightOrange,
    borderRadius: 360,
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: mbTextStyles([textStyles.largeText, {
    fontSize: 21,
    color: COLORS.black,
    marginTop: 15,
    fontWeight: '700',
  }]),
  message: mbTextStyles([textStyles.smallText, {
    color: isMobile ? '#333333' : COLORS.black,
    marginTop: 6,
  }]),
  downloadBtn: {
    height: 46,
    borderRadius: 30,
    alignSelf: 'stretch',
    backgroundColor: COLORS.primaryColor,
    marginTop: 24,
  },
  install: {
    flexDirection: 'row',
    marginTop: 24,
  },
  space: {
    width: 12.37
  },
  subDownloadText: mbTextStyles([textStyles.smallerText, {
    color: '#000',
    marginTop: 12,
  }]),
  important: {
    color: COLORS.primaryColor,
    textDecorationLine: 'underline',
  }
});