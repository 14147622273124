import React, { useEffect, useState } from 'react';

import { ActivityIndicator, StyleSheet, Text, View } from 'react-native';
import { COLORS } from '../../constants/colors';
import { MB_Button } from '@workingu/rnw.components.button';
import { textStyles } from '../../constants/textStyles';
import { UniversalScreenNavigationProp } from '../../typesAndInterfaces/componentProps';
import { useSignedInContext } from '../../context/SignedInContext';
import {  mbTextStyles } from '@workingu/rnw.utils.style-utils';

export enum LOADING_PAGE_GIVE_UP_ACTION_TYPES {
    signOut = 'signOut',
    backToHome = 'backToHome'
}

const LoadingPage = ({ giveUpActionTypes, navigation, hideGiveUpButton }: { giveUpActionTypes?: LOADING_PAGE_GIVE_UP_ACTION_TYPES, navigation?: UniversalScreenNavigationProp, hideGiveUpButton?: boolean }) => {
    const [isLoadingVisibleInternal, setIsLoadingVisibleInternal] = useState(false);
    const [isGiveUpButtonVisible, setIsGiveUpButtonVisible] = useState(false);
    const { signOut } = useSignedInContext();
    useEffect(() => {
        const buttonTimeout = setTimeout(() => { setIsGiveUpButtonVisible(true); }, 5000);
        const viewTimeout = setTimeout(() => { setIsLoadingVisibleInternal(true); }, 500);

        return () => {
            clearTimeout(buttonTimeout);
            clearTimeout(viewTimeout);
        };
    }, []);

    if (!isLoadingVisibleInternal) {
        return <View style={styles.container} />;
    }

    const onGiveUpButtonPressed = () => {
        if (giveUpActionTypes === LOADING_PAGE_GIVE_UP_ACTION_TYPES.signOut) {
            signOut({ skipSignOutRequest: true });
        } else {
            navigation?.reset({
                index: 0,
                routes: [{ name: navigation.getState().routeNames[0] }],
            });
        }
    };

    return (
        <View style={styles.container}>
            <View>
                <ActivityIndicator color="#FFFFFF" size="large" />
                <Text style={styles.loadingText}>Loading please wait...</Text>
                {isGiveUpButtonVisible && !hideGiveUpButton &&
                    <MB_Button
                        title={giveUpActionTypes === LOADING_PAGE_GIVE_UP_ACTION_TYPES.signOut ? 'Sign Out' : 'Back to Home Page'}
                        onPress={onGiveUpButtonPressed}
                        style={styles.giveUpBtn}
                    />
                }
            </View>
        </View>
    );
};

export { LoadingPage };

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#7d7d7d',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loginBannerContainer: {
        position: 'absolute',
        top: 0,
        width: '100%',
    },
    loadingText: mbTextStyles([textStyles.smallerText, {
        color: COLORS.white,
        marginTop: 19,
    }]),
    giveUpBtn: {
        marginTop: 16,
        backgroundColor: COLORS.primaryColor,
        height: 50,
    }
});
