import * as Font from 'expo-font';

import {
  Manrope_200ExtraLight,
  Manrope_300Light,
  Manrope_400Regular,
  Manrope_500Medium,
  Manrope_600SemiBold,
  Manrope_700Bold,
  Manrope_800ExtraBold,
} from '@expo-google-fonts/manrope';

export const fontLoader = async (): Promise<any> => {
  const expoFontPromise = Font.loadAsync({
    AntDesign: require('../../node_modules/react-native-vector-icons/Fonts/AntDesign.ttf'),
    Feather: require('../../node_modules/react-native-vector-icons/Fonts/Feather.ttf'),
    FontAwesome: require('../../node_modules/react-native-vector-icons/Fonts/FontAwesome.ttf'),
    MaterialCommunityIcons: require('../../node_modules/react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'),
    Entypo: require('../../node_modules/react-native-vector-icons/Fonts/Entypo.ttf'),
    Ionicons: require('../../node_modules/react-native-vector-icons/Fonts/Ionicons.ttf'),
    MaterialIcons: require('../../node_modules/react-native-vector-icons/Fonts/MaterialIcons.ttf'),

    Manrope_200: Manrope_200ExtraLight,
    Manrope_300: Manrope_300Light,
    Manrope_400: Manrope_400Regular,
    Manrope_500: Manrope_500Medium,
    Manrope_600: Manrope_600SemiBold,
    Manrope_700: Manrope_700Bold,
    Manrope_800: Manrope_800ExtraBold,
  });

  return Promise.all([expoFontPromise]);
};
