import React, { ReactElement } from 'react';
import { MB_Button } from '@workingu/rnw.components.button';
import { MB_Pressable, MB_PressableStateCallbackType } from '@workingu/rnw.components.pressable';
import { mbApplyTransparency } from '@workingu/rnw.utils.style-utils';
import { StyleProp, ViewStyle, StyleSheet, TextStyle } from 'react-native';

// TODO: Add customization options.

export interface PageIndicatorButtonProps {
    title: string,
    active?: boolean,
    onPress?: () => void,
    containerStyle?: StyleProp<ViewStyle>,
    buttonStyle?: StyleProp<ViewStyle>,
    textStyle?: StyleProp<TextStyle>,
    disabled?: boolean,
    icon?: ReactElement,
    rightIcon?: ReactElement,
    activeColor?: string,
    selectColor?: string,
    hoverColor?: string,
    activeTextColor?: string,
    inactiveTextColor?: string,
}

const PageIndicatorButton = ({
    title,
    active,
    onPress,
    containerStyle,
    buttonStyle,
    disabled,
    icon,
    rightIcon,
    textStyle,
    activeColor = '#5D3E65',
    selectColor = '#FFFFFF',
    hoverColor =  '#4D2D52',
    activeTextColor = '#5D3E65',
    inactiveTextColor = '#FFFFFF'
}: PageIndicatorButtonProps) => (
    <MB_Pressable pointerEvents={onPress ? undefined : 'none'} style={containerStyle}>
        {({ hovered }: MB_PressableStateCallbackType) => {

            const getBackgroundColor = () => {
                if (active) {
                    return selectColor;
                }
                if (hovered && !disabled && onPress) {
                    return hoverColor;
                }
                return activeColor;
            };

            return (
                <MB_Button
                    title={title}
                    style={[styles.button, buttonStyle, { backgroundColor: getBackgroundColor() }]}
                    textStyle={[textStyle, { color: active ? activeTextColor : inactiveTextColor }]}
                    onPress={onPress}
                    leftElement={icon}
                    rightElement={rightIcon}
                    disabled={disabled}
                    leftElementStyle={{ marginEnd: 0 }}
                />
            );
        }}
    </MB_Pressable>
);

export { PageIndicatorButton };

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignSelf: 'flex-end',
    },
    button: {
        height: 41.16,
        width: 41.16,
        borderRadius: 9.73,
        borderWidth: 1,
        borderColor: mbApplyTransparency('#BAC0CA', 0.30),
    },
});
