import { z } from 'zod';

type Schema = z.AnyZodObject | z.ZodEffects<z.AnyZodObject>;

const zodPagination = z.object({
  page: z.number().gt(0),
  totalItems: z.number().gte(0),
});

const zodInfinitePagination = z.object({
  nextOffset: z.string().optional(),
});

const zodUtils = {
  getPagination: (anyData: Schema) => {
    return zodPagination.extend({
      items: anyData.array(),
    });
  },
  getInfinitePagination: (anyData: Schema) => {
    return zodInfinitePagination.extend({
      items: anyData.array(),
    });
  },
  getArray: (anyData: Schema) => {
    return z.array(anyData);
  },
};

export { zodUtils };
