import React from "react";
import { RecommendedEmployeeDetailsProps } from "../../typesAndInterfaces/componentProps";
import { ApplicationInformation } from "./ApplicationInformation/ApplicationInformation";
import { ComponentWrapper } from "../helperComponents/componentWrapper/ComponentWrapper";
import { StyleSheet } from "react-native";

export const RecommendedEmployeeDetails = ({ route }: RecommendedEmployeeDetailsProps) => {

    const { job, employee } = route.params;

    return (
        <ComponentWrapper
            mobileHeaderOptions={{ showHeader: true, showBackArrow: true, pageTitle: 'Employee Profile' }}
            containerStyle={styles.container}
        >
        <ApplicationInformation
            employee={employee}
            job={job}
        />
        </ComponentWrapper>
    )
}


const styles = StyleSheet.create({
    container: {
        paddingBottom: 0
    }
})


