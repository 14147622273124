import React from 'react';
import { createDrawerNavigator, DrawerContentComponentProps, DrawerNavigationOptions, DrawerContentScrollView, DrawerItemList } from '@react-navigation/drawer';
import { AppAnalytics } from '../components/screens/AdminFlow/AppAnalytics';
import { COLORS } from '../constants/colors';
import { Image, StyleSheet } from 'react-native';
import { imageUtils } from '../utils/imageUtils';
import { AnalyticsIcon } from '../resources/svgComponents/AnalyticsIcon';
import { CaseIcon } from '../resources/svgComponents/CaseIcon';
import { WorkIcon } from '../resources/svgComponents/WorkerIcon';
import { Employers } from '../components/screens/AdminFlow/Employers';
import { Employees } from '../components/screens/AdminFlow/Employees';

export type AdminNavigatorParamList = {
    AppAnalytics: undefined,
    Employers: undefined,
    Employees: undefined,
}

const drawerOptions: Record<keyof AdminNavigatorParamList, DrawerNavigationOptions> = {
    AppAnalytics: {
        drawerLabel: 'App Analytics',
        drawerIcon: ({ focused }) => <AnalyticsIcon color={focused ? COLORS.primaryColor : COLORS.greyText} />,
    },
    Employers: {
        drawerIcon: ({ focused }) => <CaseIcon color={focused ? COLORS.primaryColor : COLORS.greyText} />,
    },
    Employees: {
        drawerIcon: ({ focused }) => <WorkIcon color={focused ? COLORS.primaryColor : COLORS.greyText} />,
    }
}

const AdminNavigatorDrawer = (props: DrawerContentComponentProps) => {

    return (
        <DrawerContentScrollView {...props} contentContainerStyle={{ alignItems: 'center' }}>
            <Image source={imageUtils.images.wuLogoBlack} style={styles.logoImage} resizeMode="contain" />
            <DrawerItemList {...props} />
        </DrawerContentScrollView>
    )
};

const Drawer = createDrawerNavigator<AdminNavigatorParamList>();

const AdminNavigator = () => {

    return (
        <Drawer.Navigator
            screenOptions={{
                headerShown: false,
                drawerType: "permanent",
                drawerActiveBackgroundColor: "#FFF4F0",
                drawerInactiveTintColor: COLORS.greyText,
                drawerActiveTintColor: COLORS.primaryColor,
                drawerStyle: {
                    width: 243,
                },
                drawerItemStyle: {
                    width: 215,
                },
                sceneContainerStyle: {
                    overflow: 'hidden', // TODO: Hovo: Why do we need this ?
                },
            }}
            drawerContent={AdminNavigatorDrawer}
        >
            <Drawer.Screen name="AppAnalytics" component={AppAnalytics} options={drawerOptions.AppAnalytics} />
            <Drawer.Screen name="Employers" component={Employers} options={drawerOptions.Employers} />
            <Drawer.Screen name="Employees" component={Employees} options={drawerOptions.Employees} />
        </Drawer.Navigator>
    );
}

export { AdminNavigator };

const styles = StyleSheet.create({
    logoImage: {
        width: 66,
        height: 47,
        marginVertical: 32,
    }
})

