import * as React from "react"
import Svg, { G, Path, Rect, Defs, ClipPath } from "react-native-svg"

const IMGIcon = ({ size = 27 }: { size?: number }) => {
    return (
        <Svg
            width={size}
            height={size}
            viewBox="0 0 27 27"
            fill="none"
        >
            <G clipPath="url(#clip0_1693_13363)">
                <Path
                    d="M23.783 7.12l-5.537-1.583L16.664 0H5.59a2.373 2.373 0 00-2.373 2.373v22.254A2.373 2.373 0 005.59 27h15.82a2.373 2.373 0 002.373-2.373V7.119z"
                    fill="#44D690"
                />
                <Path
                    d="M23.783 7.12v17.507A2.373 2.373 0 0121.41 27H13.5V0h3.164l1.582 5.537 5.537 1.582z"
                    fill="#44D690"
                />
                <Path
                    d="M23.783 7.12h-5.537c-.87 0-1.582-.713-1.582-1.583V0c.206 0 .411.08.554.237l6.328 6.328a.742.742 0 01.237.554z"
                    fill="#80F8B0"
                />
                <G clipPath="url(#clip1_1693_13363)">
                    <Rect
                        x={6.75}
                        y={10.125}
                        width={13.5}
                        height={12.6495}
                        rx={5.27063}
                        fill="#fff"
                    />
                    <Path
                        d="M18.724 10.125H8.277c-.842 0-1.527.685-1.527 1.528v9.601c0 .842.685 1.526 1.527 1.526h10.447c.842 0 1.526-.684 1.526-1.526v-9.601c0-.843-.684-1.528-1.526-1.528zm-3.244 2.272a1.475 1.475 0 110 2.95 1.475 1.475 0 010-2.95zm2.765 8.972H8.966c-.407 0-.589-.295-.405-.659l2.531-5.013c.183-.363.533-.396.78-.072l2.545 3.326c.248.323.68.35.967.06l.622-.63a.593.593 0 01.942.08l1.612 2.303c.233.334.092.605-.315.605z"
                        fill="#44D690"
                    />
                </G>
            </G>
            <Defs>
                <ClipPath id="clip0_1693_13363">
                    <Path fill="#fff" d="M0 0H27V27H0z" />
                </ClipPath>
                <ClipPath id="clip1_1693_13363">
                    <Rect
                        x={6.75}
                        y={10.125}
                        width={13.5}
                        height={12.6495}
                        rx={5.27063}
                        fill="#fff"
                    />
                </ClipPath>
            </Defs>
        </Svg>
    )
}

export { IMGIcon };
