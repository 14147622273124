import { MB_Button } from '@workingu/rnw.components.button';
import { MB_TextInputRef } from '@workingu/rnw.components.text-input';
import { MB_accessTokenUtils, Token } from '@workingu/rnw.utils.access-token-utils';
import { useQueryClient } from '@tanstack/react-query';
import React, { createRef, useCallback, useRef, useState, useEffect } from 'react';
import {
  NativeSyntheticEvent,
  StyleSheet,
  Text,
  TextInput,
  TextInputKeyPressEventData,
  TouchableOpacity,
  View,
} from 'react-native';
import { COLORS } from '../../../constants/colors';
import { STRING_CONSTANTS, USER_QUERY_KEYS } from '../../../constants/constants';
import { textStyles } from '../../../constants/textStyles';
import { SIGNED_IN_STATUS, useSignedInContext } from '../../../context/SignedInContext';
import {
  useRequestSignUpCode,
  useResetPasswordCodeRequest,
  useSignUp,
  useVerifyPasswordRecoveryCode,
} from '../../../hooks/userHooks';
import { VerifyOtpProps } from '../../../typesAndInterfaces/componentProps';
import { ServerError, SignUpRequest } from '../../../typesAndInterfaces/typesAndInterfaces';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { mbShowToast } from '@workingu/rnw.components.toast';
import { CodeType, UserRole } from '@wu/business';
import { mbTextStyles } from '@workingu/rnw.utils.style-utils';
import Clipboard from '@react-native-clipboard/clipboard';
import ReactNativeBiometrics, { BiometryTypes } from 'react-native-biometrics';

export enum VerifyOtpType {
  SignUpContractor = 'SignUpContractor',
  SignUpEmployee = 'SignUpEmployee',
  ForgetPassword = 'ForgetPassword',
}

type SignUpContractorData = {
  name: string;
  email: string;
  password: string;
};

type SignUpEmployeeData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};

type ForgetPasswordData = {
  email: string;
};

export type VerifyOtpData<T extends VerifyOtpType> = T extends VerifyOtpType.SignUpContractor
  ? SignUpContractorData
  : T extends VerifyOtpType.SignUpEmployee
    ? SignUpEmployeeData
    : ForgetPasswordData;

const getName = (data: VerifyOtpData<VerifyOtpType>): string => {
  const { firstName, lastName, name } = data as any;

  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  return name || '';
};

const VerifyOtp = ({ route, navigation }: VerifyOtpProps) => {
  const { mutate: requestSignUpCode, isLoading: isRequestSignUpLoading } = useRequestSignUpCode();
  const { mutate: resetPasswordCodeRequest, isLoading: isResetPasswordCodeRequestLoading } =
    useResetPasswordCodeRequest();
  const { mutate: verifyPasswordRecoveryCode, isLoading: isVerifyPasswordRecoveryCodeLoading } =
    useVerifyPasswordRecoveryCode();

  const { mutate: signUp, isLoading: isSignUpLoading } = useSignUp();
  const { setSignedInStatus } = useSignedInContext();
  const rnBiometrics = new ReactNativeBiometrics();

  const queryClient = useQueryClient();
  const { data, type } = route.params;
  const [isFocused, setIsFocused] = useState(0);
  const [isInvalidCode, setIsInvalidCode] = useState(false);
  const [publicKey, setPublicKey] = useState<string>('');
  const [code, setCode] = useState(Array(5).fill(''));
  const codeRef = useRef([
    createRef<MB_TextInputRef>(),
    createRef<MB_TextInputRef>(),
    createRef<MB_TextInputRef>(),
    createRef<MB_TextInputRef>(),
    createRef<MB_TextInputRef>(),
  ]);
  const inputRef = useRef<Array<TextInput | null>>([]);
  const hits = useRef(0);

  const isLoading =
    isRequestSignUpLoading ||
    isSignUpLoading ||
    isResetPasswordCodeRequestLoading ||
    isVerifyPasswordRecoveryCodeLoading;
  const isDisabled = code.join('').length !== 5 || isLoading;

  const onChangeText = useCallback(
    async (text: string, index: number) => {
      if (text.length > 1) {
        // If the length of the text is more than 1, it means that the user has pasted something
        const clipboardContent = await Clipboard.getString();
        const newCode = [...code];
        const clipboardArray = clipboardContent.split('');

        for (let i = index; i < 5; i++) {
          if (clipboardArray[i - index]) {
            newCode[i] = clipboardArray[i - index];
          }
        }

        setCode(newCode);
      } else {
        // The rest of your code
        if (index >= 0 && index < 5) {
          const newCode = [...code];
          newCode[index] = text;
          setCode(newCode);
        }

        if (text !== '' && index < 4) {
          if (inputRef.current[index + 1] !== null) {
            inputRef.current[index + 1]?.focus();
          }
        }

        if (text === '' && index > 0) {
          inputRef.current[index - 1]?.focus();
        }
      }
    },
    [code],
  );

  const onKeyPress = useCallback(
    (e: NativeSyntheticEvent<TextInputKeyPressEventData>, index: number) => {
      if (e.nativeEvent.key === 'Backspace') {
        setIsInvalidCode(false);
        hits.current++;
        if (hits.current === 2 && index > 0) {
          codeRef.current[index - 1].current?.focus();
          hits.current = 0;
        }
      }
    },
    [],
  );

  const handleFocus = useCallback((index) => {
    hits.current = 0;
    setIsFocused(index);
  }, []);

  const onError = useCallback((error: ServerError) => {
    mbShowToast({
      type: 'error',
      text1: error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN,
      position: 'bottom',
    });
  }, []);

  const onRequestSignUpCode = useCallback(() => {
    setIsInvalidCode(false);
    setCode(['', '', '', '', '']);
    if (type === VerifyOtpType.ForgetPassword) {
      resetPasswordCodeRequest(data.email, {
        onError,
        onSuccess: () => codeRef.current[0].current?.focus(),
      });
    } else {
      const name = getName(data);
      requestSignUpCode(
        { email: data.email, name },
        {
          onError,
          onSuccess: () => codeRef.current[0].current?.focus(),
        },
      );
    }
  }, [onError, requestSignUpCode, resetPasswordCodeRequest, data, type]);
  const onSuccess = useCallback(
    ({ token }: { token: Token }) => {
      MB_accessTokenUtils.setAccessToken(token);
      setSignedInStatus(SIGNED_IN_STATUS.signedIn);
      queryClient.invalidateQueries([USER_QUERY_KEYS.getCurrentUsertData]);
    },
    [queryClient, setSignedInStatus],
  );

  const onComplete = useCallback(() => {
    rnBiometrics.createKeys().then((resultObject) => {
      const { publicKey } = resultObject;
      setPublicKey(publicKey);
    });
    const codeText = code.join('');
    if (type === VerifyOtpType.ForgetPassword) {
      const { email } = data as ForgetPasswordData;
      navigation.navigate('ResetPassword', {
        email: email,
        code: codeText,
      });
      return;
    }

    const role = type === VerifyOtpType.SignUpContractor ? UserRole.Contractor : UserRole.Employee;
    const params = role === UserRole.Contractor ? { company: data } : { user: data };
    const password = (data as SignUpEmployeeData).password;
    const signUpData = {
      role,
      password,
      bioKey: publicKey,
      code: codeText,
      ...params,
    } as SignUpRequest<UserRole>;

    signUp(signUpData, {
      onError,
      onSuccess: (token) => {
        onSuccess({ token });
      },
    });
  }, [code, data, navigation, onError, onSuccess, signUp, type]);

  const onVerify = useCallback(() => {
    setIsInvalidCode(false);
    verifyPasswordRecoveryCode(
      {
        email: data.email,
        code: code.join(''),
        type: type === VerifyOtpType.ForgetPassword ? CodeType.ForgotPassword : CodeType.SignUp,
      },
      {
        onSuccess: onComplete,
        onError: () => setIsInvalidCode(true),
      },
    );
  }, [verifyPasswordRecoveryCode, data.email, code, onComplete, type]);

  return (
    <ComponentWrapper mobileHeaderOptions={{ showHeader: true, showBackArrow: true }}>
      <Text style={[textStyles.largeText, styles.title]}>Verify OTP</Text>
      <Text style={[textStyles.smallerText, styles.subTitle]}>
        Please enter the OTP that was sent to your email address.
      </Text>
      <View style={styles.codeContainer}>
        {code.map((char, index) => (
          <TextInput
            onKeyPress={(e) => onKeyPress(e, index)}
            key={index}
            ref={(input) => (inputRef.current[index] = input)}
            style={{
              ...styles.codeCell,
              borderColor: isFocused === index ? COLORS.primaryColor : COLORS.greyText,
            }}
            maxLength={5}
            onChangeText={(text) => onChangeText(text, index)}
            value={char}
            onFocus={() => handleFocus(index)}
            autoFocus={index === 0}
          />
        ))}
      </View>
      {isInvalidCode && (
        <Text style={[styles.passwordNote, styles.passwordNoteError]}>
          Invalid OTP code, Please check your email and try again!
        </Text>
      )}
      <MB_Button
        title="Verify OTP"
        style={styles.verifyOtp}
        textStyle={textStyles.smallerText}
        disabled={isDisabled}
        loading={isLoading}
        onPress={onVerify}
      />
      <Text style={[textStyles.smallerText, styles.noOtp]}>
        Didn’t receive OTP?{' '}
        <Text style={styles.resendText} onPress={onRequestSignUpCode}>
          Resend
        </Text>
      </Text>
    </ComponentWrapper>
  );
};

export { VerifyOtp };

const styles = StyleSheet.create({
  title: {
    color: COLORS.headerText,
    textAlign: 'left',
    letterSpacing: -0.8,
  },
  subTitle: {
    color: COLORS.greyText,
    textAlign: 'left',
    marginBottom: 9,
  },
  codeContainer: {
    marginTop: 18,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  codeCell: {
    width: 50,
    height: 50,
    paddingStart: 0,
    paddingEnd: 0,
    fontSize: 21,
    textAlign: 'center',
    borderWidth: 1,
    borderRadius: 5,
  },
  codeCellText: {
    fontSize: 21,
    textAlign: 'center',
  },
  verifyOtp: {
    marginTop: 22,
    height: 50,
    backgroundColor: COLORS.primaryColor,
    borderRadius: 30,
  },
  noOtp: {
    marginTop: 15,
    color: COLORS.bodyText,
  },
  resendText: {
    textDecorationLine: 'underline',
    fontWeight: '400',
    color: COLORS.primaryColor,
  },
  passwordNote: mbTextStyles([
    textStyles.smallerText,
    {
      fontSize: 11,
      color: COLORS.bodyText,
      marginTop: 15,
    },
  ]),
  passwordNoteError: {
    color: COLORS.red,
  },
});
