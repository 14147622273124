import {
  Application,
  Contractor,
  CreateContractorRequest,
  CreateEmployeeRequest,
  CreateEmployeeReviewRequest,
  Employee,
  Job,
  Review,
  UpdateContractorMeRequest,
  UpdateEmployeeMeRequest,
  RecommendedEmployee,
  RequestSignUpCodeRequest,
  UserRole,
  VerifyPasswordRecoveryCodeRequest,
} from '@wu/business';

import { Token } from '@workingu/rnw.utils.access-token-utils';
import { MB_InfinitePaginationPage } from '@workingu/rnw.utils.util-hooks';
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';

import { userApiCalls } from '../apiCalls/userApiCalls';
import { USER_QUERY_KEYS, JOBS_QUERY_KEYS } from '../constants/constants';
import { useSignedInContext } from '../context/SignedInContext';
import { ServerError, SignUpRequest, User } from '../typesAndInterfaces/typesAndInterfaces';

export const useGetCurrentUserData = (queryOptions?: UseQueryOptions<User, ServerError>) => {
  const { setCurrentUserData } = useSignedInContext();

  return useQuery<User, ServerError>(
    [USER_QUERY_KEYS.getCurrentUsertData],
    async () => {
      const currentUserData = await userApiCalls.getCurrentUserData();
      setCurrentUserData(currentUserData);
      return currentUserData;
    },
    { staleTime: Infinity, ...queryOptions },
  );
};

export const useSignIn = () => {
  return useMutation<Token, ServerError, { email: string; password: string }, unknown>(
    async (data) => userApiCalls.login(data.email, data.password),
  );
};

export const useBiometricSignIn = () => {
  return useMutation<Token, ServerError, { email: string; password: string }, unknown>(
    async (data) => userApiCalls.biometricLogin(data.email),
  );
};

export const useSignUp = () => {
  return useMutation<Token, ServerError, SignUpRequest<UserRole>, unknown>(async (params) =>
    userApiCalls.signUp(params),
  );
};

export const useRequestSignUpCode = () => {
  return useMutation<void, ServerError, RequestSignUpCodeRequest, unknown>(async (data) =>
    userApiCalls.requestSignUpCode(data),
  );
};

export const useResetPasswordRequest = () => {
  return useMutation<void, ServerError, string, unknown>(async (data) =>
    userApiCalls.resetPasswordRequest(data),
  );
};

export const useResetPasswordCodeRequest = () => {
  return useMutation<void, ServerError, string, unknown>(async (data) =>
    userApiCalls.resetPasswordCodeRequest(data),
  );
};

export const useVerifyPasswordRecoveryCode = () => {
  return useMutation<void, ServerError, VerifyPasswordRecoveryCodeRequest, unknown>(async (data) =>
    userApiCalls.verifyPasswordRecoveryCode(data),
  );
};

export const useChangePassword = () => {
  return useMutation<void, ServerError, { password: string; newPassword: string }, unknown>(
    async (data) => userApiCalls.changePassword(data.password, data.newPassword),
  );
};

export const useChangePasswordRecovery = () => {
  return useMutation<void, ServerError, { newPassword: string; recoveryToken: string }, unknown>(
    async (data) => userApiCalls.changePasswordRecovery(data.newPassword, data.recoveryToken),
  );
};

export const useChangePasswordRecoveryCode = () => {
  return useMutation<
    void,
    ServerError,
    { email: string; code: string; newPassword: string },
    unknown
  >(async (data) =>
    userApiCalls.changePasswordRecoveryCode(data.email, data.code, data.newPassword),
  );
};

export const useDeleteAccount = () => {
  return useMutation<void, ServerError>(async () => userApiCalls.deleteAccount());
};

export const useGetEmployeeData = (
  queryOptions?: UseQueryOptions<Employee | null, ServerError>,
) => {
  return useQuery<Employee | null, ServerError>(
    [USER_QUERY_KEYS.getEmployeeData],
    userApiCalls.getEmployeeData,
    { staleTime: Infinity, ...queryOptions }, // staleTime: Infinity to not cause route navigation to rerender
  );
};

export const useCreateEmployeeData = () => {
  const queryClient = useQueryClient();
  return useMutation<Employee, ServerError, CreateEmployeeRequest, unknown>(
    async (params) => userApiCalls.createEmployeeData(params),
    {
      onSuccess: (data) => {
        queryClient.setQueriesData([USER_QUERY_KEYS.getEmployeeData], data);
        queryClient.invalidateQueries([USER_QUERY_KEYS.getEmployeeData]);
      },
    },
  );
};

export const useUpdateEmployeeData = () => {
  const queryClient = useQueryClient();
  return useMutation<Employee, ServerError, UpdateEmployeeMeRequest, unknown>(
    async (params) => userApiCalls.updateEmployeeData(params),
    {
      onSuccess: (data, params) => {
        queryClient.setQueriesData([USER_QUERY_KEYS.getEmployeeData], data);
        queryClient.invalidateQueries([USER_QUERY_KEYS.getEmployeeData]);
        if (params.trades) {
          queryClient.invalidateQueries([JOBS_QUERY_KEYS.getRecommendedJobs]);
        }
      },
    },
  );
};

export const useGetContractorData = (
  queryOptions?: UseQueryOptions<Contractor | null, ServerError>,
) => {
  return useQuery<Contractor | null, ServerError>(
    [USER_QUERY_KEYS.getContractorData],
    userApiCalls.getContractorData,
    { staleTime: Infinity, ...queryOptions }, // staleTime: Infinity to not cause route navigation to rerender
  );
};

export const useCreateContractorData = () => {
  const queryClient = useQueryClient();
  return useMutation<Contractor, ServerError, CreateContractorRequest, unknown>(
    async (params) => userApiCalls.createContractorData(params),
    {
      onSuccess: (data) => {
        queryClient.setQueriesData([USER_QUERY_KEYS.getContractorData], data);
        queryClient.invalidateQueries([USER_QUERY_KEYS.getContractorData]);
      },
    },
  );
};

export const useUpdateContractorData = () => {
  const queryClient = useQueryClient();
  return useMutation<Contractor, ServerError, UpdateContractorMeRequest, unknown>(
    async (params) => userApiCalls.updateContractorData(params),
    {
      onSuccess: (data) => {
        queryClient.setQueriesData([USER_QUERY_KEYS.getContractorData], data);
        queryClient.invalidateQueries([USER_QUERY_KEYS.getContractorData]);
      },
    },
  );
};

export const useContractorApplicants = ({
  totalItemsPerPage,
  queryOptions,
}: {
  totalItemsPerPage: number;
  queryOptions?: UseInfiniteQueryOptions<MB_InfinitePaginationPage<Application>, any>;
}) => {
  return useInfiniteQuery<MB_InfinitePaginationPage<Application>, any>(
    [USER_QUERY_KEYS.getContractorApplicants],
    async ({ pageParam }: { pageParam?: string }) =>
      userApiCalls.getContractorApplicants({
        totalItemsPerPage,
        cursor: pageParam,
        offset: pageParam,
      }),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => lastPage.nextOffset ?? undefined,
      ...queryOptions,
    },
  );
};

export const useEmployeeApplications = ({
  totalItemsPerPage,
  queryOptions,
}: {
  totalItemsPerPage: number;
  queryOptions?: UseInfiniteQueryOptions<MB_InfinitePaginationPage<Application>, any>;
}) => {
  return useInfiniteQuery<MB_InfinitePaginationPage<Application>, any>(
    [USER_QUERY_KEYS.getEmployeeApplications],
    async ({ pageParam }: { pageParam?: string }) =>
      userApiCalls.getEmployeeApplications({
        totalItemsPerPage,
        cursor: pageParam,
        offset: pageParam,
      }),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => lastPage.nextOffset ?? undefined,
      ...queryOptions,
    },
  );
};

export const useRecommendedEmployees = ({
  totalItemsPerPage,
  queryOptions,
}: {
  totalItemsPerPage: number;
  queryOptions?: UseInfiniteQueryOptions<MB_InfinitePaginationPage<RecommendedEmployee>, any>;
}) => {
  return useInfiniteQuery<MB_InfinitePaginationPage<RecommendedEmployee>, any>(
    [USER_QUERY_KEYS.getRecommendedEmployees],
    async ({ pageParam }: { pageParam?: string }) =>
      userApiCalls.getRecommendedEmployees({
        totalItemsPerPage,
        cursor: pageParam,
        offset: pageParam,
      }),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => lastPage.nextOffset ?? undefined,
      ...queryOptions,
    },
  );
};

export const useEmployeeJobs = ({
  totalItemsPerPage,
  queryOptions,
}: {
  totalItemsPerPage: number;
  queryOptions?: UseInfiniteQueryOptions<MB_InfinitePaginationPage<Job>, any>;
}) => {
  return useInfiniteQuery<MB_InfinitePaginationPage<Job>, any>(
    [USER_QUERY_KEYS.getEmployeeJobs],
    async ({ pageParam }: { pageParam?: string }) =>
      userApiCalls.getEmployeeJobs({
        totalItemsPerPage,
        cursor: pageParam,
        offset: pageParam,
      }),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => lastPage.nextOffset ?? undefined,
      ...queryOptions,
    },
  );
};

export const useCreateEmployeeReview = () => {
  const queryClient = useQueryClient();
  return useMutation<Review, ServerError, CreateEmployeeReviewRequest, unknown>(
    async (params) => userApiCalls.createEmployeeReview(params),
    {
      onSuccess: (_data, params) => {
        queryClient.invalidateQueries([USER_QUERY_KEYS.getEmployeeReviews, params.id]);
        queryClient.invalidateQueries([JOBS_QUERY_KEYS.getJobApplication, params.applicationId]);
      },
    },
  );
};

export const useReviews = ({
  totalItemsPerPage,
  queryOptions,
  employeeId,
}: {
  totalItemsPerPage: number;
  employeeId: string;
  queryOptions?: UseInfiniteQueryOptions<MB_InfinitePaginationPage<Review>, any>;
}) => {
  return useInfiniteQuery<MB_InfinitePaginationPage<Review>, any>(
    [USER_QUERY_KEYS.getEmployeeReviews, employeeId],
    async ({ pageParam }: { pageParam?: string }) =>
      userApiCalls.getEmployeeReviews({
        totalItemsPerPage,
        cursor: pageParam,
        offset: pageParam,
        id: employeeId,
      }),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => lastPage.nextOffset ?? undefined,
      ...queryOptions,
    },
  );
};

export const useCompleteJob = () => {
  const queryClient = useQueryClient();
  return useMutation<void, ServerError, { applicationId: string; jobId: string }, unknown>(
    async ({ jobId }) => userApiCalls.completeJob(jobId),
    {
      onSuccess: (_data, params) => {
        queryClient.invalidateQueries([USER_QUERY_KEYS.getContractorApplicants]);
        queryClient.invalidateQueries([JOBS_QUERY_KEYS.getJob, params.jobId]);
        queryClient.invalidateQueries([JOBS_QUERY_KEYS.getJobApplication, params.applicationId]);
        queryClient.invalidateQueries([JOBS_QUERY_KEYS.getContractorRecentJobs]);
        queryClient.invalidateQueries([JOBS_QUERY_KEYS.getContractorCompletedJobs]);
      },
    },
  );
};
