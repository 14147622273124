import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { MB_PopUp, MB_PopUpSetDefaultStyles } from '@workingu/rnw.components.pop-up';
import { MB_accessTokenUtils } from '@workingu/rnw.utils.access-token-utils';
import { isMobile, MB_deviceInfoInitialize } from '@workingu/rnw.utils.device-info';
import { MB_orientationLocker, MB_ORIENTATION_LOCK } from '@workingu/rnw.utils.orientation-locker';
import { mbApplyTransparency, mbPlatformStyle, mbShadow, mbTextStyles, mbTextStylesSetDefaultFontFamily } from '@workingu/rnw.utils.style-utils';
import { userApiCalls } from '../apiCalls/userApiCalls';
import { COLORS } from '../constants/colors';
import { FONT_STYLES, textStyles } from '../constants/textStyles';
import { fontLoader } from '../utils/fontLoader';
import { MB_Toast } from '@workingu/rnw.components.toast';
import { MB_ModalAttachGlobalChildren, MB_ModalSetDefaultProps } from '@workingu/rnw.components.modal';
import { MB_ResourceUploaderSetDefaultStyles } from '@workingu/rnw.components.resource-uploader';
import { MB_ResourcePickerUISetDefaultStyles } from '@workingu/rnw.components.resource-picker';
import { CustomToastConfig } from '../components/helperComponents/CustomToastConfig';
import { MB_DropDownPickerSetDefaultStyles } from '@workingu/rnw.components.dropdownpicker/dist/src/MB_DropDownPicker';
import { MB_TextInputSetDefaultStyles } from '@workingu/rnw.components.text-input/dist/src/MB_TextInput';
import { initialWindowMetrics } from 'react-native-safe-area-context';

function useAppInitialSetup() {
    const [isDone, setIsDone] = useState(false);

    useEffect(() => {
        async function performInitialSetup() {
            await MB_deviceInfoInitialize();

            MB_orientationLocker.setDefaultOrientation(MB_ORIENTATION_LOCK.PORTRAIT);

            mbTextStylesSetDefaultFontFamily(FONT_STYLES.ManropeBase);

            MB_accessTokenUtils.setAccessTokenRefreshEndpointFunction(userApiCalls.requestNewAccessToken);

            MB_ModalSetDefaultProps({
                keyboardAwareProps: {
                    extraScrollHeight: -(initialWindowMetrics?.insets.bottom ?? 0)
                },
            });

            MB_PopUpSetDefaultStyles({
                defaultContainerStyle: styles.modalPopUpDefaultContainer,
                defaultTitleStyle: styles.modalPopUpDefaultTitle,
                defaultMessageStyle: styles.modalPopUpDefaultMessage,
                defaultPrimaryButtonStyle: styles.modalPopUpDefaultPrimaryButton,
                defaultPrimaryButtonTextStyle: styles.modalPopUpDefaultPrimaryButtonText,
                defaultSeconaryButtonStyle: styles.modalPopUpDefaultSecondaryButton,
                defaultSecondaryButtonTextStyle: styles.modalPopUpDefaultSecondaryButtonText,
                defaultButtonsContainerStyleModifier: styles.modalPopUpDefaultButtonsContainerModifier,
            });

            MB_ResourceUploaderSetDefaultStyles({
                wrapperStyle: styles.resourceUploaderDefaultContainer,
                uploadButtonStyle: styles.resourceUploaderButton,
                previewContainerStyle: styles.resourceUploaderPreviewContainer,
                previewImageBackgroundStyle: styles.resourceUploaderPreviewImageBackground,
                modalTitleStyle: styles.modalTitleStyle,
                dragAndDropAndProcessingResourceTextStyle: styles.dragAndDropAndProcessingResourceTextStyle,
            });

            MB_ResourcePickerUISetDefaultStyles({
                cameraOrGalleryModalChildrenStyle: styles.cameraOrGalleryModalChildrenStyle,
                cameraOrGalleryButtonStyle: styles.cameraOrGalleryButtonStyle,
                cameraorGalleryTextStyle: styles.cameraorGalleryTextStyle,
                cameraOrGalleryCancelButtonStyle: styles.cameraOrGalleryCancelButtonStyle
            })

            MB_DropDownPickerSetDefaultStyles({
                containerStyle: styles.dropdownWrapperStyle,
                style: styles.dropdownStyle,
                dropdownContainerStyle: styles.dropdownContainerStyle,
                labelStyle: styles.dropdownOptionLabelStyle,
                searchTextInput: styles.dropdownSearchTextInput,
                searchContainerStyle: styles.dropdownSearchContainerStyle,
                placeholderStyle: styles.dropdownPlaceholderStyle,
                dropdownLabelStyle: styles.dropdownLabelStyle,
            });

            MB_TextInputSetDefaultStyles({
                style: styles.textInput,
                textInputStyle: [textStyles.smallerText, styles.textInputText],
                styleAdditionsFocused: styles.textInputFocused,
            })


            try {
                await fontLoader();
            } catch (error) {
                console.error('Prepare stage failed', error);
            } finally {
                setIsDone(true);
            }
        }

        MB_ModalAttachGlobalChildren([
            <MB_PopUp key={'popUp'} />,
            <MB_Toast config={CustomToastConfig} key="toast" />,
        ]);

        if (!isDone) {
            performInitialSetup();
        }
    }, [isDone]);

    return isDone;
}


export { useAppInitialSetup };

const styles = StyleSheet.create({
    modalPopUpDefaultContainer: {
        backgroundColor: COLORS.white,
        borderRadius: 8,
        paddingTop: 32,
        paddingHorizontal: isMobile ? 18 : 76,
        paddingBottom: 32,
        marginHorizontal: 16,
    },
    modalPopUpDefaultTitle: mbTextStyles([
        textStyles.largeText, {
            fontWeight: '700',
            color: COLORS.black,
            ...mbPlatformStyle({
                mobile: {
                    fontSize: 18,
                },
            }),
        },
    ]),
    modalPopUpDefaultMessage: mbTextStyles([
        textStyles.smallText, {
            marginTop: isMobile ? 10 : 16,
            color: COLORS.textLightPurple,
            ...mbPlatformStyle({
                mobile: {
                    fontSize: 14,
                    color: COLORS.bodyText,
                },
            }),
        },
    ]),
    modalPopUpDefaultPrimaryButton: {
        height: isMobile ? 38 : 38,
        minWidth: 115,
        alignSelf: 'center',
        backgroundColor: COLORS.primaryColor,
    },
    modalPopUpDefaultPrimaryButtonText: mbTextStyles([
        textStyles.smallText, {
            color: COLORS.white,
        },
    ]),
    modalPopUpDefaultSecondaryButtonText: mbTextStyles([
        textStyles.smallText, {
            color: COLORS.primaryColor,
        },
    ]),
    modalPopUpDefaultSecondaryButton: {
        height: isMobile ? 38 : 38,
        minWidth: 115,
        backgroundColor: 'transparent',
        borderColor: COLORS.primaryColor,
        borderWidth: 1,
    },
    modalPopUpDefaultButtonsContainerModifier: {
        marginTop: 33,
    },
    resourceUploaderDefaultContainer: {
        backgroundColor: COLORS.white,
        paddingHorizontal: 36,
    },
    resourceUploaderPreviewContainer: {
        backgroundColor: COLORS.lightOrange,
        borderColor: COLORS.primaryColor,
        borderWidth: 1,
        borderStyle: 'dashed',
    },
    resourceUploaderButton: {
        backgroundColor: COLORS.primaryColor,
        width: 268,
        height: 62,
        marginTop: 32,
    },
    resourceUploaderPreviewImageBackground: {
        borderRadius: 300,
        width: 82,
        height: 82,
        backgroundColor: COLORS.primaryColor,
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalTitleStyle: {
        color: COLORS.headerText,
    },
    dragAndDropAndProcessingResourceTextStyle: {
        color: COLORS.primaryColor
    },
    cameraOrGalleryModalChildrenStyle: {
        backgroundColor: COLORS.white,
    },
    cameraOrGalleryButtonStyle: {
        backgroundColor: COLORS.lightOrange,
        borderColor: COLORS.primaryColor,
        borderWidth: 1,
        borderStyle: 'dashed',
    },
    cameraorGalleryTextStyle: {
        color: COLORS.primaryColor,
    },
    cameraOrGalleryCancelButtonStyle: {
        backgroundColor: COLORS.primaryColor,
    },
    // dropdownPicker
    dropdownWrapperStyle: { width: '100%' },
    dropdownStyle: {
        backgroundColor: COLORS.white,
    },
    dropdownContainerStyle: {
        backgroundColor: COLORS.white,
        borderWidth: 1,
        borderColor: mbApplyTransparency("#BAC0CA", 0.4),
    },
    dropdownOptionLabelStyle: {
        color: COLORS.bodyText,
        fontSize: 11,
    },
    dropdownLabelStyle: {
        color: COLORS.black,
        fontSize: 11,
    },
    dropdownSearchTextInput: {
        borderColor: mbApplyTransparency("#BAC0CA", 0.4),
        color: COLORS.black,
    },
    dropdownSearchContainerStyle: {
        borderBottomColor: mbApplyTransparency("#BAC0CA", 0.4),
    },
    dropdownPlaceholderStyle: {
        color: COLORS.greyText,
        fontSize: 12
    },
    // text input
    textInput: {
        width: '100%',
        height: 48,
        borderWidth: 0.5,
        borderColor: '#C4C4C4',
        borderRadius: 5,
        backgroundColor: COLORS.white,
    },
    textInputText: {
        fontSize: 11,
        color: COLORS.bodyText,
        textAlign: 'left',
    },
    textInputFocused: {
        borderWidth: 1,
        borderColor: COLORS.primaryColor,
        ...mbShadow({
            color: COLORS.primaryColor,
            offsetWidth: 0,
            offsetHeight: 2,
            opacity: 0.15,
            radius: 7,
            elevation: 7,
        }),
    },
});
