import { MB_Button } from '@workingu/rnw.components.button';
import { mbShadow } from '@workingu/rnw.utils.style-utils';
import { useNavigation } from '@react-navigation/core';
import React, { ReactChild, useState } from 'react';
import { StyleProp, StyleSheet, Text, View, ViewStyle } from 'react-native';
import { TouchableRipple } from 'react-native-paper';
import Feather from 'react-native-vector-icons/Feather';
import { COLORS } from '../../../constants/colors';
import { textStyles } from '../../../constants/textStyles';
import { UniversalScreenNavigationProp } from '../../../typesAndInterfaces/componentProps';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { ConstructionHelmet } from '../../../resources/svgComponents/ContractionHelmet';
import { UserRole } from '@wu/business';

interface RoleCardProps {
    style?: StyleProp<ViewStyle>,
    icon: ReactChild
    iconBackgroundColor?: string,
    title: string
    description: string
    isActive?: boolean
    onPress?: () => void
}

const RoleCard = ({ style, icon, title, description, iconBackgroundColor, isActive, onPress }: RoleCardProps) => {
    return (
        <TouchableRipple style={[styles.card, isActive && styles.cardActive, style]} onPress={onPress}>
            <View>
                <View style={[styles.cardIcon, { backgroundColor: iconBackgroundColor }]}>
                    {icon}
                </View>
                <Text style={[textStyles.smallText, styles.cardTitle]}>{title}</Text>
                <Text style={[textStyles.smallerText, styles.cardDescription]}>{description}</Text>
            </View>
        </TouchableRipple>
    );
};

const RoleSelector = () => {

    const [role, setRole] = useState<UserRole>();
    const navigation = useNavigation<UniversalScreenNavigationProp>();

    return (
        <ComponentWrapper mobileHeaderOptions={{ showHeader: true, showBackArrow: true }} wrapInScrollView={false}>
            <Text style={[textStyles.largeText, styles.title]}>Select your role</Text>
            <Text style={[textStyles.smallerText, styles.subTitle]}>Let us know who you would like to sign up as.</Text>
            <RoleCard
                isActive={role === UserRole.Contractor}
                onPress={() => setRole(UserRole.Contractor)}
                icon={<Feather name="briefcase" size={28} color="#00B4CE" />}
                iconBackgroundColor="#E0F8FD"
                title="Employer"
                description="You are a contractor (or employer) that wants to hire employees to complete short-term jobs."
            />
            <RoleCard
                style={{ marginTop: 12 }}
                isActive={role === UserRole.Employee}
                onPress={() => setRole(UserRole.Employee)}
                icon={<ConstructionHelmet size={28} />}
                iconBackgroundColor="#FDF6E0"
                title="Employee"
                description="You are an experienced construction worker looking for short-term jobs around you."
            />
            <MB_Button
                title="Continue"
                style={[styles.continueBtn, !role && styles.disabledBtn]}
                textStyle={textStyles.smallerText}
                disabled={!role}
                onPress={() => role && navigation.navigate('SignUp', { role })}
            />
        </ComponentWrapper>
    );
};

export { RoleSelector };

const styles = StyleSheet.create({
    title: {
        color: COLORS.headerText,
        textAlign: 'left',
        letterSpacing: -0.8,
    },
    subTitle: {
        color: COLORS.greyText,
        textAlign: 'left',
        marginBottom: 29,
    },
    continueBtn: {
        height: 50,
        backgroundColor: COLORS.primaryColor,
        borderRadius: 30,
        marginTop: 'auto',
        marginBottom: 47,
    },
    disabledBtn: {
        backgroundColor: '#FFD3BE',
    },
    // Card Style
    card: {
        paddingHorizontal: 18,
        paddingVertical: 20,
        borderWidth: 0.3,
        borderColor: '#C4C4C4',
        borderRadius: 7,
        backgroundColor: COLORS.white,
    },
    cardActive: {
        borderWidth: 0.8,
        borderColor: COLORS.primaryColor,
        ...mbShadow({
            color: COLORS.primaryColor,
            offsetWidth: 0,
            offsetHeight: 2,
            opacity: 0.17,
            radius: 7,
            elevation: 20,
        }),
    },
    cardIcon: {
        width: 52,
        height: 50,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
    },
    cardTitle: {
        fontSize: 13,
        color: COLORS.bodyText,
        marginTop: 15,
        textAlign: 'left',
        fontWeight: '700',
        letterSpacing: -0.48,
    },
    cardDescription: {
        fontSize: 11,
        color: COLORS.bodyText2,
        fontWeight: '400',
        marginTop: 3,
        textAlign: 'left',
    },
});
