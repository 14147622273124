import { MB_Button } from '@workingu/rnw.components.button';
import { MB_Image } from '@workingu/rnw.components.image';
import { mbApplyTransparency } from '@workingu/rnw.utils.style-utils';
import { useNavigation } from '@react-navigation/core';
import React from 'react';
import { ImageBackground, StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import { COLORS } from '../../constants/colors';
import { textStyles } from '../../constants/textStyles';
import { UniversalScreenNavigationProp } from '../../typesAndInterfaces/componentProps';
import { imageUtils } from '../../utils/imageUtils';

const MobileLandingPage = () => {

  const navigation = useNavigation<UniversalScreenNavigationProp>();

  return (
    <View style={styles.container} >
      <ImageBackground source={imageUtils.images.landingPageBackground} style={styles.backgroundImage}>
        <View style={styles.background}>
          <View style={styles.body}>
            <MB_Image
              source={imageUtils.images.wuLogo}
              style={styles.logo}
              resizeMode="contain"
            />
            <Text style={[textStyles.smallText, styles.logoSubText]}>Connecting and matching employers with employees.</Text>
          </View>
          <View style={styles.footer}>
            <MB_Button
              title="Sign in"
              style={styles.signUpBtn}
              textStyle={textStyles.smallerText}
              onPress={() => navigation.navigate('Login')}
            />
            <TouchableOpacity style={styles.dontHaveAccountWrapper} onPress={() => navigation.navigate('RoleSelector')} activeOpacity={1}>
              <Text style={textStyles.smallerText}>Don’t have an account? <Text style={styles.signInText}>Sign up</Text></Text>
            </TouchableOpacity>
          </View>
        </View>
      </ImageBackground>
    </View>
  );
};

export { MobileLandingPage };

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
  },
  background: {
    backgroundColor: mbApplyTransparency(COLORS.black, 0.8),
    flex: 1,
    paddingHorizontal: 20,
  },
  body: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 30,
  },
  logo: {
    width: 94,
    height: 68,
  },
  logoSubText: {
    fontSize: 13,
    marginTop: 2,
  },
  footer: {
    marginBottom: 49,
  },
  signUpBtn: {
    height: 50,
    backgroundColor: COLORS.primaryColor,
    borderRadius: 30,
  },
  dontHaveAccountWrapper: {
    marginTop: 10,
    height: 32,
    justifyContent: 'center',
    paddingBottom: 8,
  },
  signInText: {
    textDecorationLine: 'underline',
    fontWeight: '400',
  },
});
