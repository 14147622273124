import { useMemo } from 'react';

import { OnUploadCompleted, createUploader } from '../utils/uploader';
import { useCreateFile, useUploadFile } from './fileHooks';

export const useUploader = (onCompleted: OnUploadCompleted) => {
  const { mutate: getFileUploadUrl } = useCreateFile();
  const { mutate: uploadFile } = useUploadFile();

  const uploader = useMemo(
    () =>
      createUploader({
        getFileUploadUrl,
        uploadFile,
        onCompleted,
      }),
    [getFileUploadUrl, uploadFile, onCompleted]
  );

  return uploader;
};
