import React, { useCallback, useState } from "react";
import { MB_TextInput } from "@workingu/rnw.components.text-input";
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";
import Feather from "react-native-vector-icons/Feather";
import { MB_Modal } from "@workingu/rnw.components.modal";
import { COLORS } from "../../constants/colors";
import { textStyles } from "../../constants/textStyles";
import { format as formatDate } from "date-fns";
import DatePicker from 'react-native-date-picker';
import { MB_Button } from "@workingu/rnw.components.button";
import { MB_utilHooks } from "@workingu/rnw.utils.util-hooks";

const TimeSelectorModal = ({ date, isVisible, onDismiss, onDateChanged }: { date: Date, isVisible: boolean, onDismiss: () => void, onDateChanged: (newDate: Date | undefined) => void }) => {
    const [selectedDateTime, setSelectedDateTime] = useState<Date | undefined>(undefined);

    return (
        <MB_Modal
            isVisible={isVisible}
            onDismiss={onDismiss}
            hideCloseButton
            childrenWrapperStyle={styles.modalWrapper}
        >
            <DatePicker
                date={date}
                onDateChange={setSelectedDateTime}
                mode="time"
                textColor={COLORS.black}
                fadeToColor="none"
            />

            <View style={styles.buttonContainer}>
                <MB_Button
                    title="Cancel"
                    style={styles.cancelBtn}
                    textStyle={[textStyles.smallerText, { color: COLORS.primaryColor }]}
                    onPress={onDismiss}
                />
                <MB_Button
                    title="Done"
                    style={styles.doneBtn}
                    textStyle={textStyles.smallerText}
                    onPress={() => onDateChanged(selectedDateTime)}
                />
            </View>
        </MB_Modal>
    );
}

const TimeInput = ({ time, containerStyle, onTimeChanged }: { time?: Date, containerStyle?: StyleProp<ViewStyle>, onTimeChanged?: (date: Date) => void }) => {
    const [autoGeneratedTime] = useState<Date>(time ?? new Date(new Date().setHours(9, 0, 0, 0)));
    const [isTimeSelectorVisible, showTimeSelector, hideTimeSelector] = MB_utilHooks.useBool();
    const [selectedDateTime, setSelectedDateTime] = useState<Date | undefined>(time ?? undefined);

    const onDateChanged = useCallback((newDate: Date | undefined) => {
        setSelectedDateTime(newDate ?? autoGeneratedTime);
        onTimeChanged?.(newDate ?? autoGeneratedTime);

        hideTimeSelector();
    }, [autoGeneratedTime, hideTimeSelector, onTimeChanged])

    return (
        <View style={[styles.container, containerStyle]}>

            <TimeSelectorModal
                isVisible={isTimeSelectorVisible}
                onDismiss={hideTimeSelector}
                date={new Date(selectedDateTime ?? autoGeneratedTime)}
                onDateChanged={onDateChanged}
            />

            <MB_TextInput
                placeholder="-Select-"
                style={[styles.textInput]}
                placeholderTextColor={COLORS.greyText}
                textInputStyle={[textStyles.smallerText, styles.textInputText]}
                value={selectedDateTime ? formatDate(selectedDateTime, 'h:mm a') : undefined}
                disable
            />
            <Feather name="chevron-down" size={20} style={styles.chevronDown} color={COLORS.greyText} />

            <TouchableOpacity
                style={styles.touchableOpacityStyles}
                onPress={showTimeSelector}
                activeOpacity={1}
            />
        </View>
    )
}

export { TimeInput };

const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
    },
    textInput: {
        width: '100%',
        height: 48,
        borderWidth: 0.5,
        borderColor: '#C4C4C4',
        borderRadius: 5,
        backgroundColor: COLORS.white,
    },
    textInputText: {
        fontSize: 11,
        color: COLORS.bodyText,
        textAlign: 'left',
    },
    chevronDown: {
        position: 'absolute',
        right: 17.25,
    },
    doneBtn: {
        height: 50,
        backgroundColor: COLORS.primaryColor,
        borderRadius: 30,
        flex: 1,
        marginHorizontal: 4,
    },
    cancelBtn: {
        height: 50,
        backgroundColor: 'transparent',
        borderRadius: 30,
        flex: 1,
        marginHorizontal: 4,
        borderWidth: 1,
        borderColor: COLORS.primaryColor,
    },
    touchableOpacityStyles: {
        position: 'absolute',
        width: '100%',
        height: '100%',
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginTop: 'auto',
        alignSelf: 'stretch',
    },
    modalWrapper: {
        backgroundColor: COLORS.white,
        width: 275,
        paddingTop: 16,
        height: 279
    }
});
