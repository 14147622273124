import {
  Employee,
  EmployeeSchema,
  CreateEmployeeRequest,
  ContractorSchema,
  Contractor,
  CreateContractorRequest,
  UpdateEmployeeMeRequest,
  UpdateContractorMeRequest,
  Application,
  ApplicationSchema,
  ContractorApplicationsRequest,
  EmployeeApplicationsRequest,
  Job,
  JobSchema,
  EmployeeJobsRequest,
  CreateEmployeeReviewRequest,
  ReviewSchema,
  Review,
  GetEmployeeReviewsRequest,
  RecommendedEmployeesRequest,
  RecommendedEmployee,
  RequestSignUpCodeRequest,
  UserRole,
  VerifyPasswordRecoveryCodeRequest,
} from '@wu/business';

import { Token } from '@workingu/rnw.utils.access-token-utils';
import { MB_InfinitePaginationPage } from '@workingu/rnw.utils.util-hooks';
import z from 'zod';

import { envs } from '../../env';
import { SERVER_ERROR_CODES } from '../constants/constants';
import { Image, ServerError, SignUpRequest, User } from '../typesAndInterfaces/typesAndInterfaces';
import { utils } from '../utils/utils';
import { ImageObj, TokensObj, UserObj } from '../utils/zod/zodObjects';
import { zodUtils } from '../utils/zod/zodUtils';
import { axiosCaller, customAxios } from './customAxios';

const userApiCalls = {
  /**
   * Requests a new access token from a server
   * @param accessToken Access token for server validation
   * @param refreshToken Refresh token that is used to get a new access token
   * @param skipThrows If set to true, will skip all the throws, used in axiosConfig. Since we need to call this
   * when we get invalid access token or refresh token(Server needs this to potentially log some malicious activities)
   * setting this to true will prevent an infinite loop situation.
   * @returns promise containing the new access token
   */
  requestNewAccessToken: async (
    accessToken: string,
    refreshToken: string,
    skipThrows?: boolean
  ): Promise<Token> => {
    return await customAxios
      .post(
        envs.SERVER_URL + '/api/users/refresh-token',
        {
          accessToken,
        },
        {
          headers: {
            Authorization: refreshToken,
          },
        }
      )
      .then(function (response) {
        if (skipThrows) {
          return;
        }

        const tokenPair = response.data.data.tokenPair;
        const validatorResponse = TokensObj.safeParse(tokenPair);
        if (!validatorResponse.success) {
          console.error('tokenPair was ', tokenPair, validatorResponse.error.issues);
          throw utils.createErrorObject(
            'Data mismatch for request new access token',
            SERVER_ERROR_CODES.CLIENT_DATA_MISMATCH_ERROR
          );
        }
        return tokenPair;
      })
      .catch(function (error) {
        console.error('Error while requesting access code', error.response);
        if (!skipThrows) {
          throw error.response.data as ServerError;
        }
      });
  },
  getCurrentUserData: async (): Promise<User> => {
    return await axiosCaller({
      path: '/api/users/me',
      method: 'get',
      responseDataKey: 'user',
      responseValidatorObj: UserObj,
      responseFormatValidatorMessage: 'Data mismatch for current user data response',
      passToken: true,
    });
  },

  login: async (email: string, password: string): Promise<Token> => {
    return await axiosCaller({
      path: '/api/users/login',
      method: 'post',
      responseDataKey: 'tokenPair',
      responseValidatorObj: TokensObj,
      responseFormatValidatorMessage: 'Data mismatch for login response',
      data: { email, password },
    });
  },

  signUp: async (params: SignUpRequest<UserRole>): Promise<Token> => {
    return await axiosCaller({
      path: '/api/users/sign-up',
      method: 'post',
      responseValidatorObj: TokensObj,
      responseFormatValidatorMessage: 'Data mismatch for signup response',
      responseDataKey: 'tokenPair',
      data: { ...params },
    });
  },

  requestSignUpCode: async (data: RequestSignUpCodeRequest): Promise<void> => {
    return await axiosCaller({
      path: '/api/users/request-sign-up-code',
      method: 'post',
      data,
    });
  },

  resetPasswordRequest: async (email: string): Promise<void> => {
    return await axiosCaller({
      path: '/api/users/request-password-recovery-email',
      method: 'post',
      params: { email },
    });
  },

  resetPasswordCodeRequest: async (email: string): Promise<void> => {
    return await axiosCaller({
      path: '/api/users/request-password-recovery-code',
      method: 'post',
      params: { email },
    });
  },

  verifyPasswordRecoveryCode: async (params: VerifyPasswordRecoveryCodeRequest): Promise<void> => {
    return await axiosCaller({
      path: '/api/users/verify-password-recovery-code',
      method: 'post',
      params,
    });
  },

  signOut: async (): Promise<void> => {
    return await axiosCaller({
      path: '/api/users/sign-out',
      method: 'post',
      data: {},
      passToken: true,
    });
  },

  changePassword: async (password: string, newPassword: string): Promise<void> => {
    return await axiosCaller({
      path: '/api/users/change-password',
      method: 'post',
      data: { password, newPassword },
      passToken: true,
    });
  },

  changePasswordRecovery: async (newPassword: string, recoveryToken: string): Promise<void> => {
    return await axiosCaller({
      path: '/api/users/change-password-recovery',
      method: 'post',
      data: { newPassword, recoveryToken },
    });
  },

  changePasswordRecoveryCode: async (
    email: string,
    code: string,
    newPassword: string
  ): Promise<void> => {
    return await axiosCaller({
      path: '/api/users/change-password-recovery-code',
      method: 'post',
      data: { newPassword, email, code },
    });
  },

  uploadProfilePhoto: async ({
    formData,
    uploadProgress,
  }: {
    formData: FormData;
    uploadProgress?: (percentCompleted: number) => void;
  }): Promise<Image> => {
    return await axiosCaller({
      path: '/api/users/changeProfilePhoto',
      method: 'post',
      responseValidatorObj: ImageObj,
      responseFormatValidatorMessage: 'Data mismatch for upload profile photo response',
      passToken: true,
      data: formData,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = !progressEvent.total
          ? 0
          : Math.round((progressEvent.loaded * 100) / progressEvent.total);
        uploadProgress?.(percentCompleted);
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  deleteAccount: async (): Promise<void> => {
    return await axiosCaller({
      path: '/api/users/deleteAccount',
      method: 'post',
      data: {},
      passToken: true,
    });
  },

  getEmployeeData: async (): Promise<Employee | null> => {
    return await axiosCaller({
      path: '/api/employees/me',
      method: 'get',
      responseDataKey: 'employee',
      responseValidatorObj: EmployeeSchema.or(z.null()),
      responseFormatValidatorMessage: 'Data mismatch for get employee data',
      passToken: true,
    });
  },

  createEmployeeData: async (params: CreateEmployeeRequest): Promise<Employee> => {
    return await axiosCaller({
      path: '/api/employees/me',
      method: 'post',
      responseDataKey: 'employee',
      responseValidatorObj: EmployeeSchema,
      responseFormatValidatorMessage: 'Data mismatch for create employee data',
      passToken: true,
      data: params,
    });
  },

  updateEmployeeData: async (params: UpdateEmployeeMeRequest): Promise<Employee> => {
    return await axiosCaller({
      path: '/api/employees/me',
      method: 'patch',
      responseDataKey: 'employee',
      responseValidatorObj: EmployeeSchema,
      responseFormatValidatorMessage: 'Data mismatch for update employee data',
      passToken: true,
      data: params,
    });
  },

  getContractorData: async (): Promise<Contractor | null> => {
    return await axiosCaller({
      path: '/api/contractors/me',
      method: 'get',
      responseDataKey: 'contractor',
      responseValidatorObj: ContractorSchema.nullable(),
      responseFormatValidatorMessage: 'Data mismatch for get contractor data',
      passToken: true,
    });
  },
  getContractorApplicants: async (
    params: ContractorApplicationsRequest
  ): Promise<MB_InfinitePaginationPage<Application>> => {
    return await axiosCaller({
      path: '/api/contractors/me/applicants',
      method: 'get',
      responseValidatorObj: zodUtils.getInfinitePagination(ApplicationSchema),
      responseFormatValidatorMessage: 'Data mismatch for get contractor applications',
      passToken: true,
      params,
    });
  },
  getEmployeeApplications: async (
    params: EmployeeApplicationsRequest
  ): Promise<MB_InfinitePaginationPage<Application>> => {
    return await axiosCaller({
      path: '/api/employees/me/applications',
      method: 'get',
      responseValidatorObj: zodUtils.getInfinitePagination(ApplicationSchema),
      responseFormatValidatorMessage: 'Data mismatch for get employee applications',
      passToken: true,
      params,
    });
  },
  getRecommendedEmployees: async (
    params: RecommendedEmployeesRequest
  ): Promise<MB_InfinitePaginationPage<RecommendedEmployee>> => {
    return await axiosCaller({
      path: '/api/employees/recommended',
      method: 'get',
      responseFormatValidatorMessage: 'Data mismatch for get employee applications',
      passToken: true,
      params,
    });
  },
  getEmployeeJobs: async (params: EmployeeJobsRequest): Promise<MB_InfinitePaginationPage<Job>> => {
    return await axiosCaller({
      path: '/api/employees/me/jobs',
      method: 'get',
      responseValidatorObj: zodUtils.getInfinitePagination(JobSchema),
      responseFormatValidatorMessage: 'Data mismatch for get employee applications',
      passToken: true,
      params,
    });
  },
  createContractorData: async (params: CreateContractorRequest): Promise<Contractor> => {
    return await axiosCaller({
      path: '/api/contractors/me',
      method: 'post',
      responseDataKey: 'contractor',
      responseValidatorObj: ContractorSchema,
      responseFormatValidatorMessage: 'Data mismatch for create contractor data',
      passToken: true,
      data: params,
    });
  },

  updateContractorData: async (params: UpdateContractorMeRequest): Promise<Contractor> => {
    return await axiosCaller({
      path: '/api/contractors/me',
      method: 'patch',
      responseDataKey: 'contractor',
      responseValidatorObj: ContractorSchema,
      responseFormatValidatorMessage: 'Data mismatch for update contractor data',
      passToken: true,
      data: params,
    });
  },

  createEmployeeReview: async (params: CreateEmployeeReviewRequest): Promise<Review> => {
    return await axiosCaller({
      path: `/api/employees/${params.id}/reviews`,
      method: 'post',
      responseDataKey: 'review',
      responseValidatorObj: ReviewSchema,
      responseFormatValidatorMessage: 'Data mismatch for update contractor data',
      passToken: true,
      data: params,
    });
  },

  getEmployeeReviews: async (
    params: GetEmployeeReviewsRequest
  ): Promise<MB_InfinitePaginationPage<Review>> => {
    return await axiosCaller({
      path: `/api/employees/${params.id}/reviews`,
      method: 'get',
      responseValidatorObj: zodUtils.getInfinitePagination(ReviewSchema),
      responseFormatValidatorMessage: 'Data mismatch for reviews',
      passToken: true,
      params,
    });
  },

  completeJob: async (id: string): Promise<void> => {
    return await axiosCaller({
      path: `/api/debug/jobs/${id}/complete`,
      method: 'post',
      passToken: true,
      data: { id },
    });
  },
};

export { userApiCalls };
