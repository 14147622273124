import { mbApplyTransparency, mbTextStyles } from "@workingu/rnw.utils.style-utils";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import LinearGradient from "react-native-linear-gradient";
import Feather from "react-native-vector-icons/Feather";
import { COLORS } from "../../constants/colors";
import { textStyles } from "../../constants/textStyles";
import { TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/core";
import { UniversalScreenNavigationProp } from "../../typesAndInterfaces/componentProps";
import { JobsRequest } from "@wu/business";
import { SearchInputFilter } from "./SearchInputFilter";
import { useGetNotificationsCount } from "../../hooks/notificationsHooks";
import { initialWindowMetrics } from 'react-native-safe-area-context';

const CustomHeader = ({ title, filter, onFilterChange }: { title?: string, filter?: JobsRequest['filter'], onFilterChange?: (filter: JobsRequest['filter']) => void }) => {

    const navigation = useNavigation<UniversalScreenNavigationProp>();
    const { data: notificationsCount } = useGetNotificationsCount({});

    return (
        <LinearGradient
            style={styles.header}
            colors={[mbApplyTransparency('#FF5F15', 0.87), '#FFA47A']}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
            locations={[0.7, 1]}
        >
            <View style={styles.headerContent}>
                {title !== undefined && <Text style={styles.title}>{title}</Text>}
                {(filter || onFilterChange || !title) && <SearchInputFilter style={styles.searchInputFilter} filter={filter} onFilterChange={onFilterChange} />}
                <View>
                    <TouchableOpacity style={styles.headerBell} onPress={() => navigation.navigate('Notifications')}>
                        <Feather name="bell" size={20} color={COLORS.primaryColor} />
                    </TouchableOpacity>
                    {notificationsCount !== undefined && notificationsCount > 0 &&
                        <View style={styles.badgeContainer}>
                            <Text style={styles.badge}>{notificationsCount <= 9 ? notificationsCount : `9+`}</Text>
                        </View>
                    }
                </View>
            </View>
        </LinearGradient>
    )
}

export { CustomHeader };

const styles = StyleSheet.create({
    // header
    header: {
        height: 114 + (initialWindowMetrics?.insets.top ?? 0),
        paddingLeft: 17,
        paddingRight: 23,
        paddingBottom: 27,
        marginTop: -(initialWindowMetrics?.insets.top ?? 0),
        overflow: 'visible',
    },
    headerContent: {
        flexDirection: 'row',
        marginTop: 'auto',
    },
    headerBell: {
        width: 45,
        height: 45,
        borderRadius: 360,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: COLORS.lightOrange,
        marginLeft: 10,
    },
    title: mbTextStyles([
        textStyles.normalText, {
            flex: 1,
            fontWeight: '800',
            fontSize: 18,
            textAlign: 'left',
            marginTop: 'auto',
        }
    ]),
    searchInputFilter: {
        flex: 1,
    },
    badgeContainer: {
        height: 17,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 5,
        backgroundColor: COLORS.red,
        borderRadius: 360,
        position: 'absolute',
        right: -3,
        top: -3,
    },
    badge: StyleSheet.flatten([textStyles.tinyText, {
        lineHeight: 17,
    }])
});

