import {
  NotificationsRequest,
  NotificationSchema,
  Notification,
  ReadNotificationRequest,
} from '@wu/business';

import { MB_InfinitePaginationPage } from '@workingu/rnw.utils.util-hooks';

import { zodUtils } from '../utils/zod/zodUtils';
import { axiosCaller } from './customAxios';

import z from 'zod';

const notificationsApiCalls = {
  getNotifications: async (
    params: NotificationsRequest
  ): Promise<MB_InfinitePaginationPage<Notification>> => {
    return await axiosCaller({
      path: '/api/notifications',
      method: 'get',
      responseValidatorObj: zodUtils.getInfinitePagination(NotificationSchema),
      responseFormatValidatorMessage: 'Data mismatch for get notifications',
      passToken: true,
      params,
    });
  },

  markNotificationAsRead: async (params: ReadNotificationRequest): Promise<Notification> => {
    return await axiosCaller({
      path: `/api/notifications/${params.id}/read`,
      responseValidatorObj: NotificationSchema,
      responseDataKey: 'notification',
      method: 'post',
      passToken: true,
    });
  },

  markAllNotificationAsRead: async (): Promise<void> => {
    return await axiosCaller({
      path: `/api/notifications/read`,
      method: 'post',
      passToken: true,
    });
  },

  getNotificationsCount: async (): Promise<number> => {
    return await axiosCaller({
      path: '/api/notifications/count',
      method: 'get',
      responseDataKey: 'count',
      responseValidatorObj: z.number(),
      responseFormatValidatorMessage: 'Data mismatch for get notifications count',
      passToken: true,
    });
  }
};

export { notificationsApiCalls };
