import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { COLORS } from "../../constants/colors";
import { utils } from "../../utils/utils";
import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import { textStyles } from "../../constants/textStyles";
import { Rating } from "./Rating";
import { Review, } from "@wu/business";
import { imageUtils } from "../../utils/imageUtils";
import { MB_Image, mbGetSmallestImage } from "@workingu/rnw.components.image";


interface QuestionProps {
    index: number,
    text: string,
    answer: boolean
}

const Question = (props: QuestionProps) => {
    const { index, answer, text } = props;
    return <Text style={styles.question}>{index}. {text} <Text style={styles.answer}>{answer ? 'Yes' : 'No'}</Text></Text>
}

const ReviewCard = ({ review }: { review: Review }) => {
    const {  questions, contractor } = review;
    const profileImageUrl = mbGetSmallestImage(contractor.profileImage) ?? imageUtils.images.emptyProfileIcon;

    return (
        <View>
            <View style={styles.header}>
                <MB_Image source={profileImageUrl} style={styles.picture} resizeMode='contain' disableInteraction />
                <View style={styles.content}>
                    <Text style={styles.name} numberOfLines={2}>{contractor.name}</Text>
                    <Rating style={styles.rating} rating={review.rating} />
                </View>
                <Text style={styles.date}>{utils.MB_relativeTimeFromDates({ relative: new Date(review.createdAt) })}</Text>
            </View>
            {questions.map((question, index) => <Question key={index} index={index + 1} text={question.title} answer={question.answer} />)}
        </View>
    )
}

export { ReviewCard };

const styles = StyleSheet.create({
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 7,
    },
    content: {
        flex: 1,
        marginEnd: 6,
    },
    name: mbTextStyles([textStyles.smallText, {
        fontSize: 13,
        color: COLORS.bodyText,
        textAlign: 'left',
    }]),
    date: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.greyText,
        marginStart: 'auto',
    }]),
    picture: {
        height: 40,
        width: 40,
        borderRadius: 360,
        marginEnd: 11,
    },
    rating: {
        marginTop: 2,
    },
    question: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.bodyText2,
        textAlign: 'left',
        fontWeight: '400',
        marginTop: 5,
        flex: 1,
    }]),
    answer: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.bodyText2,
        fontWeight: '600',
    }]),
})
