import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { StyleSheet, Text, View, ViewStyle } from "react-native";
import Feather from "react-native-vector-icons/Feather";
import { COLORS } from "../../../constants/colors";
import { textStyles } from "../../../constants/textStyles";
import { ComponentWrapper } from "../../helperComponents/componentWrapper/ComponentWrapper"
import { MB_Table } from '@workingu/rnw.components.table';
import { Status } from "./Components/Status";
import { useGetEmployeesData } from "../../../hooks/adminHooks";
import { MB_utilHooks } from "@workingu/rnw.utils.util-hooks";
import { Employee, UserRole } from "@wu/business";
import { DeleteUserModal } from "../../modals/DeleteUserModal";
import { Rating } from "../../helperComponents/Rating";
import { DEFAULT_TABLE_MAX_ROWS } from "../../../constants/constants";
import { Indicators } from "./Indicators";

const header = [
    { content: '#', conatinerStyle: { height: 50, minWidth: 31 + 27, maxWidth: 31 + 27, paddingLeft: 24 }, textStyle: { color: COLORS.primaryColor, fontSize: 12 } },
    { content: 'NAME', conatinerStyle: { height: 50, minWidth: 55 + 61, flex: 1 }, textStyle: { color: COLORS.primaryColor, fontSize: 12 } },
    { content: 'EMAIL ADDRESS', conatinerStyle: { height: 50, minWidth: 112 + 54, flex: 1 }, textStyle: { color: COLORS.primaryColor, fontSize: 12 } },
    { content: 'PHONE NUMBER', conatinerStyle: { height: 50, minWidth: 114 + 21, maxWidth: 114 + 21 }, textStyle: { color: COLORS.primaryColor, fontSize: 12 } },
    { content: 'TRADES', conatinerStyle: { height: 50, minWidth: 66 + 82, flex: 1 }, textStyle: { color: COLORS.primaryColor, fontSize: 12 } },
    { content: 'ADDRESS', conatinerStyle: { height: 50, minWidth: 75 + 74, flex: 1 }, textStyle: { color: COLORS.primaryColor, fontSize: 12 } },
    { content: 'REVIEWS', conatinerStyle: { height: 50, minWidth: 72 + 65, maxWidth: 72 + 65 }, textStyle: { color: COLORS.primaryColor, fontSize: 12 } },
    { content: 'STATUS', conatinerStyle: { height: 50, minWidth: 66 + 40, maxWidth: 66 + 40 }, textStyle: { color: COLORS.primaryColor, fontSize: 12 } },
    { content: '', conatinerStyle: { height: 50, minWidth: 40, maxWidth: 40 }, textStyle: { color: COLORS.primaryColor, fontSize: 12 } },
];


const Employees = () => {

    const [isDeleteUserVisible, showDeleteUser, hideDeleteUser] = MB_utilHooks.useBool();
    const userToDelete = useRef<Employee>();
    const [page, setPage] = useState(1);

    const { data: response, isLoading } = useGetEmployeesData({
        params: {
            page,
            limit: DEFAULT_TABLE_MAX_ROWS,
        },
    });

    const employees = useMemo(() => response?.employees, [response]);

    const totalPages = useMemo(() => Math.ceil((response?.count || 0) / DEFAULT_TABLE_MAX_ROWS), [response]);

    

    const onDelete = useCallback((employee: Employee) => {
        userToDelete.current = employee;
        showDeleteUser();
    }, [showDeleteUser])

    const data = useMemo(() => {
        return employees?.map((employee, index) => [
            <Text style={[styles.cellText, styles.rightSpace]}>{index + 1}</Text>,
            <Text style={[styles.cellText, styles.rightSpace, styles.important]} numberOfLines={3}>{employee.firstName} {employee.lastName}</Text>,
            <Text style={[styles.cellText, styles.rightSpace]} numberOfLines={3}>{employee.email}</Text>,
            <Text style={[styles.cellText, styles.rightSpace]} numberOfLines={1}>{employee.mobileNumber}</Text>,
            <Text style={[styles.cellText, styles.rightSpace]} numberOfLines={3}>{employee.trades.join(' ')}</Text>,
            <Text style={[styles.cellText, styles.rightSpace]} numberOfLines={3}>{employee.address.text}</Text>,
            <View style={[styles.rating]}>
                <Text style={[styles.cellText, styles.ratingText]}>{employee.rating?.toFixed(1) ?? '0.0'}</Text>
                <Rating rating={employee.rating ?? 0} />
            </View>,
            <Status type="Active" />,
            <Feather name="trash" size={19} color={COLORS.red} onPress={() => onDelete(employee)} />,
        ])
    }, [employees, onDelete]);

    const keyExtractor = useCallback((index) => employees?.[index].id ?? index.toString(), [employees]); // TODO: This is weird, we should have the proper ID here.

    return (
        <ComponentWrapper
            innerContainerStyle={styles.innerContainer}
            containerStyle={styles.container}
            scrollViewStyle={styles.scrollView}
            wrapInScrollView
            disableLinearGradient
        >
            {userToDelete.current &&
                <DeleteUserModal
                    isVisible={isDeleteUserVisible}
                    onDismiss={hideDeleteUser}
                    user={userToDelete.current}
                    type={UserRole.Employee}
                />
            }
            <Text style={styles.title}>Employees</Text>
            <View style={styles.content}>
                <MB_Table
                    header={header}
                    data={data}
                    headerStyle={styles.tableHeader}
                    rowStyle={styles.rowStyle}
                    rowTextStyle={styles.rowTextStyle}
                    style={styles.table}
                    keyExtractor={keyExtractor}
                    loading={isLoading}
                />
                <View style={styles.tableFooter}>
                    <Indicators
                        page={page}
                        totalPages={totalPages}
                        onPageChange={setPage}
                    />
                </View>
            </View>
        </ComponentWrapper>
    )
}

export { Employees };

const styles = StyleSheet.create({
    innerContainer: {
        backgroundColor: COLORS.greyBG,
        flex: 1,
        maxWidth: 'auto',
        alignSelf: 'stretch',
    },
    container: {
        minWidth: 1000,
    },
    title: mbTextStyles([textStyles.largeText, {
        color: COLORS.bodyText,
        textAlign: 'left',
    }]),
    content: {
        marginBottom: 84,
    },
    scrollView: {
        overflowX: 'visible',
    } as ViewStyle,
    table: {
        marginTop: 20,
        borderBottomEndRadius: 0,
        borderBottomStartRadius: 0,
    },
    tableFooter: {
        height: 63,
        backgroundColor: COLORS.white,
        justifyContent: 'center',
        borderBottomEndRadius: 10,
        borderBottomStartRadius: 10,
        borderWidth: 0.5,
        borderBottomColor: COLORS.lightGrey,
        borderLeftColor: COLORS.lightGrey,
        borderRightColor: COLORS.lightGrey,
        borderTopWidth: 0,
    },
    rating: {
        flexDirection: 'row',
    },
    ratingText: {
        marginRight: 5,
    },
    cellText: mbTextStyles([textStyles.smallerText, {
        color: COLORS.bodyText2,
        textAlign: 'left',
    }]),
    rightSpace: {
        paddingRight: 10,
    },
    important: {
        color: COLORS.bodyText,
        fontWeight: '600',
        fontSize: 13,
    },
    tableHeader: {
        height: 50,
        backgroundColor: '#FFF4F0',
        borderBottomColor: 'transparent',
    },
    rowStyle: {
        height: 68,
        backgroundColor: COLORS.white,
        justifyContent: 'center',
    },
    rowTextStyle: mbTextStyles([textStyles.smallText, {
        fontSize: 12,
        color: COLORS.bodyText,
        textAlign: 'left',
    }]),
});
