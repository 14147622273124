import * as React from "react"
import Svg, { Path } from "react-native-svg"

const PDFWhiteIcon = ({ size = 16, color = "#FF5F15" }: { size?: number, color?: string }) => {
    return (
        <Svg
            width={size * (12 / 16)}
            height={size}
            viewBox="0 0 12 16"
            fill="none"
        >
            <Path
                d="M12 14V4.5L7.5 0H2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2zM7.5 3A1.5 1.5 0 009 4.5h2V14a1 1 0 01-1 1H2a1 1 0 01-1-1V2a1 1 0 011-1h5.5v2z"
                fill={color}
            />
            <Path
                d="M2.603 14.087a.81.81 0 01-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787.47-.267.967-.483 1.482-.645.4-.72.755-1.463 1.062-2.227a7.27 7.27 0 01-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 01.477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794.276.59.604 1.154.98 1.686a5.755 5.755 0 011.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 01-.354.416.856.856 0 01-.51.138c-.331-.014-.654-.196-.933-.417-.34-.28-.645-.6-.911-.95-.676.077-1.344.213-1.997.406-.299.53-.64 1.035-1.02 1.51-.292.35-.609.656-.927.787a.793.793 0 01-.58.029zm1.379-1.901c-.166.076-.32.156-.459.238-.328.194-.541.383-.647.547-.094.145-.096.25-.04.36.01.023.02.037.026.045a.27.27 0 00.035-.012c.137-.056.355-.235.635-.572.16-.195.31-.397.45-.606zm1.64-1.33c.334-.078.67-.142 1.01-.193a11.74 11.74 0 01-.51-.858c-.157.354-.324.705-.5 1.05zm2.446.45c.15.163.296.3.435.41.24.19.407.253.498.256a.107.107 0 00.07-.015.306.306 0 00.094-.125.436.436 0 00.059-.2.095.095 0 00-.026-.063c-.052-.062-.2-.152-.518-.21a3.886 3.886 0 00-.612-.052v-.001zM6.078 7.8a6.7 6.7 0 00.2-.828c.031-.188.043-.343.038-.465a.613.613 0 00-.032-.198.517.517 0 00-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822.024.11.054.227.09.346h-.001z"
                fill={color}
            />
        </Svg>
    )
}

export { PDFWhiteIcon };
