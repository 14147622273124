import React, { useCallback } from 'react';
import { ModalDropdown } from '@workingu/rnw.components.modal-dropdown';
import { useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, StyleProp, ViewStyle } from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import { COLORS } from '../../constants/colors';
import { textStyles } from '../../constants/textStyles';
import { mbTextStyles } from '@workingu/rnw.utils.style-utils';

interface DropdownProps {
    option?: string,
    options?: string[],
    onSelect?: (option: string, index: number) => void,
    style?: StyleProp<ViewStyle>,
    dropdownStyle?: StyleProp<ViewStyle>,
}

// TODO: Hovo: What is this dropdown for? Need a better name
const Dropdown = ({ option, options, onSelect, style, dropdownStyle }: DropdownProps) => {

    const [currentOption, setOption] = useState<string | undefined>(option);

    const handleSelect = useCallback((index: number) => {
        setOption(options?.[index]);
        if (options?.[index]) {
            onSelect?.(options?.[index], index);
        }
    }, [onSelect, options]);

    const renderRowComponent = useCallback((props: any) => (
        <TouchableOpacity onPress={props.onPress}>
            {props.children}
        </TouchableOpacity>
    ), []);

    const renderRow = useCallback((rowOption: string, index: number, isSelected: boolean) => {
        return (
            <View style={[styles.option]}>
                <Text style={[styles.optionText]}>{rowOption}</Text>
                {isSelected && <Feather name='check' size={14} color={COLORS.bodyText} />}
            </View>
        )
    }, []);

    const renderSeparator = useCallback(() => null, []);

    return (
        <ModalDropdown
            options={options}
            isFullWidth
            onSelect={handleSelect}
            renderRowComponent={renderRowComponent}
            renderRow={renderRow}
            renderSeparator={renderSeparator}
            style={[styles.modalStyle, style]}
            dropdownStyle={dropdownStyle}
            defaultIndex={option ? options?.indexOf(option) : undefined}
        >
            <View style={styles.dropdown}>
                <Text style={[textStyles.smallerText, styles.dropdownText]}>{currentOption ?? '-- Select --'}</Text>
                <Feather name="chevron-down" color={COLORS.greyText} size={20} />
            </View>
        </ModalDropdown>
    );
};

export { Dropdown };

const styles = StyleSheet.create({
    modalStyle: {
        alingItems: 'stretch',
        alignSelf: 'stretch',
    },
    dropdown: {
        flexDirection: 'row',
        height: 48,
        borderWidth: 0.5,
        borderColor: '#C4C4C4',
        borderRadius: 5,
        alignItems: 'center',
        paddingHorizontal: 15,
        justifyContent: 'space-between',
        backgroundColor: COLORS.white,
    },
    dropdownText: {
        fontSize: 11,
        color: COLORS.greyText,
        textAlign: 'left',
    },
    option: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginVertical: 12,
        marginLeft: 20,
        marginRight: 17,
    },
    optionText: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.bodyText,
        textAlign: 'left',
    }])
});
