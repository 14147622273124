import React, { useCallback, useRef } from "react";
import { View, Text, StyleProp, StyleSheet, ViewStyle, TouchableOpacity } from "react-native";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { COLORS } from "../../constants/colors";
import { textStyles } from "../../constants/textStyles";
import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import { MB_RESOURCE_PICKER_TYPE, MB_ResourceUploader } from "@workingu/rnw.components.resource-uploader";
import { MB_utilHooks } from "@workingu/rnw.utils.util-hooks";
import { File } from "../../utils/uploader";
import { useUploader } from "../../hooks/uploads";
import prettyBytes from 'pretty-bytes';

interface DocumentPickerProps {
    containerStyle?: StyleProp<ViewStyle>,
    title?: string,
    pageTitle: string,
    selectedFile?: { id: string, name: string, size: number },
    onFileSelect: (props: { id: string, fileName: string, fileSize: number }) => void,
    allowedDocumentPickerTypes?: string[],
    fileDescriptorText?: string
    required?: boolean
}

const DocumentPicker = ({ containerStyle, pageTitle, title, onFileSelect, selectedFile, allowedDocumentPickerTypes, fileDescriptorText, required = true }: DocumentPickerProps) => {
    const [isResourcePickerVisible, showResourcePicker, hideResourcePicker] = MB_utilHooks.useBool();

    const onComplete = useCallback((file: File) => {
        onFileSelect({ id: file.id, fileName: file.name, fileSize: file.contentLength });
    }, [onFileSelect]);

    const uploadProfileImage = useUploader(onComplete);

    const allowedDocPickerTypesDefault = useRef(['image/jpeg', 'image/png', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'])

    return (
        <View style={containerStyle}>

            <MB_ResourceUploader
                isVisible={isResourcePickerVisible}
                onDismiss={hideResourcePicker}
                pageTitle={pageTitle}
                uploadButtonTitle={'Upload'}
                resourcePickerType={MB_RESOURCE_PICKER_TYPE.anyFile}
                uploaderFunction={uploadProfileImage}
                allowedDocumentPickerTypes={allowedDocumentPickerTypes ?? allowedDocPickerTypesDefault.current}
                cameraorGalleryIconColor={COLORS.primaryColor}
                modalCloseButtonXProps={{ color: COLORS.greyText, size: 15 }}
                cameraOrGalleryModalCloseButtonXProps={{ color: COLORS.greyText, size: 15 }}
                previewColor={COLORS.primaryColor}
            />

            <Text style={styles.inputTitle}>
                {title ?? 'State License Document'}
                {required && (<Text style={{ color: COLORS.red }}> *</Text>)}
            </Text>
            <TouchableOpacity style={styles.upload} onPress={showResourcePicker}>
                <MaterialCommunityIcons name="folder-upload" size={25} color={COLORS.primaryColor} />
                {selectedFile === undefined ?
                    <>
                        <Text style={[textStyles.smallerText, styles.uploadText]}>{fileDescriptorText ?? 'Upload State License Document'}</Text>
                        <Text style={[textStyles.tinyText, styles.uploadSubText]}>(.jpg, .png, .pdf, .doc or .docx file format supported)</Text>
                    </>
                    :
                    <>
                        <Text style={[textStyles.smallerText, styles.uploadText]}>{selectedFile.name}</Text>
                        <Text style={[textStyles.tinyText, styles.uploadSubText]}>({prettyBytes(selectedFile.size)})</Text>
                    </>

                }
            </TouchableOpacity>
        </View>
    )
}

export { DocumentPicker };

const styles = StyleSheet.create({
    inputTitle: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.bodyText,
        marginBottom: 7,
        textAlign: 'left',
    }]),
    upload: {
        paddingVertical: 20,
        alignItems: 'center',
        borderColor: COLORS.primaryColor,
        borderWidth: 0.8,
        borderRadius: 5,
        borderStyle: 'dashed',
        backgroundColor: COLORS.white,
    },
    uploadText: {
        fontSize: 11,
        color: COLORS.primaryColor,
        marginTop: 7.75,
    },
    uploadSubText: {
        color: COLORS.greyText,
        marginTop: 3,
    },
})
