import { mbHidePopUp, mbShowPopUp } from '@workingu/rnw.components.pop-up';
import { MB_customWindowForWeb } from '@workingu/rnw.utils.custom-window-for-web';
import { isMobileApp } from '@workingu/rnw.utils.device-info';
import * as WebBrowser from 'expo-web-browser';
import React, { ComponentProps, FC } from 'react';
import { envs } from '../../env';
import { serverRedirectUtils } from '../components/screens/ServerRedirect/serverRedirectUtils';
import { SERVER_ERROR_CODES } from '../constants/constants';
import { ServerRedirectNavigationProp } from '../typesAndInterfaces/componentProps';
import { ServerError } from '../typesAndInterfaces/typesAndInterfaces';
import { MB_PASSWORD_UTIL_ERROR_TYPES } from '@workingu/rnw.utils.password-utils';
import * as mime from '@workingu/rnw.utils.mime-types';
import {
    differenceInSeconds,
    formatDistance,
    formatDistanceToNow,
} from 'date-fns';

export const utils = {
    getUrlParams: (url: string) => {
        try {
            let urlObj = new URL(url);
            return Object.fromEntries(urlObj.searchParams.entries());
        } catch (error) {
            console.error('Error: Failed to extract url params', error);
            return {};
        }
    },
    openExternalWindow: (url: string, name?: string, width?: number, height?: number) => {
        if (isMobileApp) {
            return;
        }

        const w = MB_customWindowForWeb.outerWidth - (width ?? 600);
        const h = MB_customWindowForWeb.outerHeight - (height ?? 750);
        const left = Math.round(MB_customWindowForWeb.screenX + w / 2);
        const top = Math.round(MB_customWindowForWeb.screenY + h / 2.5);

        MB_customWindowForWeb.open(
            url,
            name ?? 'LogIn',
            'width=' + (width ?? 600) + ',height=' + (height ?? 750) + ',left=' + left + ',top=' + top + ',toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0',
        );
    },
    getDeepLink: (path = '') => {
        return envs.MOBILE_DEEP_LINK_FOR_SERVER_REDIRECT + path;
    },
    openExternalAuthWindowForMobile: async (urlToOpen: string, navigation: ServerRedirectNavigationProp) => {
        try {
            const redirectUrl = utils.getDeepLink();
            let response = await WebBrowser.openAuthSessionAsync(urlToOpen, redirectUrl);
            if (response.type === 'success') {
                const params = utils.getUrlParams(response.url);
                serverRedirectUtils.handleServerRedirect({ errorParamBase64: params.error, successParamsBase64: params.success, navigation });
            } else {
                if (response.type === 'dismiss') {
                    return;
                }
                throw new Error(`Failed to find url in auth session.(${urlToOpen})`);
            }
        } catch (err) {
            console.log(`Error in handling mobile auth session (${urlToOpen}): - `, err);
        }
    },
    /**
     *
     * @param version string version 1
     * @param version2 string version 2
     * @returns -1 if version 1 < version 2, 0 if version 1 === version 2, 1 if version 1 > version 2
     */
    compareVersion: (version: string, version2: string) => {
        return version.localeCompare(version2, undefined, { numeric: true, sensitivity: 'base' });
    },
    createErrorObject: (message: string, code: SERVER_ERROR_CODES | string): ServerError => {
        return {
            message,
            code,
            status: 'error',
        };
    },

    combineComponents: (components: FC[]): FC => {
        function reducerFunction(AccumulatedComponents: React.FC<{}>, CurrentComponent: React.FC<{}>) {
            return ({ children }: ComponentProps<FC>): JSX.Element => {
                return (
                    <AccumulatedComponents>
                        <CurrentComponent>{children}</CurrentComponent>
                    </AccumulatedComponents>
                );
            };
        }

        return components.reduce(reducerFunction, ({ children }) => <>{children}</>);
    },

    showLogOutPopUp: (onSignOut?: () => void, onPopUpClosed?: () => void) => {
        mbShowPopUp({
            title: 'Are you sure you want to log out ?',
            buttonText: 'Yes',
            buttonAction: () => {
                // TODO: This is kind of ugly. We needed to do this because of some issues with iOS pop-ups and also on Android it was trying to perform an action on a component that was unmounted.
                mbHidePopUp();
                setTimeout(() => onPopUpClosed?.(), 0);
                setTimeout(() => onSignOut?.(), 400);
            },
            secondaryButtonText: 'No',
        });
    },
    generatePasswordErrorArray: (errorArray: MB_PASSWORD_UTIL_ERROR_TYPES[], doPasswordsMatch: boolean): { message: string, isError: boolean }[] => {
        const errors: { message: string, isError: boolean }[] = [];

        errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.min, isError: errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.min) || errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.max) });
        errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.lowercase, isError: errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.lowercase) });
        errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.uppercase, isError: errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.uppercase) });
        errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.digits, isError: errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.digits) });
        errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.spaces, isError: errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.spaces) });
        if (errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.unknown)) {
            errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.unknown, isError: true });
        }

        errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.passwordMismatch, isError: !doPasswordsMatch });

        return errors;
    },
    generateFormDataForFileUpload: ({ blob, uri, extraFields }: { blob: Blob | undefined, uri: string | undefined, extraFields: { key: string, value: string }[] }): FormData => {
        const formData = new FormData();

        extraFields.forEach(field => {
            formData.append(field.key, field.value);
        });

        if (isMobileApp) {

            if (!uri) {
                throw 'createFormData expects uri to not be empty or undefined when running on mobile apps';
            }

            const type = blob?.type ?? mime.lookup(uri);

            const formDataValue = {
                name: uri.split('/').pop(),
                type: !type ? 'application/octet-stream' : type,
                uri: uri,
            };

            formData.append('file', formDataValue);
            formData.append('Content-Type', type);
        } else {
            if (!blob) {
                throw 'createFormData expects blob to not be undefined when running on web';
            }

            formData.append('file', blob);
            formData.append('Content-Type', blob.type);
        }

        return formData;
    },
    /**
    * Get relative time message from Dates.
    * @param relative  - the relative dateTime, generally is in the past or future
    * @param pivot     - the dateTime of reference, generally is the current time
    */
    MB_relativeTimeFromDates({ relative, pivot, simplifySeconds }: { relative: Date | number | null, pivot?: Date | number, simplifySeconds?: boolean }): string {
        if (!relative) {
            return '';
        }

        const diffSeconds = differenceInSeconds(relative, pivot ?? new Date());

        if (diffSeconds === 0) {
            return 'now';
        }

        if (simplifySeconds && Math.abs(diffSeconds) < 60) {
            return diffSeconds > 0 ? 'in few seconds' : 'a few seconds ago';
        }

        if (pivot) {
            return formatDistance(relative, pivot, { includeSeconds: true, addSuffix: true });
        } else {
            return formatDistanceToNow(relative, { includeSeconds: true, addSuffix: true });
        }
    }
};
