import React from 'react';
import { utils } from '../utils/utils';
import { MB_PortalProvider } from './MB_Portal';
import { QueryContextProvider } from './QueryContextProvider';
import { SignedInStatusContextProvider } from './SignedInContext';

const providers: React.FC[] = [
    QueryContextProvider,
    MB_PortalProvider,
    SignedInStatusContextProvider,
];

const AppContextProvider = utils.combineComponents(providers);

export { AppContextProvider };
