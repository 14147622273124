import { Slider } from '@miblanchard/react-native-slider';
import { MB_Button } from '@workingu/rnw.components.button';
import { mbTextStyles } from '@workingu/rnw.utils.style-utils';
import React, { useCallback, useRef, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { COLORS } from '../../constants/colors';
import { textStyles } from '../../constants/textStyles';
import { DateInput } from '../helperComponents/DateInput';
import { Trades } from '../helperComponents/Trades';
import { JobsRequest } from '@wu/business';

const ModalFilterContent = ({
  filter,
  onFilterChange,
}: {
  filter?: JobsRequest['filter'];
  onFilterChange?: (filter: JobsRequest['filter']) => void;
}) => {
  const [miles, setMiles] = useState<number | undefined>(filter?.miles?.[1]);
  const [hourlyRate, setHourlyRate] = useState<number[] | undefined>(filter?.hourlyRate);
  const [startDate, setStartDate] = useState<Date | undefined>(
    filter?.startDate ? new Date(filter.startDate) : undefined,
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    filter?.endDate ? new Date(filter.endDate) : undefined,
  );
  const [startTime, setStartTime] = useState<Date | undefined>(
    filter?.startTime ? new Date(filter.startTime) : undefined,
  );
  const [endTime, setEndTime] = useState<Date | undefined>(
    filter?.endTime ? new Date(filter.endTime) : undefined,
  );
  const [trades, setTrades] = useState<string[]>(filter?.trades ?? []);
  const key = useRef('Filter-Modal');

  const onApply = useCallback(() => {
    onFilterChange?.({
      startDate: startDate?.toISOString(),
      endDate: endDate?.toISOString(), // removed time from the filter
      trades,
      miles: miles ? [0, miles] : undefined,
      hourlyRate,
    });
  }, [endDate, endTime, hourlyRate, miles, onFilterChange, startDate, startTime, trades]);

  const onReset = useCallback(() => {
    key.current = `Filter-Modal-${Math.random()}`;
    setMiles(undefined);
    setHourlyRate(undefined);
    setStartDate(undefined);
    setEndDate(undefined);
    setStartTime(undefined);
    setEndTime(undefined);
    setTrades([]);
  }, []);

  return (
    <View style={styles.content} key={key.current}>
      <View style={styles.row}>
        <Text style={styles.filterBy}>Filter by</Text>
        <Text style={styles.reset} onPress={onReset}>
          Reset
        </Text>
      </View>
      <View style={[styles.row, styles.date]}>
        <View style={styles.flex}>
          <Text style={styles.fieldTitle}>Start Date</Text>
          <DateInput onDateChange={setStartDate} date={startDate} />
        </View>
        <View style={styles.spacer} />
        <View style={styles.flex}>
          <Text style={styles.fieldTitle}>End Date</Text>
          <DateInput onDateChange={setEndDate} date={endDate} />
        </View>
      </View>
      <Text style={[styles.fieldTitle, styles.trades]}>Trades</Text>
      <Trades trades={trades} onChange={setTrades} />
      <View style={styles.jobLocationContainer}>
        <Text style={styles.sectionTitle}>Job Location</Text>
        <Text style={[styles.sectionSubTitle, styles.jobLocationSlider]}>
          Distance from my address
        </Text>
        <Slider
          value={miles ?? 50}
          onValueChange={(value) => setMiles(value[0])} // Slider only provide (value: Array<number>) => void;
          minimumValue={0}
          maximumValue={50}
          trackMarks={[0]}
          trackStyle={styles.track}
          renderBelowThumbComponent={() => (
            <Text style={[styles.thumbText, styles.milesText]}>{miles ?? 50} miles</Text>
          )}
          renderThumbComponent={() => <View style={styles.thumb} />}
          renderTrackMarkComponent={() => (
            <View style={styles.trackMark}>
              <View style={styles.thumb} />
              <Text style={[styles.thumbText, styles.trackMarkText]}>
                {(miles && miles > 4) || !miles ? '0 miles' : ' '}
              </Text>
            </View>
          )}
          step={2}
          maximumTrackTintColor={COLORS.primaryColor}
          minimumTrackTintColor={COLORS.primaryColor}
        />
      </View>
      <View style={styles.hourlyRateContainer}>
        <Text style={[styles.sectionTitle, styles.hourlyRateSlider]}>Hourly Rate</Text>
        <Slider
          value={hourlyRate ?? [10, 120]}
          onValueChange={setHourlyRate}
          minimumValue={10}
          maximumValue={120}
          trackStyle={styles.track}
          renderBelowThumbComponent={(index: number) => (
            <Text style={styles.thumbText}>${hourlyRate?.[index] ?? (index === 0 ? 10 : 120)}</Text>
          )}
          renderThumbComponent={() => <View style={styles.thumb} />}
          step={10}
          maximumTrackTintColor={COLORS.primaryColor}
          minimumTrackTintColor={COLORS.primaryColor}
        />
      </View>
      <MB_Button
        title="Apply filter"
        style={styles.applyFilterBtn}
        textStyle={textStyles.smallerText}
        onPress={onApply}
      />
    </View>
  );
};

export { ModalFilterContent };

const styles = StyleSheet.create({
  content: {
    width: '100%',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flex: {
    flex: 1,
  },
  filterBy: mbTextStyles([
    textStyles.normalText,
    {
      fontSize: 18,
      color: COLORS.headerText,
      textAlign: 'left',
    },
  ]),
  reset: mbTextStyles([
    textStyles.smallerText,
    {
      color: COLORS.primaryColor,
      textAlign: 'left',
    },
  ]),
  date: {
    marginTop: 23,
  },
  time: {
    marginTop: 30,
  },
  trades: {
    marginTop: 30,
  },
  spacer: {
    width: 7,
  },
  jobLocationContainer: {
    marginTop: 36,
  },
  sectionTitle: mbTextStyles([
    textStyles.smallText,
    {
      fontSize: 13,
      color: COLORS.headerText,
      textAlign: 'left',
    },
  ]),
  jobLocationSlider: {
    marginBottom: 14,
  },
  sectionSubTitle: mbTextStyles([
    textStyles.smallerText,
    {
      fontSize: 11,
      color: COLORS.greyText,
      textAlign: 'left',
    },
  ]),
  hourlyRateContainer: {
    marginTop: 42,
  },
  hourlyRateSlider: {
    marginBottom: 20,
  },
  fieldTitle: mbTextStyles([
    textStyles.smallerText,
    {
      fontSize: 11,
      color: COLORS.bodyText,
      textAlign: 'left',
      marginBottom: 7,
    },
  ]),
  applyFilterBtn: {
    marginTop: 78,
    marginBottom: 52,
    height: 50,
    backgroundColor: COLORS.primaryColor,
    borderRadius: 30,
  },
  track: {
    backgroundColor: COLORS.lightOrange,
  },
  trackMark: {
    marginTop: 26,
  },
  trackMarkText: {
    marginTop: 10,
  },
  thumb: {
    width: 20,
    height: 20,
    backgroundColor: COLORS.white,
    borderWidth: 4,
    borderRadius: 360,
    borderColor: COLORS.primaryColor,
  },
  thumbText: StyleSheet.flatten([
    [
      textStyles.smallerText,
      {
        color: COLORS.bodyText,
        marginLeft: -10,
      },
    ],
  ]),
  milesText: {
    marginLeft: -22,
  },
});
