import React, { useCallback, useMemo } from "react";
import { ComponentWrapper } from "../../helperComponents/componentWrapper/ComponentWrapper";
import { FlatList, ListRenderItemInfo, StyleSheet, Text, View } from "react-native";
import { textStyles } from "../../../constants/textStyles";
import { COLORS } from "../../../constants/colors";
import Feather from "react-native-vector-icons/Feather";
import { TOTAL_ITEMS_PER_PAGE } from "../../../typesAndInterfaces/typesAndInterfaces";
import { useMBInfiniteFetch } from "@workingu/rnw.utils.util-hooks";
import { FlatListLoadingIndicator } from "../../helperComponents/FlatListLoadingIndicator";
import { JobCard } from "../../helperComponents/JobCard";
import { SachelForEmptyTable } from "../../../resources/svgComponents/SachelForEmptyTable";
import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import { Job } from "@wu/business";
import { useEmployeeApplications } from "../../../hooks/userHooks";

const HeaderApplied = () => {
    return (
        <View style={styles.headerConatiner}>
            <Feather name="folder-minus" size={22} color={COLORS.primaryColor} />
            <Text style={[textStyles.largeText, styles.headerTitle]}>Applied</Text>
        </View>
    );
}

const Applied = () => {
    const getEmployeeApplications = useEmployeeApplications({ totalItemsPerPage: TOTAL_ITEMS_PER_PAGE });
    const { data: applications, loadMore, isLoading, isFetching } = useMBInfiniteFetch(getEmployeeApplications, { prefillWhileLoading: true });

    const ItemSeparatorComponent = useCallback(() => <View style={styles.separator} />, []);

    const keyExtractor = React.useMemo(() => (item: Job | string) => typeof item === 'string' ? item : item.id, []);

    const RenderJobCard = useCallback(({ item }: ListRenderItemInfo<Job | string>) => {

        if (typeof item === 'string') {
            // TODO: Render temp card cloadings
            return null;
        }

        return (
            <JobCard
                job={item}
                style={styles.jobCardContainer}
            />
        );
    }, []);

    const data = useMemo(() => applications.map((item) => {
        if (typeof item === 'string') {
            return item;
        }
        return {
            ...item.job,
            status: item.status,
        }
    }), [applications]);

    return (
        <ComponentWrapper
            mobileHeaderOptions={{ showHeader: true, customHeader: <HeaderApplied /> }}
            wrapInScrollView={false}
        >
            {applications.length === 0 ? (
                <View style={styles.emptyTableContainer}>
                    <SachelForEmptyTable />
                    <Text style={styles.emptyTableTitle}>No jobs yet</Text>
                </View>
            ) : (
                <FlatList
                    keyExtractor={keyExtractor}
                    data={data}
                    contentContainerStyle={styles.flatList}
                    renderItem={RenderJobCard}
                    onEndReached={loadMore}
                    onEndReachedThreshold={0.3}
                    ItemSeparatorComponent={ItemSeparatorComponent}
                    ListFooterComponent={isFetching || isLoading ? FlatListLoadingIndicator : null}
                />
            )}
        </ComponentWrapper>
    );
}

export { Applied };

const styles = StyleSheet.create({
    // HeaderApplied
    headerConatiner: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 33,
        marginBottom: 14,
    },
    headerTitle: {
        fontSize: 21,
        color: COLORS.headerText,
        marginStart: 8,
    },
    // content
    flatList: {
        paddingBottom: 100,
    },
    separator: {
        height: 12,
    },
    // form ContractorJobs
    jobCardContainer: {
        width: undefined,
    },
    emptyTableTitle: mbTextStyles([
        textStyles.normalText, {
            color: COLORS.bodyText,
            fontSize: 15,
            fontWeight: '700',
            marginTop: 12
        }
    ]),
    emptyTableSubtitle: mbTextStyles([
        textStyles.smallerText, {
            color: COLORS.bodyText2,
            marginTop: 6,
        }
    ]),
    emptyTableContainer: {
        width: '80%',
        flex: 1,
        alignItems: 'center',
        paddingTop: '40%',
        alignSelf: 'center'
    }
});
