import 'react-native-gesture-handler';

// Some internal React-Native libraries are using removeListener and that shows a warning every time the app runs.
import { LogBox } from 'react-native';
LogBox.ignoreLogs(['EventEmitter.removeListener']);

import React, { useEffect, useState } from 'react';
import { Platform, StatusBar } from 'react-native';
import { setResponseInterceptors } from './src/apiCalls/customAxios';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as SplashScreen from 'expo-splash-screen';
import { SafeAreaProvider, initialWindowMetrics } from 'react-native-safe-area-context';
import { AppContextProvider } from './src/context/ContextProviders';
import RootNavigation from './src/navigations/RootNavigator';
import { COLORS } from './src/constants/colors';
import { isMobile, isMobileApp } from '@workingu/rnw.utils.device-info';
import { MB_PopUp } from '@workingu/rnw.components.pop-up';
import { MB_Toast } from '@workingu/rnw.components.toast';
import { useAppInitialSetup } from './src/hooks/useAppInitialSetup';
import { CustomToastConfig } from './src/components/helperComponents/CustomToastConfig';
import { envs } from './env';

// Response interceptors for axios. By default it is used to intercept certain errors and handle them in a special way.
setResponseInterceptors();

if (Platform.OS === 'android' || Platform.OS === 'ios') {
  SplashScreen.preventAutoHideAsync().catch(warning => console.log('Warning. Failed to prevent splash screen auto hide ', warning));
}

const App = () => {
  const isInitialSetupComplete = useAppInitialSetup();
  // isAppReady is intended to be set by rootNavigator when all necessary data has been pulled from the server
  const [isAppReady, setIsAppReady] = useState(false);

  useEffect(() => {
    if (isMobileApp && isAppReady && isInitialSetupComplete) {
      SplashScreen.hideAsync().catch((error) => console.error('Error when hiding splash screen', error));
    }
  }, [isInitialSetupComplete, isAppReady]);


  if (!isInitialSetupComplete) {
    return null;
  }

  return (
    <SafeAreaProvider initialMetrics={initialWindowMetrics}>

      {isMobile &&
        <StatusBar barStyle="dark-content" backgroundColor={COLORS.white} />
      }

      <AppContextProvider>
        <RootNavigation onReady={setIsAppReady} />

        <MB_PopUp />

        {envs.FLAVOR === 'dev' && Platform.OS === 'web' &&
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        }

        <MB_Toast config={CustomToastConfig} absolutePositionForWeb />
      </AppContextProvider>
    </SafeAreaProvider>
  );
};

export default App;

