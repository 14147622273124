import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import React from "react";
import { StyleProp, StyleSheet, Text, TouchableOpacity, View, ViewStyle } from "react-native";
import Feather from "react-native-vector-icons/Feather";
import { COLORS } from "../../constants/colors";
import { textStyles } from "../../constants/textStyles";
import { format as formatDate, parseISO } from 'date-fns';
import { UniversalScreenNavigationProp } from "../../typesAndInterfaces/componentProps";
import { useNavigation } from "@react-navigation/core";
import { ApplicationStatus, Job } from "@wu/business";
import { ApplicationStatusText } from "./ApplicationStatusText";

interface JobCardProps {
    job: Job & {status?: ApplicationStatus},
    style?: StyleProp<ViewStyle>
}

const JobCard = ({ job, style }: JobCardProps) => {

    const navigation = useNavigation<UniversalScreenNavigationProp>();
    const {address, status, completed } = job;

    return (
        <TouchableOpacity style={[styles.container, style]} onPress={() => navigation.navigate('JobDetails', {jobId: job.id})} >
            <View style={styles.header}>
                <View style={styles.titleContainer}>
                    <Text style={styles.title}>{job.title}</Text>
                    <Text style={styles.company}>{job.contractor}</Text>
                </View>
                <View style={styles.rangeContainer}>
                    <Text style={styles.range}>${job.hourlyRate[0]}-${job.hourlyRate[1]}/hr</Text>
                </View>
            </View>
            <View style={styles.dateContainer}>
                <Feather name="calendar" size={15} color={COLORS.greyText} />
                <Text style={styles.date}>{formatDate(parseISO(job.startDate), 'dd MMMM')} - {formatDate(parseISO(job.endDate), 'dd MMMM')}</Text>
            </View>
            <Text style={styles.description} numberOfLines={2}>{job.description}</Text>
            <View style={styles.locationContainer}>
                <View style={{flexDirection: 'row'}}>
                    <Feather name="map-pin" size={15} color={COLORS.greyText} />
                    <Text style={styles.location}>{address.city}, {address.state}</Text>
                </View>
                <ApplicationStatusText status={status} completed={completed} />              
            </View>
        </TouchableOpacity>
    );
}

export { JobCard };

const styles = StyleSheet.create({
    container: {
        paddingTop: 20,
        paddingBottom: 17,
        paddingLeft: 17,
        paddingRight: 15,
        borderRadius: 10,
        backgroundColor: COLORS.lightOrange,
        width: 302,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    detail: {
        flex: 1,
        marginEnd: 10
    },
    titleContainer: {
        flex: 1,
        marginRight: 10,
    },
    title: mbTextStyles([textStyles.smallText, {
        color: COLORS.headerText,
        textAlign: 'left',
    }]),
    company: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.bodyText,
        textAlign: 'left',
    }]),
    rangeContainer: {
        paddingHorizontal: 10,
        paddingVertical: 7,
        borderRadius: 360,
        borderWidth: 1,
        borderColor: COLORS.primaryColor,
    },
    range: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.primaryColor,
        textAlign: 'left',
    }]),
    dateContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 6,
    },
    date: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.greyText,
        marginLeft: 7,
    }]),
    description: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.bodyText2,
        marginTop: 12,
        textAlign: 'left',
    }]),
    locationContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 19,
    },
    location: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.greyText,
        textAlign: 'left',
        marginLeft: 5.7,
    }]),
})
