import React from 'react';
import { StyleProp, TextStyle, TouchableOpacity, ViewStyle } from 'react-native';
import { COLORS } from '../../constants/colors';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

interface ICheckbox {
  isChecked: boolean,
  onPress: () => void,
  containerStyle?: StyleProp<ViewStyle>,
  checkboxStyleChecked?: StyleProp<TextStyle>,
  checkboxStyleUnchecked?: StyleProp<TextStyle>,
}

const Checkbox = ({ isChecked, onPress, containerStyle, checkboxStyleChecked, checkboxStyleUnchecked }: ICheckbox) => {
  return (
    <TouchableOpacity style={containerStyle} onPress={onPress} >
      {isChecked ?
        <MaterialCommunityIcons name="checkbox-marked" size={24} color={COLORS.primaryColor} style={checkboxStyleChecked} />
        :
        <MaterialCommunityIcons name="checkbox-blank-outline" size={24} color='#C4C4C4' style={checkboxStyleUnchecked} />
      }
    </TouchableOpacity>
  );
};

export { Checkbox };
