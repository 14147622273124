import { TradeObj } from '../utils/zod/zodObjects';
import { axiosCaller } from './customAxios';
import { z } from 'zod';
import { Trade } from '../typesAndInterfaces/typesAndInterfaces';

const utilApiCalls = {
    getVersion: async (): Promise<string> => {
        return await axiosCaller({
            path: '/api/util/version',
            method: 'get',
            responseDataKey: 'version',
            responseFormatValidatorMessage: 'Data mismatch for version data response',
            responseValidatorObj: z.string(),
        });
    },

    getMinimumMobileJSVersion: async (): Promise<string> => {
        return await axiosCaller({
            path: '/api/util/minimum-jsversion',
            method: 'get',
            responseDataKey: 'version',
            responseFormatValidatorMessage: 'Data mismatch for version data response',
            responseValidatorObj: z.string(),
        });
    },

    getTrades: async (name: string): Promise<Trade[]> => {
        return await axiosCaller({
            path: '/api/trades',
            method: 'get',
            responseDataKey: 'trades',
            responseFormatValidatorMessage: 'Data mismatch for tradse data response',
            responseValidatorObj: z.array(TradeObj),
            params: { name }
        })
    }
};

export { utilApiCalls };
