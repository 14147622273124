import * as React from "react"
import Svg, { Path } from "react-native-svg"

const CaseIcon = ({ color, size = 18 }: { color?: string, size?: number }) => {
  return (
    <Svg
      width={size}
      height={size * (17 / 18)}
      viewBox="0 0 18 17"
      fill="none"
    >
      <Path
        d="M8.988 12.533v-2.192"
        stroke={color ?? '#7D8FA5'}
        strokeWidth={0.8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        clipRule="evenodd"
        d="M14.34 2.73a2.636 2.636 0 012.636 2.643v2.973c-2.126 1.244-4.934 1.996-7.992 1.996-3.059 0-5.858-.752-7.984-1.996V5.365A2.637 2.637 0 013.644 2.73H14.34z"
        stroke={color ?? '#7D8FA5'}
        strokeWidth={0.8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12.012 2.726v-.317A1.91 1.91 0 0010.102.5H7.874a1.91 1.91 0 00-1.909 1.91v.316M1.021 11.502l.163 2.167a2.801 2.801 0 002.793 2.59h10.022a2.801 2.801 0 002.792-2.59l.164-2.167"
        stroke={color ?? '#7D8FA5'}
        strokeWidth={0.8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export { CaseIcon };
