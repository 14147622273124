import React from "react";
import { MB_PageIndicator } from "./page-indicator/page-indicator";
import Feather from "react-native-vector-icons/Feather";
import { COLORS } from "../../../constants/colors";
import { StyleSheet } from "react-native";
import { textStyles } from "../../../constants/textStyles";

const Indicators = ({ page, totalPages, onPageChange }: { page: number, totalPages: number, onPageChange?: (page: number) => void }) => {

    return (
        <MB_PageIndicator
            current={page}
            pages={totalPages}
            style={styles.pageIndicatorStyle}
            onChange={onPageChange}
            leftButtonProps={{
                title: "Previous",
                icon: <Feather name="arrow-left" color={COLORS.greyText} size={18} />,
                inactiveTextColor: COLORS.greyText,
                textStyle: [styles.buttonText, styles.leftButtonText],
                hoverColor: COLORS.lightOrange,
                selectColor: COLORS.lightOrange,
                activeColor: COLORS.white,
                buttonStyle: styles.button,
                containerStyle: styles.leftButton,
            }}
            rightButtonProps={{
                title: "Next",
                icon: undefined,
                rightIcon: <Feather name="arrow-right" color={COLORS.greyText} size={18} />,
                inactiveTextColor: COLORS.greyText,
                textStyle: styles.buttonText,
                hoverColor: COLORS.lightOrange,
                selectColor: COLORS.lightOrange,
                activeColor: COLORS.white,
                buttonStyle: styles.button,
                containerStyle: styles.rightButton,
            }}
            indicatorButtonProp={{
                activeColor: COLORS.white,
                selectColor: COLORS.lightOrange,
                hoverColor: COLORS.lightOrange,
                inactiveTextColor: COLORS.greyText,
                textStyle: styles.buttonText,
                activeTextColor: COLORS.primaryColor,
            }}
        />
    );
}

export { Indicators };

const styles = StyleSheet.create({
    pageIndicatorStyle: {
        alignSelf: 'center',
        width: '100%',
        paddingHorizontal: 24,
    },
    buttonText: StyleSheet.flatten([textStyles.smallText, {
        fontSize: 13
    }]),
    button: {
        width: 103,
        borderWidth: 0.5,
        borderColor: COLORS.lightGrey,
    },
    leftButton: {
        marginEnd: 'auto',
    },
    leftButtonText: {
        marginStart: 8,
    },
    rightButton: {
        marginStart: 'auto',
    }
})
