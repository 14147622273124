import React, { useMemo } from "react";
import { StyleSheet, Text, View } from "react-native";

import { MB_TabView } from "@workingu/rnw.components.tab-view";
import { Application, ApplicationStatus } from "@wu/business"
import { MB_Image, mbGetSmallestImage } from "@workingu/rnw.components.image";
import Feather from "react-native-vector-icons/Feather";
import { COLORS } from "../../../constants/colors";
import { DollarIcon } from "../../../resources/svgComponents/DollarIcon";
import { imageUtils } from "../../../utils/imageUtils";
import { Rating } from "../../helperComponents/Rating";
import { BasicInformation } from "./BasicInformation";
import { CareerInformation } from "./CareerInformation";
import { Reviews } from "./Reviews";
import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import { textStyles } from "../../../constants/textStyles";
import { StatusColor } from "../../helperComponents/ApplicationStatusText";

const absentText = (absent: number) => {
    switch (absent) {
        case 0:
            return 'Was never absent'
        case 1:
            return '1 time absent'
        default:
            return `${absent} times absent`
    }
}

interface ApplicationInformationProps {
    employee: Application['employee'],
    applicationStatus?: ApplicationStatus,
    job: Application['job'],
}

const ApplicationInformation = ({ employee, applicationStatus, job }: ApplicationInformationProps) => {

    const { rating = 0, absent = 0 } = employee || {};

    const tabData = useMemo(() => ({
        titles: ['Basic Information', 'Career Information', 'Reviews'],
        screens: [
            () => <BasicInformation employee={employee} />,
            () => (
                <CareerInformation
                    employee={employee}
                    job={job}
                />),
            () => employee ? <Reviews employeeId={employee.id} /> : null,
        ],
    }), [employee, job]);

    const note = useMemo(() => {
        if (applicationStatus || job?.completed) {
            const text = job?.completed ? 'Completed' : applicationStatus;
            const color = StatusColor[applicationStatus ?? 'Completed'];
            return (<Text style={styles.note}><Text style={{ color }}>{text}</Text> for your job <Text style={styles.important}>“{job?.title}”</Text></Text>);
        }
        return (<Text style={styles.note}>Might be a fit for your job <Text style={styles.important}>“{job?.title}”</Text></Text>);
    }, [applicationStatus, job?.completed, job?.title]);

    const profileImageUrl = mbGetSmallestImage(employee?.profileImage) ?? imageUtils.images.emptyProfileIcon;

    const [minHourlyRate, maxHourlyRate] = employee?.hourlyRate ?? []

    return (
        <>
            {note}
            <View style={styles.card}>
                <MB_Image source={profileImageUrl} style={styles.logo} resizeMode='contain' disableInteraction />
                <Text style={styles.fullName}>{employee?.firstName} {employee?.lastName}</Text>
                <View style={styles.review}>
                    <Text style={styles.reviewText}>{rating.toFixed(1)}</Text>
                    <Rating rating={rating} />
                </View>
                <Text style={styles.absent}>{absentText(absent)}</Text>
                <View style={styles.details}>
                    <View style={styles.detail}>
                        <DollarIcon size={20} color={COLORS.bodyText} />
                        <Text style={styles.detailText}>${minHourlyRate} - ${maxHourlyRate} / hr</Text>
                    </View>
                    <View style={styles.detail}>
                        <Feather name="map-pin" size={20} color={COLORS.bodyText} />
                        <Text style={styles.detailText}>{employee?.address.city}, {employee?.address.state}</Text>
                    </View>
                </View>
            </View>
            <MB_TabView
                titles={tabData.titles}
                screens={tabData.screens}
                tabLabelStyle={styles.tabLabel}
                tabIndicatorStyle={styles.tabIndicatorStyle}
                style={styles.tabs}
            />
        </>
    )
}

export { ApplicationInformation };

const styles = StyleSheet.create({
    container: {
        paddingBottom: 0
    },
    note: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.bodyText,
        textAlign: 'left',
    }]),
    important: {
        color: COLORS.primaryColor,
    },
    card: {
        backgroundColor: COLORS.lightOrange,
        borderRadius: 15,
        paddingVertical: 22,
        paddingHorizontal: 60,
        alignItems: 'center',
        marginTop: 12,
    },
    logo: {
        width: 80,
        height: 80,
        borderRadius: 360,
    },
    fullName: mbTextStyles([textStyles.smallText, {
        color: COLORS.headerText,
        marginTop: 13,
    }]),
    title: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.bodyText,
        marginTop: 2,
    }]),
    trades: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    review: {
        flexDirection: 'row',
        marginTop: 7,
        alignItems: 'center',
    },
    reviewText: mbTextStyles([textStyles.smallerText, {
        color: COLORS.bodyText,
        marginEnd: 5,
    }]),
    star: {
        marginEnd: 4,
    },
    absent: mbTextStyles([textStyles.smallerText, {
        color: COLORS.bodyText2,
        marginTop: 3,
        textTransform: 'uppercase',
    }]),
    details: {
        flexDirection: 'row',
        marginTop: 15,
    },
    detail: {
        alignItems: 'center',
        flex: 1,
    },
    detailText: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.bodyText,
        marginTop: 6.4,
    }]),
    tabLabel: mbTextStyles([
        textStyles.smallText, {
            color: COLORS.greyText,
            fontSize: 13,
            textTransform: 'none'
        }
    ]),
    tabIndicatorStyle: {
        backgroundColor: COLORS.primaryColor,
    },
    tabScreenContainer: {
        paddingHorizontal: 20,
        paddingVertical: 20,
    },
    tabs: {
        minHeight: 280,
    },
})
