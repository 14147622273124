import { useCallback, useState } from 'react';

export function useDownload() {
  const [progress, _setProgress] = useState(0);
  const [isDownloading, _setDownloading] = useState(false);

  const download = useCallback((_id: string) => {}, []);

  return {
    progress,
    isDownloading,
    download,
  };
}
