import React from "react"
import { Path, Svg } from "react-native-svg"

export const ConstructionHelmet = (props: ConstructionHelmetProps) => {
    return (
        <Svg
            width={35}
            height={22}
            viewBox="0 0 35 22"
            fill="none"
            {...props}
        >
            <Path
                d="M4.26 13.536c.072-.452.23-.879.373-1.309a21.455 21.455 0 011.742-3.895c1.404-2.46 3.219-4.535 5.675-5.998A12.872 12.872 0 0115.697.877C17.61.425 19.547.424 21.485.64c1.467.164 2.889.518 4.249 1.104a11.898 11.898 0 014.769 3.696c1.097 1.42 1.855 3.006 2.373 4.715.347 1.146.583 2.323.703 3.515.01.089 0 .177.002.265.034.18.049.363.045.546 0 .303.183.566.142.877-.036.282-.104.395-.382.457-.407.093-.812.204-1.236.18-.283-.016-.327-.086-.193-.331a.417.417 0 00.04-.277c-.039-.254-.068-.51-.114-.762a.74.74 0 00-.593-.613c-.505-.108-.994-.08-1.37.365a.794.794 0 00-.192.606c.027.388.121.77.107 1.162-.012.32-.146.49-.461.55-.873.168-1.744.354-2.623.474-1.229.17-2.46.32-3.693.451-.19.028-.383.017-.569-.032-.267-.08-.38-.213-.393-.486-.008-.147 0-.294-.005-.442-.012-.387.026-.775-.07-1.162a1.471 1.471 0 01.013-.657c.04-.183-.018-.245-.213-.267-.22-.025-.437-.007-.656 0-.513.012-1.027.032-1.54-.024-.427-.046-.852.028-1.276.054-.243.016-.486.018-.726.026-.11-.002-.22.014-.326.047-.139.048-.22.156-.183.287.122.437.017.86-.047 1.286-.036.242-.026.486-.035.726-.013.388-.171.53-.555.486-.839-.102-1.68-.167-2.512-.316a46.608 46.608 0 01-3.36-.707 17.126 17.126 0 01-1.824-.583c-.292-.11-.354-.206-.332-.512.025-.338.117-.668.27-.971.14-.285.18-.589.238-.89.036-.182-.064-.325-.234-.38-.418-.132-.816-.327-1.257-.392-.172-.026-.264.028-.393.152-.389.381-.452.875-.548 1.363-.02.116-.058.228-.11.333-.14.265-.328.338-.603.216-.455-.203-.933-.358-1.356-.632a1.49 1.49 0 01-.197-.139c-.169-.155-.107-.313.002-.468z"
                fill="#FFCD00"
            />
            <Path
                d="M4.26 13.536c-.033.364.045.47.512.683.375.17.756.324 1.132.492.165.074.286.03.377-.115a.937.937 0 00.145-.39c.035-.31.132-.602.212-.9a.639.639 0 01.183-.296c.063-.06.148-.121.17-.198.08-.272.275-.234.477-.207.455.06.863.273 1.3.397a.436.436 0 01.294.524c-.08.334-.14.669-.294.988a2.523 2.523 0 00-.24.977c-.009.188.144.212.257.253.463.167.93.32 1.399.47.597.19 1.21.317 1.82.444.542.113 1.084.252 1.641.313.347.037.688.143 1.039.185.647.078 1.295.157 1.942.227.186.02.3-.116.31-.35.009-.316 0-.63.041-.946.03-.216.128-.42.097-.648-.018-.13-.017-.262-.056-.39-.075-.242.031-.421.272-.484a.56.56 0 01.151-.03c.707.02 1.407-.126 2.11-.085.469.027.936.023 1.404.032.299.007.595-.047.897-.027.234.015.38.19.293.409a.944.944 0 00-.03.586c.13.489.059.98.073 1.47.014.481.129.628.618.606.482-.023.963-.089 1.444-.146.8-.093 1.602-.176 2.4-.296.675-.1 1.344-.242 2.015-.364.231-.044.464-.077.694-.121.272-.053.372-.182.382-.456a3.563 3.563 0 00-.107-1.025c-.16-.616.463-1.224 1.05-1.25.43-.018.828 0 1.142.372.162.192.151.406.2.615.043.185.056.377.09.564.034.188-.046.313-.208.436.204.077.39.06.562.036.34-.048.675-.133 1.012-.204.136-.03.182-.123.19-.26.017-.295-.032-.586-.062-.877a5.741 5.741 0 01-.025-.615c.055.197.079.402.07.607-.006.206.08.31.265.39.368.157.677.394.88.75.085.449-.01.606-.428.806-.851.408-1.772.562-2.684.754-1.192.252-2.413.345-3.614.553-.703.122-1.416.143-2.122.233-.991.125-1.99.16-2.983.258-.409.04-.822 0-1.233.05-.673.08-1.351.022-2.026.054-.917.044-1.835.057-2.752.095-1.05.044-2.1.055-3.15.085-.984.026-1.969.038-2.953.034-.795 0-1.59-.023-2.38-.057a84.28 84.28 0 01-3.055-.174 21.739 21.739 0 01-1.724-.223 11.356 11.356 0 01-1.694-.39c-.587-.189-1.13-.453-1.574-.895-.127-.126-.203-.286-.319-.418-.265-.57-.03-1.368.515-1.77.68-.5 1.468-.724 2.276-.9.402-.09.81-.153 1.22-.188a.249.249 0 00.14-.053z"
                fill="#FFE000"
            />
            <Path
                d="M33.536 17.114c0 .846-.031 1.693.005 2.538.036.824-.56 1.28-1.168 1.313-1.903.106-3.807.146-5.696-.182-1.037-.18-1.914-.684-2.73-1.319a13.544 13.544 0 01-1.045-.914c0-.127.096-.133.182-.138a103.45 103.45 0 001.777-.142c.774-.072 1.548-.15 2.321-.237.97-.11 1.937-.237 2.902-.382a45.93 45.93 0 003.227-.599c.08-.017.175-.052.225.063z"
                fill="#5B5653"
            />
            <Path
                d="M33.536 17.114c-.706.144-1.406.303-2.115.425-1.427.244-2.86.441-4.3.603a80.535 80.535 0 01-4.082.367c-.049.003-.106-.017-.139.041-.205.004-.41.005-.618.012l-6.725.213c-2.84.088-5.677.088-8.514-.066-1.3-.071-2.598-.165-3.877-.422-.839-.168-1.662-.385-2.376-.889a1.948 1.948 0 01-.638-.742.357.357 0 01-.044-.21c.012-.038.027-.085.068-.028.71.976 1.777 1.28 2.874 1.507.94.194 1.894.317 2.853.37.747.042 1.496.06 2.24.129.588.054 1.177 0 1.762.044.977.074 1.955.05 2.93.028.897-.021 1.794-.004 2.69-.042.894-.038 1.79-.053 2.684-.091.983-.044 1.966-.053 2.95-.088 1.135-.04 2.268-.14 3.4-.216.95-.064 1.895-.192 2.844-.27.678-.057 1.348-.205 2.023-.297.829-.112 1.659-.216 2.476-.392.883-.19 1.766-.367 2.586-.768.19-.094.251-.29.282-.491.008-.053-.045-.118.027-.16.353.486.242.89-.3 1.12-.31.132-.64.211-.96.314z"
                fill="#FDC600"
            />
            <Path
                d="M26.298 6.096c-.004.33-.176.52-.475.62-.383.128-.758.069-1.125-.044-.736-.227-1.462-.486-2.2-.707-.892-.264-1.806-.422-2.738-.387-1.048.04-2.063.27-3.047.635-.537.2-1.07.414-1.628.548-.313.075-.64.102-.95-.027-.61-.253-.61-.686-.27-1.148.437-.598 1.062-.958 1.728-1.25.667-.293 1.358-.472 2.068-.596.526-.1 1.06-.163 1.595-.188.836-.029 1.675-.061 2.51.067.845.13 1.668.325 2.463.65.533.21 1.028.507 1.464.878.304.265.595.557.605.949z"
                fill="#FFEB6C"
            />
            <Path
                d="M15.412 12.234c-.132.016-.22-.067-.287-.182-.32-.55-.639-1.101-.957-1.654-.1-.176-.049-.36.116-.454.165-.095.356-.057.446.095.334.568.667 1.136.988 1.711.136.245-.017.48-.306.484z"
                fill="#E7AD00"
            />
            <Path
                d="M14.77 12.354c-.015.142-.08.26-.216.306-.147.052-.271-.035-.339-.148a51.018 51.018 0 01-.868-1.51c-.094-.17-.01-.323.149-.407.16-.085.304-.007.39.141.243.42.48.842.713 1.268.062.113.183.198.17.35z"
                fill="#E7AC00"
            />
            <Path
                d="M13.667 12.745c.003.159-.077.232-.19.262-.136.036-.226-.044-.288-.152-.147-.253-.287-.511-.432-.766-.072-.127-.158-.246-.227-.376-.079-.15-.096-.298.077-.4.164-.097.277.014.348.132.238.394.46.798.686 1.2a.311.311 0 01.026.1z"
                fill="#E5AD00"
            />
            <Path
                d="M11.956 11.984a.175.175 0 01.158.102c.178.295.36.59.53.888.065.113.1.243-.05.324-.158.085-.225-.034-.29-.146-.165-.285-.333-.57-.492-.858-.085-.154-.01-.305.144-.31z"
                fill="#E7AC00"
            />
            <Path
                d="M20.863 15.204c-.007.083-.011.184-.108.19-.107.008-.142-.094-.14-.183.003-.088.04-.194.138-.192.098.001.094.11.11.185z"
                fill="#FFCD00"
            />
        </Svg>
    )
}


interface ConstructionHelmetProps { 
    size?: number
}