import { MB_RESOURCE_PICKER_TYPE, MB_ResourceUploader } from '@workingu/rnw.components.resource-uploader';
import React, { useCallback } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { COLORS } from '../../constants/colors';
import { MB_Image, MB_ImageObject, mbGetSmallestImage } from '@workingu/rnw.components.image';
import { MB_utilHooks } from '@workingu/rnw.utils.util-hooks';
import { mbApplyTransparency } from '@workingu/rnw.utils.style-utils';
import Entypo from "react-native-vector-icons/Entypo";
import { useUploader } from '../../hooks/uploads';
import { File } from '../../utils/uploader';


const ProfileImageUploader = ({ profileImage, onSuccess }: { profileImage: MB_ImageObject | undefined, onSuccess: (fileId: string) => void }) => {
    const [isResourcePickerVisible, showResourcePicker, hideResourcePicker] = MB_utilHooks.useBool();

    const onComplete = useCallback((file: File) => {
        onSuccess(file.id);
    }, [onSuccess]);

    const uploadProfileImage = useUploader(onComplete)

    // TODO: Hovo: mbGetSmallestImage return type is broken, it can be undefined actually. We need to fix the reusable
    const profileImageUrl = mbGetSmallestImage(profileImage);

    return (
        <View style={styles.profilePicture}>
            <MB_ResourceUploader
                isVisible={isResourcePickerVisible}
                onDismiss={hideResourcePicker}
                pageTitle={'Upload Profile Photo'}
                uploadButtonTitle={'Upload'}
                resourcePickerType={MB_RESOURCE_PICKER_TYPE.image}
                uploaderFunction={uploadProfileImage}
                cameraorGalleryIconColor={COLORS.primaryColor}
                modalCloseButtonXProps={{ color: COLORS.greyText, size: 15 }}
                cameraOrGalleryModalCloseButtonXProps={{ color: COLORS.greyText, size: 15 }}
                previewColor={COLORS.primaryColor}
            />
            <TouchableOpacity style={styles.emptyBackground} onPress={showResourcePicker}>
                {profileImageUrl === undefined ?
                    <FontAwesome name="camera" color={COLORS.white} size={16} />
                    :
                    <MB_Image source={profileImageUrl} style={styles.profileImage} resizeMode='contain' disableInteraction />
                }
            </TouchableOpacity>

            <View style={styles.profileImageOverlay} pointerEvents='none'>
                <Entypo name='camera' size={20} color={COLORS.white} />
            </View>
        </View>
    );
};


export { ProfileImageUploader };

const styles = StyleSheet.create({
    profilePicture: {
        width: 75,
        height: 75,
        backgroundColor: COLORS.bluishGrey,
        alignSelf: 'center',
        borderRadius: 360,
        overflow: 'hidden',
    },
    emptyBackground: {
        backgroundColor: mbApplyTransparency(COLORS.black, 0.5),
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    profileImage: {
        width: 90,
        height: 90,
        borderRadius: 300,
    },
    profileImageOverlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: mbApplyTransparency(COLORS.black, 0.2),
        alignItems: 'center',
        justifyContent: 'center',
    }
});
