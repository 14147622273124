import React from 'react';
import { Linking, StyleSheet } from 'react-native';
import { COLORS } from '../../constants/colors';
import { MB_Button, MB_ButtonProps } from '@workingu/rnw.components.button';
import DeviceInfo from 'react-native-device-info';


const UpdateAppButton = (props: MB_ButtonProps) => {
    // TODO: Hovo: Make a proper update button like we did in Eks

    const openAppPageOnStore = async () => {
        const baseOS = await DeviceInfo.getBaseOs();
        if (baseOS === 'Android') {
            Linking.openURL('market://details?id=com.workingunited.app');
        } else if (baseOS === 'iOS') {
            Linking.openURL('itms-apps://apps.apple.com/id/app/instagram/id389801252?l=id');
        }
    };

    return (
        <MB_Button
            {...props}
            onPress={openAppPageOnStore}
            style={[styles.updateBtn, props.style]}
        />
    );
};

export { UpdateAppButton };

const styles = StyleSheet.create({
    updateBtn: {
        marginTop: 30,
        width: '100%',
        alignSelf: 'center',
        height: 44,
        backgroundColor: COLORS.buttonPurple,
    },
});
