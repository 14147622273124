import {
  CreateJobRequest,
  CreateJobResponse,
  Job,
  JobResponse,
  JobRequest,
  JobsRequest,
  DeclineApplicationRequest,
  Application,
  AcceptApplicationRequest,
  StringBoolean,
  UpdateJobRequest,
  DeleteJobRequest,
} from '@wu/business';

import { MB_InfinitePaginationPage } from '@workingu/rnw.utils.util-hooks';
import {
  UseInfiniteQueryOptions,
  useMutation,
  useInfiniteQuery,
  useQueryClient,
  UseQueryOptions,
  useQuery,
} from '@tanstack/react-query';

import { jobsApiCalls } from '../apiCalls/jobsApiCalls';
import { JOBS_QUERY_KEYS, USER_QUERY_KEYS } from '../constants/constants';
import { ServerError } from '../typesAndInterfaces/typesAndInterfaces';

export const useCreateJob = () => {
  const queryClient = useQueryClient();
  return useMutation<CreateJobResponse, ServerError, CreateJobRequest, unknown>(
    async (params) => jobsApiCalls.createJob(params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([JOBS_QUERY_KEYS.getContractorRecentJobs]);
        queryClient.invalidateQueries([USER_QUERY_KEYS.getRecommendedEmployees]);
      },
    }
  );
};

export const useUpdateJob = () => {
  const queryClient = useQueryClient();
  return useMutation<Job, ServerError, UpdateJobRequest, unknown>(
    async (params) => jobsApiCalls.updateJob(params),
    {
      onSuccess: (_, props) => {
        queryClient.invalidateQueries([JOBS_QUERY_KEYS.getContractorRecentJobs]);
        queryClient.invalidateQueries([JOBS_QUERY_KEYS.getJob, props.id]);
      },
    }
  );
};

export const useDeleteJob = () => {
  const queryClient = useQueryClient();
  return useMutation<null, ServerError, DeleteJobRequest, unknown>(
    async (params) => jobsApiCalls.deleteJob(params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([JOBS_QUERY_KEYS.getContractorRecentJobs]);
        queryClient.invalidateQueries([USER_QUERY_KEYS.getRecommendedEmployees]);
      },
    }
  );
};

export const useGetContractorJobs = ({
  completed,
  totalItemsPerPage,
  queryOptions,
  key,
}: {
  key: JOBS_QUERY_KEYS.getContractorCompletedJobs | JOBS_QUERY_KEYS.getContractorRecentJobs;
  completed: StringBoolean;
  totalItemsPerPage: number;
  queryOptions?: UseInfiniteQueryOptions<MB_InfinitePaginationPage<Job>, any>;
}) => {
  return useInfiniteQuery<MB_InfinitePaginationPage<Job>, any>(
    [key, completed],
    async ({ pageParam }: { pageParam?: string }) =>
      jobsApiCalls.getJobsForContractor({
        totalItemsPerPage,
        cursor: pageParam,
        offset: pageParam,
        completed,
      }),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => lastPage.nextOffset ?? undefined,
      ...queryOptions,
    }
  );
};

export const useGetRecommendedJobs = ({
  completed,
  totalItemsPerPage,
  filter,
  queryOptions,
}: {
  completed: StringBoolean;
  totalItemsPerPage: number;
  filter?: JobsRequest['filter'];
  queryOptions?: UseInfiniteQueryOptions<MB_InfinitePaginationPage<Job>, any>;
}) => {
  return useInfiniteQuery<MB_InfinitePaginationPage<Job>, any>(
    [JOBS_QUERY_KEYS.getRecommendedJobs, JSON.stringify(filter || {}), completed],
    async ({ pageParam }: { pageParam?: string }) =>
      jobsApiCalls.getRecommendedJobs({
        totalItemsPerPage,
        cursor: pageParam,
        offset: pageParam,
        completed,
        filter,
      }),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => lastPage.nextOffset ?? undefined,
      ...queryOptions,
    }
  );
};

export const useGetAllJobs = ({
  totalItemsPerPage,
  filter,
  queryOptions,
}: {
  totalItemsPerPage: number;
  filter?: JobsRequest['filter'];
  queryOptions?: UseInfiniteQueryOptions<MB_InfinitePaginationPage<Job>, any>;
}) => {
  return useInfiniteQuery<MB_InfinitePaginationPage<Job>, any>(
    [JOBS_QUERY_KEYS.getAllJobs, JSON.stringify(filter || {})],
    async ({ pageParam }: { pageParam?: string }) =>
      jobsApiCalls.getAllJobs({
        totalItemsPerPage,
        cursor: pageParam,
        offset: pageParam,
        filter,
      }),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => lastPage.nextOffset ?? undefined,
      ...queryOptions,
    }
  );
};

export const useGetJob = (props: {
  jobId: string;
  queryOptions?: UseQueryOptions<JobResponse, ServerError>;
}) => {
  return useQuery<JobResponse, ServerError>(
    [JOBS_QUERY_KEYS.getJob, props.jobId],
    async () => jobsApiCalls.getJob({ id: props.jobId }),
    props?.queryOptions
  );
};

export const useGetApplication = (props: {
  applicationId: string;
  queryOptions?: UseQueryOptions<Application, ServerError>;
}) => {
  return useQuery<Application, ServerError>(
    [JOBS_QUERY_KEYS.getJobApplication, props.applicationId],
    async () => jobsApiCalls.getApplication({ id: props.applicationId }),
    props?.queryOptions
  );
};

export const useApplyForJob = () => {
  const queryClient = useQueryClient();
  return useMutation<void, ServerError, JobRequest, unknown>(
    async (params) => jobsApiCalls.applyForJob(params),
    {
      onSuccess: (_, props) => {
        queryClient.invalidateQueries([JOBS_QUERY_KEYS.getAllJobs]);
        queryClient.invalidateQueries([JOBS_QUERY_KEYS.getRecommendedJobs]);
        queryClient.invalidateQueries([USER_QUERY_KEYS.getEmployeeApplications]);
        queryClient.invalidateQueries([JOBS_QUERY_KEYS.getJob, props.id]);
      },
    }
  );
};

export const useDeclineApplication = () => {
  const queryClient = useQueryClient();
  return useMutation<Application, ServerError, DeclineApplicationRequest, unknown>(
    async (params) => jobsApiCalls.declineApplication(params),
    {
      onSuccess: (_, props) => {
        queryClient.invalidateQueries([USER_QUERY_KEYS.getContractorApplicants]);
        queryClient.invalidateQueries([JOBS_QUERY_KEYS.getJobApplication, props.id]);
      },
    }
  );
};

export const useAcceptApplication = () => {
  const queryClient = useQueryClient();
  return useMutation<Application, ServerError, AcceptApplicationRequest, unknown>(
    async (params) => jobsApiCalls.acceptApplication(params),
    {
      onSuccess: (_, props) => {
        queryClient.invalidateQueries([USER_QUERY_KEYS.getContractorApplicants]);
        queryClient.invalidateQueries([JOBS_QUERY_KEYS.getJobApplication, props.id]);
      },
    }
  );
};
