import React, { useMemo, useState } from 'react';
import { useGetAllTrades } from '../../hooks/serverUtilHooks';
import { MB_DropDownPicker } from '@workingu/rnw.components.dropdownpicker';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { COLORS } from '../../constants/colors';
import { mbTextStyles } from '@workingu/rnw.utils.style-utils';
import { textStyles } from '../../constants/textStyles';

// TODO: Anas: Do we need to set a limit to this ? Talk with dovrat
// Dovrat: need to ask the client

const Trades = ({
  trades,
  onChange,
  style,
  zIndex,
}: {
  trades?: string[];
  onChange?: (trades: string[]) => void;
  style?: StyleProp<ViewStyle>;
  zIndex?: number;
}) => {
  const [searchText, setSearchText] = useState('');
  const { data: allTrades } = useGetAllTrades();

  const options = useMemo(() => {
    const filteredTrades =
      allTrades?.filter((trade) => trade.name.match(new RegExp(searchText, 'i'))) ?? [];
    return filteredTrades.map((trade) => ({
      label: trade.name,
      value: trade.name,
    }));
  }, [allTrades, searchText]);

  return (
    <MB_DropDownPicker
      placeholder="Add trades"
      items={options}
      onValueChange={(selectedOptions) => onChange?.((selectedOptions as string[] | null) ?? [])}
      multiple
      value={trades}
      searchable={false}
      disableLocalSearch
      arrowColor={COLORS.greyText}
      selectedOptionColor={COLORS.bodyText}
      containerStyle={style}
      badgeStyle={styles.badge}
      badgeTextStyle={styles.badgeText}
      zIndex={zIndex}
    />
  );
};

export { Trades };

const styles = StyleSheet.create({
  badge: {
    backgroundColor: '#FFF4F0',
    borderRadius: 7,
    paddingVertical: 7,
  },
  badgeText: mbTextStyles([
    textStyles.smallerText,
    {
      fontSize: 11,
      color: COLORS.bodyText,
    },
  ]),
});
