import * as React from "react"
import Svg, { Mask, Path } from "react-native-svg"

const WorkIcon = ({ color, size = 18 }: { color?: string, size?: number }) => {
  return (
    <Svg
      width={size}
      height={size * (21 / 18)}
      viewBox="0 0 18 21"
      fill="none"
    >
      <Mask id="a" fill="#fff">
        <Path d="M1.292 8.34H.6a.6.6 0 00-.6.6v1.8a.6.6 0 00.435.577c.042.012.316.09.765.2v2.823a.6.6 0 00.6.6h.85c.14.49.338.963.591 1.413l-.605.605a.6.6 0 000 .849l1.697 1.697a.6.6 0 00.849 0l.605-.605c.45.253.923.45 1.413.59v.891a.6.6 0 00.6.6h2.4a.6.6 0 00.6-.6v-.89c.49-.14.963-.338 1.413-.591l.605.605a.6.6 0 00.849 0l1.697-1.697a.6.6 0 000-.849l-.605-.605a6.52 6.52 0 00.59-1.413h.851a.6.6 0 00.6-.6v-2.823c.45-.11.723-.188.765-.2A.6.6 0 0018 10.74v-1.8a.6.6 0 00-.6-.6h-.692a7.87 7.87 0 00-4.085-5.748l.25-.558a.6.6 0 00-.31-.796A8.918 8.918 0 009 .5c-1.232 0-2.43.248-3.563.738a.6.6 0 00-.31.796l.25.558A7.871 7.871 0 001.292 8.34zm14.308 5.4h-.721a.6.6 0 00-.588.48 5.346 5.346 0 01-.79 1.892.6.6 0 00.075.756l.515.515-.848.848-.515-.515a.6.6 0 00-.756-.076 5.346 5.346 0 01-1.892.79.6.6 0 00-.48.589v.761H8.4v-.761a.6.6 0 00-.48-.588 5.345 5.345 0 01-1.892-.79.6.6 0 00-.756.075l-.515.515-.848-.848.515-.515a.6.6 0 00.076-.756 5.345 5.345 0 01-.79-1.892.6.6 0 00-.589-.48H2.4v-1.951c.703.147 1.557.306 2.5.44A4.205 4.205 0 009 17.34a4.205 4.205 0 004.1-5.111 38.655 38.655 0 002.5-.44v1.951zm-9.6-.6c0-.26.033-.515.098-.763.93.1 1.914.163 2.902.163.988 0 1.972-.064 2.902-.162A3.003 3.003 0 019 16.14c-1.654-.001-3-1.347-3-3.001zm10.8-3.6v.738c-1.168.296-4.507 1.062-7.8 1.062s-6.632-.766-7.8-1.062V9.54h15.6zM5.869 3.69l1.383 3.095a.6.6 0 001.096-.49L6.48 2.12A7.716 7.716 0 019 1.7c.864 0 1.709.141 2.52.42L9.651 6.295a.6.6 0 101.096.49L12.13 3.69c1.74.948 2.995 2.687 3.36 4.649H2.51a6.736 6.736 0 013.359-4.65z" />
      </Mask>
      <Path
        d="M1.292 8.34H.6a.6.6 0 00-.6.6v1.8a.6.6 0 00.435.577c.042.012.316.09.765.2v2.823a.6.6 0 00.6.6h.85c.14.49.338.963.591 1.413l-.605.605a.6.6 0 000 .849l1.697 1.697a.6.6 0 00.849 0l.605-.605c.45.253.923.45 1.413.59v.891a.6.6 0 00.6.6h2.4a.6.6 0 00.6-.6v-.89c.49-.14.963-.338 1.413-.591l.605.605a.6.6 0 00.849 0l1.697-1.697a.6.6 0 000-.849l-.605-.605a6.52 6.52 0 00.59-1.413h.851a.6.6 0 00.6-.6v-2.823c.45-.11.723-.188.765-.2A.6.6 0 0018 10.74v-1.8a.6.6 0 00-.6-.6h-.692a7.87 7.87 0 00-4.085-5.748l.25-.558a.6.6 0 00-.31-.796A8.918 8.918 0 009 .5c-1.232 0-2.43.248-3.563.738a.6.6 0 00-.31.796l.25.558A7.871 7.871 0 001.292 8.34zm14.308 5.4h-.721a.6.6 0 00-.588.48 5.346 5.346 0 01-.79 1.892.6.6 0 00.075.756l.515.515-.848.848-.515-.515a.6.6 0 00-.756-.076 5.346 5.346 0 01-1.892.79.6.6 0 00-.48.589v.761H8.4v-.761a.6.6 0 00-.48-.588 5.345 5.345 0 01-1.892-.79.6.6 0 00-.756.075l-.515.515-.848-.848.515-.515a.6.6 0 00.076-.756 5.345 5.345 0 01-.79-1.892.6.6 0 00-.589-.48H2.4v-1.951c.703.147 1.557.306 2.5.44A4.205 4.205 0 009 17.34a4.205 4.205 0 004.1-5.111 38.655 38.655 0 002.5-.44v1.951zm-9.6-.6c0-.26.033-.515.098-.763.93.1 1.914.163 2.902.163.988 0 1.972-.064 2.902-.162A3.003 3.003 0 019 16.14c-1.654-.001-3-1.347-3-3.001zm10.8-3.6v.738c-1.168.296-4.507 1.062-7.8 1.062s-6.632-.766-7.8-1.062V9.54h15.6zM5.869 3.69l1.383 3.095a.6.6 0 001.096-.49L6.48 2.12A7.716 7.716 0 019 1.7c.864 0 1.709.141 2.52.42L9.651 6.295a.6.6 0 101.096.49L12.13 3.69c1.74.948 2.995 2.687 3.36 4.649H2.51a6.736 6.736 0 013.359-4.65z"
        fill={color ?? '#7D8FA5'}
        stroke="#fff"
        strokeWidth={0.4}
        mask="url(#a)"
      />
    </Svg>
  )
}

export { WorkIcon };
