import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import { useNavigation } from "@react-navigation/core";
import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import Feather from "react-native-vector-icons/Feather";
import { COLORS } from "../../../constants/colors";
import { textStyles } from "../../../constants/textStyles";
import { UniversalScreenNavigationProp } from "../../../typesAndInterfaces/componentProps";
import { ComponentWrapper } from "../../helperComponents/componentWrapper/ComponentWrapper";
import { useSignedInContext } from "../../../context/SignedInContext";
import { UserRole } from "@wu/business";

const Card = ({ title, description, onPress }: { title: string, description: string, onPress?: () => void }) => {
    return (
        <TouchableOpacity style={styles.card} onPress={onPress} >
            <View style={styles.cardContent}>
                <Text style={styles.cardTitle}>{title}</Text>
                <Text style={styles.cardDescription}>{description}</Text>
            </View>
            <Feather name="chevron-right" color={COLORS.greyText} size={20} />
        </TouchableOpacity>
    );
};

const EditProfile = () => {

    const navigation = useNavigation<UniversalScreenNavigationProp>();
    const { currentUserData } = useSignedInContext();

    const isContractor = currentUserData?.role === UserRole.Contractor

    return (
        <ComponentWrapper mobileHeaderOptions={{ showHeader: true, showBackArrow: true }}>
            <Text style={[textStyles.largeText, styles.title]}>{!isContractor ? 'Edit Profile' : 'Edit Company Info'}</Text>
            <Text style={[textStyles.smallerText, styles.subTitle]}>Edit your profile information and save changes when done.</Text>
            <Card
                title="Account Details"
                description={isContractor ? 'Company details and other account information' : 'Profile picture, name, contact info, password'}
                onPress={() => navigation.navigate('EditAccountDetails')}
            />
            {!isContractor &&
                <Card
                    title="Personal Information"
                    description="About you, location/adress"
                    onPress={() => navigation.navigate('EditPersonalInformation')}
                />
            }
            {!isContractor &&
                <Card
                    title="Career Information"
                    description="Trades, skills, work experience, hourly rate, references"
                    onPress={() => navigation.navigate('EditCareerInformation')}
                />
            }
            {isContractor &&
                <Card
                    title="Contractor License"
                    description="State license number, state license document"
                    onPress={() => navigation.navigate('EditContractorLicense')}
                />
            }
        </ComponentWrapper>
    )
}

export { EditProfile };

const styles = StyleSheet.create({
    title: {
        color: COLORS.headerText,
        textAlign: 'left',
        letterSpacing: -0.8,
    },
    subTitle: {
        color: COLORS.greyText,
        textAlign: 'left',
        marginBottom: 22,
    },

    // card
    card: {
        flexDirection: 'row',
        paddingHorizontal: 15,
        paddingVertical: 15,
        marginTop: 15,
        borderRadius: 5,
        borderWidth: 0.5,
        borderColor: '#C4C4C4',
        alignItems: 'center',
    },
    cardContent: {
        marginRight: 'auto',
    },
    cardTitle: mbTextStyles([textStyles.smallText, {
        fontSize: 13,
        color: COLORS.bodyText,
        textAlign: 'left',
    }]),
    cardDescription: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.bodyText2,
        textAlign: 'left',
        marginTop: 3,
    }])
});
