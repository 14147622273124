import React, { useCallback, useEffect, useMemo, useState } from "react";
import { COLORS } from "../../constants/colors";
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { StyleSheet, View } from "react-native";
import { StyleProp } from "react-native";
import { ViewStyle } from "react-native";

export const Rating = (props: RatingProps) => {
    const { onRatingChange } = props;
    const [rating, setRating] = useState(props.rating);
    const stars = useMemo(() => Array.from({ length: 5 }, (_, i) => i < rating), [rating])

    useEffect(() => {
        setRating(props.rating);
    }, [props.rating]);

    const onStar = useCallback((star: number) => {
        if (onRatingChange) {
            onRatingChange(star)
            setRating(star)
        }
    }, [onRatingChange])

    return (
        <View style={[styles.row, props.style]}>
            {stars.map((star, index) => {
                const color = star ? COLORS.primaryColor : COLORS.lightGrey;
                const onPress = onRatingChange ? () => onStar(index + 1) : undefined;

                const floorRating = Math.floor(rating);
                const starName = (star && index === floorRating && (rating - floorRating) < 1) ? "star-half-empty" : "star"

                return (
                    <FontAwesome
                        key={index}
                        name={starName}
                        color={color}
                        size={13}
                        style={styles.star}
                        onPress={onPress}
                    />
                )
            })}
        </View>
    )
}


interface RatingProps {
    rating: number;
    onRatingChange?: (rating: number) => void;
    style?: StyleProp<ViewStyle>
}

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    star: {
        marginEnd: 4,
    },
})