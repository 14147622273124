import { MB_Button } from '@workingu/rnw.components.button';
import { mbShowPopUp } from '@workingu/rnw.components.pop-up';
import { mbTextStyles } from '@workingu/rnw.utils.style-utils';
import React, { useCallback, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { COLORS } from '../../../../constants/colors';
import { STRING_CONSTANTS } from '../../../../constants/constants';
import { textStyles } from '../../../../constants/textStyles';
import { useCreateContractorData } from '../../../../hooks/userHooks';
import { ContractorLicenseProps } from '../../../../typesAndInterfaces/componentProps';
import { ComponentWrapper } from '../../../helperComponents/componentWrapper/ComponentWrapper';
import { LicenseEditor, LicenseEditorLicense } from '../../../helperComponents/LicenseEditor';
import { mbShowToast } from '@workingu/rnw.components.toast';

const ContractorLicense = ({ route }: ContractorLicenseProps) => {

    const { mutate: createContractorData, isLoading } = useCreateContractorData();

    const [licenses, setLicenses] = useState<{ number?: string, state: string, fileId?: string }[]>([]);

    const isDisabled = licenses.length === 0;

    const onSubmit = useCallback(() => {
        const { companySize, trades } = route.params;
        createContractorData({
            trades,
            size: companySize,
            licenses: licenses,
        }, {
            onSuccess: () => {
                mbShowToast({ text1: 'Success', text2: 'Sign up successful', position: 'bottom' });
            },
            onError: (error) => {
                mbShowPopUp({
                    title: 'Error',
                    message: error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN,
                });
            },
        })
    }, [licenses, route.params, createContractorData]);

    const onLicenseChanged = useCallback((type: 'edit' | 'create' | 'delete', updatedLicenses: LicenseEditorLicense[]) => {
        if (type === 'delete') {
            // wait for the modal to hide
            setTimeout(() => {
                mbShowToast({ text1: 'License deleted successfully', text2: '', position: 'bottom' })
            }, 500);
        }
        setLicenses(updatedLicenses.map(license => ({ number: license.number, state: license.state, fileId: license.fileData?.id })));
    }, []);

    return (
        <ComponentWrapper mobileHeaderOptions={{ showHeader: true, showBackArrow: true }}>
            <Text style={[textStyles.smallerText, styles.topNote]}>Step 2 of 2</Text>
            <Text style={[textStyles.largeText, styles.title]}>Contractor License</Text>
            <Text style={[textStyles.smallerText, styles.subTitle]}>Please enter your license details below for verification.</Text>
            <LicenseEditor
                showModalInitially
                onLicensesChange={onLicenseChanged}
            />
            <View style={{ minHeight: 20, marginTop: 'auto' }} />
            <MB_Button
                title="Continue"
                style={[styles.continueBtn]}
                textStyle={textStyles.smallerText}
                disabled={isDisabled || isLoading}
                loading={isLoading}
                onPress={onSubmit}
            />
        </ComponentWrapper>
    );
};

export { ContractorLicense };

const styles = StyleSheet.create({
    topNote: {
        marginBottom: 7,
        color: COLORS.bluishGrey,
        textAlign: 'left',
    },
    title: {
        color: COLORS.headerText,
        textAlign: 'left',
        letterSpacing: -0.8,
    },
    subTitle: {
        color: COLORS.greyText,
        textAlign: 'left',
        marginBottom: 9,
    },
    inputTitle: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.bodyText,
        marginTop: 15,
        marginBottom: 7,
        textAlign: 'left',
    }]),
    required: {
        color: COLORS.red,
    },
    addLicense: {
        color: COLORS.primaryColor,
        textDecorationLine: 'underline',
        marginTop: 11,
        textAlign: 'left',
    },
    continueBtn: {
        height: 50,
        backgroundColor: COLORS.primaryColor,
        borderRadius: 30,
        marginBottom: 47,
    },
    // LicenseCreator
    licenseCreatorForm: {
        backgroundColor: COLORS.greyBG,
        borderRadius: 6,
        paddingHorizontal: 14.5,
        marginTop: 18,
        paddingTop: 18,
        paddingBottom: 22,
    },
    licenseCreatorFormTitle: {
        fontSize: 15,
        color: COLORS.bodyText,
        textAlign: 'left',
    },
    upload: {
        paddingVertical: 20,
        alignItems: 'center',
        borderColor: COLORS.primaryColor,
        borderWidth: 0.8,
        borderRadius: 5,
        borderStyle: 'dashed',
        backgroundColor: COLORS.white,
    },
    uploadText: {
        fontSize: 11,
        color: COLORS.primaryColor,
        marginTop: 7.75,
    },
    uploadSubText: {
        color: COLORS.greyText,
        marginTop: 3,
    },
    licenseCreatorFormBtns: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 24,
    },
    licenseCreatorFormCancelBtn: {
        height: 40,
        backgroundColor: 'transparent',
        borderColor: COLORS.primaryColor,
        borderWidth: 1,
        borderRadius: 36,
        width: 96,
        marginEnd: 5,
    },
    licenseCreatorFormSaveBtn: {
        height: 40,
        backgroundColor: COLORS.primaryColor,
        borderRadius: 36,
        width: 96,
    },
    licenseCreatorFormSaveBtnDisabled: {
        backgroundColor: '#FFD3BE',
    },
    // LicenseCard
    licenseCard: {
        flexDirection: 'row',
        backgroundColor: COLORS.lightOrange,
        borderRadius: 6,
        paddingTop: 12,
        paddingBottom: 18,
        paddingLeft: 14,
        paddingRight: 10,
        justifyContent: 'space-between',
        marginTop: 12,
    },
    licenseCardText: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.bodyText,
        textAlign: 'left',
    }]),
    licenseCardTextImportant: {
        color: COLORS.headerText,
    },
    licenseCardTextMargin: {
        marginTop: 7,
    },
    licenseCardEdit: {
        flexDirection: 'row',
    },
    licenseCardEditText: {
        fontSize: 11,
        color: COLORS.bodyText,
        marginLeft: 6,
    },
    licenseCardDeleteContainer: {
        marginTop: 8,
    },
    licenseCardDeleteText: {
        fontSize: 11,
        color: COLORS.red,
        marginLeft: 6,
    },
});
