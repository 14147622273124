import React, { useCallback, useEffect, useMemo } from "react";
import { ApplicationDetailsProps } from "../../typesAndInterfaces/componentProps";
import { useAcceptApplication, useGetApplication } from "../../hooks/jobsHooks";
import { LoadingPage } from "../helperComponents/LoadingPage";
import { ApplicationInformation } from "./ApplicationInformation/ApplicationInformation";
import { ReviewModal } from "../modals/ReviewModal";
import { MB_utilHooks } from "@workingu/rnw.utils.util-hooks";
import { ComponentWrapper } from "../helperComponents/componentWrapper/ComponentWrapper";
import { StyleSheet } from "react-native";
import { mbShowToast } from "@workingu/rnw.components.toast";
import { delay } from "@wu/utils";
import { mbShowPopUp } from "@workingu/rnw.components.pop-up";
import { STRING_CONSTANTS } from "../../constants/constants";
import { DeclineApplicationModal } from "../modals/DeclineApplicationModal";
import { View } from "react-native";
import { ApplicationStatus } from "@wu/business";
import { MB_Button } from "@workingu/rnw.components.button";
import { COLORS } from "../../constants/colors";
import Feather from "react-native-vector-icons/Feather";
import { textStyles } from "../../constants/textStyles";
import { useCompleteJob } from "../../hooks/userHooks";
import { envs } from "../../../env";

const ApplicationDetails = (props: ApplicationDetailsProps) => {
    const { route } = props;
    const [isReviewModalVisible, showReviewModal, hideReviewModal] = MB_utilHooks.useBool();
    const { applicationId, isReview = false } = route.params;

    const { data: application, isLoading } = useGetApplication({ applicationId });

    const { employee, status, job, isReviewed } = application || {};

    const [isDeclineModalVisible, showDeclineModal, hideDeclineModal] = MB_utilHooks.useBool();
    const { mutate: acceptApplication, isLoading: isAcceptingApplication } = useAcceptApplication()
    const { mutate: completeJob, isLoading: isCompleteJobLoading } = useCompleteJob();

    const accept = useCallback(() => {
        if (!applicationId) {
            return;
        }
        acceptApplication({ id: applicationId }, {
            onSuccess: () => {
                delay(1000)
                    .then(() => mbShowToast({ text1: `Application accepted`, text2: '', position: 'bottom' }));

            },
            onError: (error) => {
                mbShowPopUp({
                    title: 'Error',
                    message: error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN,
                });
            }
        })
    }, [acceptApplication, applicationId])

    useEffect(() => {

        const canShow = Boolean(job && employee)
        const isReviewable = canShow && isReview && !isReviewed;

        if (isReviewable) {
            showReviewModal();
        }
    }, [isReview, showReviewModal, job, employee, isReviewed]);

    const isAwaitingResponse = status === ApplicationStatus.AwaitingResponse;

    const mobileStickyFooter = useMemo(() => {
        if (!isAwaitingResponse) {
            return null;
        }
        return (
            <View style={styles.footer}>
                {applicationId &&
                    <DeclineApplicationModal
                        isVisible={isDeclineModalVisible}
                        onDismiss={hideDeclineModal}
                        id={applicationId}
                    />
                }
                <MB_Button
                    title="Decline"
                    leftElement={<Feather name="x" size={15} color={COLORS.primaryColor} />}
                    style={styles.rejectBtn}
                    textStyle={styles.rejectBtnText}
                    onPress={showDeclineModal}
                />
                <MB_Button
                    title="Accept"
                    leftElement={<Feather name="check" size={15} color={COLORS.white} />}
                    style={styles.acceptBtn}
                    textStyle={styles.acceptBtnText}
                    onPress={accept}
                    loading={isAcceptingApplication}
                    disabled={isAcceptingApplication}
                />
            </View>
        )
    }, [isAwaitingResponse, applicationId, isDeclineModalVisible, hideDeclineModal, showDeclineModal, accept, isAcceptingApplication]);

    if (isLoading || !employee || !job) {
        return <LoadingPage />
    }

    return (
        <ComponentWrapper
            mobileHeaderOptions={{ showHeader: true, showBackArrow: true, pageTitle: 'Employee Profile' }}
            mobileStickyFooter={mobileStickyFooter}
            containerStyle={styles.container}
        >
            
            {envs.FLAVOR !== "prod" && !job.completed && // TODO: this only for debuging
                <MB_Button
                    title="Complete job [Debug]"
                    textStyle={textStyles.smallText}
                    style={{ backgroundColor: COLORS.primaryColor, marginVertical: 10, height: 40 }}
                    onPress={() => completeJob({ applicationId, jobId: job.id })}
                    disabled={isCompleteJobLoading}
                    loading={isCompleteJobLoading}
                />
            }
            <ApplicationInformation
                employee={employee}
                applicationStatus={status}
                job={job}
            />
            <ReviewModal
                key={`${isReviewModalVisible}`}
                isVisible={isReviewModalVisible}
                onDismiss={hideReviewModal}
                employee={employee}
                jobId={job.id}
                applicationId={applicationId}
            />
        </ComponentWrapper>
    )
}

export { ApplicationDetails };


const styles = StyleSheet.create({
    container: {
        paddingBottom: 0
    },
    acceptBtn: {
        backgroundColor: COLORS.primaryColor,
        borderRadius: 30,
        height: 46,
        width: 161,
    },
    acceptBtnText: {
        color: COLORS.white,
    },
    rejectBtn: {
        backgroundColor: 'transparent',
        borderRadius: 30,
        height: 46,
        borderWidth: 1,
        borderColor: COLORS.primaryColor,
        width: 161,
    },
    rejectBtnText: {
        color: COLORS.primaryColor,
    },
    footer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        paddingVertical: 20,
    },
});