import { isMobileApp } from '@workingu/rnw.utils.device-info';
import {
  createNavigationContainerRef,
  NavigationContainer,
  NavigatorScreenParams,
} from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, { ReactNode, useEffect, useState } from 'react';
import { envs } from '../../env';
import { MobileLandingPage } from '../components/screens/MobileLandingPage';
import { NotFound } from '../components/screens/NotFound';
import { ServerRedirect } from '../components/screens/ServerRedirect/ServerRedirect';
import { ContractorLicense } from '../components/screens/SignUpFlow/Contractor/ContractorLicense';
import { EmployeePersonalInformation } from '../components/screens/SignUpFlow/Employee/EmployeePersonalInformation';
import { EmployeeCareerInformation } from '../components/screens/SignUpFlow/Employee/EmployeeCareerInformation';
import { RoleSelector } from '../components/screens/SignUpFlow/RoleSelector';
import { SIGNED_IN_STATUS, useSignedInContext } from '../context/SignedInContext';
import { PasswordRecoveryToken } from '../typesAndInterfaces/typesAndInterfaces';
import { Login } from '../components/screens/LoginFlow/Login';
import { ForgotPassword } from '../components/screens/ForgotPasswordFlow/ForgotPassword';

import {
  VerifyOtp,
  VerifyOtpData,
  VerifyOtpType,
} from '../components/screens/ForgotPasswordFlow/VerifyOtp';
import { ResetPassword } from '../components/screens/ForgotPasswordFlow/ResetPassword';
import { Profile } from '../components/screens/ProfileFlow/Profile';
import { EditProfile } from '../components/screens/ProfileFlow/EditProfile';
import { EditAccountDetails } from '../components/screens/ProfileFlow/EditAccountDetails';
import { ChangePassword } from '../components/screens/ProfileFlow/ChangePassword';
import { EditPersonalInformation } from '../components/screens/ProfileFlow/EditPersonalInformation';
import { EditCareerInformation } from '../components/screens/ProfileFlow/EditCareerInformation';
import { EmployeeHomeNavigatorParamList, HomeNavigator } from './HomeNavigator';
import { ViewAll, ViewAllType } from '../components/screens/ViewAll';
import { JobDetails } from '../components/screens/JobDetails';
import { AdminNavigator, AdminNavigatorParamList } from './AdminNavigator';
import { ContractorCompanyInformation } from '../components/screens/SignUpFlow/Contractor/ContractorCompanyInformation';
import {
  useGetContractorData,
  useGetCurrentUserData,
  useGetEmployeeData,
} from '../hooks/userHooks';
import { MB_accessTokenUtils, Token } from '@workingu/rnw.utils.access-token-utils';
import { UpdateApp } from '../components/helperComponents/UpdateApp';
import {
  LoadingPage,
  LOADING_PAGE_GIVE_UP_ACTION_TYPES,
} from '../components/helperComponents/LoadingPage';
import { ServerIsDownPage } from '../components/helperComponents/ServerIsDownPage';
import { utils } from '../utils/utils';
import { getVersionNumber } from '../utils/getVersionNumber/getVersionNumber';
import { useGetMinimumMobileJSVersion, useGetServerVersion } from '../hooks/serverUtilHooks';
import { Address, Application, CompanySize, UserRole } from '@wu/business';
import { SignUp } from '../components/screens/SignUpFlow/SignUp';
import { EditContractorLicense } from '../components/screens/ProfileFlow/EditContractorLicense';
import { ApplicationDetails } from '../components/screens/ApplicationDetails';
import { Notifications } from '../components/screens/Notifications';
import { ContactUs } from '../components/screens/ProfileFlow/ContactUs';
import { RecommendedEmployeeDetails } from '../components/screens/RecommendedEmployeeDetails';
import { LandingPage } from '../components/screens/LandingPage';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useQueryClient } from '@tanstack/react-query';
import ReactNativeBiometrics, { BiometryTypes } from 'react-native-biometrics';

// TODO: Hovo: Go over everything here

export type RootStackParamList = {
  MobileLandingPage: undefined;
  Notifications: undefined;
  // login Flow
  Login: undefined;
  // signUp Flow
  RoleSelector: undefined;
  SignUp: { role: UserRole };
  // - contractor
  ContractorCompanyInformation: undefined;
  ContractorLicense: { companySize?: CompanySize; trades: string[] };
  // - employee
  EmployeePersonalInformation: undefined;
  EmployeeCareerInformation: {
    aboutYou: string;
    address: Address;
    phoneNumber: string;
    profileImageFileId: string | undefined;
  };
  // forgot password flow
  ForgotPassword:
    | { passwordRecoveryToken?: PasswordRecoveryToken; recoveryFailed?: boolean }
    | undefined;
  VerifyOtp: { data: VerifyOtpData<VerifyOtpType>; type: VerifyOtpType };
  ResetPassword: { email: string; code: string };
  // profile flow
  Profile: undefined;
  ContactUs: undefined;
  EditProfile: undefined;
  EditAccountDetails: undefined;
  ChangePassword: undefined;
  EditPersonalInformation: undefined;
  EditCareerInformation: undefined;
  EditContractorLicense: undefined;
  // Home
  Home: NavigatorScreenParams<EmployeeHomeNavigatorParamList>;
  ViewAll: { type: ViewAllType };
  JobDetails: { jobId: string };
  ApplicationDetails: { applicationId: string; isReview?: boolean };
  RecommendedEmployeeDetails: { job: Application['job']; employee: Application['employee'] };
  // admin
  AdminNavigator: NavigatorScreenParams<AdminNavigatorParamList>;
  // other
  ServerRedirect: { success?: string; error?: string } | undefined;
  NotFound: undefined;
};

const Stack = createNativeStackNavigator<RootStackParamList>();

const navRef = createNavigationContainerRef<RootStackParamList>();

export const getRootNavRef = () => {
  return navRef.isReady() ? navRef : undefined;
};

const config: {
  screens: { [key in keyof RootStackParamList]: string | { path: string; screens: Object } };
} = {
  screens: {
    MobileLandingPage: 'mobileLandingPage',
    Notifications: 'notifications',
    // login Flow
    Login: 'login',
    // signUp Flow
    RoleSelector: 'roleSelector',
    SignUp: 'SignUp',
    // - contarctor
    ContractorCompanyInformation: 'contractorCompanyInformation',
    ContractorLicense: 'contractorLicense',
    // - employee
    EmployeePersonalInformation: 'employeePersonalInformation',
    EmployeeCareerInformation: 'employeeCareerInformation',
    // forgot password flow
    ForgotPassword: 'forgotPassword',
    VerifyOtp: 'verifyOtp',
    ResetPassword: 'resetPassword',
    // profile flow
    Profile: 'profile',
    ContactUs: 'contactUs',
    EditProfile: 'editProfile',
    EditAccountDetails: 'editAccountDetails',
    ChangePassword: 'changePassword',
    EditPersonalInformation: 'editPersonalInformation',
    EditCareerInformation: 'editCareerInformation',
    EditContractorLicense: 'editContractorLicense',
    // HomeNavigator
    Home: {
      path: 'home',
      screens: {
        Explore: 'explore',
        Applied: 'applied',
        MyJobs: 'myJobs',
        Profile: 'profile',
      },
    },
    ViewAll: 'viewAll',
    JobDetails: 'jobDetails',
    ApplicationDetails: 'applicationDetails',
    RecommendedEmployeeDetails: 'RecommendedEmployeeDetails',
    // Admin
    AdminNavigator: {
      path: 'admin',
      screens: {
        AppAnalytics: 'AppAnalytics',
        Employers: 'Employers',
        Employees: 'Employees',
      },
    },
    // other
    ServerRedirect: 'serverRedirect',
    NotFound: '*',
  },
};

const linking = {
  prefixes: [envs.WEBSITE_BASE_URL, envs.MOBILE_DEEP_LINK],
  config,
};

const RootNavigation = ({ onReady }: { onReady: (isReady: boolean) => void }) => {
  const [serverIsDown, setServerIsDown] = useState<boolean | undefined>();
  const { isSignedIn, signedInStatus, setSignedInStatus } = useSignedInContext();
  const rnBiometrics = new ReactNativeBiometrics();
  const date = new Date();


  const {
    data: serverVersion,
    failureCount: serverVersionRequestFailureCount,
    isLoading: isLoadingServerVersion,
    refetch: refetchServerVersion,
  } = useGetServerVersion({ retry: 2, enabled: !serverIsDown, refetchOnWindowFocus: false });
  const { data: minimumMobileJSVersion } = useGetMinimumMobileJSVersion({
    enabled: isMobileApp && !serverIsDown,
    refetchOnWindowFocus: false,
  });
  const { data: currentUserData, refetch: refetchCurrentUserData } = useGetCurrentUserData({
    enabled: isSignedIn && !serverIsDown,
  });
  const { jsVersion } = getVersionNumber();

  // TODO: Question from Hovo to Anas. We used to use isFetching here which was bad because on each fetch, navigator was getting unmounted.
  // We showed the loading screen when isFetching was true. Did we needed it for something?
  const { data: employeeData, isFetched: isEmployeeDataFetched } = useGetEmployeeData({
    enabled: isSignedIn && currentUserData?.role === UserRole.Employee,
  });
  const { data: contractorData, isFetched: isContractorDataFetched } = useGetContractorData({
    enabled: isSignedIn && currentUserData?.role === UserRole.Contractor,
  });

  const BioSignin = async () => {
    const can = await rnBiometrics.isSensorAvailable();
    date.setDate(date.getDate() + 1);
    const dateString = date.getTime().toString();
// if you have biometrics, pass for fail will log you in
    if (can) {
      try {
        rnBiometrics.simplePrompt({ promptMessage: 'Confirm fingerprint' })
          .then((resultObject) => {

            const { success } = resultObject;
            if (success) {
              AsyncStorage.setItem('refresh-date', dateString);
              setSignedInStatus(SIGNED_IN_STATUS.signedIn);
            } else {
              setSignedInStatus(SIGNED_IN_STATUS.signedOut);
            }
          })
          .catch(() => {
            console.log('biometrics failed');
            setSignedInStatus(SIGNED_IN_STATUS.signedOut);
          });
      } catch (error) {
        console.error(error, 'bio auth failed');
      }
    } else {
      setSignedInStatus(SIGNED_IN_STATUS.signedOut);
    }
  };

  // Initial check for sign in status based on stored token.
  // if you have a token, check if it's expired or not using our own timeStamp, if it's expired, bioSignin()
  useEffect(() => {
    if (signedInStatus === SIGNED_IN_STATUS.loading && !serverIsDown) {
      MB_accessTokenUtils.getAccessToken().then(async (accessToken) => {
        if (accessToken) {
          const refreshDate = await AsyncStorage.getItem('refresh-date');
          const dateToCheck = new Date(refreshDate ?? '');
          const currentDate = new Date();
          if (refreshDate && currentDate > dateToCheck) {
            setSignedInStatus(SIGNED_IN_STATUS.signedIn);

          } else {
            BioSignin();
          }
        } else {
          setSignedInStatus(SIGNED_IN_STATUS.signedOut);
        }
      });
    }
  }, [onReady, serverIsDown, setSignedInStatus, signedInStatus]);

  // Checks to see if server is down or not by pulling the backend version.
  useEffect(() => {
    if (serverVersion) {
      setServerIsDown(false);
      if (isSignedIn) {
        refetchCurrentUserData();
      }
    } else if (!serverIsDown && serverVersionRequestFailureCount >= 3) {
      setServerIsDown(true);
    }

    onReady(true);
  }, [
    onReady,
    signedInStatus,
    isSignedIn,
    serverVersion,
    serverIsDown,
    serverVersionRequestFailureCount,
    refetchCurrentUserData,
  ]);

  function renderGuestRoutes(): ReactNode {
    if (isEmployeeDataFetched && employeeData === null) {
      return (
        <>
          <Stack.Screen
            name="EmployeePersonalInformation"
            component={EmployeePersonalInformation}
          />
          <Stack.Screen name="EmployeeCareerInformation" component={EmployeeCareerInformation} />
        </>
      );
    }

    if (isContractorDataFetched && contractorData === null) {
      return (
        <>
          <Stack.Screen
            name="ContractorCompanyInformation"
            component={ContractorCompanyInformation}
          />
          <Stack.Screen name="ContractorLicense" component={ContractorLicense} />
        </>
      );
    }

    if (!isSignedIn) {
      return (
        <>
          {isMobileApp ? (
            <Stack.Screen name="MobileLandingPage" component={MobileLandingPage} />
          ) : (
            <Stack.Screen name="Home" component={LandingPage} />
          )}
          <Stack.Screen name="Login" component={Login} />
          <Stack.Screen name="RoleSelector" component={RoleSelector} />
          <Stack.Screen name="SignUp" component={SignUp} />
          <Stack.Screen name="ForgotPassword" component={ForgotPassword} />
          <Stack.Screen name="VerifyOtp" component={VerifyOtp} />
          <Stack.Screen name="ResetPassword" component={ResetPassword} />
        </>
      );
    }

    if (isSignedIn) {
      if (currentUserData?.role === UserRole.Admin) {
        return <Stack.Screen name="AdminNavigator" component={AdminNavigator} />;
      }
      return (
        <>
          <Stack.Screen name="Home" component={HomeNavigator} />
          <Stack.Screen name="Notifications" component={Notifications} />
          <Stack.Screen name="ViewAll" component={ViewAll} />
          <Stack.Screen name="JobDetails" component={JobDetails} />
          <Stack.Screen name="ApplicationDetails" component={ApplicationDetails} />
          <Stack.Screen name="RecommendedEmployeeDetails" component={RecommendedEmployeeDetails} />
          <Stack.Screen name="Profile" component={Profile} />
          <Stack.Screen name="ContactUs" component={ContactUs} />
          <Stack.Screen name="EditProfile" component={EditProfile} />
          <Stack.Screen name="EditAccountDetails" component={EditAccountDetails} />
          <Stack.Screen name="ChangePassword" component={ChangePassword} />
          <Stack.Screen name="EditPersonalInformation" component={EditPersonalInformation} />
          <Stack.Screen name="EditCareerInformation" component={EditCareerInformation} />
          <Stack.Screen name="EditContractorLicense" component={EditContractorLicense} />
        </>
      );
    }
  }

  if (serverIsDown) {
    return (
      <ServerIsDownPage
        onTryAgainPressed={refetchServerVersion}
        isLoading={isLoadingServerVersion}
      />
    );
  }

  if (
    isMobileApp &&
    minimumMobileJSVersion &&
    utils.compareVersion(jsVersion, minimumMobileJSVersion) < 0
  ) {
    return <UpdateApp version={minimumMobileJSVersion} />;
  }

  if (
    signedInStatus === SIGNED_IN_STATUS.loading ||
    (isSignedIn && currentUserData === undefined) ||
    (isSignedIn &&
      currentUserData &&
      currentUserData?.role !== UserRole.Admin &&
      employeeData === undefined &&
      contractorData === undefined) ||
    !serverVersion ||
    (isMobileApp && !minimumMobileJSVersion)
  ) {
    return (
      <LoadingPage
        giveUpActionTypes={LOADING_PAGE_GIVE_UP_ACTION_TYPES.signOut}
        hideGiveUpButton={serverIsDown === undefined}
      />
    );
  }

  return (
    <NavigationContainer linking={linking} ref={navRef}>
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
        }}
      >
        {renderGuestRoutes()}
        <Stack.Screen name="NotFound" component={NotFound} />
        <Stack.Screen name="ServerRedirect" component={ServerRedirect} />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default RootNavigation;
