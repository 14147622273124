import { MB_Button } from "@workingu/rnw.components.button";
import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import React, { useMemo, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { COLORS } from "../../../../constants/colors";
import { textStyles } from "../../../../constants/textStyles";
import { ComponentWrapper } from "../../../helperComponents/componentWrapper/ComponentWrapper";
import { Dropdown } from "../../../helperComponents/Dropdown";
import { Trades } from "../../../helperComponents/Trades";
import { useNavigation } from "@react-navigation/core";
import { UniversalScreenNavigationProp } from "../../../../typesAndInterfaces/componentProps";
import { useSignedInContext } from "../../../../context/SignedInContext";
import { CompanySize } from "@wu/business";

const ContractorCompanyInformation = () => {

    const navigation = useNavigation<UniversalScreenNavigationProp>();
    const { signOut } = useSignedInContext();

    const companySizes = useMemo(() => Object.values(CompanySize).map(size => `${size} employees`), []);
    const [companySizeIndex, setCompanySizeIndex] = useState<number>();
    const [trades, setTrades] = useState<string[]>([]);

    const companySize = companySizeIndex !== undefined ? Object.values(CompanySize)[companySizeIndex] : undefined

    const isDisabled = trades.length === 0;

    return (
        <ComponentWrapper
            mobileHeaderOptions={{ showHeader: true, showBackArrow: true, backArrowCustomAction: signOut }}
            keyboardShouldPersistTapsForScrollView="handled"
        >
            <Text style={[textStyles.smallerText, styles.topNote]}>Step 1 of 2</Text>
            <Text style={[textStyles.largeText, styles.title]}>Company Information</Text>
            <Text style={[textStyles.smallerText, styles.subTitle]}>Enter some more information about your company below</Text>
            <Text style={styles.inputTitle}>Company Size</Text>
            <Dropdown
                options={companySizes}
                option={companySizeIndex !== undefined ? companySizes[companySizeIndex] : undefined}
                onSelect={(_, index) => setCompanySizeIndex(index)}
            />
            <Text style={styles.inputTitle}>Classification/Trades <Text style={styles.required}>*</Text></Text>
            <Trades
                trades={trades}
                onChange={setTrades}
            />
            <View style={{ flexGrow: 1, minHeight: 100 }} />
            <MB_Button
                title="Continue"
                style={[styles.continueBtn]}
                textStyle={textStyles.smallerText}
                onPress={() => navigation.navigate('ContractorLicense', { companySize, trades })}
                disabled={isDisabled}
            />
        </ComponentWrapper>
    )
}

export { ContractorCompanyInformation };

const styles = StyleSheet.create({
    topNote: {
        marginBottom: 7,
        color: COLORS.bluishGrey,
        textAlign: 'left',
    },
    title: {
        color: COLORS.headerText,
        textAlign: 'left',
        letterSpacing: -0.8,
    },
    subTitle: {
        color: COLORS.greyText,
        textAlign: 'left',
        marginBottom: 27,
    },
    inputTitle: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.bodyText,
        marginTop: 15,
        marginBottom: 7,
        textAlign: 'left',
    }]),
    required: {
        color: COLORS.red,
    },
    continueBtn: {
        height: 50,
        backgroundColor: COLORS.primaryColor,
        borderRadius: 30,
        marginBottom: 47,
    },
})
