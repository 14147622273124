import { StyleSheet } from 'react-native';
import { mbTextStyles } from '@workingu/rnw.utils.style-utils';
import { COLORS } from './colors';

enum FONT_STYLES {
    ManropeBase = 'Manrope',
    Manrope_100 = 'Manrope_200',
    Manrope_100_italic = 'Manrope_200',
    Manrope_200 = 'Manrope_200',
    Manrope_200_italic = 'Manrope_200',
    Manrope_300 = 'Manrope_300',
    Manrope_300_italic = 'Manrope_300',
    Manrope_400 = 'Manrope_400',
    Manrope_400_italic = 'Manrope_400',
    Manrope_500 = 'Manrope_500',
    Manrope_500_italic = 'Manrope_500',
    Manrope_600 = 'Manrope_600',
    Manrope_600_italic = 'Manrope_600',
    Manrope_700 = 'Manrope_700',
    Manrope_700_italic = 'Manrope_700',
    Manrope_800 = 'Manrope_800',
    Manrope_800_italic = 'Manrope_800',
    Manrope_900 = 'Manrope_800',
    Manrope_900_italic = 'Manrope_800',
}

const textStyles = StyleSheet.create({
    superLargeText: mbTextStyles({
        fontFamily: FONT_STYLES.ManropeBase,
        fontSize: 44,
        fontWeight: '700',
        color: COLORS.white,
        textAlign: 'center',
    }),
    largestText: mbTextStyles({
        fontFamily: FONT_STYLES.ManropeBase,
        fontSize: 32,
        fontWeight: '700',
        color: COLORS.white,
        textAlign: 'center',
    }),
    largerText: mbTextStyles({
        fontFamily: FONT_STYLES.ManropeBase,
        fontSize: 28,
        fontWeight: '600',
        color: COLORS.white,
        textAlign: 'center',
    }),
    largeText: mbTextStyles({
        fontFamily: FONT_STYLES.ManropeBase,
        fontSize: 24,
        fontWeight: '500',
        color: COLORS.white,
        textAlign: 'center',
    }),
    normalText: mbTextStyles({
        fontFamily: FONT_STYLES.ManropeBase,
        fontSize: 16,
        fontWeight: '500',
        color: COLORS.white,
        textAlign: 'center',
    }),
    smallText: mbTextStyles({
        fontFamily: FONT_STYLES.ManropeBase,
        fontSize: 14,
        fontWeight: '500',
        color: COLORS.white,
        textAlign: 'center',
    }),
    smallerText: mbTextStyles({
        fontFamily: FONT_STYLES.ManropeBase,
        fontWeight: 'normal',
        fontSize: 12,
        color: COLORS.white,
        textAlign: 'center',
    }),
    tinyText: mbTextStyles({
        fontFamily: FONT_STYLES.ManropeBase,
        fontWeight: 'normal',
        fontSize: 10,
        color: COLORS.white,
        textAlign: 'center',
    }),
    popUpErrorMessageText: mbTextStyles({
        fontFamily: FONT_STYLES.ManropeBase,
        fontWeight: 'normal',
        fontSize: 12,
        textAlign: 'center',
        color: COLORS.errorColor,
    }),
});


export { textStyles, FONT_STYLES };
