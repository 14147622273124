import * as React from "react"
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg"

const DocIcon = ({ size = 27 }: { size?: number }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 27 27"
      fill="none"
    >
      <G clipPath="url(#clip0_1693_13335)">
        <Path
          d="M23.783 7.12l-5.537-1.583L16.664 0H5.59a2.373 2.373 0 00-2.373 2.373v22.254A2.373 2.373 0 005.59 27h15.82a2.373 2.373 0 002.373-2.373V7.119z"
          fill="#4086F4"
        />
        <Path
          d="M23.783 7.12v17.507A2.373 2.373 0 0121.41 27H13.5V0h3.164l1.582 5.537 5.537 1.582z"
          fill="#4086F4"
        />
        <Path
          d="M23.783 7.12h-5.537c-.87 0-1.582-.713-1.582-1.583V0c.206 0 .411.08.554.237l6.328 6.328a.742.742 0 01.237.554z"
          fill="#80AEF8"
        />
        <Path
          d="M18.246 12.709H8.754a.79.79 0 110-1.582h9.492a.79.79 0 110 1.582zM18.246 15.873H8.754a.79.79 0 110-1.582h9.492a.79.79 0 110 1.582zM18.246 19.037H8.754a.79.79 0 110-1.582h9.492a.79.79 0 110 1.582zM15.082 22.201H8.754a.79.79 0 110-1.582h6.328a.79.79 0 110 1.582z"
          fill="#FFF5F5"
        />
        <Path
          d="M13.5 22.201h1.582a.79.79 0 100-1.582H13.5v1.582zM13.5 19.037h4.746a.79.79 0 100-1.582H13.5v1.582zM13.5 15.873h4.746a.79.79 0 100-1.582H13.5v1.582zM13.5 12.709h4.746a.79.79 0 100-1.582H13.5v1.582z"
          fill="#FFF5F5"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_1693_13335">
          <Path fill="#fff" d="M0 0H27V27H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}

export { DocIcon }
