import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import React from "react";
import { StyleProp, StyleSheet, Text, View, ViewStyle } from "react-native";
import { COLORS } from "../../../../constants/colors";
import { textStyles } from "../../../../constants/textStyles";
import { CaseIcon } from "../../../../resources/svgComponents/CaseIcon";
import { PeopleIcon } from "../../../../resources/svgComponents/PeopleIcon";
import { WorkIcon } from "../../../../resources/svgComponents/WorkerIcon";

type IconName = "poeple" | "case" | "worker" 

const StatisticsCard = ({ iconName, title, description, style }: { iconName: IconName, title: string, description: string | number, style?: StyleProp<ViewStyle> }) => {
    return (
        <View style={[styles.container, style]}>
            <View style={styles.icon}>
                { iconName === "poeple" && <PeopleIcon color={COLORS.primaryColor} size={24} /> }
                { iconName === "case" && <CaseIcon color={COLORS.primaryColor} size={24} /> }
                { iconName === "worker" && <WorkIcon color={COLORS.primaryColor} size={24} /> }
            </View>
            <Text style={styles.title}>{title}</Text>
            <Text style={styles.description}>{description}</Text>
        </View>
    )
}

export { StatisticsCard }

const styles = StyleSheet.create({
    container: {
        width: 258,
        height: 152,
        paddingVertical: 24.5,
        paddingStart: 15,
        borderRadius: 7,
        backgroundColor: COLORS.white,
    },
    icon: {
        width: 42,
        height: 42,
        backgroundColor: '#FFF4F0',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
    },
    title: mbTextStyles([textStyles.tinyText, {
        color: COLORS.greyText,
        letterSpacing: -0.32,
        marginTop: 10,
        textAlign: 'left',
    }]),
    description: mbTextStyles([textStyles.largeText, {
        color: COLORS.headerText,
        letterSpacing: -0.8,
        marginTop: 4,
        textAlign: 'left',
    }])
})
