import { mbApplyTransparency, mbTextStyles } from '@workingu/rnw.utils.style-utils';
import React, { useCallback, useState } from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import { COLORS } from '../../../../constants/colors';
import { textStyles } from '../../../../constants/textStyles';
import { ComponentWrapper } from '../../../helperComponents/componentWrapper/ComponentWrapper';
import FontAwesome from 'react-native-vector-icons/FontAwesome';
import { MB_Button } from '@workingu/rnw.components.button';
import { useNavigation } from '@react-navigation/core';
import { UniversalScreenNavigationProp } from '../../../../typesAndInterfaces/componentProps';
import { MB_LocationPicker } from "@workingu/rnw.components.location-picker";
import { MB_utilHooks, Address as PartialAddress } from "@workingu/rnw.utils.util-hooks";
import { Address, MobileNumberSchema } from '@wu/business';
import { envs } from '../../../../../env';
import { useSignedInContext } from '../../../../context/SignedInContext';
import { MB_TextInput } from '@workingu/rnw.components.text-input';
import { MB_RESOURCE_PICKER_TYPE, MB_ResourceUploader } from '@workingu/rnw.components.resource-uploader';
import { MB_Image } from '@workingu/rnw.components.image';
import { File } from '../../../../utils/uploader';
import { useUploader } from '../../../../hooks/uploads';
import Feather from 'react-native-vector-icons/Feather';


const EmployeePersonalInformation = () => {
    const [profileImageData, setProfileImageData] = useState<File | undefined>();
    const [isResourcePickerVisible, showResourcePicker, hideResourcePicker] = MB_utilHooks.useBool();

    const navigation = useNavigation<UniversalScreenNavigationProp>();
    const { signOut } = useSignedInContext();

    const [address, setAddress] = useState<Address>();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [aboutYou, setAboutYou] = useState('');

    const isPhoneNumberValid = MobileNumberSchema.safeParse(phoneNumber).success

    const uploadProfileImage = useUploader(setProfileImageData)


    const onSelect = useCallback((selectedAddress: PartialAddress) => {

        // TODO: Anas: Why are we using these set of defaults here ? We should probably let it fail if any of the required address fields are not set.

        setAddress({
            text: selectedAddress.text ?? ' ',
            state: selectedAddress.state ?? ' ',
            city: selectedAddress.city ?? ' ',
            country: selectedAddress.country ?? ' ',
            postalCode: selectedAddress.postalCode ?? ' ',
            lat: selectedAddress.lat ?? 0,
            lng: selectedAddress.lng ?? 0,
        })
    }, []);

    const isDisabled = !address || phoneNumber === '' || !isPhoneNumberValid;

    function onContinuePressed() {
        if (address) {
            navigation.navigate('EmployeeCareerInformation', { address, aboutYou, phoneNumber, profileImageFileId: profileImageData?.id });
        }
    }

    return (
        <ComponentWrapper mobileHeaderOptions={{ showHeader: true, showBackArrow: true, backArrowCustomAction: signOut }}>

            <MB_ResourceUploader
                isVisible={isResourcePickerVisible}
                onDismiss={hideResourcePicker}
                pageTitle={'Upload Profile Photo'}
                uploadButtonTitle={'Choose'}
                resourcePickerType={MB_RESOURCE_PICKER_TYPE.image}
                uploaderFunction={uploadProfileImage}
                cameraorGalleryIconColor={COLORS.primaryColor}
                modalCloseButtonXProps={{ color: COLORS.greyText, size: 15 }}
                cameraOrGalleryModalCloseButtonXProps={{ color: COLORS.greyText, size: 15 }}
                previewColor={COLORS.primaryColor}
            />

            <Text style={[textStyles.smallerText, styles.topNote]}>Step 1 of 2</Text>
            <Text style={[textStyles.largeText, styles.title]}>Personal Information</Text>
            <Text style={[textStyles.smallerText, styles.subTitle]}>Upload a profile picture and tell us more about you.</Text>
            <View style={styles.profilePicture}>
                <TouchableOpacity style={styles.emptyBackground} onPress={showResourcePicker}>
                    {profileImageData?.uri === undefined ?
                        <FontAwesome name="camera" color={COLORS.white} size={16} />
                        :
                        <MB_Image source={profileImageData.uri} style={styles.profileImage} resizeMode='contain' disableInteraction />
                    }
                </TouchableOpacity>
            </View>
            <Text style={styles.inputTitle}>About You</Text>
            <MB_TextInput
                placeholder="Tell us more about you..."
                multiline
                style={styles.aboutYou}
                maxLength={100}
                value={aboutYou}
                onChangeText={setAboutYou}
                placeholderTextColor={COLORS.greyText}
            />
            <Text style={styles.inputTitle}>Phone number <Text style={styles.required}>*</Text></Text>
            <View>
                <View>
                    <MB_TextInput
                        value={phoneNumber}
                        onChangeText={setPhoneNumber}
                        placeholder="123-456-7890"
                        placeholderTextColor={COLORS.greyText}
                        isError={phoneNumber !== '' && !isPhoneNumberValid}
                        maxLength={12}
                    />
                    {phoneNumber !== '' && !isPhoneNumberValid &&
                        <View style={styles.alertIcon}>
                            <Feather name="alert-circle" color={COLORS.red} size={16} />
                        </View>
                    }
                </View>
                {phoneNumber !== '' && !isPhoneNumberValid && <Text style={styles.passwordError}>Phone number should be a valid USA number</Text>}
            </View>
            <Text style={styles.inputTitle}>Location <Text style={styles.required}>*</Text></Text>

            <MB_LocationPicker
                apiKey={envs.LOCATION_PICKER_API_KEY}
                onSelect={onSelect}
                arrowColor={COLORS.greyText}
                highlightColor={COLORS.greyText}
            />

            <View style={styles.space} />

            <MB_Button
                title="Continue"
                style={styles.continueBtn}
                textStyle={textStyles.smallerText}
                onPress={onContinuePressed}
                disabled={isDisabled}
            />
        </ComponentWrapper>
    );
};

export { EmployeePersonalInformation };

const styles = StyleSheet.create({
    topNote: {
        marginBottom: 7,
        color: COLORS.bluishGrey,
        textAlign: 'left',
    },
    title: {
        color: COLORS.headerText,
        textAlign: 'left',
        letterSpacing: -0.8,
    },
    subTitle: {
        color: COLORS.greyText,
        textAlign: 'left',
        marginBottom: 9,
    },
    profilePicture: {
        marginTop: 27,
        width: 98,
        height: 98,
        backgroundColor: COLORS.bluishGrey,
        alignSelf: 'center',
        borderRadius: 360,
        overflow: 'hidden',
    },
    emptyBackground: {
        backgroundColor: mbApplyTransparency(COLORS.black, 0.5),
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputTitle: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.bodyText,
        marginTop: 15,
        marginBottom: 7,
        textAlign: 'left',
    }]),
    required: {
        color: COLORS.red,
    },
    aboutYou: {
        height: 100,
    },
    space: {
        flex: 1,
    },
    continueBtn: {
        height: 50,
        backgroundColor: COLORS.primaryColor,
        borderRadius: 30,
        marginTop: 20,
    },
    profileImage: {
        width: 98,
        height: 98,
    },
    passwordError: mbTextStyles([textStyles.smallText, {
        fontSize: 11,
        color: COLORS.red,
        marginTop: 2,
        textAlign: 'left',
    }]),
    alertIcon: {
        position: 'absolute',
        right: 18,
        justifyContent: 'center',
        height: '100%',
    },
});
