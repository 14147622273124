import React, { useCallback } from "react";
import { StyleSheet, Text, View } from "react-native";
import { textStyles } from "../../../constants/textStyles";
import { LicenseEditor, LicenseEditorLicense } from "../../helperComponents/LicenseEditor";
import { ComponentWrapper } from "../../helperComponents/componentWrapper/ComponentWrapper";
import { useGetContractorData, useUpdateContractorData } from "../../../hooks/userHooks";
import { mbShowPopUp } from "@workingu/rnw.components.pop-up";
import { STRING_CONSTANTS } from "../../../constants/constants";
import { COLORS } from "../../../constants/colors";
import { mbShowToast } from "@workingu/rnw.components.toast";


const EditContractorLicense = () => {

    const { mutate: updateContractorData, isLoading } = useUpdateContractorData();
    const { data: contractorData } = useGetContractorData();


    const onLicenseChanged = useCallback((type: 'edit' | 'create' | 'delete', updatedLicenses: LicenseEditorLicense[]) => {

        updateContractorData({
            licenses: updatedLicenses.map(license => ({ number: license.number, state: license.state, fileId: license.fileData?.id })),
        }, {
            onSuccess: () => {
                mbShowToast({ text1: `License ${type === 'edit' || type === 'create' ? type + 'ed' : 'deleted'} successfully`, text2: '', position: 'bottom' })
            },
            onError: (error) => {
                mbShowPopUp({
                    title: 'Error',
                    message: error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN,
                });
            },
        })

    }, [updateContractorData]);

    return (
        <ComponentWrapper mobileHeaderOptions={{ showHeader: true, showBackArrow: true }}>
            <Text style={[textStyles.largeText, styles.title]}>Edit Contractor License</Text>
            <Text style={[textStyles.smallerText, styles.subTitle]}>Edit your license details below.</Text>
            <LicenseEditor
                initialLicenses={contractorData?.licenses.map(license => {
                    const fileData = license.file ? { id: license.file.id, name: license.file.name, size: license.file.contentLength } : undefined;
                    return { state: license.state, number: license.number, fileData };
                })}
                onLicensesChange={onLicenseChanged}
                isLoading={isLoading}
            />
            <View style={{ minHeight: 20, marginTop: 'auto' }} />
        </ComponentWrapper>
    );
}

export { EditContractorLicense };

const styles = StyleSheet.create({
    title: {
        color: COLORS.headerText,
        textAlign: 'left',
        letterSpacing: -0.8,
    },
    subTitle: {
        color: COLORS.greyText,
        textAlign: 'left',
        marginBottom: 9,
    },
});
