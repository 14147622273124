import * as React from "react"
import Svg, { Path } from "react-native-svg"

const FilterIcon = ({ color = "#fff", size = 13 }: { color?: string, size?: number }) => {
  return (
    <Svg
      width={size * (14 / 13)}
      height={size}
      viewBox="0 0 14 13"
      fill="none"
    >
      <Path
        d="M5.748 10.07H1.022M7.855 2.8h4.726"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        clipRule="evenodd"
        d="M4.545 2.76C4.545 1.788 3.75 1 2.772 1 1.794 1 1 1.788 1 2.76c0 .971.794 1.76 1.772 1.76.98 0 1.773-.789 1.773-1.76zM13 10.04c0-.972-.793-1.76-1.772-1.76-.98 0-1.773.788-1.773 1.76s.794 1.76 1.773 1.76c.979 0 1.772-.788 1.772-1.76z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  )
}

export default FilterIcon
