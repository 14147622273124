import { Job } from "@wu/business";
import { ListRenderItemInfo, StyleSheet } from "react-native";
import { JobCard } from "../../../helperComponents/JobCard";
import React, { useCallback } from "react";
import { StyleProp } from "react-native";
import { ViewStyle } from "react-native";

export const useRenderJobs = (props?: Props) => {
    const renderJobs = useCallback(({ item }: ListRenderItemInfo<Job | string>) => {

        if (typeof item === 'string') {
            // TODO: Render temp card cloadings
            return null;
        }


        return (
            <JobCard
                job={item}
                style={[styles.jobCardContainer, props]}
            />
        );
    }, [props]);


    return { renderJobs };
}

const styles = StyleSheet.create({
    jobCardContainer: {
        width: '100%',
        marginBottom: 12,
    },
});

type Props = StyleProp<ViewStyle>