import React, { ReactNode, useCallback, useMemo } from "react";
import { MB_Modal } from "@workingu/rnw.components.modal"
import { isMobile } from "@workingu/rnw.utils.device-info";
import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import { View, Text, StyleSheet, FlatList, ListRenderItemInfo, TouchableOpacity } from "react-native";
import { COLORS } from "../../constants/colors";
import { textStyles } from "../../constants/textStyles";
import { PDFIcon } from "../../resources/svgComponents/PDFIcon";
import { Contractor, ContractorLicense } from "@wu/business";
import { MB_customWindowForWeb } from "@workingu/rnw.utils.custom-window-for-web";
import { DocIcon } from "../../resources/svgComponents/DocIcon";
import { IMGIcon } from "../../resources/svgComponents/IMGIcon";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import { WithoutNullableKeys } from "../../typesAndInterfaces/typesAndInterfaces";

interface LicenseDocumentsModalProps {
    isVisible: boolean,
    onDismiss?: () => void,
    contractor: Contractor,
}

const FileIcons: Record<string, ReactNode> = {
    'application/pdf': <PDFIcon size={40} />,
    'application/msword': <DocIcon size={40} />,
    // TODO: we need icon for 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': <MaterialCommunityIcons name="google-spreadsheet" size={27} color={COLORS.statusGreen} />,
    'image/jpeg': <IMGIcon size={40} />,
    'image/png': <IMGIcon size={40} />,
}

type FullContractorLicense = WithoutNullableKeys<ContractorLicense>;

const LicenseDocumentsModal = ({ isVisible, onDismiss, contractor }: LicenseDocumentsModalProps) => {

    const renderItem = useCallback(({ item }: ListRenderItemInfo<FullContractorLicense>) => {
        return (
            <TouchableOpacity style={styles.itemContainer} onPress={() => item.file.url && MB_customWindowForWeb.open(item.file.url, item.file.name)}>
                {FileIcons[item.file.contentType]}
                <Text style={styles.itemTitle} numberOfLines={1}>{item.file.name}</Text>
            </TouchableOpacity>
        );
    }, []);

    const ItemSeparatorComponent = useCallback(() => <View style={styles.separator} />, []);
    
    const data = useMemo(() => contractor.licenses.filter(lisence => lisence.file) as FullContractorLicense[], [contractor.licenses]);

    return (
        <MB_Modal
            isVisible={isVisible}
            onDismiss={onDismiss}
            childrenWrapperStyle={styles.container}
            closeButtonXProps={{ color: COLORS.greyText, size: 15 }}
        >
            <View style={styles.titleWrapper}>
                <Text style={styles.title}>{contractor.name} Licenses</Text>
            </View>
            <FlatList
                style={styles.itemsList}
                contentContainerStyle={styles.itemsListContent}
                data={data}
                renderItem={renderItem}
                ItemSeparatorComponent={ItemSeparatorComponent}
            />
        </MB_Modal>
    )
}

export { LicenseDocumentsModal };

const styles = StyleSheet.create({
    container: {
        backgroundColor: COLORS.white,
        paddingHorizontal: 14,
        alignItems: 'stretch',
        paddingBottom: 27,
        width: 398,
        height: 441,
    },
    titleWrapper: {
        borderBottomWidth: 1,
        borderBottomColor: COLORS.lightGrey,
        position: 'absolute',
        left: 0,
        right: 0,
        top: isMobile ? 14 : 18,
        paddingLeft: 22,
        paddingBottom: 9,
    },
    title: mbTextStyles([
        textStyles.normalText, {
            color: COLORS.bodyText,
            fontWeight: '700',
            fontSize: 18,
            textAlign: 'left',
        },
    ]),
    itemContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    itemTitle: mbTextStyles([
        textStyles.smallText, {
            color: COLORS.bodyText,
            marginStart: 15,
        },
    ]),
    separator: {
        height: 27,
    },
    itemsList: {
        marginTop: 10,
    },
    itemsListContent: {
        paddingTop: 10,
    }
});