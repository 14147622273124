import { MB_Button } from '@workingu/rnw.components.button';
import { MB_accessTokenUtils } from '@workingu/rnw.utils.access-token-utils';
import { mbTextStyles } from '@workingu/rnw.utils.style-utils';
import { useNavigation } from '@react-navigation/core';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { COLORS } from '../../../constants/colors';
import { USER_QUERY_KEYS } from '../../../constants/constants';
import { textStyles } from '../../../constants/textStyles';
import { SIGNED_IN_STATUS, useSignedInContext } from '../../../context/SignedInContext';
import { useSignIn as useUserSignIn } from '../../../hooks/userHooks';
import { useSignIn as useAdminSignIn } from '../../../hooks/adminHooks';
import { UniversalScreenNavigationProp } from '../../../typesAndInterfaces/componentProps';
import { MB_TextInput } from '@workingu/rnw.components.text-input';
import { useTrimmedText } from '../../../hooks/text';

const useSignIn = (isAdmin: boolean) => isAdmin ? useAdminSignIn : useUserSignIn;

const LoginForm = ({ onlyLogin, isAdmin = false }: { onlyLogin?: boolean, isAdmin?: boolean }) => {

    const navigation = useNavigation<UniversalScreenNavigationProp>();
    const { mutate: signIn, isLoading: isSignInLoading } = useSignIn(isAdmin)()
    const { setSignedInStatus } = useSignedInContext();
    const queryClient = useQueryClient();

    const [email, setEmail] = useTrimmedText()
    const [password, setPassword] = useState('');
    const [showError, setShowError] = useState(false);

    const isSignInDisabled = !email || !password;

    const onSignIn = useCallback(() => {
        signIn({
            email,
            password,
        }, {
            onSuccess: (data) => {
                setSignedInStatus?.(SIGNED_IN_STATUS.signedIn);
                queryClient.invalidateQueries([USER_QUERY_KEYS.getCurrentUsertData]);
                MB_accessTokenUtils.setAccessToken(data);
            },
            onError: () => setShowError(true),
        })
    }, [email, password, queryClient, setSignedInStatus, signIn]);

    return (
        <View>
            <Text style={styles.inputTitle}>Email Address</Text>
            <MB_TextInput
                placeholder="Email Address"
                value={email}
                onChangeText={setEmail}
                placeholderTextColor={COLORS.greyText}
            />
            <Text style={styles.inputTitle}>Password</Text>
            <MB_TextInput
                placeholder="Password"
                secureTextEntry
                value={password}
                onChangeText={setPassword}
                placeholderTextColor={COLORS.greyText}
            />
            {showError && <Text style={[textStyles.smallerText, styles.passwordError]}>Incorrect password. Please enter the correct password.</Text>}
            {!onlyLogin && <Text style={[textStyles.smallerText, styles.forgetPassword]} onPress={() => navigation.navigate('ForgotPassword')}>Forgot password?</Text>}
            <MB_Button
                title="Sign in"
                style={styles.signInBtn}
                textStyle={textStyles.smallerText}
                disabled={isSignInDisabled || isSignInLoading}
                loading={isSignInLoading}
                onPress={onSignIn}
            />
            {!onlyLogin && <Text style={[textStyles.smallerText, styles.noAccount]}>Don’t have an account? <Text style={styles.signUpText} onPress={() => navigation.navigate('RoleSelector')}>Sign up</Text></Text>}
        </View>
    );
};

export { LoginForm };

const styles = StyleSheet.create({
    inputTitle: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.bodyText,
        marginTop: 15,
        marginBottom: 7,
        textAlign: 'left',
    }]),
    passwordError: {
        fontSize: 11,
        color: COLORS.red,
        marginTop: 2,
        textAlign: 'left',
    },
    forgetPassword: {
        color: COLORS.primaryColor,
        marginTop: 12,
        textAlign: 'right',
    },
    signInBtn: {
        marginTop: 33,
        height: 50,
        backgroundColor: COLORS.primaryColor,
        borderRadius: 30,
    },
    noAccount: {
        marginTop: 15,
        color: COLORS.bodyText,
    },
    signUpText: {
        textDecorationLine: 'underline',
        fontWeight: '400',
        color: COLORS.primaryColor,
    },
});
