import { UserRole } from '@wu/business';

import { z } from 'zod';

import { REDIRECT_REASON } from '../components/screens/ServerRedirect/serverRedirectUtils';
import { PAYMENT_PROVIDER, SERVER_ERROR_CODES } from '../constants/constants';
import {
  ImageObj,
  PasswordRecoveryTokenObj,
  TradeObj,
  UserObj,
  VideoObj,
} from '../utils/zod/zodObjects';

export type PasswordRecoveryToken = z.infer<typeof PasswordRecoveryTokenObj>;

export type Image = z.infer<typeof ImageObj>;

export type User = z.infer<typeof UserObj>;

export type Video = z.infer<typeof VideoObj>;

export type Trade = z.infer<typeof TradeObj>;

export interface ServerError {
  message?: string;
  code: SERVER_ERROR_CODES | string;
  status: 'error' | 'success';
  data?: any;
  redirectReason?: REDIRECT_REASON;
}

export interface ServerSuccess {
  status: 'error' | 'success';
  data: null | any;
  redirectReason?: REDIRECT_REASON;
}

export interface PaginationPage<T> {
  items: T[];
  page: number;
  totalItems: number;
}

export interface InfinitePaginationPage<T> {
  items: T[];
  nextOffset?: string | null;
}

export type UploadParams = {
  formData: FormData;
  uploadProgress?: (percentCompleted: number) => void;
};

type AcknowledgeRequestApple = {
  /**
   * The receipt data received from the App Store.
   */
  receiptData: string;
  /**
   * The transaction id from App Store's transaction.
   */
  transactionId?: string;
};

type AcknowledgeRequestGoogle = {
  /**
   * The purchase token received from the Play Store.
   */
  purchaseToken: string;
};

export type AcknowledgeRequest<P extends PAYMENT_PROVIDER> = {
  paymentProvider: P;
  /**
   * The purchased product's id.
   */
  productId: string;
} & (P extends PAYMENT_PROVIDER.apple ? AcknowledgeRequestApple : AcknowledgeRequestGoogle);

type ContractorSignUpRequest = {
  role: UserRole.Contractor;
  password: string;
  bioKey: string;
  code: string;
  company: {
    name: string;
    email: string;
  };
};

type EmployeeSignUpRequest = {
  role: UserRole.Employee;
  password: string;
  code: string;
  user: {
    email: string;
    firstName: string;
    lastName: string;
  };
};

export type SignUpRequest<T extends UserRole> = T extends UserRole.Contractor
  ? ContractorSignUpRequest
  : EmployeeSignUpRequest;

export const TOTAL_ITEMS_PER_PAGE = 10;

export type WithoutNullableKeys<T> = {
  [K in keyof T]-?: WithoutNullableKeys<NonNullable<T[K]>>;
};
