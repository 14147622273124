import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import React, { useState } from "react";
import { StyleSheet, Text, View, ViewStyle } from "react-native";
import { COLORS } from "../../../constants/colors";
import { textStyles } from "../../../constants/textStyles";
import { ComponentWrapper } from "../../helperComponents/componentWrapper/ComponentWrapper";
import { StatisticsCard } from "./Components/StatisticsCard";
import { useGetStatsData } from "../../../hooks/adminHooks";
import { LOADING_PAGE_GIVE_UP_ACTION_TYPES, LoadingPage } from "../../helperComponents/LoadingPage";
import { Dropdown } from "../../helperComponents/Dropdown";
import { StatsFilter } from "@wu/business";

const AppAnalytics = () => {

    const [statsFilter, setStatsFilter] = useState<StatsFilter>(StatsFilter.ThisWeek);
    
    const { data: stats, isLoading } = useGetStatsData({ filter: statsFilter });

    if (isLoading || !stats) {
        return (
            <LoadingPage giveUpActionTypes={LOADING_PAGE_GIVE_UP_ACTION_TYPES.signOut} />
        );
    }

    const { users, employees, employers, jobs } = stats;

    return (
        <ComponentWrapper
            innerContainerStyle={styles.innerContainer}
            containerStyle={styles.container}
            scrollViewStyle={styles.scrollView}
            wrapInScrollView
            disableLinearGradient
        >
            <View style={styles.content}>
                <View style={styles.titleContainer}>
                    <Text style={styles.title}>App Analytics</Text>
                    <Dropdown
                        options={Object.values(StatsFilter)}
                        option={statsFilter}
                        onSelect={(value) => setStatsFilter(value as StatsFilter)}
                        style={styles.dropdownContainer}
                        dropdownStyle={styles.dropdown}
                    />
                </View>
                <View style={styles.cards}>
                    <StatisticsCard iconName="poeple" title="TOTAL NO. OF USERS" description={users} style={styles.card} />
                    <StatisticsCard iconName="case" title="NO. OF EMPLOYERS" description={employers} style={styles.card} />
                    <StatisticsCard iconName="worker" title="NO. OF EMPLOYEES" description={employees} style={styles.card} />
                    <StatisticsCard iconName="poeple" title="NO. OF BOOKED JOBS" description={jobs} style={styles.card} />
                </View>
            </View>
        </ComponentWrapper>
    )
}

export { AppAnalytics };

const styles = StyleSheet.create({
    innerContainer: {
        backgroundColor: COLORS.greyBG,
        flex: 1,
        maxWidth: 'auto',
        alignSelf: 'stretch',
    },
    container: {
        minWidth: 1000,
    },
    scrollView: {
        overflowX: 'visible',
    } as ViewStyle,
    content: {
        maxWidth: 1200,
    },
    titleContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    title: mbTextStyles([textStyles.largeText, {
        color: COLORS.bodyText,
        textAlign: 'left',
    }]),
    dropdownContainer: {
        width: 120,
    },
    dropdown: {
        height: 120,
    },
    cards: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    card: {
        marginTop: 30,
    }
});
