import React, { useCallback } from "react";
import { ScrollView, StyleSheet, Text } from "react-native";
import Feather from "react-native-vector-icons/Feather";
import { MB_Button } from "@workingu/rnw.components.button";
import { Application } from "@wu/business"
import { useDownload } from "../../../hooks/download";
import { COLORS } from "../../../constants/colors";
import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import { textStyles } from "../../../constants/textStyles";


type CareerInformationProps = {
    employee?: Application['employee'],
    job?: Application['job']
}

const CareerInformation = ({ employee, job }: CareerInformationProps) => {
    const { download, isDownloading, } = useDownload();

    const onDownloadResume = useCallback(() => {
        if (!employee?.resume) {
            return;
        }
        download(employee?.resume.id);
    }, [download, employee?.resume]);



    if (!employee || !job) {
        return null;
    }

    return (
        <ScrollView contentContainerStyle={styles.tabScreenContainer}>
            <Text style={styles.sectionTitle}>Trades</Text>
            <Text style={styles.sectionText}>
                {employee.trades.join(', ')}
            </Text>
            <Text style={[styles.sectionTitle, styles.top]}>Skills</Text>
            <Text style={styles.sectionText}>
                {employee.skills}
            </Text>
            <Text style={[styles.sectionTitle, styles.top]}>References</Text>
            <Text style={styles.sectionText}>{employee.references}</Text>
            <MB_Button
                title="Download resume"
                leftElement={<Feather name="download" color={COLORS.primaryColor} size={16} />}
                style={styles.download}
                textStyle={styles.downloadText}
                loading={isDownloading}
                activityIndicatorProps={{ color: COLORS.primaryColor }}
                onPress={onDownloadResume}
            />
        </ScrollView>
    );
};

export { CareerInformation };

const styles = StyleSheet.create({
    tabScreenContainer: {
        paddingHorizontal: 20,
        paddingVertical: 20,
    },
    sectionTitle: mbTextStyles([textStyles.smallText, {
        fontSize: 13,
        color: COLORS.bodyText,
        textAlign: 'left',
        fontWeight: '700',
    }]),
    sectionText: mbTextStyles([textStyles.smallerText, {
        color: COLORS.bodyText,
        textAlign: 'left',
        marginTop: 2,
    }]),
    download: {
        backgroundColor: 'transparent',
        borderColor: COLORS.primaryColor,
        borderRadius: 5,
        marginTop: 12,
        borderWidth: 1,
        width: 142,
        height: 33,
    },
    downloadText: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.primaryColor,
    }]),
    top: {
        marginTop: 12,
    },
})
