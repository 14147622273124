import React, { useCallback, useMemo, useState } from 'react';
import { MB_ModalMobileBottom } from "@workingu/rnw.components.modal";
import { StyleSheet, Text, View } from "react-native";
import { COLORS } from '../../constants/colors';
import { mbTextStyles } from '@workingu/rnw.utils.style-utils';
import { textStyles } from '../../constants/textStyles';
import { MB_TextInput } from '@workingu/rnw.components.text-input';
import { DateInput } from './DateInput';
import { TimeInput } from './TimeInput';
import { Address, CreateJobRequestSchema, AddressSchema, Job, UpdateJobRequestSchema } from '@wu/business';
import { Address as LocationPickerAddress } from '@workingu/rnw.utils.util-hooks';
import { MB_LocationPicker } from '@workingu/rnw.components.location-picker';
import { envs } from '../../../env';
import { Slider } from '@miblanchard/react-native-slider';
import { useCreateJob, useUpdateJob } from '../../hooks/jobsHooks';
import { MB_Button } from '@workingu/rnw.components.button';
import { mbShowPopUp } from '@workingu/rnw.components.pop-up';
import { STRING_CONSTANTS } from '../../constants/constants';
import { mbShowToast } from '@workingu/rnw.components.toast';
import { ZodError } from 'zod';

import { delay } from "@wu/utils"
import { Trades } from './Trades';

// TODO: Logic for hourly rate picker


export const getErrorMessage = (error: unknown) => {
    if ((error as ZodError).name === 'ZodError') {
        return (error as ZodError).issues[0].message
    }

    return error instanceof Error ? error.message : STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN;

}

const CreateJobsPopUpContent = ({ onDismiss, job, shouldUpdateJob }: { onDismiss: () => void, job?: Job, shouldUpdateJob?: boolean }) => {
    const [jobTitle, setJobTitle] = useState(job?.title ?? '')
    const [jobDescription, setJobDescription] = useState(job?.description ?? '')
    const [requiredSkills, setRequiredSkills] = useState(job?.skills ?? '')
    const [address, setAddress] = useState<Address | undefined>(job?.address);
    const [hourlyRate, setHourlyRate] = useState(job?.hourlyRate ?? [15, 50]);
    const [startDate, setStartDate] = useState<Date | undefined>(job?.startDate ? new Date(job.startDate) : undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(job?.endDate ? new Date(job.endDate) : undefined);
    const [startDateTime, setStartDateTime] = useState<Date | undefined>(job?.startTime ? new Date(job.startTime) : undefined);
    const [endDateTime, setEndDateTime] = useState<Date | undefined>(job?.endTime ? new Date(job.endTime) : undefined);
    const [trades, setTrades] = useState<string[]>(job?.trades ?? []);

    const isUpdate = shouldUpdateJob && Boolean(job);


    const schema = useMemo(() => isUpdate ? UpdateJobRequestSchema : CreateJobRequestSchema, [isUpdate]);
    const id = job?.id;

    const createJobProps = useMemo(() => ({
        id,
        title: jobTitle,
        description: jobDescription,
        address: address,
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
        startTime: startDateTime?.toISOString(),
        endTime: endDateTime?.toISOString(),
        hourlyRate,
        skills: requiredSkills,
        trades,
    }), [jobTitle, jobDescription, requiredSkills, address, hourlyRate, startDate, endDate, startDateTime, endDateTime, trades, id]);


    const validation = useMemo(() => schema.safeParse(createJobProps), [createJobProps, schema]);


    function getAddressValue(addr: Address | undefined) {
        if (!addr) {
            return undefined;
        }

        return `${addr.text}, ${addr.city}, ${addr.state}, ${addr.postalCode}`;
    }

    const { mutate: createJob, isLoading: isCreateJobInProgress } = useCreateJob();
    const { mutate: updateJob, isLoading: isUpdateJobInProgress } = useUpdateJob();

    const isLoading = isCreateJobInProgress || isUpdateJobInProgress;
    const mutate = useMemo(() => isUpdate ? updateJob : createJob, [isUpdate, updateJob, createJob]);
    const popupText = isUpdate ? `Job updated successfully` : `Job created successfully`;



    const onSelectAddress = useCallback((selectedAddress: LocationPickerAddress) => {
        const addressValidation = AddressSchema.safeParse(selectedAddress);
        if (!addressValidation.success) {
            mbShowPopUp({ title: STRING_CONSTANTS.ERROR, message: STRING_CONSTANTS.INVALID_ADDRESS });
            // TODO: We should probably change the Address name that is coming from util-hooks. Also, why is it coming from util hooks and not from LocationPicker?
            return;
        }

        setAddress(addressValidation.data)
    }, []);

    function onCreatePressed() {
        if (!address || !startDate || !endDate || !startDateTime || !endDateTime) {
            mbShowPopUp({ title: STRING_CONSTANTS.ERROR, message: STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN });
            return;
        }

        if (startDate.getTime() > endDate.getTime()) {
            mbShowPopUp({ title: STRING_CONSTANTS.ERROR, message: STRING_CONSTANTS.ERROR_START_DATE_BEFORE_END });
            return;
        } else if (startDateTime.getTime() >= endDateTime.getTime()) {
            mbShowPopUp({ title: STRING_CONSTANTS.ERROR, message: STRING_CONSTANTS.ERROR_START_TIME_BEFORE_END });
            return;
        }

        if (!validation.success) {
            mbShowPopUp({ title: STRING_CONSTANTS.ERROR, message: getErrorMessage(validation.error) });
            return;
        }

        // @ts-ignore
        mutate(validation.data, {
            onSuccess: () => {
                onDismiss()
                delay(500).then(() => mbShowToast({ text1: 'Success', text2: popupText, position: 'bottom', bottomOffset: 120 }))
            },
            onError: (error: Error) => {
                console.log('Error when trying to create a job', error);
                const message = getErrorMessage(error);

                mbShowPopUp({ title: STRING_CONSTANTS.ERROR, message });
            },
        })
    }

    // TODO: it might be good to disable validation from the schema
    const isDisabled = !validation.success && (validation.error.issues.length === 1 ? validation.error.issues[0].message !== 'Hourly rate minimum and maximum difference must not be greater than $5' : true);

    return (
        <>
            <Text style={styles.title}>{isUpdate ? 'Edit Job Posting' : 'Create Job Posting'}</Text>
            <Text style={styles.subtitle}>{isUpdate ? 'Edit the details of this job in the form below.' : 'Fill the form below with the details of the job.'}</Text>

            <MB_TextInput
                title='Job Name '
                titleStyle={styles.inputTitle}
                style={styles.textInputStyle}
                styleAdditionsFocused={styles.textInputStyleWhenFocused}
                textInputStyle={styles.textInputText}
                value={jobTitle}
                onChangeText={setJobTitle}
                showRequiredAsteriks
                placeholderTextColor={COLORS.greyText}
                maxLength={200}
            />

            <MB_TextInput
                title='Job Description '
                titleStyle={styles.inputTitle}
                style={[styles.textInputStyle, { height: 100 }]}
                styleAdditionsFocused={styles.textInputStyleWhenFocused}
                textInputStyle={styles.textInputText}
                value={jobDescription}
                onChangeText={setJobDescription}
                multiline
                showRequiredAsteriks
                placeholderTextColor={COLORS.greyText}
                maxLength={500}
            />

            <Text style={[styles.inputTitle, styles.top]}>Jobsite Address <Text style={styles.required}>*</Text></Text>
            <MB_LocationPicker
                value={getAddressValue(address)}
                apiKey={envs.LOCATION_PICKER_API_KEY}
                onSelect={onSelectAddress}
                containerStyle={styles.topTitle}
                arrowColor={COLORS.greyText}
                highlightColor={COLORS.greyText}
                zIndex={2}
            />

            <View style={styles.horizontalContainer}>
                <View style={{ flex: 1, marginEnd: 4 }}>
                    <Text style={styles.inputTitle}>Start Date <Text style={styles.required}>*</Text></Text>
                    <DateInput
                        containerStyle={styles.datePickerContainer}
                        minDate={new Date()}
                        date={startDate}
                        onDateChange={setStartDate}
                    />

                    <Text style={styles.inputTitle}>Start Time <Text style={styles.required}>*</Text></Text>
                    <TimeInput
                        containerStyle={{ marginTop: 8 }}
                        onTimeChanged={setStartDateTime}
                        time={startDateTime}
                    />
                </View>
                <View style={{ flex: 1, marginStart: 4 }}>
                    <Text style={styles.inputTitle}>End Date <Text style={styles.required}>*</Text></Text>
                    <DateInput
                        containerStyle={styles.datePickerContainer}
                        minDate={startDate ?? new Date()}
                        date={endDate}
                        onDateChange={setEndDate}
                    />

                    <Text style={styles.inputTitle}>End Time <Text style={styles.required}>*</Text></Text>
                    <TimeInput
                        containerStyle={{ marginTop: 8 }}
                        onTimeChanged={setEndDateTime}
                        time={endDateTime}
                    />
                </View>
            </View>

            <Text style={styles.inputTitle}>Classification/Trades <Text style={styles.required}>*</Text></Text>
            <Trades
                trades={trades}
                onChange={setTrades}
                zIndex={1}
                style={styles.topTitle}
            />

            <MB_TextInput
                title='Required Skills '
                titleStyle={styles.inputTitle}
                style={[styles.textInputStyle, { height: 100 }]}
                styleAdditionsFocused={styles.textInputStyleWhenFocused}
                textInputStyle={styles.textInputText}
                value={requiredSkills}
                onChangeText={setRequiredSkills}
                multiline
                showRequiredAsteriks
                placeholderTextColor={COLORS.greyText}
                maxLength={500}
            />

            <Text style={styles.inputTitle}>Hourly Rate (max range gap of $5) <Text style={styles.required}>*</Text></Text>
            <View style={{}}>
                <Slider
                    value={hourlyRate}
                    onValueChange={setHourlyRate}
                    minimumValue={15}
                    maximumValue={50}
                    trackStyle={{ backgroundColor: COLORS.lightOrange }}
                    renderBelowThumbComponent={(index: number) => <Text style={[textStyles.smallerText, { color: COLORS.bodyText, marginLeft: -10 }]}>${hourlyRate[index]}</Text>}
                    renderThumbComponent={() => <View style={{ width: 20, height: 20, backgroundColor: COLORS.white, borderWidth: 4, borderRadius: 360, borderColor: COLORS.primaryColor }} />}
                    step={5}
                    maximumTrackTintColor={COLORS.primaryColor}
                    minimumTrackTintColor={COLORS.primaryColor}
                />
            </View>

            <MB_Button
                title={isUpdate ? 'Save changes' : 'Post job'}
                style={styles.createJobButton}
                onPress={onCreatePressed}
                loading={isLoading}
                disabled={isDisabled}
            />
        </>
    );

};



const CreateJobsPopUp = ({ isVisible, onDismiss, job, shouldUpdateJob }: { isVisible: boolean, onDismiss: () => void, job?: Job, shouldUpdateJob?: boolean }) => {

    return (
        <MB_ModalMobileBottom
            isVisible={isVisible}
            onDismiss={onDismiss}
            childrenWrapperStyle={styles.container}
            closeButtonXProps={{ color: COLORS.primaryColor, size: 15 }}
        >
            <CreateJobsPopUpContent
                onDismiss={onDismiss}
                job={job}
                shouldUpdateJob={shouldUpdateJob}
            />
        </MB_ModalMobileBottom>
    );
};

export { CreateJobsPopUp };

const styles = StyleSheet.create({
    container: {
        backgroundColor: COLORS.white,
        paddingHorizontal: 20,
        alignItems: 'stretch',
        paddingBottom: 32,
    },
    title: mbTextStyles([
        textStyles.largeText, {
            fontSize: 21,
            color: COLORS.black,
            fontWeight: '800',
            alignSelf: 'flex-start',
        }
    ]),
    subtitle: mbTextStyles([
        textStyles.smallerText, {
            color: COLORS.greyText,
            alignSelf: 'flex-start',
        }
    ]),
    inputTitle: mbTextStyles([
        textStyles.smallerText, {
            textAlign: 'left',
            color: COLORS.bodyText,
            fontSize: 11,
            alignSelf: 'flex-start',
        },
    ]),
    top: {
        marginTop: 15,
    },
    topTitle: {
        marginTop: 8,
    },
    textInputStyle: {
        marginTop: 24,
    },
    textInputStyleWhenFocused: {
        borderColor: COLORS.primaryColor,
    },
    horizontalContainer: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginTop: 16,
        alignItems: 'stretch',
        marginBottom: 16
    },
    datePickerContainer: {
        marginTop: 8,
        marginBottom: 16,
    },
    required: {
        color: COLORS.red,
    },
    createJobButton: {
        backgroundColor: COLORS.primaryColor,
        marginTop: 38,
        borderRadius: 30,
    },
    textInputText: mbTextStyles([
        textStyles.smallText, {
            fontSize: 11,
            color: COLORS.bodyText,
            textAlign: 'left',
        }
    ]),
})