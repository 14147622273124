import { MB_Button } from '@workingu/rnw.components.button';
import { mbShowPopUp } from '@workingu/rnw.components.pop-up';
import { mbTextStyles } from '@workingu/rnw.utils.style-utils';
import { useNavigation } from '@react-navigation/core';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { COLORS } from '../../../constants/colors';
import { STRING_CONSTANTS } from '../../../constants/constants';
import { textStyles } from '../../../constants/textStyles';
import { useRequestSignUpCode } from '../../../hooks/userHooks';
import { UniversalScreenNavigationProp } from '../../../typesAndInterfaces/componentProps';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { VerifyOtpType } from '../ForgotPasswordFlow/VerifyOtp';
import { SignUpProps } from '../../../typesAndInterfaces/componentProps';
import { utils } from '../../../utils/utils';
import { MB_passwordUtils } from '@workingu/rnw.utils.password-utils';
import { MB_TextInput, MB_TextInputToolTipPortal, MB_TextInputToolTipPortalRef } from '@workingu/rnw.components.text-input';
import { MB_PasswordToolTip, TOOLTIP_ARROW_DIRECTION, TOOLTIP_POSITION } from '@workingu/rnw.components.tool-tip';
import { useTextLimiter, useTrimmedText } from '../../../hooks/text';
import { mbShowToast } from '@workingu/rnw.components.toast';
import { UserRole } from '@wu/business';

const SignUp = ({ route }: SignUpProps) => {

    const navigation = useNavigation<UniversalScreenNavigationProp>();
    const { mutate: requestSignUpCode, isLoading: isRequestSignUpLoading } = useRequestSignUpCode();
    const role = route.params.role;

    const [name, setName] = useTextLimiter(100)
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useTrimmedText()
    const [password, setPassword] = useState('');
    const [passwordErrors, setPasswordErrors] = useState<{ message: string, isError: boolean }[]>(utils.generatePasswordErrorArray(MB_passwordUtils.validatePassword('').errorArray, true));
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordErrors, setConfirmPasswordErrors] = useState<{ message: string, isError: boolean }[]>(utils.generatePasswordErrorArray(MB_passwordUtils.validatePassword('').errorArray, true));
    const portalRef = useRef<MB_TextInputToolTipPortalRef>(null);
    const [isEmailAlreadyInUseErrorVisible, showEmailAlreadyInUseError] = useState(false);

    const onPasswordChange = useCallback((text: string) => {
        setPasswordErrors(utils.generatePasswordErrorArray(MB_passwordUtils.validatePassword(text).errorArray, text === confirmPassword));
        setConfirmPasswordErrors(utils.generatePasswordErrorArray(MB_passwordUtils.validatePassword(confirmPassword).errorArray, text === confirmPassword));
        setPassword(text);
    }, [confirmPassword]);

    const onConfirmPasswordChange = useCallback((text: string) => {
        setConfirmPasswordErrors(utils.generatePasswordErrorArray(MB_passwordUtils.validatePassword(text).errorArray, text === password));
        setPasswordErrors(utils.generatePasswordErrorArray(MB_passwordUtils.validatePassword(password).errorArray, text === password));
        setConfirmPassword(text);
    }, [password]);

    const errorMessage = useMemo(() => {
        if(!confirmPassword) {
            return undefined;
        }

        const validatePassword = MB_passwordUtils.validatePassword(password);
        if (validatePassword.errorMessage) {
            return validatePassword.errorMessage;
        }

        const validateRepeatPassword = MB_passwordUtils.validatePassword(confirmPassword);
        if (validateRepeatPassword.errorMessage) {
            return validateRepeatPassword.errorMessage;
        }

        if (password !== confirmPassword) {
            return STRING_CONSTANTS.PASSWORDS_DO_NOT_MATCH;
        }
    }, [confirmPassword, password]);
    const isEmployee = role === UserRole.Employee;
    const isSignUpDisabled = (isEmployee ? !firstName || !lastName : !name) || !email || errorMessage !== undefined;
    const signUpName = isEmployee ? `${firstName} ${lastName}` : name;

    const onSignUp = useCallback(() => {
        requestSignUpCode({ email, name: signUpName }, {
            onError: (error) => {
                if (error.message?.includes('Email already in use')) {
                    showEmailAlreadyInUseError(true);
                    mbShowToast({ type: 'error', text1: 'Email already in use', position: 'bottom' });
                } else {
                    showEmailAlreadyInUseError(false);
                    mbShowPopUp({
                        title: 'Error',
                        message: error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN,
                    });
                }
            },
            onSuccess: () => {
                navigation.navigate('VerifyOtp', role === UserRole.Employee ? {
                    type: VerifyOtpType.SignUpEmployee,
                    data: {
                        email,
                        firstName,
                        lastName,
                        password,
                    }
                } : {
                    type: VerifyOtpType.SignUpContractor,
                    data: {
                        email,
                        name,
                        password,
                    }
                })
            }
        });
    }, [requestSignUpCode, email, navigation, role, firstName, lastName, password, name, signUpName]);

    return (
        <ComponentWrapper mobileHeaderOptions={{ showHeader: true, showBackArrow: true }}>
            <Text style={[textStyles.largeText, styles.title]}>Sign Up</Text>
            <Text style={[textStyles.smallerText, styles.subTitle]}>Fill the form below to create your {role.toLocaleLowerCase()} account.</Text>
            {role === UserRole.Employee ?
                <View style={styles.row}>
                    <View style={styles.inputContainer}>
                        <Text style={styles.inputTitle}>First Name <Text style={styles.required}>*</Text></Text>
                        <MB_TextInput
                            placeholder="First Name"
                            value={firstName}
                            onChangeText={setFirstName}
                            placeholderTextColor={COLORS.greyText}
                        />
                    </View>
                    <View style={{ width: 7 }} />
                    <View style={styles.inputContainer}>
                        <Text style={styles.inputTitle}>Last Name <Text style={styles.required}>*</Text></Text>
                        <MB_TextInput
                            placeholder="Last Name"
                            value={lastName}
                            onChangeText={setLastName}
                            placeholderTextColor={COLORS.greyText}
                        />
                    </View>
                </View>
                :
                <>
                    <Text style={styles.inputTitle}>Company Name <Text style={styles.required}>*</Text></Text>
                    <MB_TextInput
                        placeholder="Company name"
                        value={name}
                        onChangeText={setName}
                        placeholderTextColor={COLORS.greyText}
                    />
                </>
            }
            <Text style={styles.inputTitle}>{role === UserRole.Employee ? "Email Address" : "Company Email Address"} <Text style={styles.required}>*</Text></Text>
            <MB_TextInput
                placeholder={role === UserRole.Employee ? "Email address" : "Company email address"}
                value={email}
                onChangeText={setEmail}
                placeholderTextColor={COLORS.greyText}
                isError={isEmailAlreadyInUseErrorVisible}
            />
            {isEmailAlreadyInUseErrorVisible && <Text style={[styles.passwordNote, styles.passwordNoteError]}>Email already in use</Text>}
            <Text style={styles.inputTitle}>Password <Text style={styles.required}>*</Text></Text>
            <MB_TextInput
                placeholder="Create a password"
                value={password}
                onChangeText={onPasswordChange}
                secureTextEntry
                toolTipData={passwordErrors && portalRef ? {
                    showOnFocus: true,
                    toolTipPosition: TOOLTIP_POSITION.top,
                    moveBy: {
                        x: 0,
                        y: -60,
                    },
                    toolTipElement: <MB_PasswordToolTip errorArray={passwordErrors} arrowDirection={TOOLTIP_ARROW_DIRECTION.down} arrowColor={COLORS.greyBG} containerStyle={styles.tooltip} />,
                    portalRef: portalRef,
                } : undefined}
                placeholderTextColor={COLORS.greyText}
                isError={password !== '' && passwordErrors.some(error => error.isError)}
            />
            <Text style={styles.inputTitle}>Confirm Password <Text style={styles.required}>*</Text></Text>
            <MB_TextInput
                placeholder="Confirm password"
                value={confirmPassword}
                onChangeText={onConfirmPasswordChange}
                secureTextEntry
                toolTipData={confirmPasswordErrors && portalRef ? {
                    showOnFocus: true,
                    toolTipPosition: TOOLTIP_POSITION.top,
                    moveBy: {
                        x: 0,
                        y: -60,
                    },
                    toolTipElement: <MB_PasswordToolTip errorArray={confirmPasswordErrors} arrowDirection={TOOLTIP_ARROW_DIRECTION.down} arrowColor={COLORS.greyBG} containerStyle={styles.tooltip} />,
                    portalRef: portalRef,
                } : undefined}
                placeholderTextColor={COLORS.greyText}
                isError={confirmPassword !== '' && confirmPasswordErrors.some(error => error.isError)}
            />
            {errorMessage && <Text style={[styles.passwordNote, styles.passwordNoteError]}>{errorMessage}</Text>}
            <Text style={[textStyles.smallerText, styles.note]}>By signing up you confirm you agree to our <Text style={styles.important}>Terms of Service</Text> and <Text style={styles.important}>Privacy Policy.</Text></Text>
            <MB_Button
                title="Sign up"
                style={styles.signUpBtn}
                textStyle={textStyles.smallerText}
                disabled={isSignUpDisabled || isRequestSignUpLoading}
                loading={isRequestSignUpLoading}
                onPress={onSignUp}
            />
            <Text style={styles.signInText} onPress={() => navigation.navigate('Login')}>Already have an account? <Text style={styles.important}>Sign in</Text></Text>
            <MB_TextInputToolTipPortal MB_Ref={portalRef} />
        </ComponentWrapper>
    );
};

export { SignUp };

const styles = StyleSheet.create({
    title: {
        color: COLORS.headerText,
        textAlign: 'left',
        letterSpacing: -0.8,
    },
    subTitle: {
        color: COLORS.greyText,
        textAlign: 'left',
        marginBottom: 9,
    },
    row: {
        flexDirection: 'row',
        width: '100%',
    },
    inputContainer: {
        flex: 1,
    },
    inputTitle: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.bodyText,
        marginTop: 15,
        marginBottom: 7,
        textAlign: 'left',
    }]),
    required: {
        color: COLORS.red,
    },
    passwordNote: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.bodyText,
        marginTop: 5,
        textAlign: 'left',
    }]),
    passwordNoteError: {
        color: COLORS.red,
    },
    note: {
        marginTop: 45,
        marginBottom: 16,
        fontSize: 11,
        color: COLORS.bodyText,
        width: 246,
        alignSelf: 'center',
    },
    important: {
        color: COLORS.primaryColor,
        textDecorationLine: 'underline',
    },
    signUpBtn: {
        height: 50,
        backgroundColor: COLORS.primaryColor,
        borderRadius: 30,
    },
    signInText: mbTextStyles([textStyles.smallerText, {
        marginTop: 15,
        color: COLORS.bodyText,
    }]),
    tooltip: {
        marginTop: -150,
        backgroundColor: COLORS.greyBG,
    }
});
