import React from "react";
import { MB_Button } from "@workingu/rnw.components.button";
import { MB_ModalMobileBottom } from "@workingu/rnw.components.modal";
import { useCallback } from "react";
import { StyleSheet, Text } from "react-native";
import { useDeclineApplication } from "../../hooks/jobsHooks";
import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import { COLORS } from "../../constants/colors";
import { textStyles } from "../../constants/textStyles";
import { mbShowToast } from "@workingu/rnw.components.toast";
import { mbShowPopUp } from "@workingu/rnw.components.pop-up";
import { STRING_CONSTANTS } from "../../constants/constants";
import { delay } from "@wu/utils";


type DeclineApplicationModalProps = {
    isVisible: boolean,
    id: string
    onDismiss: () => void,
}

const DeclineApplicationModal = ({ isVisible, onDismiss, id }: DeclineApplicationModalProps) => {

    const { mutate: declineApplication, isLoading: isDeclineLoading } = useDeclineApplication()
    const decline = useCallback(() => {
        declineApplication({ id }, {
            onSuccess: () => {

                onDismiss();
                delay(1000)
                .then(() => mbShowToast({ text1: `Application declined`, text2: '', position: 'bottom' }));
                
                
            },
            onError: (error) => {
                mbShowPopUp({
                    title: 'Error',
                    message: error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN,
                });
            }
        })
    }, [id, declineApplication, onDismiss]);

    return (
        <MB_ModalMobileBottom
            isVisible={isVisible}
            onDismiss={onDismiss}
            childrenWrapperStyle={styles.declineApplicationContainer}
        >
            <Text style={styles.declineApplicationTitle}>Decline application?</Text>
            <Text style={styles.declineApplicationDescription}>Are you sure you want to decline this application? Please note that this action cannot be undone.</Text>
            <MB_Button
                title="Decline"
                style={styles.declineBtn}
                textStyle={styles.declineBtnText}
                onPress={decline}
                loading={isDeclineLoading}
                disabled={isDeclineLoading}
            />
            <MB_Button
                title="Cancel"
                style={styles.cancelBtn}
                textStyle={styles.cancelBtnText}
                onPress={onDismiss}
                disabled={isDeclineLoading}
            />
        </MB_ModalMobileBottom>
    )
};

export { DeclineApplicationModal };

const styles = StyleSheet.create({
    declineApplicationContainer: {
        backgroundColor: COLORS.white,
        paddingHorizontal: 20,
        alignItems: 'stretch',
        paddingBottom: 32,
        paddingTop: 20,
    },
    declineApplicationTitle: mbTextStyles([textStyles.largeText, {
        fontSize: 21,
        color: COLORS.headerText,
        textAlign: 'left',
        fontWeight: '800',
    }]),
    declineApplicationDescription: mbTextStyles([textStyles.smallerText, {
        color: COLORS.bodyText,
        textAlign: 'left',
        marginTop: 3,
    }]),
    declineBtn: {
        backgroundColor: COLORS.red,
        borderRadius: 30,
        height: 50,
        marginTop: 25,
    },
    declineBtnText: {
        color: COLORS.white
    },
    cancelBtn: {
        backgroundColor: 'transparent',
        borderRadius: 30,
        height: 50,
        borderWidth: 1,
        borderColor: COLORS.primaryColor,
        marginTop: 10,
    },
    cancelBtnText: {
        color: COLORS.primaryColor,
    }
})
