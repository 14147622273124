import React from 'react';
import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import { MB_LinearGradient } from '@workingu/rnw.components.linear-gradient';
import { COLORS } from '../../constants/colors';



export const LinearGradientBackground = React.memo(({ locations, style }: { locations?: number[], style?: StyleProp<ViewStyle> }) => {
    return (
        <MB_LinearGradient
            colors={[COLORS.darkPurpleLight, COLORS.englishViolet]}
            start={{ x: 0, y: 0 }} end={{ x: 0, y: 1 }}
            locations={locations ?? [0.64, 1]}
            style={[styles.linearGradientStyle, style]}
        />
    );
});

const styles = StyleSheet.create({
    linearGradientStyle: {
        width: '100%',
        height: 530,
        position: 'absolute',
    },
});

