import * as React from "react"
import Svg, { Path } from "react-native-svg"

const DollarIcon = ({ color, size = 18 }: { color?: string, size?: number }) => {
    return (
        <Svg
            width={size * (19 / 18)}
            height={size}
            viewBox="0 0 19 18"
            fill="none"
        >
            <Path
                d="M15.857 2.643A8.936 8.936 0 009.5 0a8.936 8.936 0 00-6.357 2.643A8.936 8.936 0 00.5 9c0 2.395.939 4.652 2.643 6.357A8.936 8.936 0 009.5 18a8.936 8.936 0 006.357-2.643A8.936 8.936 0 0018.5 9a8.936 8.936 0 00-2.643-6.357zM9.5 16.945c-4.381 0-7.945-3.564-7.945-7.945S5.119 1.055 9.5 1.055 17.445 4.619 17.445 9s-3.564 7.945-7.945 7.945z"
                fill={color ?? "#333"}
                stroke="#FFF4F0"
                strokeWidth={0.4}
            />
            <Path
                d="M9.5 2.145A6.863 6.863 0 002.645 9 6.863 6.863 0 009.5 15.855 6.863 6.863 0 0016.355 9 6.863 6.863 0 009.5 2.145zm0 12.656A5.807 5.807 0 013.7 9c0-3.199 2.601-5.8 5.8-5.8S15.3 5.8 15.3 9s-2.601 5.8-5.8 5.8z"
                fill={color ?? "#333"}
                stroke="#FFF4F0"
                strokeWidth={0.4}
            />
            <Path
                d="M11.082 7.412a.527.527 0 001.055 0c0-.584-.302-1.145-.83-1.54-.359-.27-.802-.45-1.28-.527v-.57a.527.527 0 10-1.054 0v.57c-.479.077-.921.257-1.281.527-.527.395-.829.956-.829 1.54 0 .584.302 1.146.829 1.54.36.27.802.45 1.28.527v2.089c-.607-.148-1.054-.543-1.054-.992a.527.527 0 00-1.055 0c0 .584.302 1.146.829 1.54.36.27.802.45 1.28.527v.575a.527.527 0 001.055 0v-.575c.479-.076.921-.256 1.28-.526.528-.395.83-.957.83-1.54 0-.585-.302-1.146-.83-1.541-.359-.27-.802-.45-1.28-.527V6.42c.608.149 1.055.543 1.055.992zm-3.164 0c0-.449.447-.843 1.055-.992v1.984c-.608-.148-1.055-.543-1.055-.992zm3.164 3.164c0 .45-.447.844-1.055.992V9.584c.608.149 1.055.543 1.055.992z"
                fill={color ?? "#333"}
                stroke="#FFF4F0"
                strokeWidth={0.4}
            />
        </Svg>
    )
}

export { DollarIcon };
