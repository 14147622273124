import React from "react";
import { MB_Button } from "@workingu/rnw.components.button";
import { StyleSheet, Text, View } from "react-native";
import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import { COLORS } from "../../constants/colors";
import { textStyles } from "../../constants/textStyles";
import { useSignedInContext } from "../../context/SignedInContext";
import { TimerIcon } from "../../resources/svgComponents/TimerIcon";
import { MB_Modal } from "@workingu/rnw.components.modal";


type ComingSoonModalProps = {
    isVisible: boolean,
}

const ComingSoonModal = ({ isVisible }: ComingSoonModalProps) => {

    const { signOut } = useSignedInContext();

    return (
        <MB_Modal
            isVisible={isVisible}
            childrenWrapperStyle={[styles.childrenWrapper, styles.container]}
            withoutScrollView
            closeButtonProps={{ disabled: true }}
        >
            <View style={styles.icon}>
                <TimerIcon />
            </View>
            <Text style={styles.title}>Coming soon</Text>
            <Text style={styles.description}>W.U. is currently only supported for California licensed contractors. We’ll let you know as soon as we start supporting other states.</Text>
            <MB_Button
                title="Sign out"
                style={styles.signOutBtn}
                textStyle={styles.signOutBtnText}
                onPress={() => signOut()}
            />
        </MB_Modal>
    )
};

export { ComingSoonModal };

const styles = StyleSheet.create({
    childrenWrapper: {
        backgroundColor: COLORS.darkBackground,
        borderRadius: 0,
        marginBottom: 0,
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        width: '100%',
        alignItems: 'center',
        marginTop: 'auto',
    },
    container: {
        backgroundColor: COLORS.white,
        paddingHorizontal: 20,
        alignItems: 'stretch',
        paddingBottom: 32,
        paddingTop: 20,
    },
    icon: {
        alignItems: 'center',
    },
    title: mbTextStyles([textStyles.largeText, {
        fontSize: 21,
        color: COLORS.headerText,
        fontWeight: '800',
        marginTop: 21,
    }]),
    description: mbTextStyles([textStyles.smallerText, {
        color: COLORS.bodyText,
        marginTop: 3,
    }]),
    signOutBtn: {
        backgroundColor: COLORS.primaryColor,
        borderRadius: 30,
        height: 50,
        marginTop: 26,
    },
    signOutBtnText: {
        color: COLORS.white
    }
})
