import { z } from 'zod';
import { zodUtils } from './zodUtils';
import { UserRole } from '@wu/business';

export const ImageObj = z.object({
    _id: z.string().optional(),
    small: z.string().optional(),
    medium: z.string().optional(),
    large: z.string().optional(),
}).strict();

export const VideoObj = z.object({
    _id: z.string().optional(),
    thumbnail: ImageObj.optional(),
    url: z.string(),
    isProcessing: z.boolean(),
});

export const UserObj = z.object({
    id: z.string(),
    email: z.string(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    role: z.nativeEnum(UserRole),
}).strict();

export const AddressObj = z.object({
    lat: z.number(),
    lng: z.number(),
    text: z.string(),
    postalCode: z.string(),
    city: z.string(),
    country: z.string(),
    state: z.string(),
}).strict();


export const AccessTokenObj = z.object({
    expiresAt: z.string(),
    expiresAtMs: z.number(),
    expiresIn: z.string(),
    expiresInMs: z.number(),
    token: z.string(),
}).strict();

export const TokensObj = z.object({
    refreshToken: z.string(),
    accessToken: AccessTokenObj,
}).strict();

export const PasswordRecoveryTokenObj = z.object({
    token: z.string(),
    expiresAtMs: z.number(),
}).strict();

export const TradeObj = z.object({
    name: z.string(),
    description: z.string(),
})

export const UserObjPagination = zodUtils.getPagination(UserObj);


