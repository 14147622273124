import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import React from "react";
import { StyleProp, StyleSheet, Text, TouchableOpacity, View, ViewStyle } from "react-native";
import { COLORS } from "../../constants/colors";
import { textStyles } from "../../constants/textStyles";

import { ApplicationStatus, Employee, Job } from "@wu/business";
import { MB_Image, mbGetSmallestImage } from "@workingu/rnw.components.image";
import { imageUtils } from "../../utils/imageUtils";
import { Rating } from "./Rating";
import { ApplicationStatusText } from "./ApplicationStatusText";

interface ApplicantCardProps {
    employee: Employee;
    job: Job;
    style?: StyleProp<ViewStyle>
    onPress: () => void
    jobPrefix: string,
    status?: ApplicationStatus
}

const ApplicantCard = ({ employee, job, style, onPress, jobPrefix, status }: ApplicantCardProps) => {

    const profileImageUrl = mbGetSmallestImage(employee.profileImage) ?? imageUtils.images.emptyProfileIcon;
    const rating = employee.rating || 0

    return (
        <TouchableOpacity style={[styles.container, style]} onPress={onPress}>
            <View style={styles.header}>
                <MB_Image source={profileImageUrl} style={styles.profilePicture} resizeMode='contain' disableInteraction />
                <View style={styles.profileInfo}>
                    <Text style={styles.title}>{employee.firstName} {employee.lastName}</Text>
                    <View style={{flexDirection: 'row'}}>
                        <Text style={styles.profileReview}>{rating.toFixed(1)}</Text>
                        <Rating rating={rating}/>
                    </View>
                    <Text style={styles.profileExperience}>{employee.trades[0]}: {employee.experience} years</Text>
                </View>
                <View style={styles.rangeContainer}>
                    <Text style={styles.range}>${employee.hourlyRate[0]}-${employee.hourlyRate[1]}/hr</Text>
                </View>
            </View>
            <Text style={styles.description} numberOfLines={3}>{employee.about}</Text>
            <View style={styles.noteContainer}>
                <Text style={styles.note}>{jobPrefix} <Text style={styles.noteImportant} >“{job.title}”</Text></Text>
            </View>
            <ApplicationStatusText status={status} completed={job.completed} />              
        </TouchableOpacity>
    );
}



export { ApplicantCard };

const styles = StyleSheet.create({
    container: {
        paddingTop: 20,
        paddingBottom: 17,
        paddingLeft: 17,
        paddingRight: 15,
        borderRadius: 10,
        backgroundColor: COLORS.lightOrange,
        width: 302,
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    profilePicture: {
        width: 45,
        height: 45,
        marginEnd: 8,
        borderRadius: 360,
    },
    profileInfo: {
        flex: 1,
        marginEnd: 'auto',
    },
    title: mbTextStyles([textStyles.smallText, {
        color: COLORS.headerText,
        textAlign: 'left',
    }]),
    profileReview: mbTextStyles([textStyles.smallerText, {
        color: COLORS.bodyText,
        textAlign: 'left',
        paddingRight: 5
    }]),
    profileExperience: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.bodyText,
        textAlign: 'left',
    }]),
    rangeContainer: {
        paddingHorizontal: 10,
        paddingVertical: 7,
        borderRadius: 360,
        borderWidth: 1,
        borderColor: COLORS.primaryColor,
    },
    range: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.primaryColor,
        textAlign: 'left',
    }]),
    dateContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 6,
    },
    date: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.greyText,
        marginLeft: 7,
    }]),
    description: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.bodyText2,
        marginTop: 12,
        textAlign: 'left',
    }]),
    noteContainer: {
        marginTop: 19,
    },
    note: mbTextStyles([textStyles.smallerText, {
        fontSize: 11,
        color: COLORS.bodyText2,
        textAlign: 'left',
    }]),
    noteImportant: {
        color: COLORS.bodyText,
    }
})
