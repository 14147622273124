import { Notification, ReadNotificationRequest } from '@wu/business';

import { MB_InfinitePaginationPage } from '@workingu/rnw.utils.util-hooks';
import {
  UseInfiniteQueryOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { notificationsApiCalls } from '../apiCalls/notificationsApiCalls';
import { NOTIFICATIONS_QUERY_KEYS } from '../constants/constants';
import { ServerError } from '../typesAndInterfaces/typesAndInterfaces';

export const useGetNotifications = ({
  totalItemsPerPage,
  queryOptions,
}: {
  totalItemsPerPage: number;
  queryOptions?: UseInfiniteQueryOptions<MB_InfinitePaginationPage<Notification>, any>;
}) => {
  return useInfiniteQuery<MB_InfinitePaginationPage<Notification>, any>(
    [NOTIFICATIONS_QUERY_KEYS.getNotifications],
    async ({ pageParam }: { pageParam?: string }) =>
      notificationsApiCalls.getNotifications({
        totalItemsPerPage,
        cursor: pageParam,
        offset: pageParam,
      }),
    {
      refetchOnWindowFocus: false,
      getNextPageParam: (lastPage) => lastPage.nextOffset ?? undefined,
      ...queryOptions,
    }
  );
};

export const useReadNotification = () => {
  const queryClient = useQueryClient();
  return useMutation<Notification, ServerError, ReadNotificationRequest, unknown>(
    async (params) => notificationsApiCalls.markNotificationAsRead(params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([NOTIFICATIONS_QUERY_KEYS.getNotifications]);
        queryClient.invalidateQueries([NOTIFICATIONS_QUERY_KEYS.getNotificationsCount]);
      },
    }
  );
};

export const useReadAllNotification = () => {
  const queryClient = useQueryClient();
  return useMutation<void, ServerError, void, unknown>(
    async () => notificationsApiCalls.markAllNotificationAsRead(),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([NOTIFICATIONS_QUERY_KEYS.getNotifications]);
        queryClient.invalidateQueries([NOTIFICATIONS_QUERY_KEYS.getNotificationsCount]);
      },
    }
  );
};

export const useGetNotificationsCount = ({ queryOptions }: { queryOptions?: UseQueryOptions<number, any> }) => {
  return useQuery<number, any>(
    [NOTIFICATIONS_QUERY_KEYS.getNotificationsCount],
    async () => notificationsApiCalls.getNotificationsCount(),
    {
      refetchOnWindowFocus: false,
      ...queryOptions,
    }
  );
};
