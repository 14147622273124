import { mbApplyTransparency, mbTextStyles } from '@workingu/rnw.utils.style-utils';
import React, { useCallback, useMemo, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { COLORS } from '../../../../constants/colors';
import { textStyles } from '../../../../constants/textStyles';
import { ComponentWrapper } from '../../../helperComponents/componentWrapper/ComponentWrapper';
import { MB_Button } from '@workingu/rnw.components.button';
import { Dropdown } from '../../../helperComponents/Dropdown';
import { Slider } from '@miblanchard/react-native-slider';
import { Trades } from '../../../helperComponents/Trades';
import { EmployeeCareerInformationtProps } from '../../../../typesAndInterfaces/componentProps';
import { useCreateEmployeeData } from '../../../../hooks/userHooks';
import { mbShowPopUp } from '@workingu/rnw.components.pop-up';
import { STRING_CONSTANTS } from '../../../../constants/constants';
import { MB_TextInput } from '@workingu/rnw.components.text-input';
import { DocumentPicker } from '../../../helperComponents/DocumentPicker';
import { mbShowToast } from '@workingu/rnw.components.toast';
import { EmployeeExperience } from '@wu/business';

const EmployeeCareerInformation = ({ route }: EmployeeCareerInformationtProps) => {
  const { mutate: createEmployeeData, isLoading } = useCreateEmployeeData();
  const [resume, setResume] = useState<{ id: string; name: string; size: number } | undefined>(
    undefined,
  );

  const onFileSelect = useCallback(
    ({ id, fileName, fileSize }: { id: string; fileName: string; fileSize: number }) => {
      setResume({ id, name: fileName, size: fileSize });
    },
    [],
  );

  const [skills, setSkills] = useState('');
  const [hourlyRate, setHourlyRate] = useState([15, 25]);
  const [trades, setTrades] = useState<string[]>([]);
  const [references, setReferences] = useState('');

  const experiences = useMemo(
    () => Object.values(EmployeeExperience).map((experience) => `${experience} experience`),
    [],
  );
  const [experienceIndex, setExperienceIndex] = useState<number>();
  const isDisabled = !skills || !references || trades.length === 0 || experienceIndex === undefined;

  // slider logic for keeping the range within 10$
  const handleHourlyRateChange = (values) => {
    const [min, max] = values;
    const [prevMin, prevMax] = hourlyRate;

    if (min !== prevMin) {
      if (max - min > 10) {
        setHourlyRate([min, min + 10]);
      } else {
        setHourlyRate(values);
      }
      return;
    }
    if (max !== prevMax) {
      if (max - min > 10) {
        setHourlyRate([max - 10, max]);
      } else {
        setHourlyRate(values);
      }
      return;
    }
  };

  const onSubmit = useCallback(() => {
    const { aboutYou, phoneNumber, address } = route.params;

    if (hourlyRate[1] - hourlyRate[0] > 10) {
      mbShowPopUp({
        title: 'Error',
        message: 'Hourly rate minimum and maximum difference must not be greater than $10s',
      });
      return;
    }

    createEmployeeData(
      {
        experience: Object.values(EmployeeExperience)[experienceIndex as number],
        about: aboutYou,
        trades,
        skills,
        hourlyRate,
        mobileNumber: phoneNumber,
        address,
        fileId: route.params.profileImageFileId,
        resumeFileId: resume?.id ?? '',
        references,
      },
      {
        onSuccess: () => {
          mbShowToast({ text1: 'Success', text2: 'Sign up successful', position: 'bottom' });
        },
        onError: (error) => {
          mbShowPopUp({
            title: 'Error',
            message: error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN,
          });
        },
      },
    );
  }, [
    route.params,
    resume,
    hourlyRate,
    createEmployeeData,
    experienceIndex,
    trades,
    skills,
    references,
  ]);

  return (
    <ComponentWrapper
      mobileHeaderOptions={{ showHeader: true, showBackArrow: true }}
      mobileStickyFooter={
        <MB_Button
          title="Continue"
          style={styles.continueBtn}
          textStyle={textStyles.smallerText}
          disabled={isDisabled || isLoading}
          onPress={onSubmit}
          loading={isLoading}
        />
      }
      keyboardShouldPersistTapsForScrollView="handled"
    >
      <Text style={[textStyles.smallerText, styles.topNote]}>Step 2 of 2</Text>
      <Text style={[textStyles.largeText, styles.title]}>Career Information</Text>
      <Text style={[textStyles.smallerText, styles.subTitle]}>
        Share some information about your skills and experience.
      </Text>
      <Text style={styles.inputTitle}>
        Trades <Text style={styles.required}>*</Text>
      </Text>
      <Trades trades={trades} onChange={setTrades} />
      <Text style={styles.inputTitle}>
        Skills<Text style={styles.required}>*</Text>
      </Text>
      <MB_TextInput
        placeholder="Enter some of your skills..."
        multiline
        style={styles.multiline}
        maxLength={100}
        value={skills}
        onChangeText={setSkills}
        placeholderTextColor={COLORS.greyText}
      />
      <Text style={styles.inputTitle}>
        Years of Experience <Text style={styles.required}>*</Text>
      </Text>
      <Dropdown
        options={experiences}
        option={experienceIndex !== undefined ? experiences[experienceIndex] : undefined}
        onSelect={(_, index) => setExperienceIndex(index)}
      />
      <Text style={styles.inputTitle}>
        Hourly rate <Text style={styles.highlight}>(max range gap of $10)</Text>
        <Text style={styles.required}>*</Text>
      </Text>
      <View style={styles.slider}>
        <Slider
          value={hourlyRate}
          onValueChange={handleHourlyRateChange}
          minimumValue={15}
          maximumValue={50}
          trackStyle={{ backgroundColor: COLORS.lightOrange }}
          renderBelowThumbComponent={(index: number) => (
            <Text style={[textStyles.smallerText, { color: COLORS.bodyText, marginLeft: -10 }]}>
              ${hourlyRate[index]}
            </Text>
          )}
          renderThumbComponent={() => (
            <View
              style={{
                width: 20,
                height: 20,
                backgroundColor: COLORS.white,
                borderWidth: 4,
                borderRadius: 360,
                borderColor: COLORS.primaryColor,
              }}
            />
          )}
          step={5}
          maximumTrackTintColor={COLORS.primaryColor}
          minimumTrackTintColor={COLORS.primaryColor}
        />
      </View>
      <DocumentPicker
        containerStyle={styles.documentPickerContainer}
        selectedFile={resume}
        onFileSelect={onFileSelect}
        pageTitle="Upload Resume"
        title="Resume"
        fileDescriptorText="Upload Resume Document"
        required={false}
      />
      <Text style={styles.inputTitle}>
        References<Text style={styles.required}>*</Text>
      </Text>
      <MB_TextInput
        placeholder="List some of your references..."
        multiline
        style={styles.multiline}
        maxLength={100}
        value={references}
        onChangeText={setReferences}
        placeholderTextColor={COLORS.greyText}
      />
    </ComponentWrapper>
  );
};

export { EmployeeCareerInformation };

const styles = StyleSheet.create({
  topNote: {
    marginBottom: 7,
    color: COLORS.bluishGrey,
    textAlign: 'left',
  },
  title: {
    color: COLORS.headerText,
    textAlign: 'left',
    letterSpacing: -0.8,
  },
  subTitle: {
    color: COLORS.greyText,
    textAlign: 'left',
    marginBottom: 9,
  },
  profilePicture: {
    marginTop: 27,
    width: 98,
    height: 98,
    backgroundColor: COLORS.bluishGrey,
    alignSelf: 'center',
    borderRadius: 360,
    overflow: 'hidden',
  },
  emptyBackground: {
    backgroundColor: mbApplyTransparency(COLORS.black, 0.5),
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputTitle: mbTextStyles([
    textStyles.smallerText,
    {
      fontSize: 11,
      color: COLORS.bodyText,
      marginTop: 15,
      marginBottom: 7,
      textAlign: 'left',
    },
  ]),
  required: {
    color: COLORS.red,
  },
  multiline: {
    height: 100,
  },
  highlight: {
    color: COLORS.greyText,
  },
  slider: {
    marginBottom: 23,
  },
  continueBtn: {
    height: 50,
    backgroundColor: COLORS.primaryColor,
    borderRadius: 30,
    marginTop: 20,
    marginBottom: 38,
    marginHorizontal: 20,
  },
  documentPickerContainer: {
    marginTop: 15,
    alignSelf: 'stretch',
  },
});
