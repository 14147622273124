import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { COLORS } from "../../../../constants/colors";
import { textStyles } from "../../../../constants/textStyles";

type StatusType = 'Active'

const Status = ({ type }: { type: StatusType }) => {
    return (
        <View style={styles.status}>
            <Text style={styles.statusText}>{type}</Text>
        </View>
    )
}

export { Status };

const styles = StyleSheet.create({
    status: {
        flexDirection: 'row',
        paddingVertical: 3.46,
        paddingLeft: 10,
        paddingRight: 12,
        backgroundColor: COLORS.success_50,
        borderRadius: 13.85,
        alignItems: 'center',
    },
    dot: {
        borderRadius: 360,
        height: 5.2,
        width: 5.2,
        backgroundColor: COLORS.success_500,
        marginRight: 6,
    },
    statusText: mbTextStyles([
        textStyles.tinyText, {
            color: COLORS.success_700,
        }
    ])
})
