import { MB_Image } from '@workingu/rnw.components.image';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { COLORS } from '../../../constants/colors';
import { imageUtils } from '../../../utils/imageUtils';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { LoginForm } from './LoginForm';

const Login = () => {
    return (
        <ComponentWrapper hideWebHeader innerContainerStyle={styles.innerContainer} containerStyle={styles.container} disableLinearGradient>
            <View style={styles.content}>
                <MB_Image
                    source={imageUtils.images.wuLogoBlack}
                    style={styles.logo}
                    resizeMode="contain"
                    disableInteraction
                />
                <View style={styles.loginForm}>
                    <LoginForm onlyLogin isAdmin />
                </View>
            </View>
        </ComponentWrapper>
    );
};

export { Login };

const styles = StyleSheet.create({
    container: {
        backgroundColor: COLORS.greyBG,
    },
    innerContainer: {
        backgroundColor: COLORS.greyBG,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    content: {
        alignItems: 'center',
    },
    logo: {
        width: 94,
        height: 68,
    },
    loginForm: {
        width: 383,
        backgroundColor: COLORS.white,
        paddingHorizontal: 24,
        paddingVertical: 28,
        borderRadius: 7,
        marginTop: 32,
    },
});
