import { CreateFileRequest, CreateFileResponse, File } from '@wu/business';

import { UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';

import { fileApiCalls } from '../apiCalls/fileApiCalls';
import { FILE_QUERY_KEYS } from '../constants/constants';
import { ServerError } from '../typesAndInterfaces/typesAndInterfaces';

export const useCreateFile = () => {
  return useMutation<CreateFileResponse, ServerError, CreateFileRequest, unknown>(async (data) =>
    fileApiCalls.createFile(data)
  );
};

export const useGetFile = (queryOptions?: UseQueryOptions<File, ServerError>) => {
  return useQuery<File, ServerError>(
    [FILE_QUERY_KEYS.getFile],
    async () =>
      fileApiCalls.getFile({
        id: '123',
      }),
    queryOptions
  );
};

export const useUploadFile = () => {
  return useMutation<void, any, UploadFileVariables, unknown>(
    async ({ formData, uploadUrl, uploadProgress }) =>
      fileApiCalls.uploadFile({ formData, uploadUrl, uploadProgress })
  );
};

export type UploadProgressListener = (percentCompleted: number) => void;
export type UploadFileVariables = {
  formData: FormData;
  uploadUrl: string;
  uploadProgress?: UploadProgressListener;
};
