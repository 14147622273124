import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { UTIL_QUERY_KEYS } from '../constants/constants';
import {
    ServerError, Trade,
} from '../typesAndInterfaces/typesAndInterfaces';
import { utilApiCalls } from '../apiCalls/utilApiCalls';

export const useGetServerVersion = (queryOptions?: UseQueryOptions<string, ServerError>) => {
    return useQuery<string, ServerError>(
        [UTIL_QUERY_KEYS.serverVersion],
        async () => utilApiCalls.getVersion(),
        { staleTime: Infinity, ...queryOptions }
    );
};

export const useGetMinimumMobileJSVersion = (queryOptions?: UseQueryOptions<string, ServerError>) => {
    return useQuery<string, ServerError>(
        [UTIL_QUERY_KEYS.minimumMobileJSVersion],
        async () => utilApiCalls.getMinimumMobileJSVersion(),
        { staleTime: Infinity, ...queryOptions }
    );
};

export const useGetAllTrades = (queryOptions?: UseQueryOptions<Trade[], ServerError>) => {
    return useQuery<Trade[], ServerError>(
        [UTIL_QUERY_KEYS.getAllTrades],
        async () => utilApiCalls.getTrades(''),
        { staleTime: Infinity, ...queryOptions }
    );
}
