import React, { memo, useCallback, useMemo, useRef } from "react";
import { ComponentWrapper } from "../../helperComponents/componentWrapper/ComponentWrapper"
import { Dimensions, FlatList, ListRenderItemInfo, StyleSheet, Text, View } from "react-native";
import { textStyles } from "../../../constants/textStyles";
import { COLORS } from "../../../constants/colors";
import { MB_TabView } from "@workingu/rnw.components.tab-view";
import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import { Application, ApplicationStatus, Employee, Job } from "@wu/business";
import { SachelForEmptyTable } from "../../../resources/svgComponents/SachelForEmptyTable";
import MaterialIcons from "react-native-vector-icons/MaterialIcons";
import { ApplicantCard } from "../../helperComponents/ApplicantCard";
import { useContractorApplicants, useRecommendedEmployees } from "../../../hooks/userHooks";
import { useMBInfiniteFetch } from "@workingu/rnw.utils.util-hooks";
import { TOTAL_ITEMS_PER_PAGE } from "../../../typesAndInterfaces/typesAndInterfaces";
import { FlatListLoadingIndicator } from "../../helperComponents/FlatListLoadingIndicator";

import { useNavigation } from "@react-navigation/core";
import { UniversalScreenNavigationProp } from "../../../typesAndInterfaces/componentProps";

const HeaderEmployees = () => {
    return (
        <View style={styles.headerConatiner}>
            <MaterialIcons name="groups" size={22} color={COLORS.primaryColor} />
            <Text style={[textStyles.largeText, styles.headerTitle]}>Employees</Text>
        </View>
    );
}


type ApplicantsViewType = 'Recommended' | 'Applicants'
type ApplicantsViewData = {
    id?: string;
    employee: Employee;
    job: Job;
    status?: ApplicationStatus;
}


type ApplicantsViewProps = {
    data: (ApplicantsViewData | string)[];
    type: ApplicantsViewType
    loadMore: () => void
    isLoading: boolean;
    onPress?: (params: { applicationId?: string, job: Application['job'], employee: Application['employee'] }) => void;
}

const ApplicantsView = (props: ApplicantsViewProps) => {

    const { data, type, loadMore, isLoading, onPress } = props
    const ItemSeparatorComponent = useCallback(() => <View style={styles.separator} />, []);
    const keyExtractor = React.useMemo(() => (item: ApplicantsViewData | string) => {
        if (typeof item === 'string') {
            return item
        }

        const { employee, job, id } = item

        return id || `${employee.id}${job.id}`
    }, []);

    const jobPrefix = useMemo(() => type === 'Recommended' ? 'Might be a fit for your job' : 'Applied for your job', [type]);
    const RenderJobCard = useCallback(({ item }: ListRenderItemInfo<ApplicantsViewData | string>) => {

        if (typeof item === 'string') {
            // TODO: Render temp card cloadings
            return null;
        }

        return (
            <ApplicantCard
                onPress={() => onPress?.({ applicationId: item.id, job: item.job, employee: item.employee })}
                employee={item.employee}
                job={item.job}
                style={styles.jobCardContainer}
                jobPrefix={jobPrefix}
                status={item.status}
            />
        );
    }, [onPress, jobPrefix]);


    if (data.length === 0) {
        return (
            <View style={styles.emptyTableContainer}>
                <SachelForEmptyTable />
                <Text style={styles.emptyTableTitle}>{type === 'Recommended' ? 'No recommendations yet' : 'No applicants yet'}</Text>
                <Text style={styles.emptyTableSubtitle}>
                    {type === 'Recommended' ? 'There are currently no employees that match any of your jobs listed.' : 'There are currently no applications for any of your jobs listed.'}
                </Text>
            </View>
        );
    }


    return (
        <FlatList
            keyExtractor={keyExtractor}
            data={data}
            renderItem={RenderJobCard}
            ItemSeparatorComponent={ItemSeparatorComponent}
            contentContainerStyle={styles.contentList}
            onEndReached={loadMore}
            onEndReachedThreshold={0.3}
            ListFooterComponent={isLoading ? FlatListLoadingIndicator : null}
        />
    );

}

const ApplicantsList = memo(() => {

    const navigation = useNavigation<UniversalScreenNavigationProp>();

    const getContractorApplicants = useContractorApplicants({ totalItemsPerPage: TOTAL_ITEMS_PER_PAGE });
    const { data, loadMore, isLoading, isFetching } = useMBInfiniteFetch(getContractorApplicants, { prefillWhileLoading: true });

    return (
        <ApplicantsView
            data={data}
            isLoading={isLoading || isFetching}
            onPress={({ applicationId }) => navigation.navigate('ApplicationDetails', { applicationId: applicationId! })}
            loadMore={loadMore}
            type="Applicants"
        />
    )
})


const RecommendedList = memo(() => {

    const navigation = useNavigation<UniversalScreenNavigationProp>();

    const getRecommendedEmployees = useRecommendedEmployees({ totalItemsPerPage: TOTAL_ITEMS_PER_PAGE });
    const { data, loadMore, isLoading, isFetching } = useMBInfiniteFetch(getRecommendedEmployees, { prefillWhileLoading: true });

    return (
        <ApplicantsView
            data={data}
            isLoading={isLoading || isFetching}
            onPress={({ job, employee }) => navigation.navigate('RecommendedEmployeeDetails', { job, employee })}
            loadMore={loadMore}
            type="Recommended"
        />
    )
})


const Employees = () => {

    const tabData = useRef({
        titles: ['Recommended', 'Applicants'],
        screens: [
            () => <RecommendedList />,
            () => <ApplicantsList />
        ],
    });

    return (
        <ComponentWrapper
            containerStyle={styles.container}
            mobileHeaderOptions={{ showHeader: true, customHeader: <HeaderEmployees /> }}
            wrapInScrollView={false}
        >
            <MB_TabView
                titles={tabData.current.titles}
                screens={tabData.current.screens}
                tabLabelStyle={styles.tabLabel}
                tabIndicatorStyle={styles.tabIndicatorStyle}
                screenContainerStyle={styles.tabScreenContainer}
            />
        </ComponentWrapper>
    )
}

export { Employees };

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 0,
    },
    // HeaderApplied
    headerConatiner: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 33,
        marginBottom: 14,
        paddingHorizontal: 20,
    },
    headerTitle: {
        fontSize: 21,
        color: COLORS.headerText,
        marginStart: 8,
    },
    separator: {
        height: 12,
    },
    // content
    tabLabel: mbTextStyles([
        textStyles.smallText, {
            color: COLORS.greyText,
            fontSize: 13,
            textTransform: 'none'
        }
    ]),
    tabIndicatorStyle: {
        backgroundColor: COLORS.primaryColor,
        width: (Dimensions.get('screen').width / 2) - 40,
        marginHorizontal: 20,
    },
    tabScreenContainer: {
        borderTopColor: '#DADADA',
        borderTopWidth: 0.5,
    },
    jobCardContainer: {
        width: undefined,
    },
    contentList: {
        paddingTop: 20,
        paddingBottom: 100,
        paddingHorizontal: 20,
    },
    // form ContractorJobs
    emptyTableTitle: mbTextStyles([
        textStyles.normalText, {
            color: COLORS.bodyText,
            fontSize: 15,
            fontWeight: '700',
            marginTop: 12
        }
    ]),
    emptyTableSubtitle: mbTextStyles([
        textStyles.smallerText, {
            color: COLORS.bodyText2,
            marginTop: 6,
        }
    ]),
    emptyTableContainer: {
        width: '80%',
        flex: 1,
        alignItems: 'center',
        paddingTop: '40%',
        alignSelf: 'center'
    }
});
