import React, { useCallback } from "react";
import { MB_Modal } from "@workingu/rnw.components.modal";
import { StyleSheet, Text, View } from "react-native";
import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import { textStyles } from "../../constants/textStyles";
import { COLORS } from "../../constants/colors";
import { MB_Button } from "@workingu/rnw.components.button";
import Feather from "react-native-vector-icons/Feather";
import { Contractor, Employee, UserRole } from "@wu/business";
import { isMobile } from "@workingu/rnw.utils.device-info";
import { mbShowPopUp } from "@workingu/rnw.components.pop-up";
import { STRING_CONSTANTS } from "../../constants/constants";
import { useDeleteContractor, useDeleteEmployee } from "../../hooks/adminHooks";

interface DeleteUserModalProps<T extends UserRole.Contractor | UserRole.Employee> {
    isVisible: boolean,
    onDismiss?: () => void,
    user: T extends UserRole.Contractor ? Contractor : Employee,
    type: T
}

const DeleteUserModal = <T extends UserRole.Contractor | UserRole.Employee>({ isVisible, onDismiss, user, type }: DeleteUserModalProps<T>) => {

    const { mutate: deleteEmployee, isLoading: isDeletingEmployee} = useDeleteEmployee();
    const { mutate: deleteContractor, isLoading: isDeletingContractor } = useDeleteContractor();


    const name = type === UserRole.Contractor ? (user as Contractor).name : `${(user as Employee).firstName} ${(user as Employee).lastName}`;

    const deleteUser = type === UserRole.Contractor ? deleteContractor : deleteEmployee;

    const onDelete = useCallback(() => {
        deleteUser({ id: user.id }, {
            onSuccess: onDismiss,
            onError: (error) => {
                mbShowPopUp({
                    title: 'Error',
                    message: error.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN,
                });
            }
        })
    }, [deleteUser, onDismiss, user.id]);

    return (
        <MB_Modal
            isVisible={isVisible}
            onDismiss={onDismiss}
            childrenWrapperStyle={styles.container}
            closeButtonXProps={{ color: COLORS.greyText, size: 15 }}
        >
            <View style={styles.titleWrapper}>
                <Text style={styles.title}>Delete User</Text>
            </View>
            <Text style={styles.message}>Are you sure you want to delete “<Text style={styles.important}>{name}</Text>”? This action will permanently delete all of this user’s data.</Text>
            <MB_Button
                title="Delete user"
                leftElement={<Feather name="trash" size={20} color={COLORS.white} />}
                style={styles.btn}
                onPress={onDelete}
                disabled={isDeletingEmployee || isDeletingContractor}
                loading={isDeletingEmployee || isDeletingContractor}
            />
        </MB_Modal>
    )
};

export { DeleteUserModal };

const styles = StyleSheet.create({
    container: {
        backgroundColor: COLORS.white,
        paddingHorizontal: 23,
        alignItems: 'stretch',
        paddingBottom: 27,
        width: 432,
    },
    titleWrapper: {
        borderBottomWidth: 1,
        borderBottomColor: COLORS.lightGrey,
        position: 'absolute',
        left: 0,
        right: 0,
        top: isMobile ? 14 : 18,
        paddingBottom: isMobile ? 12 : 18,
        paddingHorizontal: isMobile ? 36 : 44,
        marginHorizontal: 23,
    },
    title: mbTextStyles([
        textStyles.normalText, {
            color: COLORS.bodyText,
            fontWeight: '700',
            fontSize: 21,
        },
    ]),
    message: mbTextStyles([textStyles.smallText, {
        textAlign: 'left',
        color: COLORS.bodyText,
        marginBottom: 22,
        marginTop: 30,
    }]),
    important: mbTextStyles([textStyles.smallText, {
        textAlign: 'left',
        color: COLORS.bodyText,
        fontWeight: '600',
    }]),
    btn: {
        backgroundColor: COLORS.red,
        borderRadius: 30,
    }
})
