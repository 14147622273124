import * as React from "react"
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg"

const PDFIcon = ({ size = 27 }: { size?: number }) => {
    return (
        <Svg
            width={size}
            height={size}
            viewBox="0 0 27 27"
            fill="none"
        >
            <G clipPath="url(#clip0_1693_13193)">
                <Path
                    d="M23.783 7.12l-5.537-1.583L16.664 0H5.59a2.373 2.373 0 00-2.373 2.373v22.254A2.373 2.373 0 005.59 27h15.82a2.373 2.373 0 002.373-2.373V7.119z"
                    fill="#D30000"
                />
                <Path
                    d="M23.783 7.12v17.507A2.373 2.373 0 0121.41 27H13.5V0h3.164l1.582 5.537 5.537 1.582z"
                    fill="#D30000"
                />
                <Path
                    d="M23.783 7.12h-5.537c-.87 0-1.582-.713-1.582-1.583V0c.206 0 .411.08.554.237l6.328 6.328a.742.742 0 01.237.554z"
                    fill="#F48382"
                />
                <G clipPath="url(#clip1_1693_13193)">
                    <Path
                        d="M17.068 17.714c-.702 0-1.58.123-1.867.166-1.19-1.242-1.528-1.948-1.604-2.136a8.34 8.34 0 00.513-2.565c.025-.647-.111-1.131-.406-1.438a1.07 1.07 0 00-.753-.33c-.359 0-.96.182-.96 1.395 0 1.053.491 2.17.627 2.459-.715 2.081-1.483 3.506-1.646 3.8-2.872 1.08-3.097 2.127-3.097 2.423 0 .533.38.85 1.015.85 1.543 0 2.952-2.591 3.185-3.041 1.096-.437 2.564-.707 2.937-.772 1.07 1.02 2.307 1.291 2.82 1.291.387 0 1.293 0 1.293-.93 0-.865-1.108-1.172-2.057-1.172zm-.074.611c.833 0 1.054.276 1.054.422 0 .091-.035.39-.482.39-.4 0-1.092-.232-1.773-.734a9.309 9.309 0 011.2-.078zm-4.087-6.321c.076 0 .126.024.167.082.24.332.046 1.42-.189 2.27-.227-.73-.397-1.848-.158-2.242.047-.077.1-.11.18-.11zm-.405 6.512c.302-.61.64-1.498.824-2 .37.616.865 1.19 1.152 1.5-.893.189-1.569.377-1.976.5zM8.475 21.57c-.02-.023-.023-.073-.008-.133.031-.125.271-.745 2.008-1.522-.248.392-.637.952-1.064 1.37-.301.282-.535.424-.696.424-.058 0-.137-.016-.24-.139z"
                        fill="#fff"
                    />
                </G>
            </G>
            <Defs>
                <ClipPath id="clip0_1693_13193">
                    <Path fill="#fff" d="M0 0H27V27H0z" />
                </ClipPath>
                <ClipPath id="clip1_1693_13193">
                    <Path
                        fill="#fff"
                        transform="translate(7.875 11.25)"
                        d="M0 0H11.25V11.25H0z"
                    />
                </ClipPath>
            </Defs>
        </Svg>
    )
}

export { PDFIcon }
