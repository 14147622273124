import React, { ReactNode, useCallback, useRef, useState } from 'react';
import { useNavigation } from '@react-navigation/core';
import { View, StyleSheet, Text, StyleProp, ViewStyle, Pressable } from 'react-native';
import { UniversalScreenNavigationProp } from '../../../typesAndInterfaces/componentProps';
import { utils } from '../../../utils/utils';
import { useSignedInContext } from '../../../context/SignedInContext';
import { COLORS } from '../../../constants/colors';
import { textStyles } from '../../../constants/textStyles';
import { mbApplyTransparency, mbTextStyles, mbPlatformStyle, mbShadow } from '@workingu/rnw.utils.style-utils';
import { MB_OnHoverWrapper } from '@workingu/rnw.components.on-hover-wrapper';
import { MB_AnimatedChevron, MB_AnimatedChevronRef } from '@workingu/rnw.components.animated-chevron';
import { utilHooks } from '../../../hooks/utilHooks';
import { ModalDropdown } from '@workingu/rnw.components.modal-dropdown';
import { ProfileIcon } from '../ProfileIcon';


const Header = React.memo(({ containerStyle, children }: { containerStyle?: StyleProp<ViewStyle>, children?: ReactNode }) => {
    const [isMenuDropdownVisible, setIsMenuDropdownVisible] = useState(false);

    const { isSignedIn, signOut, currentUserData } = useSignedInContext();

    utilHooks.useWidthChange(isMenuDropdownVisible, useCallback(() => modalDropdownRef.current?.hide(), []));

    const navigation = useNavigation<UniversalScreenNavigationProp>();

    const modalDropdownRef = useRef<ModalDropdown>(null);

    let menuItems: string[] = ['Logout'];

    const foundOutSourceChevronRef = useRef<MB_AnimatedChevronRef>(null);

    const onOptionSelected = (index: number) => {
        switch (menuItems[index]) {
            case 'Logout':
                utils.showLogOutPopUp(signOut);
                break;
        }
    };

    const onLoginClick = () => {
        navigation.navigate('Login');
    };

    return (
        <View>
            <View style={[styles.container, containerStyle]}>
                <View style={{ paddingVertical: 4, minWidth: 450, flex: 1, flexDirection: 'row' }}>
                    {children}
                </View>
                {isSignedIn ?
                    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                        <ModalDropdown
                            ref={modalDropdownRef}
                            options={menuItems}
                            onDropdownWillHide={() => {
                                setIsMenuDropdownVisible(false);
                                foundOutSourceChevronRef.current?.setToInitialRotation();
                            }}
                            onDropdownWillShow={() => {
                                setIsMenuDropdownVisible(true);
                                foundOutSourceChevronRef.current?.setToFinalRotation();
                            }}
                            dropdownStyle={styles.profileIconDropdown}
                            renderSeparator={() => null}
                            renderRow={(option: any, index: any) => {
                                return (
                                    <View>
                                        <MB_OnHoverWrapper
                                            wrapperStyle={{
                                                paddingVertical: 9,
                                                paddingHorizontal: 10,
                                                marginTop: Number(index) === 0 ? 15 : 0,
                                                marginBottom: Number(index) === menuItems.length - 1 ? 15 : 0,
                                                borderRadius: 8,
                                            }}
                                            onHoverWrapperStyleModifier={{
                                                backgroundColor: "#FFF4F0",
                                            }}
                                            onPress={() => {
                                                onOptionSelected(Number(index));
                                                modalDropdownRef.current?.hide();
                                            }}
                                        >
                                            {({ hovered }) => <Text style={[textStyles.normalText, { textAlign: 'left', color: hovered ? COLORS.primaryColor : COLORS.greyText }]}>{option}</Text>}
                                        </MB_OnHoverWrapper>
                                    </View>
                                );
                            }}
                        >
                            <View style={styles.profileIconContainer}>
                                <ProfileIcon image={undefined} firstName={currentUserData?.firstName} lastName={currentUserData?.lastName} fontStyle={{ fontSize: 16 }} />
                                <Text style={[textStyles.normalText, { marginHorizontal: 4, fontSize: 18, color: COLORS.greyText }]}>{currentUserData?.firstName}</Text>
                                <MB_AnimatedChevron chevronColor={COLORS.greyText} ref={foundOutSourceChevronRef} chevronSize={14} />
                            </View>
                        </ModalDropdown>
                    </View>
                    :
                    <Pressable style={styles.loginButton} onPress={onLoginClick} >
                        <Text style={styles.loginButtonText} >Login</Text>
                    </Pressable>}
            </View>
        </View>
    );
});

export { Header };

const styles = StyleSheet.create({
    container: {
        alignSelf: 'stretch',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingVertical: 16.5,
        marginBottom: 30,
        backgroundColor: COLORS.white,
        paddingRight: 35,
        ...mbShadow({
            elevation: 3,
            color: '#000000',
            offsetWidth: -4,
            offsetHeight: 2,
            opacity: 0.03,
            radius: 7,
        })
    },
    profileIconContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginStart: 16,
    },
    profileIconDropdown: StyleSheet.flatten([
        mbShadow({ elevation: 3, color: COLORS.black, opacity: 0.45, radius: 15, offsetWidth: 2, offsetHeight: 2 }), {
            width: 277,
            height: 'auto',
            elevation: 5,
            backgroundColor: COLORS.white,
            borderRadius: 10,
            borderWidth: 0,
            overflow: 'hidden',
            paddingHorizontal: 16,
        },
    ]),
    loginButton: {
        backgroundColor: mbApplyTransparency(COLORS.white, 0.15),
        width: 99,
        height: 40,
        justifyContent: 'center',
        borderRadius: 6,
    },
    loginButtonText: mbTextStyles([
        textStyles.normalText, {
            fontWeight: '400',
            ...mbPlatformStyle({
                web: {
                    cursor: 'pointer',
                },
            }),
        },
    ]),
});
