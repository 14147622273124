import React, { useCallback, useState } from "react";
import { FlatList, StyleSheet, Text, View } from "react-native";
import { COLORS } from "../../constants/colors";
import { textStyles } from "../../constants/textStyles";
import { ComponentWrapper } from "../helperComponents/componentWrapper/ComponentWrapper";
import { SearchInputFilter } from "../helperComponents/SearchInputFilter";
import { useRenderJobs } from "./HomeFlow/Explore/renderJobs";
import { ViewAllProps } from "../../typesAndInterfaces/componentProps";
import { UseInfiniteQueryResult } from "@tanstack/react-query";
import { useGetAllJobs, useGetRecommendedJobs } from "../../hooks/jobsHooks";
import { Job, JobsRequest, StringBoolean } from "@wu/business";
import { MB_InfinitePaginationPage, useMBInfiniteFetch } from "@workingu/rnw.utils.util-hooks";
import { FlatListLoadingIndicator } from "../helperComponents/FlatListLoadingIndicator";

export type ViewAllType = "Recommended" | "All"

const useGetData = (type: ViewAllType): (params: { totalItemsPerPage: number; completed: StringBoolean, filter?: JobsRequest['filter'] }) => UseInfiniteQueryResult<MB_InfinitePaginationPage<Job>> => {
    if (type === 'Recommended') {
        return useGetRecommendedJobs;
    }
    return useGetAllJobs;
}

const ViewAll = ({ route }: ViewAllProps) => {

    const type = route.params.type;

    const { renderJobs } = useRenderJobs();
    const [filter, setFilter] = useState<JobsRequest['filter']>();

    const getJobs = useGetData(type)({ totalItemsPerPage: 10, completed: "false", filter });
    const { data: jobs, isFetching, isLoading, loadMore } = useMBInfiniteFetch(getJobs, { prefillWhileLoading: true });

    const ItemSeparatorComponent = useCallback(() => <View style={styles.separator} />, []);

    const keyExtractor = React.useMemo(() => (item: Job | string) => typeof item === 'string' ? item : item.id, []);

    return (
        <ComponentWrapper mobileHeaderOptions={{ showHeader: true, showBackArrow: true }} wrapInScrollView={false} >
            <Text style={[textStyles.largeText, styles.title]}>{type === "Recommended" ? "Recommended For You" : "All Jobs"}</Text>
            <Text style={[textStyles.smallerText, styles.subTitle]}>{type === "Recommended" ? "Based on your profile information." : "All our job postings"}</Text>
            {type === "All" &&
                <SearchInputFilter style={styles.searchInputFilter} filter={filter} onFilterChange={setFilter} />
            }
            <FlatList
                data={jobs}
                renderItem={renderJobs}
                ItemSeparatorComponent={ItemSeparatorComponent}
                keyExtractor={keyExtractor}
                contentContainerStyle={styles.flatList}
                ListFooterComponent={isFetching || isLoading ? FlatListLoadingIndicator : null}
                onEndReached={loadMore}
                onEndReachedThreshold={0.3}
            />
        </ComponentWrapper>
    )
}

export { ViewAll };

const styles = StyleSheet.create({
    title: {
        color: COLORS.headerText,
        textAlign: 'left',
        letterSpacing: -0.8,
    },
    subTitle: {
        color: COLORS.greyText,
        textAlign: 'left',
        marginBottom: 22,
    },
    job: {
        width: 'auto',
    },
    separator: {
        height: 12,
    },
    searchInputFilter: {
        marginBottom: 22,
        zIndex: 2,
    },
    flatList: {
        paddingBottom: 54,
    }
});
