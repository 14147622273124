import { MB_Modal } from "@workingu/rnw.components.modal";
import { MB_TextInput } from "@workingu/rnw.components.text-input";
import React, { useCallback, useEffect, useState } from "react";
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native";
import Feather from "react-native-vector-icons/Feather";
import { COLORS } from "../../constants/colors";
import { FONT_STYLES, textStyles } from "../../constants/textStyles";
import { format as formatDate } from "date-fns";
import { MB_Button } from "@workingu/rnw.components.button";
import { mbTextStyles } from "@workingu/rnw.utils.style-utils";
import { MB_Calendar } from "@workingu/rnw.components.calendar";
import { MB_utilHooks } from "@workingu/rnw.utils.util-hooks";

interface DateInputProps {
    date?: Date,
    minDate?: Date,
    onDateChange?: (date: Date) => void,
    containerStyle?: StyleProp<ViewStyle>,
}

interface CalendarModalProps {
    initialDate: number | undefined,
    isVisible: boolean,
    onDismiss: () => void,
    onDateChanged: (newDate: number | undefined) => void,
    minDate?: Date,
}

const CalendarModal = ({ initialDate, isVisible, onDismiss, onDateChanged, minDate }: CalendarModalProps) => {
    const [calendarDate, setCalendarDate] = useState<number | undefined>(initialDate);

    useEffect(() => {
        if (isVisible && initialDate) {
            setCalendarDate(initialDate);
        }
    }, [initialDate, isVisible])

    return (
        <MB_Modal
            isVisible={isVisible}
            hideCloseButton
            childrenWrapperStyle={{ backgroundColor: COLORS.white, paddingTop: 16, height: 450 }}
            onDismiss={onDismiss}
        >
            <MB_Calendar
                onDayChanged={(newDate) => {
                    const finalizedDate = new Date(newDate.dateString.replace(/-/g, '/'));
                    setCalendarDate(finalizedDate.getTime())
                }}
                initialSelectedDate={initialDate ? formatDate(initialDate, 'yyyy-MM-dd') : undefined}
                minDate={minDate}
                selectedDateTextStyle={{ color: COLORS.white }}
                selectedDateContainerStyle={{ backgroundColor: COLORS.primaryColor, borderRadius: 100 }}
                arrowContainerStyle={styles.arrowContainer}
                arrowColor={COLORS.primaryColor}
                headerTextStyle={styles.calendarHeaderText}
                theme={{
                    calendarBackground: 'transparent',
                    dayTextColor: COLORS.bodyText,
                    textDayFontFamily: FONT_STYLES.Manrope_500,
                    textDayFontSize: 13,
                    textDayFontWeight: '400',
                    selectedDayTextColor: COLORS.white,
                    textDisabledColor: COLORS.lightGrey,
                    arrowWidth: 20,
                    arrowHeight: 20,
                    arrowStyle: {
                        paddingHorizontal: 0,
                        paddingVertical: 0,
                        paddingBottom: 27,
                    }
                }}
            />


            <View style={styles.buttonContainer}>
                <MB_Button
                    title="Cancel"
                    style={styles.cancelBtn}
                    textStyle={[textStyles.smallerText, { color: COLORS.primaryColor }]}
                    onPress={onDismiss}
                />
                <MB_Button
                    title="Done"
                    style={styles.doneBtn}
                    textStyle={textStyles.smallerText}
                    onPress={() => onDateChanged(calendarDate)}
                    disabled={calendarDate === undefined}
                />
            </View>
        </MB_Modal>
    );
}

const DateInput = ({ date, minDate, onDateChange, containerStyle }: DateInputProps) => {

    const [selectDate, setSelectedDate] = useState<number | undefined>(date?.getTime() ?? undefined);
    const [isCalendarVisible, showCalendar, hideCalendar] = MB_utilHooks.useBool();

    const onDateChanged = useCallback((newDate: number | undefined) => {
        if (newDate !== undefined) {
            setSelectedDate(newDate)
            onDateChange?.(new Date(newDate));
        }
        hideCalendar();
    }, [hideCalendar, onDateChange])

    return (
        <View style={[styles.container, containerStyle]}>

            <CalendarModal
                initialDate={selectDate}
                isVisible={isCalendarVisible}
                onDismiss={hideCalendar}
                onDateChanged={onDateChanged}
                minDate={minDate}
            />

            <MB_TextInput
                placeholder="-Select-"
                style={[styles.textInput]}
                placeholderTextColor={COLORS.greyText}
                textInputStyle={[textStyles.smallerText, styles.textInputText]}
                value={selectDate ? formatDate(selectDate, 'MM/dd/yyyy') : undefined}
                disable
            />
            <Feather name="calendar" size={20} style={styles.calendar} color={COLORS.greyText} />

            <TouchableOpacity
                style={styles.touchableOpacityStyles}
                onPress={showCalendar}
                activeOpacity={1}
            />
        </View>
    )
}

export { DateInput };

const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
    },
    textInput: {
        width: '100%',
        height: 48,
        borderWidth: 0.5,
        borderColor: '#C4C4C4',
        borderRadius: 5,
        backgroundColor: COLORS.white,
    },
    textInputText: {
        fontSize: 11,
        color: COLORS.bodyText,
        textAlign: 'left',
    },
    calendar: {
        position: 'absolute',
        right: 17.25,
    },
    headerTitle: mbTextStyles([textStyles.smallText, {
        fontSize: 13,
        color: COLORS.headerText,
        paddingBottom: 27,
    }]),
    arrowContainer: {
        borderRadius: 300,
        backgroundColor: '#FFF4F0',
        alignItems: 'center',
        justifyContent: 'center',
    },
    doneBtn: {
        height: 50,
        backgroundColor: COLORS.primaryColor,
        borderRadius: 30,
        flex: 1,
        marginHorizontal: 4,
    },
    cancelBtn: {
        height: 50,
        backgroundColor: 'transparent',
        borderRadius: 30,
        flex: 1,
        borderWidth: 1,
        borderColor: COLORS.primaryColor,
        marginHorizontal: 4,
    },
    selectedDayContainerStyle: {
        borderRadius: 360,
        alignItems: 'center',
        justifyContent: 'center',
        width: 32,
        height: 32,
        backgroundColor: COLORS.primaryColor,
    },
    selectedDayText: mbTextStyles([textStyles.smallText, {
        fontSize: 13,
    }]),
    touchableOpacityStyles: {
        position: 'absolute',
        width: '100%',
        height: '100%',
    },
    calendarHeaderText: mbTextStyles([
        textStyles.normalText, {
            fontSize: 13,
            color: COLORS.bodyText,
        },
    ]),
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginTop: 'auto',
        alignSelf: 'stretch',
    }
});
